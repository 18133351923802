<div class="row bg-brown mb-3 py-2">
    <div class="col-lg-6 col-md-12">
        <h2 class="`text-default">{{email}}</h2>
    </div>
    <div class="col-lg-6 col-md-12">
        <div class="text-right">
            <a routerLink="../admin-edit" class="btn btn-outline-secondary rounded-pill nav-button mt-2">Edit Admin
                User</a>
            <button type="button" (click)="deleteUser()"
                class="btn btn-outline-secondary rounded-pill nav-button ml-1 mt-2">Delete Admin User</button>
        </div>
    </div>
</div>
<div class="container">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink>Account</a>
            <ng-template ngbNavContent>
                <app-account></app-account>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Assignments</a>
            <ng-template ngbNavContent>
                <app-assignments></app-assignments>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-1"></div>
    <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
</div>