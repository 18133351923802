<div class="container mt-2">
  <h2 class="`text-default">Edit Meta Group</h2>
  <form [formGroup]="frmMetagroup" (ngSubmit)="submit()">
    <div class="card">
      <div class="card-header">
        Meta Group details
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="col-md-2">
            <label for="url">Url</label>
          </div>
          <div class="col-md-10">
            <input type="url" class="form-control" id="url" formControlName="url" placeholder="Enter url" required>
            <div *ngIf="frmMetagroup.controls['url'].invalid && frmMetagroup.controls['url'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmMetagroup.controls['url'].hasError('required')">
                Please enter url.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="title">Title</label>
          </div>
          <div class="col-md-10">
            <input type="text" class="form-control" id="title" formControlName="title" placeholder="Enter title"
              required>
            <div *ngIf="frmMetagroup.controls['title'].invalid && frmMetagroup.controls['title'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmMetagroup.controls['title'].hasError('required')">
                Please enter title.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="description">Description</label>
          </div>
          <div class="col-md-10">
            <input type="text" class="form-control" id="description" formControlName="description"
              placeholder="Enter description" required>
            <div *ngIf="frmMetagroup.controls['description'].invalid && frmMetagroup.controls['description'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmMetagroup.controls['description'].hasError('required')">
                Please enter description.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="imageUrl">Image url</label>
          </div>
          <div class="col-md-10">
            <input type="text" class="form-control" id="imageUrl" formControlName="imageUrl"
              placeholder="Enter image url">
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row m-3">
      <button type="submit" [disabled]="!frmMetagroup.valid" class="btn btn-primary">Update Meta Group
      </button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
  </form>
</div>