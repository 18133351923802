import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class AmenitycategoriesService extends BaseService {

  httpOptions: any;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }

  viewAmenitiesCategories(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'Amenity/amenityCategory/' + id + '', this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAmenitiesCategories() {
    return this.http.get(this.configService.resourceApiURI + 'Amenity/categories', this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchAmenityCategories(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'Amenity/amenitycategoryfilter', data, this.httpOptions).pipe(catchError(this.handleError));
  }

  addAmenityCategory(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'amenity/addAmenityCateg', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  updateAmenitycategory(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'amenity/EditAmenityCategory', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  AmenityCategoriesDelete(ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'amenity/RemoveAmenityCategory', options).pipe(
      catchError(this.handleError)
    );
  }

  AmenityCategoriesFilters(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'amenity/amenitycategoryfilter', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  fetchAmenityCategoriesDataForExport() {
    return this.http.get(this.configService.resourceApiURI + 'Amenity/categories-data-export', this.httpOptions).pipe(catchError(this.handleError));
  }
}
