
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { BankAccountService } from 'src/app/services/bank-account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.css']
})
export class PayoutsComponent implements OnInit {

  payouts: any;

  id: number = 0;
  frmFilter: FormGroup;

  orderColumn: string = "PayoutDate"
  orderDirection: string = "DESC"
  constructor(private bankService: BankAccountService, private route: ActivatedRoute, private tostr: ToastrService,
    private modalService: NgbModal, private fb: FormBuilder) {
    this.frmFilter = this.fb.group({
      bookingId: [],
      status: [''],
      payoutDate: ['']
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.getPayouts();

  }
  processPayout(id: number) {
    console.log(id);

    this.bankService.processPayout(id).pipe(finalize(() => {
    })).subscribe(
      res => {
        let result: any;
        result = res;
        console.log(res)
        if (result.isSuccess) {
          this.tostr.success("Success");
        }
        else {

          this.tostr.error(result.message);
        }
        this.getPayouts();
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }
  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = 'ASC';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getPayouts();
  }
  getPayouts() {
    var request = {
      // page: this.page,
      //pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      //type: this.type,
      id: this.frmFilter.controls["bookingId"].value,
      payoutDate: this.frmFilter.controls["payoutDate"].value,
      status: this.frmFilter.controls["status"].value
    };
    this.bankService.fetchPostPayouts(request).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.payouts = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    this.getPayouts();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      bookingId: null,
      status: "",
      payoutDate: ""
    });
    this.modalService.dismissAll('submit');
    this.getPayouts();
  }
}