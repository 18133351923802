<div class="container mt-2">
    <h2 class="`text-default">New library photo</h2>
    <form [formGroup]="frmPhoto" (submit)="submit()">
        <div class="card">
            <div class="card-header">
                Library photo details
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="model">Boat model</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" id="model" formControlName="model" required>
                        <div *ngIf="frmPhoto.controls['model'].invalid && frmPhoto.controls['model'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmPhoto.controls['model'].hasError('required')">
                                Please enter boat model.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="type">Boat type</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" id="type" formControlName="type" required>
                        <div *ngIf="frmPhoto.controls['type'].invalid && frmPhoto.controls['type'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmPhoto.controls['type'].hasError('required')">
                                Please enter boat type.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="fileUrl">Image </label>
                    </div>
                    <div class="col-md-10">
                        <span *ngIf="fileUrl != ''">
                            <img src="{{fileUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
                        </span>
                        <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadFile()">Upload</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row m-3">
            <button type="submit" [disabled]="!frmPhoto.valid" class="btn btn-primary">Create Library</button>
            <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
        </div>
    </form>
</div>