import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { BoatLibraryService } from 'src/app/services/boat-library.service';

@Component({
  selector: 'app-library-view',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.css']
})
export class LibraryViewComponent implements OnInit {
  boatLibraryDetails: any;
  id: number = 0;
  public resourceType: string = "";
  public resourceId: string = "";
  constructor(private activatedRoute: ActivatedRoute, private boatLibraryService: BoatLibraryService,
    private router: Router, private tostr: ToastrService, private clipBoardService: ClipboardService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.resourceId = this.id.toString();
    this.resourceType = "Boat Library";

    this.boatLibraryService.fetchBoatLibrary(this.id).subscribe(
      response => {
        this.boatLibraryDetails = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  delete(id: number) {
    var isDelete = confirm("are you sure want to delete selected record?");
    if (isDelete) {
      this.boatLibraryService.delete([id])
        .subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.router.navigate(["admin/library"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  copy(text: string) {
    this.clipBoardService.copy(text);
  }
}
