import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { PoEditorService } from 'src/app/services/cms services/po-Editors/po-editor.service';
import { CategoryService } from 'src/app/services/cms services/category/category.service';

@Component({
  selector: 'app-category-new',
  templateUrl: './category-new.component.html',
  styleUrls: ['./category-new.component.css']
})

export class CategoryNewComponent implements OnInit {
  frmCategory: FormGroup;
  poEditorsLanguages: any;

  constructor(private fb: FormBuilder, private router: Router, private tostr: ToastrService,
    private poEditorService: PoEditorService, private categoryService: CategoryService) {
    this.frmCategory = this.fb.group({
      language: ['', [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.poEditorLanguages();

  }

  poEditorLanguages() {
    this.categoryService.fetchPoEditorLanguages()
      .subscribe(
        response => {
          this.poEditorsLanguages = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while fetching languages, please try again.");
        }
      );
  }

 
  reset() {
    this.router.navigate(["admin/categories"]);
  }

  submit() {
    if (this.frmCategory.valid) {
      var request = {
        languageCode: this.frmCategory.controls["language"].value,
        categoryName: this.frmCategory.controls["name"].value,
      }; 
      this.categoryService.SaveCategory(request)
        .subscribe(
          response => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/categories']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          }
        );
    }
  }

}
