import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReferralService } from 'src/app/services/referral.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-referrals',
  templateUrl: './user-referrals.component.html',
  styleUrls: ['./user-referrals.component.css']
})
export class UserReferralsComponent implements OnInit {
  @Input() name;
  id: number = 0;
  referals: any;

  constructor(private referralService: ReferralService, private route: ActivatedRoute, private tostr: ToastrService) {
    this.route.queryParams.subscribe(param => { this.id = param.id });
  }

  ngOnInit(): void {
    this.referralService.fetchUserRefferal(this.id).subscribe(
      res => {
        this.referals = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }
}
