import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ConversationsService } from 'src/app/services/conversations.service';
import { CommonProvider } from 'src/app/shared/modals/common-provider';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-conversation-edit',
  templateUrl: './conversation-edit.component.html',
  styleUrls: ['./conversation-edit.component.css']
})
export class ConversationEditComponent implements OnInit {
  id: number = 0;
  assignees: any;
  followUpTaskList: any;
  frmConversation: FormGroup;

  constructor(private router: Router, private commonService: CommonService,
    private common: CommonProvider, private conversationService: ConversationsService
    , private route: ActivatedRoute, private fb: FormBuilder
    , private tostr: ToastrService) {
    this.frmConversation = this.fb.group({
      followUpTask: [''],
      followUpStatus: [''],
      followUpDate: [''],
      assignee: ['']
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => { this.id = params.id });
    // this.getAssignees();
    this.followUpTaskList = this.common.getFollowUpTask();
  }

  getAssignees() {
    this.commonService.fetchAssignees().subscribe(
      res => {
        this.assignees = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  submit() {
    var followUpRequest = {
      id: this.id,
      followUpTask: this.frmConversation.controls["followUpTask"].value,
      followUpStatus: this.frmConversation.controls["followUpStatus"].value,
      followUpDate: this.frmConversation.controls["followUpDate"].value,
      // assigneeId: this.frmConversation.controls["assignee"].value
    };
    this.conversationService.updateConversationfollowUp(followUpRequest).subscribe(
      res => {
        this.tostr.success(res.toString());
        this.router.navigate(["admin/conversations"]);
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  reset() {
    this.frmConversation.reset();
    this.router.navigate(["admin/conversations"]);
  }

}
