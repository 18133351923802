<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>BM Info</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="boatDetails!= null && boatDetails != undefined">
                    <tr>
                        <td class="bt-none">BOOKING MANAGER</td>
                        <td class="bt-none">
                            <a *ngIf="boatDetails.bookingManagerId != null && boatDetails.bookingManagerId != '' && boatDetails.bookingManagerCompanyId != null && boatDetails.stripbookingManagerCompanyIdePayoutId != ''"
                                href="https://portal.booking-manager.com/wbm2/page.html?view=YachtDetails&companyid={{boatDetails.bookingManagerCompanyId}}&yachtId={{boatDetails.bookingManagerId}}&addMargins=true&setlang=en">
                                {{boatDetails.bookingManagerId}}</a>
                        </td>
                    </tr>
                    <tr>
                        <td>BOOKING MANAGER<br />COMMISSION PERCENTAGE</td>
                        <td>
                            {{boatDetails.bookingManagerCompanyCommissionPer}}
                        </td>
                    </tr>
                    <tr>
                        <td>BOOKING MANGER<br />COMPANY</td>
                        <td>
                            <a *ngIf="boatDetails.bookingManagerCompanyId != null && boatDetails.stripbookingManagerCompanyIdePayoutId != ''"
                                href="https://portal.booking-manager.com/wbm2/app/my_partners/?partner_id={{boatDetails.bookingManagerCompanyId}}">
                                {{boatDetails.bookingManagerCompanyId}}</a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>