<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <h2 class=" mt-2">Referrals</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9">
            <button class="btn btn-outline-secondary mt-2" (click)="getAccepted()">Accepted({{accepted}})</button>
            <button class="btn btn-outline-secondary mt-2 ml-2"
                (click)="getUnaccepted()">Unaccepted({{unaccepted}})</button>
        </div>
        <div class="col-md-2">
            <button class="btn btn-secondary float-right" (click)="new()">New Referral</button>
        </div>
        <div class="col-md-1">
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('Email')" style="cursor: pointer;">Email</a>
                            <th>
                                Accepted
                            </th>
                            <th>
                                Invited By
                            </th>
                            <th>
                                <a (click)="toggleSort('InvitationAcceptedAt')" style="cursor: pointer;">Invitation
                                    Accepted At</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('InvitationSentAt')" style="cursor: pointer;">Invitation Sent
                                    At</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="referralListDetails != undefined  && referralListDetails.totalEntries > 0">
                        <tr
                            *ngFor="let ref of referralListDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:referralListDetails.totalEntries}">
                            <td>
                                <a routerLink="../referral-view" [queryParams]="{id: ref.id}" class="text-primary"
                                    style="text-decoration: underline;">{{ref.id}}</a>
                            </td>
                            <td>
                                {{ref.email}}
                            </td>
                            <td>
                                <p *ngIf="ref.accepted != '' && ref.accepted == 'YES'"
                                    style="background-color: #6090DB;width:fit-content;">{{ref.accepted}}</p>
                                <p *ngIf="ref.accepted != '' && ref.accepted == 'NO'"
                                    style="background-color: grey;width:fit-content;">{{ref.accepted}}</p>
                            </td>
                            <td>
                                <a (click)="gotoUser(ref.userFName,ref.userLName,ref.invitedbyId)" class="text-primary"
                                    style="text-decoration: underline; cursor: pointer;">{{ref.userFName}}
                                    {{ref.userLName}}</a>
                            </td>
                            <td>
                                {{ref.invitationAcceptedAt}}
                            </td>
                            <td>
                                {{ref.invitationSentAt}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="referralListDetails != undefined && referralListDetails.totalEntries != undefined && referralListDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="email">EMAIL</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="email" formControlName="email" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>