<div class="container mt-2">
    <h2 class="`text-default">Verifications</h2>
    <form [formGroup]="frmVerification" (ngSubmit)="submit()">
        <div class="card">
            <div class="card-header">
                Action Verifications
            </div>
            <div class="card-body">
                <div class="form-group row pl-3">
                    <div class="col-md-3">
                        <div class="row">
                            <label for="certificate" class="mt-30">Boat Certificate</label>
                        </div>
                    </div>
                    <div class="col-md-3 ml-2">
                        <div class="row">
                            <label for="status">Status</label>
                        </div>
                        <div class="row">
                            <select formControlName="status" class="form-control">
                                <option value="">select</option>
                                <option value="approved">Approved</option>
                                <option value="pending">Pending</option>
                                <option value="declined">Declined</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 ml-3">
                        <div class="row">
                            <label for="certificateType">Certificate Type</label>
                        </div>
                        <div class="row">
                            <select formControlName="certificateType" class="form-control">
                                <option value="">select</option>
                                <option value="yacht skipper">Yacht skipper</option>
                                <option value="dueligheds">Dueligheds</option>
                                <option value="moterboat">Moterboat</option>
                                <option value="sailboat">Sailboat</option>
                                <option value="speedboat">Speedboat</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row pl-3">
                    <div class="col-md-3">
                        <div class="row">
                            <label for="photoIdStatus" class="mt-30">Photo Id</label>
                        </div>
                    </div>
                    <div class="col-md-3 ml-2">
                        <div class="row">
                            <label for="photoIdStatus">Status</label>
                        </div>
                        <div class="row">
                            <select formControlName="photoIdStatus" class="form-control">
                                <option value="">select</option>
                                <option value="approved">Approved</option>
                                <option value="pending">Pending</option>
                                <option value="declined">Declined</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row pl-3">
                    <div class="col-md-3">
                        <div class="row">
                            <label for="photoIdBackStatus" class="mt-30">Photo Id(Back)</label>
                        </div>
                    </div>
                    <div class="col-md-3 ml-2">
                        <div class="row">
                            <label for="photoIdBackStatus">Status</label>
                        </div>
                        <div class="row">
                            <select formControlName="photoIdBackStatus" class="form-control">
                                <option value="">select</option>
                                <option value="approved">Approved</option>
                                <option value="pending">Pending</option>
                                <option value="declined">Declined</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row pl-3">
                    <div class="col-md-3">
                        <div class="row">
                            <label for="addressCertificate" class="mt-30">Address Document</label>
                        </div>
                    </div>
                    <div class="col-md-3 ml-2">
                        <div class="row">
                            <label for="addressCertificateStatus">Status</label>
                        </div>
                        <div class="row">
                            <select formControlName="addressCertificateStatus" class="form-control">
                                <option value="">select</option>
                                <option value="approved">Approved</option>
                                <option value="pending">Pending</option>
                                <option value="declined">Declined</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row pl-3">
                    <div class="col-md-3">
                        <div class="row">
                            <label for="additionalDoc" class="mt-30">Additional Certificate</label>
                        </div>
                    </div>
                    <div class="col-md-3 ml-2">
                        <div class="row">
                            <label for="additionalDocStatus">Status</label>
                        </div>
                        <div class="row">
                            <select formControlName="additionalDocStatus" class="form-control">
                                <option value="">select</option>
                                <option value="approved">Approved</option>
                                <option value="pending">Pending</option>
                                <option value="declined">Declined</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <button type="submit" class="btn btn-primary">Update
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>