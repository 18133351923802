<div class="container mt-2">
  <h2 class="`text-default">Edit Admin User</h2>
  <div class="card">
    <div class="card-header">
      Admin Details
    </div>
    <div class="card-body">
      <form [formGroup]="frmChangePass" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input type="email" class="form-control" id="exampleInputEmail1" formControlName="email"
            aria-describedby="emailHelp" placeholder="Enter email" required readonly>
          <div *ngIf="frmChangePass.controls['email'].invalid && frmChangePass.controls['email'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmChangePass.controls['email'].hasError('required')">
              Please enter email.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="currentpass">Current password</label>
          <input type="password" class="form-control" id="currentpass" formControlName="currentPassword"
            placeholder="Current Password" required>
          <div
            *ngIf="frmChangePass.controls['currentPassword'].invalid && frmChangePass.controls['currentPassword'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmChangePass.controls['currentPassword'].hasError('required')">
              Please enter current password.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="newPass">New password</label>
          <input type="password" class="form-control" id="newPass" formControlName="newPassword"
            placeholder="New Password" required>
          <div *ngIf="frmChangePass.controls['newPassword'].invalid && frmChangePass.controls['newPassword'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmChangePass.controls['newPassword'].hasError('required')">
              Please enter new password.
            </div>
            <div *ngIf="frmChangePass.controls['newPassword'].hasError('pattern')">
              Password should contain at least one special character, one upper case letter and one number.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPass">Confirm New password</label>
          <input type="password" class="form-control" id="confirmPass" formControlName="confirmPassword"
            placeholder="Confirm New Password" required>
          <div
            *ngIf="frmChangePass.controls['confirmPassword'].invalid && frmChangePass.controls['confirmPassword'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmChangePass.controls['confirmPassword'].hasError('required')">
              Please re-enter new password.
            </div>
            <div *ngIf="frmChangePass.controls['confirmPassword'].hasError('confirmPasswordValidator')">
              Passsword and Confirm Password didn't match.
            </div>
          </div>
        </div>

        <button type="submit" [disabled]="!frmChangePass.valid" class="btn btn-primary">Update Admin User</button>
        <button type="reset" (click)="onReset()" class="btn btn-secondary ml-2">Cancel</button>
      </form>
    </div>
  </div>
</div>