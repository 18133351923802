<div class="card">
    <div class="card-header">
        Admin User Details
      </div>
    <div class="card-body">
     <table class="table table-striped table-bordered" *ngIf="userDetails != null && userDetails != undefined">
         <tr>
             <td>
                 <p>ID</p>
             </td>
             <td>
                <p class="pl-3">{{userDetails.userId}}</p>
             </td>
         </tr>
         <tr>
            <td>
                <p>EMAIL</p>
            </td>
            <td>
                <p class="pl-3">{{userDetails.email}}</p>
            </td>
        </tr>
        <tr>
            <td>
                <p>CURRENT SIGN IN AT</p>
            </td>
            <td>
                <p class="pl-3">{{userDetails.currentSignInAt}}</p>
            </td>
        </tr>
        <tr>
            <td>
                <p>CURRENT SIGN IN IP</p>
            </td>
            <td>
                <p class="pl-3">{{userDetails.currentSignInIp}}</p>
            </td>
        </tr>
        <tr>
            <td>
                <p>CREATED AT</p>
            </td>
            <td>
                <p class="pl-3">{{userDetails.createdAt}}</p>
            </td>
        </tr>
        <tr>
            <td>
                <p>UPDATED AT</p>
            </td>
            <td>
                <p class="pl-3">{{userDetails.updatedAt}}</p>
            </td>
        </tr>
     </table>
    </div>
  </div>
