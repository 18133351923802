<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Dashboard</h2>
    <div class="row top-buffer">
        <div class="col-sm-4">
            <div class="card bg-c-blue order-card">
                <div class="card-block">
                    <h5 class="m-b-20">BOOKINGS <button type="button" class="btn btn-primary btn-sm"
                            (click)="open(mymodal)">Filter</button></h5>
                    <div class="container">
                        <div class="row">
                            <div class="col">

                                <div class="row" *ngFor="let stats of yearlyBookings;">
                                    <div class="col">
                                        <p class="m-b-0">{{stats.barName}}<span class="f-right">{{stats.value}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="card bg-c-blue order-card">
                <div class="card-block">

                    <div class="container bg-c-white">
                        <div class="row">
                            <div class="col">

                                <canvas id="myChart" width="1200px" height="550" (click)="open(myYearmodal)"></canvas>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div class="col-sm-3">
            <div class="card bg-c-blue order-card" *ngIf="userStats!=undefined">
                <div class="card-block">
                    <h5 class="m-b-20">USERS & BOATS</h5>
                    <p class="m-b-0">Users total:<span class="f-right">{{userStats.userCount}}</span></p>
                    <p class="m-b-0">Published privately owned:<span
                            class="f-right">{{userStats.publishedOwnedCount}}</span></p>
                    <p class="m-b-0">Published booking manager:<span
                            class="f-right">{{userStats.publishedBMCount}}</span></p>

                </div>
            </div>
            <div class="card bg-c-blue order-card" *ngIf="gmvStats!=undefined">
                <div class="card-block">
                    <h5 class="m-b-20">ECONOMICS IN € <select [ngModel]="selectedGMVYear"
                            (ngModelChange)="onGMVYearChange($event)">
                            <option *ngFor="let year of years" value="{{year}}">{{year}}</option>

                        </select></h5>
                    <p class="m-b-0">GMV YTD (paid out + confirmed):<span class="f-right">{{gmvStats.amount}}</span></p>
                    <p class="m-b-0">Boatflex fee YTD (paid out + confirmed):<span
                            class="f-right">{{gmvStats.fee}}</span></p>


                </div>
            </div>

        </div>
        <div class="col-sm-4">
            <div class="card bg-c-blue order-card">
                <div class="card-block">
                    <h5 class="m-b-20">DAYS SPENT ON THE WATER <select [ngModel]="selectedDIWYear"
                            (ngModelChange)="onDIWYearChange($event)">
                            <option *ngFor="let year of years" value="{{year}}">{{year}}</option>

                        </select></h5>
                    <div class="container">
                        <div class="row">
                            <div class="col">

                                <div class="row" *ngFor="let diw of diwStats;let i = index;">
                                    <div class="col" *ngIf="i<6">
                                        <p class="m-b-0">{{diw.monthName}}<span class="f-right">{{diw.totalDays}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">

                                <div class="row" *ngFor="let diw of diwStats;let i = index;">
                                    <div class="col" *ngIf="i>5">
                                        <p class="m-b-0">{{diw.monthName}}<span class="f-right">{{diw.totalDays}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="row top-buffer">

        <div class="col-sm-4">
            <div class="card bg-c-blue order-card">
                <div class="card-block">

                    <div class="container bg-c-white">
                        <div class="row">
                            <div class="col">

                                <canvas id="1" width="1200px" height="550" (click)="open(myYearmodal)"></canvas>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">

            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdFrom">CREATED FROM</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdFrom" formControlName="createdFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdTo">CREATED TO</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdTo" formControlName="createdTo" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="startOnFrom">START ON FROM</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="startOnFrom" formControlName="startOnFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="startOnTo">START ON TO</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="startOnTo" formControlName="startOnTo" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="endsOnFrom">ENDS ON FROM</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="endsOnFrom" formControlName="endsOnFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="endsOnTo">ENDS ON TO</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="endsOnTo" formControlName="endsOnTo" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>
<ng-template #myYearmodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmBarFilter" id="barFilter">

            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdFrom">Years</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="years" id="iStatus" class="form-control" ng-options="" multiple>
                        <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
                    </select>
                </div>
            </div>

        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submitBarYear()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearYearFilter()">Clear Filters</button>
    </div>
</ng-template>