<div class="card">
    <div class="card-header">
        Bookings
    </div>
    <div class="card-body">
        <table class="table table-striped table-bordered"
            *ngIf="adminAssignments != null && adminAssignments != undefined">
            <thead>
                <tr>
                    <th>#</th>
                    <th>BOAT</th>
                    <th>BOAT OWNER</th>
                    <th>RENTER</th>
                    <th>FROM DATE</th>
                    <th>TO DATE</th>
                    <th>STATUS</th>
                    <th>MANUAL PAYOUT</th>
                    <th>CREATED AT</th>
                    <th>FOLLOW UP DATE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let booking of adminAssignments.adminBookings">
                    <td>{{booking.id}}</td>
                    <td>{{booking.boatId}}</td>
                    <td>{{booking.boatOwner}}</td>
                    <td>{{booking.renterId}}</td>
                    <td>{{booking.startsOn}}</td>
                    <td>{{booking.endsOn}}</td>
                    <td>{{booking.status}}</td>
                    <td>{{booking.manualPayout}}</td>
                    <td>{{booking.createdAt}}</td>
                    <td>{{booking.followUpDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="card mt-1">
    <div class="card-header">
        Boats
    </div>
    <div class="card-body">
        <table class="table table-striped table-bordered"
            *ngIf="adminAssignments != null && adminAssignments != undefined">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>OWNER</th>
                    <th>COUNTRY CODE</th>
                    <th>PUBLISHED AT</th>
                    <th>ALL FILLED</th>
                    <th>CREATED AT</th>
                    <th>FOLLOW UP DATE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let boat of adminAssignments.adminBoats">
                    <td>{{boat.id}}</td>
                    <td>{{boat.name}}</td>
                    <td>{{boat.ownerId}}</td>
                    <td>{{boat.countryCode}}</td>
                    <td>{{boat.publishedAt}}</td>
                    <td>{{boat.allFilled}}</td>
                    <td>{{boat.createdAt}}</td>
                    <td>{{boat.followUpDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="card mt-1">
    <div class="card-header">
        Users
    </div>
    <div class="card-body">
        <table class="table table-striped table-bordered"
            *ngIf="adminAssignments != null && adminAssignments != undefined">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>FULL NAME</th>
                    <th>EMAIL</th>
                    <th>PHOTO ID VERIFIED?</th>
                    <th>PHOTO ID BACK VERIFIED?</th>
                    <th>DOCUMENT ID VERIFIED?</th>
                    <th>EMAIL VERIFIED?</th>
                    <th>CREATED AT</th>
                    <th>FOLLOW UP DATE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of adminAssignments.adminUsers">
                    <td>{{user.id}}</td>
                    <td>{{user.fullName}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.photoIdVerified}}</td>
                    <td>{{user.photoIdBackVerified}}</td>
                    <td>{{user.documentIdVerified}}</td>
                    <td>{{user.emailVerified}}</td>
                    <td>{{user.createdDate}}</td>
                    <td>{{user.followUpDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="card mt-1">
    <div class="card-header">
        Conversations
    </div>
    <div class="card-body">
        <table class="table table-striped table-bordered"
            *ngIf="adminAssignments != null && adminAssignments != undefined">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>BOAT OWNER</th>
                    <th>RENTER</th>
                    <th>BOOKING</th>
                    <th>CREATED AT</th>
                    <th>SUBJECT</th>
                    <th>UPDATED AT</th>
                    <th>FOLLOW UP DATE</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let conversation of adminAssignments.adminConversations">
                    <td>{{conversation.id}}</td>
                    <td>{{conversation.boatOwner}}</td>
                    <td>{{conversation.guestId}}</td>
                    <td>{{conversation.bookingId}}</td>
                    <td>{{conversation.createdAt}}</td>
                    <td>{{conversation.itemType}}</td>
                    <td>{{conversation.updatedAt}}</td>
                    <td>{{conversation.followUpDate}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>