import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { LoginService } from 'src/app/login.service';
import { UserService } from 'src/app/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {
  email: any;
  id: string = "";
  resourceType: string = "";
  resourceId: string = "";

  constructor(private authService: LoginService, private userService: UserService,
    private route: ActivatedRoute, private router: Router, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params.id,
        this.email = params.email
    });
    if (this.email == null || this.email == undefined) {
      this.email = this.authService.email;

    }
    this.resourceType = "Admin-User";
    this.resourceId = this.id != undefined ? this.id : this.authService.userId;
  }
  deleteUser() {
    var userIds: string[] = [];
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      userIds.push(this.id);
      this.userService.deleteUsers(userIds).subscribe(
        response => {
          this.tostr.success("Deleted Successfully.");
          userIds = [];
          this.router.navigate(['admin/admin-users']);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
    }
  }
}
