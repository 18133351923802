import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthGuard } from './auth.guard';
import { AmenitiesComponent } from './components/boat/amenity/amenities/amenities.component';
import { AmenitiescategoryviewComponent } from './components/boat/amenity-category/amenitiescategoryview/amenitiescategoryview.component';
import { AmenitiesviewComponent } from './components/boat/amenity/amenitiesview/amenitiesview.component';
import { AmenitycategoriesComponent } from './components/boat/amenity-category/amenitycategories/amenitycategories.component';
import { BoatComponent } from './components/boat/boat/boats.component';
import { PayoutsComponent } from './components/boat/payouts/payouts.component';
import { UserListComponent } from './components/social/user-list/user-list.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { ErrorComponent } from './components/error/error.component';
import { EventComponent } from './components/events/event/event.component';
import { EditUserComponent } from './components/system/users/edit-user/edit-user.component';
import { UserComponent } from './components/system/users/user.component';
import { LoginComponent } from './login/login.component';
import { EditComponent } from './components/social/edit/edit.component';
import { AdminUsersComponent } from './components/system/users/admin-users/admin-users.component';
import { CreateUserComponent } from './components/system/users/create-user/create-user.component';
import { UserViewComponent } from './components/social/user-view/user-view.component';
import { AmenityNewComponent } from './components/boat/amenity/amenity-new/amenity-new.component';
import { AmenityEditComponent } from './components/boat/amenity/amenity-edit/amenity-edit.component';
import { AmenitycategoryNewComponent } from './components/boat/amenity-category/amenitycategory-new/amenitycategory-new.component';
import { AmenitycategoryEditComponent } from './components/boat/amenity-category/amenitycategory-edit/amenitycategory-edit.component';
import { ReviewsComponent } from './components/boat/review/reviews/reviews.component';
import { ReviewViewComponent } from './components/boat/review/review-view/review-view.component';
import { ReviewEditComponent } from './components/boat/review/review-edit/review-edit.component';
import { MetagroupsComponent } from './components/system/metagroup/metagroups/metagroups.component';
import { MetagroupEditComponent } from './components/system/metagroup/metagroup-edit/metagroup-edit.component';
import { MetagroupViewComponent } from './components/system/metagroup/metagroup-view/metagroup-view.component';
import { MetagroupNewComponent } from './components/system/metagroup/metagroup-new/metagroup-new.component';
import { BoatViewComponent } from './components/boat/boat/boat-view/boat-view.component';
import { BoatEditComponent } from './components/boat/boat/boat-edit/boat-edit.component';
import { InsuranceComponent } from './components/boat/boat/boat-view/insurance/insurance.component';
import { AlertsComponent } from './components/system/alert/alerts/alerts.component';
import { AlertViewComponent } from './components/system/alert/alert-view/alert-view.component';
import { ConversationsComponent } from './components/social/conversations/conversations.component';
import { ConversationViewComponent } from './components/social/conversations/conversation-view/conversation-view.component';
import { ConversationEditComponent } from './components/social/conversations/conversation-edit/conversation-edit.component';
import { ReferralsComponent } from './components/social/referrals/referrals.component';
import { ReferralViewComponent } from './components/social/referrals/referral-view/referral-view.component';
import { ReferralNewComponent } from './components/social/referrals/referral-new/referral-new.component';
import { UpdateCreditComponent } from './components/social/user-view/update-credit/update-credit.component';
import { BookingsComponent } from './components/boat/bookings/bookings.component';
import { BookingViewComponent } from './components/boat/bookings/booking-view/booking-view.component';
import { BoatStatisticsComponent } from './components/reports/boat-statistics/boat-statistics.component';
import { ReviewByWeekComponent } from './components/reports/review-by-week/review-by-week.component';
import { SearchesByDestinationComponent } from './components/reports/searches-by-destination/searches-by-destination.component'
import { TripTypesComponent } from './components/boat/trip-type/trip-types/trip-types.component'
import { TripTypeNewComponent } from './components/boat/trip-type/trip-type-new/trip-type-new.component'
import { TripTypeViewComponent } from './components/boat/trip-type/trip-type-view/trip-type-view.component'
import { TripTypeEditComponent } from './components/boat/trip-type/trip-type-edit/trip-type-edit.component'
import { PopularDestinationsComponent } from './components/boat/popular-destination/popular-destinations/popular-destinations.component';
import { PopularDestinationViewComponent } from './components/boat/popular-destination/popular-destination-view/popular-destination-view.component';
import { PopularDestinationNewComponent } from './components/boat/popular-destination/popular-destination-new/popular-destination-new.component';
import { PopularDestinationEditComponent } from './components/boat/popular-destination/popular-destination-edit/popular-destination-edit.component';
import { BookingBroadcastsComponent } from './components/boat/booking-broadcast/booking-broadcasts/booking-broadcasts.component';
import { BookingBroadcastViewComponent } from './components/boat/booking-broadcast/booking-broadcast-view/booking-broadcast-view.component';
import { RulesComponent } from './components/system/rule/rules/rules.component';
import { RuleViewComponent } from './components/system/rule/rule-view/rule-view.component';
import { RuleNewComponent } from './components/system/rule/rule-new/rule-new.component';
import { RuleEditComponent } from './components/system/rule/rule-edit/rule-edit.component';
import { InsurancePriceRangesComponent } from './components/system/insurance-price-range/insurance-price-ranges/insurance-price-ranges.component';
import { InsurancePriceRangeViewComponent } from './components/system/insurance-price-range/insurance-price-range-view/insurance-price-range-view.component';
import { InsurancePriceRangeNewComponent } from './components/system/insurance-price-range/insurance-price-range-new/insurance-price-range-new.component';
import { InsurancePriceRangeEditComponent } from './components/system/insurance-price-range/insurance-price-range-edit/insurance-price-range-edit.component';
import { CategoriesComponent } from './components/cms/support center/categories/categories/categories.component';
import { CategoryNewComponent } from './components/cms/support center/categories/category-new/category-new.component';
import { CategoryEditComponent } from './components/cms/support center/categories/category-edit/category-edit.component';
import { VerificationComponent } from './components/social/user-view/verification/verification.component';
import { BookingRequestComponent } from './components/boat/booking-request/booking-request.component';
import { BookingRequestViewComponent } from './components/boat/booking-request/booking-request-view/booking-request-view.component';
import { ReferralEditComponent } from './components/social/referrals/referral-edit/referral-edit.component';
import { BoatLibraryComponent } from './components/system/boatLibrary/boat-library/boat-library.component';
import { LibraryNewComponent } from './components/system/boatLibrary/library-new/library-new.component';
import { LibraryEditComponent } from './components/system/boatLibrary/library-edit/library-edit.component';
import { LibraryViewComponent } from './components/system/boatLibrary/library-view/library-view.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard], children:
      [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
        { path: 'boats', component: BoatComponent, canActivate: [AuthGuard] },
        { path: 'boat-view', component: BoatViewComponent, canActivate: [AuthGuard] },
        { path: 'boat-edit', component: BoatEditComponent, canActivate: [AuthGuard] },
        { path: 'payouts', component: PayoutsComponent, canActivate: [AuthGuard] },
        { path: 'bookings', component: BookingsComponent, canActivate: [AuthGuard] },
        { path: 'booking-view', component: BookingViewComponent, canActivate: [AuthGuard] },
        { path: 'conversations', component: ConversationsComponent, canActivate: [AuthGuard] },
        { path: 'conversation-view', component: ConversationViewComponent, canActivate: [AuthGuard] },
        { path: 'conversation-edit', component: ConversationEditComponent, canActivate: [AuthGuard] },
        { path: 'insurance', component: InsuranceComponent, canActivate: [AuthGuard] },
        { path: 'referrals', component: ReferralsComponent, canActivate: [AuthGuard] },
        { path: 'referral-view', component: ReferralViewComponent, canActivate: [AuthGuard] },
        { path: 'referral-new', component: ReferralNewComponent, canActivate: [AuthGuard] },
        { path: 'referral-edit', component: ReferralEditComponent, canActivate: [AuthGuard] },
        { path: 'event', component: EventComponent, canActivate: [AuthGuard] },
        { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
        { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
        { path: 'user-view', component: UserViewComponent, canActivate: [AuthGuard] },
        { path: 'user-edit', component: EditComponent, canActivate: [AuthGuard] },
        { path: 'admin-edit', component: EditUserComponent, canActivate: [AuthGuard] },
        { path: 'admin-new', component: CreateUserComponent, canActivate: [AuthGuard] },
        { path: 'admin-users', component: AdminUsersComponent, canActivate: [AuthGuard] },
        { path: 'alerts', component: AlertsComponent, canActivate: [AuthGuard] },
        { path: 'alert-view', component: AlertViewComponent, canActivate: [AuthGuard] },
        { path: 'amenitycategories', component: AmenitycategoriesComponent, canActivate: [AuthGuard] },
        { path: 'amenities', component: AmenitiesComponent, canActivate: [AuthGuard] },
        { path: 'amenity-view', component: AmenitiesviewComponent, canActivate: [AuthGuard] },
        { path: 'amenity-new', component: AmenityNewComponent, canActivate: [AuthGuard] },
        { path: 'amenity-edit', component: AmenityEditComponent, canActivate: [AuthGuard] },
        { path: 'amenitycategory-new', component: AmenitycategoryNewComponent, canActivate: [AuthGuard] },
        { path: 'amenitycategory-view', component: AmenitiescategoryviewComponent, canActivate: [AuthGuard] },
        { path: 'amenitycategory-edit', component: AmenitycategoryEditComponent, canActivate: [AuthGuard] },
        { path: 'reviews', component: ReviewsComponent, canActivate: [AuthGuard] },
        { path: 'review-view', component: ReviewViewComponent, canActivate: [AuthGuard] },
        { path: 'review-edit', component: ReviewEditComponent, canActivate: [AuthGuard] },
        { path: 'metagroups', component: MetagroupsComponent, canActivate: [AuthGuard] },
        { path: 'metagroup-view', component: MetagroupViewComponent, canActivate: [AuthGuard] },
        { path: 'metagroup-edit', component: MetagroupEditComponent, canActivate: [AuthGuard] },
        { path: 'metagroup-new', component: MetagroupNewComponent, canActivate: [AuthGuard] },
        { path: 'update-credit', component: UpdateCreditComponent, canActivate: [AuthGuard] },
        { path: 'boat-statistics', component: BoatStatisticsComponent, canActivate: [AuthGuard] },
        { path: 'review-by-week', component: ReviewByWeekComponent, canActivate: [AuthGuard] },
        { path: 'searches-by-destination', component: SearchesByDestinationComponent, canActivate: [AuthGuard] },
        { path: 'trip-types', component: TripTypesComponent, canActivate: [AuthGuard] },
        { path: 'trip-type-view/:id', component: TripTypeViewComponent, canActivate: [AuthGuard] },
        { path: 'trip-type-new', component: TripTypeNewComponent, canActivate: [AuthGuard] },
        { path: 'trip-type-edit', component: TripTypeEditComponent, canActivate: [AuthGuard] },
        { path: 'popular-destinations', component: PopularDestinationsComponent, canActivate: [AuthGuard] },
        { path: 'popular-destination-view', component: PopularDestinationViewComponent, canActivate: [AuthGuard] },
        { path: 'popular-destination-new', component: PopularDestinationNewComponent, canActivate: [AuthGuard] },
        { path: 'popular-destination-edit', component: PopularDestinationEditComponent, canActivate: [AuthGuard] },
        { path: 'booking-broadcasts', component: BookingBroadcastsComponent, canActivate: [AuthGuard] },
        { path: 'booking-broadcast-view', component: BookingBroadcastViewComponent, canActivate: [AuthGuard] },
        { path: 'rules', component: RulesComponent, canActivate: [AuthGuard] },
        { path: 'rule-view', component: RuleViewComponent, canActivate: [AuthGuard] },
        { path: 'rule-new', component: RuleNewComponent, canActivate: [AuthGuard] },
        { path: 'rule-edit', component: RuleEditComponent, canActivate: [AuthGuard] },
        { path: 'insurance-price-ranges', component: InsurancePriceRangesComponent, canActivate: [AuthGuard] },
        { path: 'insurance-price-range-view', component: InsurancePriceRangeViewComponent, canActivate: [AuthGuard] },
        { path: 'insurance-price-range-new', component: InsurancePriceRangeNewComponent, canActivate: [AuthGuard] },
        { path: 'insurance-price-range-edit', component: InsurancePriceRangeEditComponent, canActivate: [AuthGuard] },
        { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
        { path: 'category-new', component: CategoryNewComponent, canActivate: [AuthGuard] },
        { path: 'category-edit', component: CategoryEditComponent, canActivate: [AuthGuard] },
        { path: 'verifications', component: VerificationComponent, canActivate: [AuthGuard] },
        { path: 'booking-requests', component: BookingRequestComponent, canActivate: [AuthGuard] },
        { path: 'booking-request-view', component: BookingRequestViewComponent, canActivate: [AuthGuard] },
        { path: 'library', component: BoatLibraryComponent, canActivate: [AuthGuard] },
        { path: 'library-new', component: LibraryNewComponent, canActivate: [AuthGuard] },
        { path: 'library-edit', component: LibraryEditComponent, canActivate: [AuthGuard] },
        { path: 'library-view', component: LibraryViewComponent, canActivate: [AuthGuard] },
        { path: 'events', component: EventComponent, canActivate: [AuthGuard] },
        { path: 'error', component: ErrorComponent },
        { path: '**', component: ErrorComponent }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
