<!-- <div class="row">
    <div class="col-sm-4 offset-4 mt170 border bg-c-g">
        <h3 class="text-center p10">Login</h3>
        <form [formGroup]="form" class="form-group" (ngSubmit)="login()">
            <div class="row mt10 p10">
            <input type="text" class="form-control" placeholder="enter username" name="user" formControlName="username" required>
            <div *ngIf="form.controls['username'].invalid && form.controls['username'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="form.controls['username'].errors">
                   Please enter username.
                </div>
                </div>
            </div>
            <div class="row mt10 p10">
            <input type="password" class="form-control" placeholder="enter password" name="pass" formControlName="password" required>
            <div *ngIf="form.controls['password'].invalid && form.controls['password'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="form.controls['password'].errors">
                   Please enter password.
                </div>
                </div>
            </div>
            <div class="row mt10 p10">
            <button type="submit" class="btn btn-info width100">Login</button>
            </div>
        </form>
    </div>
    </div> -->
<!-- <div class="loader" *ngIf="spiner"></div> -->
<div class="main-loader" *ngIf="spiner">
    <div class="loader">
        <mat-spinner mode="indeterminate">
            <p class="p-3">Loading</p>
        </mat-spinner>
    </div>
</div>