<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>{{name}}'s Boats</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>PUBLISHED AT</th>
                        <th>ALL FILLED</th>
                        <th>CREATED AT</th>
                    </thead>
                    <tbody *ngIf="boats!= null && boats != undefined && boats.length > 0 else noRecord ">
                        <tr *ngFor="let boat of boats">
                            <td> <a routerLink="../boat-view"
                                    [queryParams]="{id: boat.boatId,name:boat.name}">{{boat.boatId}}</a></td>
                            <td>{{boat.name}}</td>
                            <td>{{boat.publishedAt}}</td>
                            <td>{{boat.allFilled}}</td>
                            <td>{{boat.createdAt}}</td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>