<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h5>{{boatName}}'s Price</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="prices!= null && prices != undefined">
                    <tr>
                        <td class="bt-none">Base Price</td>
                        <td class="bt-none">

                            <p>{{prices.basePrice}} {{prices.currency}}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h5>Custom Prices</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="prices!= null && prices != undefined">
                    <tr>
                        <th>Date Range</th>
                        <th>Price</th>
                    </tr>
                    <tr *ngFor="let price of prices.customPrices">
                        <td class="bt-none">{{price.dateRange}}</td>
                        <td class="bt-none">

                            <p>{{price.dailyPrice}} {{price.currency}}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>