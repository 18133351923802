import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { timeStamp } from 'console';
import { ToastrService } from 'ngx-toastr';
import { ReferralService } from 'src/app/services/referral.service';

@Component({
  selector: 'app-referral-new',
  templateUrl: './referral-new.component.html',
  styleUrls: ['./referral-new.component.css']
})
export class ReferralNewComponent implements OnInit {
  frmEditReferral: FormGroup;
  userList;
  initialUserList;
  constructor(private fb: FormBuilder, private router: Router, private refService: ReferralService
    , private tostrService: ToastrService) {
    this.frmEditReferral = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      invitedBy: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.refService.fetchInvitedByUsers().subscribe(
      res => {
        this.initialUserList = res;
        this.userList = this.initialUserList;
      },
      err => {
        this.tostrService.error("Error occurred while fetching data.");
        console.log(err);
      }
    )
  }

  onSubmit() {
    if (!this.frmEditReferral.invalid) {
      var referralAddRequest = {
        "firstName": this.frmEditReferral.controls["firstName"].value,
        "lastName": this.frmEditReferral.controls["lastName"].value,
        "email": this.frmEditReferral.controls["email"].value,
        "invitedById": this.frmEditReferral.controls["invitedBy"].value
      };
      this.refService.addRefferals(referralAddRequest).subscribe(
        res => {
          this.tostrService.success("Created successfully.");
          this.router.navigate(["admin/referrals"]);
        },
        err => {
          this.tostrService.error("Error occurred while adding data.");
          console.log(err);
        }
      );
    }
    else {
      this.tostrService.error("Error occurred while adding data.");
    }
  }

  onReset() {
    this.router.navigate(["admin/referrals"]);
  }

  onKey(event: any) {
    this.userList = this.search(event.target.value);
  }

  search(value: string) {
    if (value.length > 0) {
      let filter = value.toLowerCase();
      return this.userList.filter(x => x.fullName.toLowerCase().startsWith(filter));
    }
    return this.userList = this.initialUserList;
  }
}
