<div class="container mt-2">
    <h2 class="`text-default">Edit Conversation</h2>
    <form [formGroup]="frmConversation" (ngSubmit)="submit()">
        <div class="card">
            <div class="card-header">
                Follow-Up
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="followUpTask">FOLLOW UP TASK</label>
                    </div>
                    <div class="col-md-8" *ngIf="followUpTaskList != null && followUpTaskList.length > 0">
                        <select formControlName="followUpTask" id="followUpTask" class="form-control" ng-options="">
                            <option value="">--Select--</option>
                            <option *ngFor="let task of followUpTaskList" value={{task.value}}>{{task.text}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="followUpDate">FOLLOW UP DATE</label>
                    </div>
                    <div class="col-md-8">
                        <input type="date" id="followUpDate" formControlName="followUpDate" class="form-control">
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <div class="col-md-4">
                        <label for="assignee">ASSIGNEE</label>
                    </div>
                    <div class="col-md-8" *ngIf="assignees != null && assignees.length > 0">
                        <select formControlName="assignee" id="assignee" class="form-control" ng-options="">
                            <option value="0">--Select--</option>
                            <option *ngFor="let assignee of assignees" value={{assignee.id}}>{{assignee.email}}</option>
                        </select>
                    </div>
                </div> -->
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="followUpStatus">FOLLOW UP STATUS</label>
                    </div>
                    <div class="col-md-8">
                        <select formControlName="followUpStatus" id="followUpStatus" class="form-control" ng-options="">
                            <option value="">--Select--</option>
                            <option value="confirmed">Confirmed</option>
                            <option value="follow_up">Follow Up</option>
                            <option value="closed">Closed</option>
                            <option value="take_action">Take Action</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row m-3">
            <button type="submit" [disabled]="!frmConversation.valid" class="btn btn-primary">Update
                Conversation</button>
            <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
        </div>
    </form>
</div>