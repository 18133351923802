import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MetagroupService } from 'src/app/services/metagroup.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-metagroup-edit',
  templateUrl: './metagroup-edit.component.html',
  styleUrls: ['./metagroup-edit.component.css']
})
export class MetagroupEditComponent implements OnInit {
  id: number = 0;
  frmMetagroup: FormGroup;
  metagroup: any;

  constructor(private activatedRoute: ActivatedRoute, private route: Router, private fb: FormBuilder,
    private metagroupService: MetagroupService, private tostr: ToastrService) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.id = data.id;
    });
    this.frmMetagroup = this.fb.group({
      url: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      imageUrl: ['']
    });
  }

  ngOnInit(): void {
    this.getMetaGroup();
  }

  getMetaGroup() {
    this.metagroupService.fetchMetagroup(this.id)
      .pipe(finalize(() => {
      })).subscribe(
        response => {
          this.metagroup = response;
          this.frmMetagroup.setValue({
            url: this.metagroup.url,
            title: this.metagroup.title,
            description: this.metagroup.description,
            imageUrl: this.metagroup.imageUrl
          });
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  reset() {
    this.route.navigate(["admin/metagroups"]);
  }

  submit() {
    if (this.frmMetagroup.valid) {
      var metagroup = {
        id: this.id,
        url: this.frmMetagroup.controls["url"].value,
        title: this.frmMetagroup.controls["title"].value,
        description: this.frmMetagroup.controls["description"].value,
        imageUrl: this.frmMetagroup.controls["imageUrl"].value
      };
      this.metagroupService.editMetagroup(metagroup)
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Updated Sucessfully.");
            this.route.navigate(['admin/metagroups']);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }
}
