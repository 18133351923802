<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h5>Business info</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="businessInfo != null && businessInfo != undefined">
                    <tr>
                        <td class="bt-none">BUSINESS NAME</td>
                        <td class="bt-none">{{businessInfo.name}}</td>
                    </tr>
                    <tr>
                        <td>BUSINESS TAX</td>
                        <td>{{businessInfo.tax}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h5>Business Address</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="businessInfo != null && businessInfo != undefined">
                    <tr>
                        <td class="bt-none">BUSINESS ADDRESS FIRST LINE</td>
                        <td class="bt-none">{{businessInfo.addressFirstLine}}</td>
                    </tr>
                    <tr>
                        <td>BUSINESS ADDRESS SECOND LINE</td>
                        <td>{{businessInfo.addressSecondLine}}</td>
                    </tr>
                    <tr>
                        <td>BUSINESS ADDRESS ZIP</td>
                        <td>{{businessInfo.addressZip}}</td>
                    </tr>
                    <tr>
                        <td>BUSINESS ADDRESS CITY</td>
                        <td>{{businessInfo.addressCity}}</td>
                    </tr>
                    <tr>
                        <td>BUSINESS ADDRESS STATE</td>
                        <td>{{businessInfo.addressState}}</td>
                    </tr>
                    <tr>
                        <td>BUSINESS ADDRESS COUNTRY</td>
                        <td>{{businessInfo.addressCountry}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>