<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>{{name}}'s Referees</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th>ID</th>
                        <th>EMAIL</th>
                        <th>INVITATION STATUS</th>
                    </thead>
                    <tbody *ngIf="referals!= null && referals != undefined && referals.length > 0 else noRecord ">
                        <tr *ngFor="let referal of referals">
                            <td>{{referal.id}}</td>
                            <td>{{referal.email}}</td>
                            <td>{{referal.status}}</td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>