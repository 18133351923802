import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/login.service';
import { AccountService } from 'src/app/services/account/account.service';
import { ConfirmPasswordValidator } from 'src/app/shared/customValidator';
import { ChangePassword } from 'src/app/shared/modals/changePassword';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  frmChangePass: FormGroup;
  email: any;

  constructor(private fb: FormBuilder, private authService: LoginService,
    private accountService: AccountService, private router: Router,
    private route: ActivatedRoute, private tostr: ToastrService) {
    if (this.route.snapshot.queryParams['id']) {
      this.route.queryParams.subscribe(params => { this.email = params.email });
    }
    else {
      this.email = localStorage.getItem("email");
    }
    this.frmChangePass = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}')]],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: ConfirmPasswordValidator("newPassword", "confirmPassword")
      }
    );
  }


  ngOnInit(): void {

  }
  onSubmit() {
    var changePassword = new ChangePassword(this.frmChangePass.controls["currentPassword"].value, this.frmChangePass.controls["email"].value, this.frmChangePass.controls["newPassword"].value)
    this.accountService.editUser(changePassword).subscribe(
      response => {
        this.tostr.success(response.toString());
        this.authService.signout();
       //this.router.navigate(["../login"]);

      },
      error => {
        this.tostr.error("Something went wrong, please try again with valid current password.");
      }
    );
  }
  onReset() {
    this.router.navigate(["admin/admin-users"]);
  }
}
