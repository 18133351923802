<div class="container-fluid">
    <div class="row">
        <div class="col-md-4 d-inline-block pt-2 pb-2">
            <h2 class=" mt-2 mb-4">{{email}}</h2>
        </div>
        <div class="col-md-8 text-right pt-2 pb-2">
            <a routerLink="../referral-edit" [queryParams]="{id:id}" class="btn btn-secondary ml-2">Edit Referral</a>
            <button type="button" class="btn btn-secondary ml-2" (click)="delete()">Delete Referral</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Referral Details</h5>
                </div>
                <div class="card-body table-responsive">
                    <table class="table table-striped" *ngIf="referral != null && referral != undefined">
                        <tr>
                            <td class="bt-none">EMAIL</td>
                            <td class="bt-none">{{referral.email}}</td>
                        </tr>
                        <tr>
                            <td>RESET PASSWORD TOKEN</td>
                            <td>
                                {{referral.resetPasseordToken}}
                            </td>
                        </tr>
                        <tr>
                            <td>RESET PASSWORD SENT AT</td>
                            <td>
                                {{referral.resetPasswordSentAt}}
                            </td>
                        </tr>
                        <tr>
                            <td>REMEMBER CREATED AT</td>
                            <td>
                                {{referral.rememberCreatedAt}}
                            </td>
                        </tr>
                        <tr>
                            <td>CURRENT SIGN IN AT</td>
                            <td>
                                {{referral.currentSignInAt}}
                            </td>
                        </tr>
                        <tr>
                            <td>LAST SIGN IN AT</td>
                            <td> {{referral.lastSignInAt}}</td>
                        </tr>
                        <tr>
                            <td>CURRENT SIGN IN IP</td>
                            <td>
                                {{referral.currentSignInIp}}
                            </td>
                        </tr>
                        <tr>
                            <td>LAST SIGN IN IP</td>
                            <td>
                                {{referral.lastSignInIp}}
                            </td>
                        </tr>
                        <tr>
                            <td>CONFIRMATION TOKEN</td>
                            <td>
                                {{referral.confirmationToken}}
                            </td>
                        </tr>
                        <tr>
                            <td>CONFIRMED AT</td>
                            <td> {{referral.confirmedAt}}</td>
                        </tr>
                        <tr>
                            <td>CONFIRMATION SENT AT</td>
                            <td>
                                {{referral.confirmationSentAt}}
                            </td>
                        </tr>
                        <tr>
                            <td>UNCONFIRMED EMAIL</td>
                            <td>
                                {{referral.unconfirmedEmail}}
                            </td>
                        </tr>
                        <tr>
                            <td>CREATED AT</td>
                            <td>
                                {{referral.createdAt}}
                            </td>
                        </tr>
                        <tr>
                            <td>UPDATED AT</td>
                            <td> {{referral.updatedAt}}</td>
                        </tr>
                        <tr>
                            <td>LAST NAME</td>
                            <td>
                                {{referral.lastName}}
                            </td>
                        </tr>
                        <tr>
                            <td>FIRST NAME</td>
                            <td>
                                {{referral.firstName}}
                            </td>
                        </tr>
                        <tr>
                            <td>ONBOARDED AT</td>
                            <td>
                                {{referral.onboardedAt}}
                            </td>
                        </tr>
                        <tr>
                            <td>GENDER</td>
                            <td> {{referral.gender}}</td>
                        </tr>
                        <tr>
                            <td>BORN ON</td>
                            <td>
                                {{referral.bornOn}}
                            </td>
                        </tr>
                        <tr>
                            <td>ADDRESS FIRST LINE</td>
                            <td>
                                {{referral.addressFirstLine}}
                            </td>
                        </tr>
                        <tr>
                            <td>ADDRESS SECOND LINE</td>
                            <td> {{referral.addressSecondLine}}</td>
                        </tr>
                        <tr>
                            <td>ADDRESS ZIP</td>
                            <td>
                                {{referral.addressZip}}
                            </td>
                        </tr>
                        <tr>
                            <td>ADDRESS CITY</td>
                            <td>
                                {{referral.addressCity}}
                            </td>
                        </tr>
                        <tr>
                            <td>ADDRESS STATE</td>
                            <td> {{referral.addressState}}</td>
                        </tr>
                        <tr>
                            <td>ADDRESS COUNTRY</td>
                            <td> {{referral.addressCountry}}</td>
                        </tr>
                        <tr>
                            <td>BIO</td>
                            <td> {{referral.bio}}</td>
                        </tr>
                        <tr>
                            <td>LOCATION</td>
                            <td> {{referral.location}}</td>
                        </tr>
                        <tr>
                            <td>BOATING EXPERIENCE</td>
                            <td> {{referral.boatingExperience}}</td>
                        </tr>
                        <tr>
                            <td>AVATAR URL</td>
                            <td> {{referral.avatarUrl}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID URL</td>
                            <td> {{referral.photoIdUrl}}</td>
                        </tr>
                        <tr>
                            <td>INVITATION TOKEN</td>
                            <td> {{referral.invitationToken}}</td>
                        </tr>
                        <tr>
                            <td>INVITATION CREATED AT</td>
                            <td> {{referral.invitationCreatedAt}}</td>
                        </tr>
                        <tr>
                            <td>INVITATION SENT AT</td>
                            <td> {{referral.invitationSentAt}}</td>
                        </tr>
                        <tr>
                            <td>INVITATION ACCEPTED AT</td>
                            <td> {{referral.invitationAcceptedAt}}</td>
                        </tr>
                        <tr>
                            <td>INVITATION LIMIT</td>
                            <td> {{referral.invitationLimit}}</td>
                        </tr>
                        <tr>
                            <td>INVITED BY</td>
                            <td> {{referral.invitedByFName}} {{referral.invitedByLName}}</td>
                        </tr>
                        <tr>
                            <td>INVITED BY TYPE</td>
                            <td> {{referral.invitedByType}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE ACCOUNT</td>
                            <td> {{referral.stripeAccount}}</td>
                        </tr>
                        <tr>
                            <td>ACCOUNT TYPE</td>
                            <td> {{referral.accountType}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS NAME</td>
                            <td> {{referral.businessName}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS TAX</td>
                            <td> {{referral.businessTax}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS ADDRESS FIRST LINE</td>
                            <td> {{referral.businessAddFirstLine}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS ADDRESS SECOND LINE</td>
                            <td> {{referral.businessAddSecondLine}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS ADDRESS ZIP</td>
                            <td> {{referral.businessAddZip}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS ADDRESS CITY</td>
                            <td> {{referral.businessAddCity}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS ADDRESS STATE</td>
                            <td> {{referral.businessAddState}}</td>
                        </tr>
                        <tr>
                            <td>BUSINESS ADDRESS COUNTRY</td>
                            <td> {{referral.businessAddCountry}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE FIELDS NEEDED</td>
                            <td> {{referral.stripeFieldsNeeded}}</td>
                        </tr>
                        <tr>
                            <td>CURRENCY</td>
                            <td> {{referral.currency}}</td>
                        </tr>
                        <tr>
                            <td>PROVIDER</td>
                            <td> {{referral.provider}}</td>
                        </tr>
                        <tr>
                            <td>UID</td>
                            <td> {{referral.userId}}</td>
                        </tr>
                        <tr>
                            <td>LOCALE</td>
                            <td> {{referral.locale}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE SECRET KEY</td>
                            <td> {{referral.stripeSecretKey}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE PUBLISHABLE KEY</td>
                            <td> {{referral.stripePublishableKey}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE STATUS</td>
                            <td> {{referral.stripeStatus}}</td>
                        </tr>
                        <tr>
                            <td>MADE FIRST BOOKING</td>
                            <td>
                                <p *ngIf="referral.madeFirstBooking!= '' && referral.madeFirstBooking == 'YES'"
                                    style="background-color: #6090DB;width:fit-content;">{{referral.madeFirstBooking}}
                                </p>
                                <p *ngIf="referral.madeFirstBooking!= '' && referral.madeFirstBooking == 'NO'"
                                    style="background-color: grey;width:fit-content;">{{referral.madeFirstBooking}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>RECEIVED FIRST BOOKING</td>
                            <td>
                                <p *ngIf="referral.receivedFirstBooking!= '' && referral.receivedFirstBooking == 'YES'"
                                    style="background-color: #6090DB;width:fit-content;">
                                    {{referral.receivedFirstBooking}}</p>
                                <p *ngIf="referral.receivedFirstBooking!= '' && referral.receivedFirstBooking == 'NO'"
                                    style="background-color: grey;width:fit-content;">{{referral.receivedFirstBooking}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>SAIL CERTIFICATE VERIFIED AT</td>
                            <td> {{referral.sailCertificateVerifiedAt}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID VERIFIED AT</td>
                            <td> {{referral.photoIdVerifiedAt}}</td>
                        </tr>
                        <tr>
                            <td>VERIFICATIONS UPDATED AT</td>
                            <td> {{referral.verificationsUpdatedAt}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE CREATION STATUS</td>
                            <td> {{referral.stripeCreationStatus}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE CUSTOMER</td>
                            <td> {{referral.stripeCustomer}}</td>
                        </tr>
                        <tr>
                            <td>SIGNUP BONUS</td>
                            <td>
                                <p *ngIf="referral.signUpBonus!= '' && referral.signUpBonus == 'YES'"
                                    style="background-color: #6090DB;width:fit-content;">{{referral.signUpBonus}}</p>
                                <p *ngIf="referral.signUpBonus!= '' && referral.signUpBonus == 'NO'"
                                    style="background-color: grey;width:fit-content;">{{referral.signUpBonus}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>OWNER SERVICE FEE PERCENT</td>
                            <td> {{referral.ownerServiceFeeCents}}</td>
                        </tr>
                        <tr>
                            <td>RENTER SERVICE FEE PERCENT</td>
                            <td> {{referral.renterServiceFeeCents}}</td>
                        </tr>
                        <tr>
                            <td>FOLLOW UP DATE</td>
                            <td> {{referral.followUpDate}}</td>
                        </tr>
                        <tr>
                            <td>ASSIGNEE</td>
                            <td> {{referral.assignee}}</td>
                        </tr>
                        <tr>
                            <td>DESIGNATION</td>
                            <td> {{referral.designation}}</td>
                        </tr>
                        <tr>
                            <td>FOLLOW UP TASK</td>
                            <td> {{referral.followUpTask}}</td>
                        </tr>
                        <tr>
                            <td>DELETED AT</td>
                            <td> {{referral.deletedAt}}</td>
                        </tr>
                        <tr>
                            <td>FOLLOW UP STATUS</td>
                            <td> {{referral.followUpStatus}}</td>
                        </tr>
                        <tr>
                            <td>CURRENT TIMEZONE</td>
                            <td> {{referral.currentTimeZone}}</td>
                        </tr>
                        <tr>
                            <td>SAIL CERTIFICATE TYPE</td>
                            <td> {{referral.sailCertificateType}}</td>
                        </tr>
                        <tr>
                            <td>SAIL CERTIFICATE STATUS</td>
                            <td> {{referral.sailCertificateStatus}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID STATUS</td>
                            <td> {{referral.photoIdStatus}}</td>
                        </tr>
                        <tr>
                            <td>SAIL CERTIFICATE DECLINE REASON</td>
                            <td> {{referral.sailCertificateDeclineReason}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID DECLINE REASON</td>
                            <td> {{referral.photoIdDeclineReason}}</td>
                        </tr>
                        <tr>
                            <td>IS BOAT OWNER</td>
                            <td>
                                <p *ngIf="referral.isBoatOwner!= '' && referral.isBoatOwner == 'YES'"
                                    style="background-color: #6090DB;width:fit-content;">{{referral.isBoatOwner}}</p>
                                <p *ngIf="referral.isBoatOwner!= '' && referral.isBoatOwner == 'NO'"
                                    style="background-color: grey;width:fit-content;">{{referral.isBoatOwner}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>STRIPE DISABLED REASON</td>
                            <td> {{referral.stripeDisabledReason}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE VERIFICATION DETAILS CODE</td>
                            <td> {{referral.stripeVerificationCode}}</td>
                        </tr>
                        <tr>
                            <td>HAS SAIL CERTIFICATE</td>
                            <td>
                                <p *ngIf="referral.hasSailCertificate!= '' && referral.hasSailCertificate == 'YES'"
                                    style="background-color: #6090DB;width:fit-content;">{{referral.hasSailCertificate}}
                                </p>
                                <p *ngIf="referral.hasSailCertificate!= '' && referral.hasSailCertificate == 'NO'"
                                    style="background-color: grey;width:fit-content;">{{referral.hasSailCertificate}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>HAS BOAT RENTAL INTEREST</td>
                            <td>
                                <p *ngIf="referral.hasBoatRentalInterest!= '' && referral.hasBoatRentalInterest == 'YES'"
                                    style="background-color: #6090DB;width:fit-content;">
                                    {{referral.hasBoatRentalInterest}}</p>
                                <p *ngIf="referral.hasBoatRentalInterest!= '' && referral.hasBoatRentalInterest == 'NO'"
                                    style="background-color: grey;width:fit-content;">{{referral.hasBoatRentalInterest}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>STATS</td>
                            <td> {{referral.stats}}</td>
                        </tr>
                        <tr>
                            <td>MARKETING SOURCE</td>
                            <td> {{referral.marketingSource}}</td>
                        </tr>
                        <tr>
                            <td>MARKETING SOURCE NOTE</td>
                            <td> {{referral.marketingSourceNote}}</td>
                        </tr>
                        <tr>
                            <td>AVERAGE REVIEW STARS</td>
                            <td> {{referral.averageReviewStars}}</td>
                        </tr>
                        <tr>
                            <td>STRIPE PERSON</td>
                            <td> {{referral.stripePerson}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID BACK URL</td>
                            <td> {{referral.photoIdBackUrl}}</td>
                        </tr>
                        <tr>
                            <td>DOCUMENT ID URL</td>
                            <td> {{referral.documentIdUrl}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID BACK STATUS</td>
                            <td> {{referral.photoIdBackStatus}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID BACK DECLINE REASON</td>
                            <td> {{referral.photoIdBackDeclineReason}}</td>
                        </tr>
                        <tr>
                            <td>PHOTO ID BACK VERIFIED AT</td>
                            <td> {{referral.photoIdBackVerifiedAt}}</td>
                        </tr>
                        <tr>
                            <td>DOCUMENT ID STATUS</td>
                            <td> {{referral.documentIdStatus}}</td>
                        </tr>
                        <tr>
                            <td>DOCUMENT ID DECLINE REASON</td>
                            <td> {{referral.documentIdDeclineReason}}</td>
                        </tr>
                        <tr>
                            <td>DOCUMENT ID VERIFIED AT</td>
                            <td> {{referral.documentIdVerifiedAt}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>