<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Amenity Categories</h2>
    <div class="row row-y">
        <div class="col-md-4">
            <div ngbDropdown class="d-inline-block pt-2 pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteUsers()">Delete Selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-8 text-right">
            <button class="btn btn-secondary float-right ml-2" (click)="open(mymodal)">Filters</button>

            <a routerLink="../amenitycategory-new">
                <button type="button" class="btn btn-secondary float-right">New Amenity Category</button>
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked" (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('Name')" style="cursor: pointer;">Name</a>
                            </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="amenityDetails != undefined  && amenityDetails.totalEntries > 0">
                        <tr
                            *ngFor="let amenitydata of amenityDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:amenityDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" value="{{amenitydata.id}}" [(ngModel)]="amenitydata.selected"
                                        (change)="checkboxChange($event,amenitydata.id)">
                                </div>
                            </td>
                            <td><a routerLink="../amenitycategory-view"
                                    [queryParams]="{id: amenitydata.id}">{{amenitydata.id}}</a></td>
                            <td>{{amenitydata.name}} </td>

                            <td>
                                <a routerLink="../amenitycategory-view" [queryParams]="{id: amenitydata.id}">
                                    <button type="submit" title="Click to View" class="btn">
                                        <i class="bi bi-eye"></i>
                                    </button>
                                </a>
                                <a routerLink="../amenitycategory-edit" [queryParams]="{id: amenitydata.id}"
                                    class="ml-2">
                                    <button type="submit" title="Click to Edit" class="btn">
                                        <i class="bi bi-pencil-square"></i>
                                    </button>
                                </a>
                                <button type="submit" title="Click to Delete" (click)="onDelete(amenitydata.id)"
                                    class="btn">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="amenityDetails != undefined && amenityDetails.totalEntries != undefined && amenityDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="name">Name</label>
                </div>
                <div class="col-md-8">
                    <input type="text" formControlName="name" class="form-control" id="NameText">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="onFilter()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="reset()">Clear Filters</button>
    </div>
</ng-template>