import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BoatLibraryService } from 'src/app/services/boat-library.service';
import { environment } from 'src/environments/environment';
import * as filestack from 'filestack-js';

@Component({
  selector: 'app-library-edit',
  templateUrl: './library-edit.component.html',
  styleUrls: ['./library-edit.component.css']
})
export class LibraryEditComponent implements OnInit {
  id: number = 0;
  frmLibrary: FormGroup;
  fileUrl: string = "";
  boatLibraryDetails: any;
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private boatLibraryService: BoatLibraryService,
    private router: Router, private tostr: ToastrService) {
    this.frmLibrary = this.fb.group({
      model: ['', [Validators.required]],
      type: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });

    this.boatLibraryService.fetchBoatLibrary(this.id).subscribe(
      response => {
        this.boatLibraryDetails = response;
        if (this.boatLibraryDetails != null) {
          this.frmLibrary.setValue({
            model: this.boatLibraryDetails.boatModel,
            type: this.boatLibraryDetails.boatType
          });
          this.fileUrl = this.boatLibraryDetails.imageUrl;
        }
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  submit() {
    if (this.frmLibrary.valid) {
      var boatLibraryRequest = {
        boatType: this.frmLibrary.controls["type"].value,
        boatModel: this.frmLibrary.controls["model"].value,
        imageUrl: this.fileUrl,
        id: this.id
      };
      this.boatLibraryService.updateBoatLibrary(boatLibraryRequest)
        .subscribe(
          res => {
            this.tostr.success("Updated successfully.");
            this.router.navigate(['admin/library']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }

  reset() {
    this.router.navigate(["admin/library"]);
  }

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string }[]; }) => (this.fileUrl = file.filesUploaded[0].url)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

}
