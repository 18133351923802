<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Boat Statistics</h2>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4> Booking Manager</h4>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped" *ngIf="boatStatisticsDetail != null && boatStatisticsDetail != undefined">
                                <tr>
                                    <td> Published :</td>
                                    <td>{{boatStatisticsDetail.bookingManagerPublishedCount}}</td>
                                </tr>
                                <tr>
                                    <td>Unpublished :</td>
                                    <td>{{boatStatisticsDetail.bookingManagerUnpublishedCount}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h4> User Owned</h4>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped" *ngIf="boatStatisticsDetail != null && boatStatisticsDetail != undefined">
                                <tr>
                                    <td> Published :</td>
                                    <td>{{boatStatisticsDetail.userOwnedPublishedCount}}</td>
                                </tr>
                                <tr>
                                    <td>Unpublished :</td>
                                    <td>{{boatStatisticsDetail.userOwnedUnpublishedCount}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>