import { Component, OnDestroy, OnInit } from '@angular/core';
import { TripTypeService } from 'src/app/services/trip-type/trip-type.service';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-trip-types',
  templateUrl: './trip-types.component.html',
  styleUrls: ['./trip-types.component.css']
})

export class TripTypesComponent implements OnInit, OnDestroy {
  tripTpyeDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  includeAll: boolean = true;
  defaultvalue: number = 0;
  frmFilter: FormGroup;
  orderColumn: string = "id";
  orderDirection: string = "desc";
  navigationSubscription: any;
  public exportFileName :string = "trip_types";

  constructor(private tripTypeService: TripTypeService, private modalService: NgbModal, private fb: FormBuilder,
    private router: Router, private tostr: ToastrService) {

    this.frmFilter = this.fb.group({
      title: [''],
      subTitle: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.reset();
      }
    });

  }

  ngOnInit(): void {
    this.getTripTypes();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getTripTypes() {
    var tripTypeRequest = {
      title: this.frmFilter.controls["title"].value,
      subTitle: this.frmFilter.controls["subTitle"].value,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      includeAll: this.includeAll

    };
    this.tripTypeService.fetchTripTypes(tripTypeRequest)
      .subscribe(
        response => {
          this.tripTpyeDetails = response;
          this.changeCheckBoxStatus();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }
  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getTripTypes();
  }

  getPage(page: number) {
    this.page = page;
    this.getTripTypes();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  onFilter() {
    let title = this.frmFilter.controls["title"].value;
    let subTitle = this.frmFilter.controls["subTitle"].value;
    if (title == "" && subTitle == "") {
      this.includeAll = true;
    }
    else {
      this.includeAll = false;
    }
    this.getTripTypes();
    this.modalService.dismissAll('submit');
  }

  reset() {
    this.frmFilter.setValue({
      title: "",
      subTitle: ""
    });
    this.includeAll = true;
    this.modalService.dismissAll('submit');
    this.getTripTypes();
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected Trip Type?");
    if (isDelete) {
      this.tripTypeService.DeleteTripType([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getTripTypes();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.tripTpyeDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.tripTpyeDetails.data[a].id);
      }
      this.tripTpyeDetails.data.forEach((tripTpyedata) => {
        tripTpyedata.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.tripTpyeDetails.data.forEach((tripTpyedata) => {
        tripTpyedata.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.tripTpyeDetails.data.length && this.tripTpyeDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  deleteTripTypes() {
    var isDelete = confirm("are you sure want to delete selected Trip Types?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.tripTypeService.DeleteTripType(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getTripTypes();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

}
