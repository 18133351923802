import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConversationsService } from 'src/app/services/conversations.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-conversation-view',
  templateUrl: './conversation-view.component.html',
  styleUrls: ['./conversation-view.component.css']
})
export class ConversationViewComponent implements OnInit {
  id: number = 0;
  conversation: any;
  resourceType: string = "";
  resourceId: string = "";

  constructor(private userService: UsersService,
    private router: Router, private route: ActivatedRoute,
    private conversationService: ConversationsService, private tostr: ToastrService) {
    this.route.queryParams.subscribe(param => { this.id = param.id });
  }

  ngOnInit(): void {
    this.resourceId = this.id.toString();
    this.resourceType = "Conversation";
    this.getConversationDetails();
  }

  getConversationDetails() {
    this.conversationService.fetchConversationDetails(this.id).subscribe(
      res => {
        this.conversation = res;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }
}
