<div class="container-fluid">
    <div class="row">
        <div class="col-md-6">
            <h2 class=" mt-2">Conversations</h2>
        </div>
        <div class="col-md-6">
            <button class="btn btn-secondary float-right mt-2" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th>
                                Guest
                            <th>
                                Host
                            </th>
                            <th>
                                References
                            </th>
                            <th>
                                <a (click)="toggleSort('FollowUpStatus')" style="cursor: pointer;">Follow Up Status</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('FollowUpTask')" style="cursor: pointer;">Follow Up Task</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('FollowUpDate')" style="cursor: pointer;">Follow Up Date</a>
                            </th>
                            <th>
                                Assignee
                            </th>
                            <th>
                                <a (click)="toggleSort('CreatedAt')" style="cursor: pointer;">Created At</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('UpdatedAt')" style="cursor: pointer;">Last Message</a>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="conversationsDetails != undefined  && conversationsDetails.totalEntries > 0">
                        <tr
                            *ngFor="let conversation of conversationsDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:conversationsDetails.totalEntries}">
                            <td>
                                <a routerLink="../conversation-view" [queryParams]="{id: conversation.id}"
                                    class="text-primary" style="text-decoration: underline;">{{conversation.id}}</a>
                            </td>
                            <td>
                                <a (click)="gotoUser(conversation.guestFName,conversation.guestLName,conversation.guestId)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{conversation.guestFName}}
                                    {{conversation.guestLName}}</a>
                            </td>
                            <td>
                                <a (click)="gotoUser(conversation.hostFName,conversation.hostLName,conversation.hostId)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{conversation.hostFName}}
                                    {{conversation.hostLName}}</a>
                            </td>
                            <td>
                                <a routerLink="../boat-view"
                                    [queryParams]="{id: conversation.boatId,name:conversation.boatName}"
                                    class="text-primary" style="text-decoration: underline;">{{conversation.boatName}}
                                    {{conversation.boatModel}}</a>
                            </td>
                            <td>
                                <p [style.backgroundColor]="conversation.statusColor"
                                    style="color: #ffffff;width: fit-content;">{{conversation.followUpStatus}}</p>
                            </td>
                            <td>
                                {{conversation.followUpTask}}
                            </td>
                            <td>
                                {{conversation.followUpDate}}
                            </td>
                            <td>
                                {{conversation.assignee}}
                            </td>
                            <td>{{conversation.createdAt}} </td>
                            <td>{{conversation.lastMessage}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="conversationsDetails != undefined && conversationsDetails.totalEntries != undefined && conversationsDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="followUpTask">FOLLOW UP TASK</label>
                </div>
                <div class="col-md-8" *ngIf="followUpTaskList != null && followUpTaskList.length > 0">
                    <select formControlName="followUpTask" id="followUpTask" class="form-control" ng-options="">
                        <option *ngFor="let task of followUpTaskList" value={{task.value}}>{{task.text}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdFrom">FOLLOW UP DATE FROM</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdFrom" formControlName="from" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdTo">FOLLOW UP DATE TO</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdTo" formControlName="to" class="form-control">
                </div>
            </div>
            <!-- <div class="form-group row">
                <div class="col-md-4">
                    <label for="assignee">ASSIGNEE</label>
                </div>
                <div class="col-md-8" *ngIf="assignees != null && assignees.length > 0">
                    <select formControlName="assignee" id="assignee" class="form-control" ng-options="">
                        <option *ngFor="let assignee of assignees" value={{assignee.id}}>{{assignee.email}}</option>
                    </select>
                </div>
            </div> -->
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="followUpStatus">FOLLOW UP STATUS</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="followUpStatus" id="followUpStatus" class="form-control" ng-options="">
                        <option value="confirmed">Confirmed</option>
                        <option value="follow_up">Follow Up</option>
                        <option value="closed">Closed</option>
                        <option value="take_action">Take Action</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="closureReason">CLOSURE REASON</label>
                </div>
                <div class="col-md-8" *ngIf="closureReasons != null && closureReasons.length > 0">
                    <select formControlName="closureReason" id="closureReason" class="form-control" ng-options="">
                        <option *ngFor="let reason of closureReasons" value={{reason.value}}>{{reason.text}}</option>
                    </select>
                </div>
            </div>
            <!-- <div class="form-group row">
                <div class="col-md-4">
                    <label for="country">COUNTRY</label>
                </div>
                <div class="col-md-8" *ngIf="countries != null && countries.length > 0">
                    <select formControlName="country" id="country" class="form-control" ng-options="">
                        <option *ngFor="let country of countries" value={{country.code}}>{{country.name}}</option>
                    </select>
                </div>
            </div> -->
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>