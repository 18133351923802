import { Component, OnInit } from '@angular/core';
import { AssignmentsService } from 'src/app/services/assignment/assignments.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login.service';


@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.css']
})
export class AssignmentsComponent implements OnInit {
  adminAssignments: any;
  id: string = "";

  constructor(private AssignmentService: AssignmentsService, private route: ActivatedRoute,
    public loader: LoaderService, private tostr: ToastrService,private authService: LoginService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => { this.id = params.id });
    if(this.id == null || this.id == undefined){
      this.id = this.authService.userId;
    }
    this.AssignmentService.fetchAdminAssignments(this.id).pipe(finalize(() => {
    })).subscribe(res => {
      this.adminAssignments = res;
    },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      });
  }

}
