import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BoatService } from 'src/app/services/boat.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-boat-details',
  templateUrl: './boat-details.component.html',
  styleUrls: ['./boat-details.component.css']
})
export class BoatDetailsComponent implements OnInit {
  @Input() boatName;
  id: number = 0;
  @Input() boatDetails: any;

  constructor(private boatService: BoatService, private route: ActivatedRoute,
    private userService: UsersService, private router: Router, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });

  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

}
