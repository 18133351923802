import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class RuleService extends BaseService {
  httpOptions: any;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }

  fetchRules(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'rule/rules', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  fetchRule(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'rule/' + id, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  SaveRule(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'rule', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  EditRule(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'rule', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  DeleteRules(ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'rule/remove-rules', options).pipe(
      catchError(this.handleError)
    );
  }

  fetchRuleDataForExport() {
    return this.http.get(this.configService.resourceApiURI + 'rule/rule-data-export', this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

}
