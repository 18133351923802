<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="aminityViewDetails != null">{{aminityViewDetails.name}}</h2>
        </div>
        <div class="col-lg-6 col-md-12 text-right" *ngIf="aminityViewDetails != null">
            <a routerLink="../amenity-edit" [queryParams]="{id: aminityViewDetails.id}" class="btn btn-secondary">Edit
                Amenity</a>
            <button type="button" (click)="delete(aminityViewDetails.id)" class="btn btn-secondary m-2">Delete
                Amenity</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="aminityViewDetails != undefined && aminityViewDetails != null">
                <div class="card-header">
                    <h5>Amenity Details</h5>
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Id</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{aminityViewDetails.id}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Category</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label><a routerLink="../amenitycategory-view"
                                    [queryParams]="{id: aminityViewDetails.categoryId}">{{aminityViewDetails.categoryName}}</a></label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Name</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{aminityViewDetails.name}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>InActive Icon</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <img src="{{aminityViewDetails.inActiveIconUrl}}" height="40px" width="40px" alt="icon"
                                style="border-radius: 20px;">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Active Icon </label>
                        </div>
                        <div class="col-md-3 float-left">
                            <img src="{{aminityViewDetails.activeIconUrl}}" height="40px" width="40px" alt="icon"
                                style="border-radius: 20px;">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Green Icon </label>
                        </div>
                        <div class="col-md-3 float-left">
                            <img src="{{aminityViewDetails.greenIconUrl}}" height="40px" width="40px" alt="icon"
                                style="border-radius: 20px;">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>InActive Icon File Type </label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{aminityViewDetails.inActiveIconFileType}}</label>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Active Icon File Type </label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{aminityViewDetails.activeIconFileType}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Green Icon File Type </label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{aminityViewDetails.greenIconFileType}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>