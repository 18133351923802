import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from 'src/app/services/insurance/insurance.service';


@Component({
  selector: 'app-insurance-price-ranges',
  templateUrl: './insurance-price-ranges.component.html',
  styleUrls: ['./insurance-price-ranges.component.css']
})

export class InsurancePriceRangesComponent implements OnInit, OnDestroy {
  insurancePriceDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  includeAll: boolean = true;
  defaultvalue: number = 0;
  frmFilter: FormGroup;
  orderColumn: string = "id";
  orderDirection: string = "ASC";
  navigationSubscription: any;
  public exportFileName: string = "insurance_price_range";

  constructor(private insuranceService: InsuranceService, private modalService: NgbModal,
    private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      boatPrice: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.getInsurancePriceRanges();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getInsurancePriceRanges() {
    var request = {
      boatPrice: this.frmFilter.controls["boatPrice"].value == "" || this.frmFilter.controls["boatPrice"].value == null ? 0 : this.frmFilter.controls["boatPrice"].value,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      includeAll: this.includeAll

    };
    this.insuranceService.fetchInsurancePriceRanges(request)
      .subscribe(
        response => {
          this.insurancePriceDetails = response;
          // this.changeCheckBoxStatus();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getInsurancePriceRanges();
  }

  getPage(page: number) {
    this.page = page;
    this.getInsurancePriceRanges();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  applyFilter() {
    let boatPrice = this.frmFilter.controls["boatPrice"].value;
    if (boatPrice == "" || boatPrice == null || boatPrice == 0) {
      this.includeAll = true;
    }
    else {
      this.includeAll = false;
    }
    this.getInsurancePriceRanges();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      boatPrice: 0
    });
    this.includeAll = true;
    this.modalService.dismissAll('submit');
    this.getInsurancePriceRanges();
  }

  // globalCheckboxChange(e: any) {
  //   this.checkboxCheckedList = []
  //   if (e.target.checked) {
  //     this.isDisableBatch = false;
  //     for (let a = 0; a < this.insurancePriceDetails.data.length; a++) {
  //       this.checkboxCheckedList.push(this.insurancePriceDetails.data[a].id);
  //     }
  //     this.insurancePriceDetails.data.forEach((insuranceRangedata) => {
  //       insuranceRangedata.selected = true;
  //     });
  //   }

  //   else {
  //     this.isDisableBatch = true;
  //     this.insurancePriceDetails.data.forEach((insuranceRangedata) => {
  //       insuranceRangedata.selected = false;
  //     });
  //   }
  //   this.getAllCheckedStatus();
  // }

  // checkboxChange(e: any, id: number) {
  //   if (e.target.checked) {
  //     this.isDisableBatch = false;
  //     this.checkboxCheckedList.push(id);
  //   }
  //   else {
  //     this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
  //     if (this.checkboxCheckedList.length <= 0) {
  //       this.isDisableBatch = true;
  //     }
  //   }
  //   this.getAllCheckedStatus();
  // }

  // changeCheckBoxStatus() {
  //   this.isDisableBatch = true;
  //   this.checkboxCheckedList = [];
  //   this.getAllCheckedStatus();
  // }

  // getAllCheckedStatus() {
  //   if (this.checkboxCheckedList.length === this.insurancePriceDetails.data.length && this.insurancePriceDetails.data.length > 0) {
  //     this.isGlobalChecked = true;
  //   }
  //   else {
  //     this.isGlobalChecked = false;
  //   }
  // }

  // onDelete(id: number) {
  //   var isDelete = confirm("are you sure want to delete selected Insurance Price Range?");
  //   if (isDelete) {
  //     this.insuranceService.DeleteInsurancePriceRange([id])
  //       .pipe(finalize(() => {
  //       })).subscribe(
  //         response => {
  //           this.tostr.success("Deleted Successfully.");
  //           this.getInsurancePriceRanges();
  //         },
  //         error => {
  //           console.log(error);
  //           this.tostr.error("Something went wrong, please try again.");
  //         }
  //       );
  //   }
  // }

  // deleteInsurancePriceRanges() {
  //   var isDelete = confirm("are you sure want to delete selected insurance price ranges?");
  //   if (isDelete) {
  //     if (this.checkboxCheckedList.length > 0) {
  //       this.insuranceService.DeleteInsurancePriceRange(this.checkboxCheckedList).subscribe(
  //         response => {
  //           this.tostr.success("Deleted Successfully.");
  //           this.checkboxCheckedList = [];
  //           this.getInsurancePriceRanges();
  //         },
  //         error => {
  //           console.log(error);
  //           this.tostr.error("Something went wrong, please try again.");
  //         }
  //       );
  //     }
  //   }
  // }


}
