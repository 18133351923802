import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmenitiesService } from 'src/app/services/amenities/amenities.service';
import { AmenitycategoriesService } from 'src/app/services/amenitycategories/amenitycategories.service'
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BmAmenityService } from 'src/app/services/bm-amenity.service';


@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.css']
})

export class AmenitiesComponent implements OnInit, OnDestroy {
  aminitiesDetails: any;
  amenitiesCategories: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  defaultvalue: number = 0;
  frmFilter: FormGroup;
  orderColumn: string = "a.id";
  orderDirection: string = "desc";
  navigationSubscription: any;
  public exportFileName: string = "amenities";

  constructor(
    //private amenityService: AmenitiesService,
    // private amenityCategoriesService: AmenitycategoriesService
    private amenityService: BmAmenityService,
    private modalService: NgbModal,
    private fb: FormBuilder, private router: Router,
    private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      category: [0],
      name: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.reset();
      }
    });

  }

  ngOnInit(): void {
    this.setPageForReload();
    this.getAmenities();
    this.amenityCategories();

  }

  setPageForReload() {
    var pageNumber = localStorage.getItem('pageAmenities');
    if (pageNumber !== null) { this.page = +pageNumber; }
    else { this.page = 1; }

    window.onbeforeunload = function () {
      localStorage.setItem('pageAmenities', $(".ngx-pagination .current span:eq(1)").text());
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getAmenities() {
    var amenityRequest = {
      categoryId: this.frmFilter.controls["category"].value == null ? 0 : this.frmFilter.controls["category"].value,
      name: this.frmFilter.controls["name"].value,
      page: this.page > 0 ? this.page : 1,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection
    };

    this.amenityService.fetchAmenities(amenityRequest)
      .subscribe(
        response => {
          this.aminitiesDetails = response;
          this.changeCheckBoxStatus();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  amenityCategories() {
    this.amenityService.fetchAmenitiesCategories()
      .subscribe(
        response => {
          this.amenitiesCategories = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );

  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getAmenities();
  }

  getPage(page: number) {
    this.page = page;
    this.getAmenities();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  onFilter() {
    this.page = 1;
    this.getAmenities();
    this.modalService.dismissAll('submit');
  }

  reset() {
    this.frmFilter.setValue({
      category: 0,
      name: ""
    });
    this.modalService.dismissAll('submit');
    this.page = 1;
    this.getAmenities();
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected Amenitie?");
    if (isDelete) {
      this.amenityService.AmenitiesDelete([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getAmenities();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.aminitiesDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.aminitiesDetails.data[a].id);
      }
      this.aminitiesDetails.data.forEach((amenitydata) => {
        amenitydata.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.aminitiesDetails.data.forEach((amenitydata) => {
        amenitydata.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.aminitiesDetails.data.length && this.aminitiesDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  deleteUsers() {
    var isDelete = confirm("are you sure want to delete selected Amenities?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.amenityService.AmenitiesDelete(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getAmenities();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

}
