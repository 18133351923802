import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { ConversationsService } from 'src/app/services/conversations.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CommonProvider } from 'src/app/shared/modals/common-provider';
import { ToastRef, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.css']
})
export class ConversationsComponent implements OnInit, OnDestroy {
  page: number = 1;
  pageSize: number = 10;
  orderColumn: string = "Id"
  orderDirection: string = "DESC"
  frmFilter: FormGroup;
  countries: any;
  closureReasons: any;
  assignees: any;
  followUpTaskList: any;
  includeAll: boolean = true;
  conversationsDetails; any;
  navigationSubscription: any;
  public exportFileName :string = "conversations";

  constructor(private userService: UsersService, private router: Router,
    private modalService: NgbModal, private fb: FormBuilder,
    private commonService: CommonService, private common: CommonProvider,
    private conversationService: ConversationsService, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      followUpTask: [''],
      followUpStatus: [''],
      from: [''],
      to: [''],
      assignee: [''],
      closureReason: [''],
      country: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.getConversations();
    //this.getCountries();
    // this.getAssignees();
    this.followUpTaskList = this.common.getFollowUpTask();
    this.closureReasons = this.common.getClosureReason();
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getCountries() {
    this.commonService.fetchCountryList().subscribe(
      res => {
        this.countries = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  getAssignees() {
    this.commonService.fetchAssignees().subscribe(
      res => {
        this.assignees = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  getConversations() {
    var convParams = {
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      followUpTask: this.frmFilter.controls["followUpTask"].value,
      followUpStatus: this.frmFilter.controls["followUpStatus"].value,
      closureReason: this.frmFilter.controls["closureReason"].value,
      countryCode: "",
      AssigneeId: this.frmFilter.controls["assignee"].value != "" ? this.frmFilter.controls["assignee"].value : null,
      from: this.frmFilter.controls["from"].value,
      to: this.frmFilter.controls["to"].value,
      includeAll: this.includeAll
    };
    this.conversationService.fetchConversationList(convParams).subscribe(
      res => {
        this.conversationsDetails = res;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getConversations();
  }

  getPage(page: number) {
    this.page = page;
    this.getConversations();
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    this.includeAll = false;
    this.getConversations();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.reset();
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getConversations();
  }

}
