import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../login.service';
import { UserService } from '../services/user/user.service';
import { LoaderService } from 'src/app/loader.service';
import { PoEditorService } from '../services/cms services/po-Editors/po-editor.service';
import { ToastrService } from 'ngx-toastr';
import { error } from 'console';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  isCollapsed = true;
  isCollapsedSystem = false;
  claims: any;
  user: any;
  buttonClass: string = '';

  constructor(private authService: LoginService, public loader: LoaderService, private userService: UserService,
    private cookieService: CookieService, private router: Router, private poeditorService: PoEditorService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {

    ///To keep class active of a clicked menu item
    // $(document).ready(function () {
    //   $('a').click(function () {
    //     $('a.list-group-item.list-group-item-action').removeClass("background-light");
    //     $(this).addClass("background-light");
    //   });
    // });

    //Toggle Click Function
    $("#menu-toggle").click(function (e) {
      e.preventDefault();
      $("#wrapper").toggleClass("toggled");
    });
    this.user = this.authService.email;

    //to replace menu icon  when open in small window size screen or mobile screen 
    this.switchIcon();
  }
  logout() {
    this.authService.signout();
  }
  toggleButtonClass() {
    if (this.buttonClass == 'bi bi-x-lg') {
      this.buttonClass = 'bi bi-arrow-right';

    } else {
      this.buttonClass = 'bi bi-x-lg';
    }
  }
  switchIcon() {
    if (window.matchMedia("(max-width:599px)").matches) {
      this.buttonClass = 'bi bi-arrow-right';
    }
    else {
      this.buttonClass = 'bi bi-x-lg';
    }
  }

  goToDashboard() {
    this.router.navigate(["admin/dashboard"]);
  }

  refreshCache() {
    this.poeditorService.refresh().subscribe(
      res => {
        if (res != undefined && res.toString() == "Success") {
          this.toastrService.success(res.toString());
        }
        else {
          this.toastrService.error(res.toString());
        }
      },
      error => {
        //console.log(error);
        this.toastrService.error("Something went wrong, please try again.");
      }
    );
  }
}
