import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {

  error: boolean = false;
  spiner: boolean = false;
  constructor(private authService: LoginService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }
  async ngOnInit() {
    this.spiner = true;
    // check for error
    if (this.route.snapshot.fragment.indexOf('error') >= 0) {
      this.error = true;
      console.log(this.error);
      return;
    }

    // await this.authService.completeAuthentication();
    // this.router.navigate(['admin']);
    // this.spiner = false;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let currentUrl = event.url;
        let currentUrlanother = window.location.pathname + window.location.search;
        sessionStorage.setItem('activeUri', currentUrl);
      }
    });

    if (this.authService.isAuthenticated()) {
      // here's the important bit ---
      let uri = sessionStorage.getItem('activeUri');
      if (uri !== null) {
        this.router.navigateByUrl(uri, { skipLocationChange: false });
      }
      else {
        this.router.navigate(['admin']);
      }
    }
    else {
      await this.authService.completeAuthentication();
      this.router.navigate(['admin']);
    }
    this.spiner = false;
  }
}
