import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PopularDestinationService } from 'src/app/services/popular-destination/popular-destination.service';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-popular-destination-edit',
  templateUrl: './popular-destination-edit.component.html',
  styleUrls: ['./popular-destination-edit.component.css']
})
export class PopularDestinationEditComponent implements OnInit {
  popularDestinationEditDetails: any;
  id: number = 0;
  frmEditPopulardestination: FormGroup;
  fileUrl: string = "";


  constructor(private activatedRoute: ActivatedRoute, private popularDestinationService: PopularDestinationService, private fb: FormBuilder,
    private router: Router, private tostr: ToastrService) {
    this.frmEditPopulardestination = this.fb.group({
      name: ['', [Validators.required]],
      latitude: ['', [Validators.required]],
      longitude: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.getPopularDestination();
  }

  getPopularDestination() {
    this.popularDestinationService.fetchPopularDestination(this.id)
      .subscribe(
        response => {
          this.popularDestinationEditDetails = response;
          if (this.popularDestinationEditDetails != null) {
            this.frmEditPopulardestination.setValue({
              name: this.popularDestinationEditDetails.name,
              latitude : this.popularDestinationEditDetails.latitude,
              longitude : this.popularDestinationEditDetails.longitude
            });
            this.fileUrl = this.popularDestinationEditDetails.iconUrl;
          }
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }


  reset() {
    this.router.navigate(["admin/popular-destinations"]);
  }

  submit() {
    if (this.frmEditPopulardestination.valid) {
      var popularDestinationRequest = {
        id: this.id,
        name: this.frmEditPopulardestination.controls["name"].value,
        latitude: this.frmEditPopulardestination.controls["latitude"].value,
        longitude: this.frmEditPopulardestination.controls["longitude"].value,
        iconUrl: this.fileUrl
      }
      this.popularDestinationService.EditPopularDestination(popularDestinationRequest).subscribe(
        res => {
          this.tostr.success("Updated successfully.");
          this.router.navigate(['admin/popular-destinations']);
        },
        err => {
          this.tostr.error("Something went wrong, please try again.")
          console.log(err);
        });
    }
  }


  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string; }[]; }) => this.fileUrl = file.filesUploaded[0].url
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

}
