<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Booking-Broadcasts</h2>
    <div class="row row-y">
        <div class="col-md-5">
            <div ngbDropdown class="d-inline-block pt-2 pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteBookingBroadcasts()">Delete selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <button class="btn btn-secondary float-right filter-btn" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal"  [checked]="isGlobalChecked" (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.userName')" style="cursor: pointer;">User</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.location')" style="cursor: pointer;">Location</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.fromDate')" style="cursor: pointer;">From Date</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.toDate')" style="cursor: pointer;">To Date</a>
                            </th>
                            <th scope="col">Price Range
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.boatType')" style="cursor: pointer;">Boat type</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.bookingType')" style="cursor: pointer;">Booking Type</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('a.createdAt')" style="cursor: pointer;">Created At</a>
                            </th>
                            <th scope="col" class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="bookingBroadcastsDetails != undefined  && bookingBroadcastsDetails.totalEntries > 0">
                        <tr
                            *ngFor="let bookingBroadcastData of bookingBroadcastsDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:bookingBroadcastsDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" value="{{bookingBroadcastData.id}}"
                                    [(ngModel)]="bookingBroadcastData.selected"
                                        (change)="checkboxChange($event,bookingBroadcastData.id)">
                                </div>
                            </td>
                            <td><a routerLink="../booking-broadcast-view"
                                    [queryParams]="{id: bookingBroadcastData.id}">{{bookingBroadcastData.id}}</a></td>
                            <td><a (click)="gotoUser(bookingBroadcastData.userId)" class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{bookingBroadcastData.userName}}</a>
                            </td>
                            <td>{{bookingBroadcastData.location}} </td>
                            <td>{{bookingBroadcastData.fromDate}} </td>
                            <td>{{bookingBroadcastData.toDate}} </td>
                            <td>{{bookingBroadcastData.minimumPrice}} - {{bookingBroadcastData.maximumPrice}} </td>
                            <td>{{bookingBroadcastData.boatType}} </td>
                            <td>{{bookingBroadcastData.bookingType}} </td>
                            <td>{{bookingBroadcastData.createdAt}} </td>
                            <td>
                                <a routerLink="../booking-broadcast-view" [queryParams]="{id: bookingBroadcastData.id}">
                                    <button type="submit" title="Click to View" class="btn"><i
                                            class="bi bi-eye"></i></button>
                                </a>
                                <button type="submit" title="Click to Delete"
                                    (click)="onDelete(bookingBroadcastData.id)" class="btn"><i
                                        class="bi bi-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <!-- <app-export-data [exportFileName]="exportFileName"></app-export-data> -->
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="bookingBroadcastsDetails != undefined && bookingBroadcastsDetails.totalEntries != undefined && bookingBroadcastsDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="location">Location</label>
                </div>
                <div class="col-md-8">
                    <input type="text" formControlName="location" class="form-control" id="location">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="bookingType">Booking Type</label>
                </div>
                <div class="col-md-8">
                    <input type="text" formControlName="bookingType" class="form-control" id="bookingType">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="boatType">Boat Type</label>
                </div>
                <div class="col-md-8">
                    <input type="text" formControlName="boatType" class="form-control" id="boatType">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdFrom">Created From</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdFrom" formControlName="createdFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdTo">Created To</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdTo" formControlName="createdTo" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="fromDateFrom">From-Date From </label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="fromDateFrom" formControlName="fromDateFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="fromDateTo">From-Date To</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="fromDateTo" formControlName="fromDateTo" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="toDateFrom">To-Date From</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="toDateFrom" formControlName="toDateFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="toDateTo">To-Date To</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="toDateTo" formControlName="toDateTo" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="applyFilter()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>