import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from 'src/app/services/insurance/insurance.service';

@Component({
  selector: 'app-insurance-price-range-view',
  templateUrl: './insurance-price-range-view.component.html',
  styleUrls: ['./insurance-price-range-view.component.css']
})

export class InsurancePriceRangeViewComponent implements OnInit {

  insurancePriceViewDetails: any;
  id: number = 0;
  public resourceType: string = "";
  public resourceId: string = "";

  constructor(private activatedRoute: ActivatedRoute, private insuranceService: InsuranceService,
    private router: Router, private tostr: ToastrService) { }

  
    ngOnInit(): void {
      this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
      this.resourceId = this.id.toString();
      this.resourceType = "insurance-price-range";
  
      this.insuranceService.fetchInsurancePriceRange(this.id).subscribe(
        response => {
          this.insurancePriceViewDetails = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
    }
  
    delete(id: number) {
      var isDelete = confirm("are you sure want to delete selected insurance price range?");
      if (isDelete) {
        this.insuranceService.DeleteInsurancePriceRange([id])
          .pipe(finalize(() => {
          })).subscribe(
            response => {
              this.tostr.success("Deleted Successfully.");
              this.router.navigate(["admin/insurance-price-ranges"]);
            },
            error => {
              console.log(error);
              this.tostr.error("Something went wrong, please try again.");
            }
          );
      }
    }

}
