<div class="container-fluid">

  <div class="row row-y">
    <div ngbDropdown class="col-lg-4 col-md-12 d-inline-block pt-2 pb-2">
      <h2 class=" mt-2 mb-4">{{fullName}}</h2>
    </div>
    <div class="col-lg-8 col-md-12 text-right pt-2 pb-2 edituserBtn">
      <a routerLink="../user-edit" [queryParams]="{id:id}" class="btn btn-secondary">Edit User</a>
      <button type="button" (click)="delete()" class="btn btn-secondary ml-2">Delete User</button>
      <button type="button" (click)="verifyEmail()" class="btn btn-secondary ml-2">Verify
        Email</button>
      <button type="button" (click)="verifyPhone()" class="btn btn-secondary ml-2">Verify
        Phone</button>
      <a href="https://www.boatflex.com" target="_blank" class="btn btn-secondary ml-2">Sign in as {{fName}}</a>
      <a routerLink="../update-credit" [queryParams]="{id:id}" class="btn btn-secondary ml-2">Update {{fName}}'s
        Credits</a>
      <button type="button" (click)="verification(id)" [style.display]="btnVerification"
        class="btn btn-secondary ml-2">Verifications</button>
    </div>
  </div>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (navChange)="tabNavChange($event)">
    <li ngbNavItem [ngbNavItem]="1">
      <a ngbNavLink>Account</a>
      <ng-template ngbNavContent>
        <app-user-account></app-user-account>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="2">
      <a ngbNavLink>Person</a>
      <ng-template ngbNavContent>
        <app-user-person></app-user-person>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="3">
      <a ngbNavLink>Business</a>
      <ng-template ngbNavContent>
        <app-user-business></app-user-business>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="4">
      <a ngbNavLink>Payouts</a>
      <ng-template ngbNavContent>
        <app-user-payout></app-user-payout>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="5">
      <a ngbNavLink>Boats</a>
      <ng-template ngbNavContent>
        <app-user-boats [name]="fName"></app-user-boats>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="6">
      <a ngbNavLink>Bookings as Renter</a>
      <ng-template ngbNavContent>
        <app-user-bookings [name]="fName" [isRenter]=true></app-user-bookings>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="7">
      <a ngbNavLink>Bookings as Owner</a>
      <ng-template ngbNavContent>
        <app-user-bookings [name]="fName" [isRenter]=false></app-user-bookings>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="8">
      <a ngbNavLink>Referral</a>
      <ng-template ngbNavContent>
        <app-user-referrals [name]="fName"></app-user-referrals>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="9">
      <a ngbNavLink>Conversations</a>
      <ng-template ngbNavContent>
        <app-user-conversations [name]="fName" (newItemEvent)="changeTab($event)"></app-user-conversations>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="10">
      <a ngbNavLink>User sections</a>
      <ng-template ngbNavContent>
        <app-user-sections [name]="fName"></app-user-sections>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-1"></div>
  <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
  <div>