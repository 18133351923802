import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booking-view',
  templateUrl: './booking-view.component.html',
  styleUrls: ['./booking-view.component.css']
})
export class BookingViewComponent implements OnInit {
  id: number = 0;
  booking: any;
  resourceType: string = ""
  resourceId: string = ""
  frmDeposit: FormGroup;
  frmRefund: FormGroup;

  constructor(private bookingService: BookingService,
    private modalService: NgbModal,
    private router: ActivatedRoute, private fb: FormBuilder, private route: Router,
    private userService: UsersService, private tostr: ToastrService) {
    this.router.queryParams.subscribe(param => { this.id = param.id });
    this.frmDeposit = this.fb.group({
      amount: []
    });
    this.frmRefund = this.fb.group({
      amount: [],
      amf: [],
      nod: [],
      serviceFee: []
    });
  }

  ngOnInit(): void {
    this.resourceType = "Booking";
    this.resourceId = this.id.toString();
    this.getBooking();
  }

  getBooking() {
    this.bookingService.fetchBooking(this.id).subscribe(
      response => {
        this.booking = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }
  cancel() {
    this.frmDeposit.setValue({
      amount: null
    });
    this.modalService.dismissAll('submit');
  }
  markAsTransfered() {
    var request = { id: this.id };
    this.bookingService.markTransferred(request).subscribe(
      res => {
        this.tostr.success(res.toString());
      },
      error => {
        this.tostr.error("Error occurred while updating data.");
      }
    );
  }

  approveBooking() {

    var isApprove = confirm("are you sure want to approve booking?");
    if (isApprove) {
      var request = { bookingId: this.id };
      this.bookingService.approveBooking(request).subscribe(
        res => {
          this.tostr.success(res.toString());
          this.getBooking();
        },
        error => {
          this.tostr.error("Error occurred while updating data.");
        }
      );
    }
  }
  rejectBooking() {

    var isReject = confirm("are you sure want to decline this booking request?");
    if (isReject) {
      var request = { bookingId: this.id };
      this.bookingService.rejectBooking(request).subscribe(
        res => {
          this.tostr.success(res.toString());
          this.getBooking();
        },
        error => {
          this.tostr.error("Error occurred while updating data.");
        }
      );
    }
  }
  cancelBooking() {

    var isReject = confirm("are you sure want to cancel this booking request?");
    if (isReject) {
      var request = {
        bookingId: this.id,
        isOwner: true,
        isRenter: false,
        feedback: "",
        cancellationReason: ""
      };
      this.bookingService.cancelBooking(request).subscribe(
        res => {
          this.tostr.success(res.toString());
          this.getBooking();
        },
        error => {
          this.tostr.error("Error occurred while updating data.");
        }
      );
    }
  }
  open(content) {
    this.frmDeposit.setValue({
      amount: this.booking.priceData.ownerPriceData.deposit
    });
    console.log(this.booking)

    const modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
  chageDeposit() {
    var request = { id: this.id, amount: this.frmDeposit.controls["amount"].value };
    this.bookingService.chargeDeposit(request).subscribe(
      res => {
        this.tostr.success(res.toString());
        this.modalService.dismissAll('submit');
        this.getBooking();
      },
      error => {
        this.tostr.error("Error occurred while updating data.");
      }
    );
  }
  openRefund(content) {
    this.frmRefund.setValue({
      amount: this.booking.priceData.renterPriceData.totalPrice,
      serviceFee: this.booking.priceData.renterPriceData.renterServiceFee,
      amf: this.booking.priceData.totalPriceWSF,
      nod: this.booking.priceData.renterPriceData.days
    });
    console.log(this.booking)

    const modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  cancelRefund() {
    this.frmRefund.setValue({
      amount: null,
      serviceFee: null,
      amf: null,
      nod: this.booking.priceData.renterPriceData.days
    });
    this.modalService.dismissAll('submit');
  }
  refund() {
    var request = { id: this.id, amount: this.frmRefund.controls["amount"].value * 100 };
    this.bookingService.refundPayment(request).subscribe(
      res => {
        this.tostr.success(res.toString());
        this.modalService.dismissAll('submit');
        this.getBooking();
      },
      error => {
        this.tostr.error("Error occurred while updating data.");
      }
    );
  }
  onNODChange(event: any) {
    var perDaysPrice = this.booking.priceData.totalPriceWSF / this.booking.priceData.renterPriceData.days;
    var amount = Math.round(perDaysPrice * this.frmRefund.controls["nod"].value);
    var perDayFee = this.booking.priceData.renterPriceData.renterServiceFee / this.booking.priceData.renterPriceData.days;
    var fee = Math.round(perDayFee * this.frmRefund.controls["nod"].value);
    var finalAmount = amount + fee;
    this.frmRefund.setValue({
      amount: finalAmount,
      serviceFee: fee,
      amf: amount,
      nod: this.frmRefund.controls["nod"].value
    });
  }
  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.route.navigate(["admin/user-view"], { queryParams: { id: id } })
  }
  downloadPickupReturnPdf(forPickup: boolean) {
    var request = { bookingId: this.id, reportFor: forPickup ? "PickUp" : "Return" };
    this.bookingService.fetchPickupReturnPdf(request).subscribe(
      res => {
        //console.warn("downloadPickupReturnPdf response : ", res);
        const source = `data:application/pdf;base64,${res}`;
        const link = document.createElement("a");
        link.href = source;
        link.download = `${forPickup ? "Pickup" : "Return"}.pdf`
        link.click();
      },
      error => {
        console.warn("downloadPickupReturnPdf error : ", error);
        this.tostr.error("Error occurred while downloading pdf.");
      }
    );
  }
}
