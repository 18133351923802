import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from 'src/app/services/insurance/insurance.service';

@Component({
  selector: 'app-insurance-price-range-new',
  templateUrl: './insurance-price-range-new.component.html',
  styleUrls: ['./insurance-price-range-new.component.css']
})
export class InsurancePriceRangeNewComponent implements OnInit {
  formInsurancePriceRange: FormGroup;

  constructor(private insuranceService: InsuranceService, private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.formInsurancePriceRange = this.fb.group({
      minBoatPrice: ['', [Validators.required]],
      maxBoatPrice: ['', [Validators.required]],
      weeklyFees: ['', [Validators.required]],
      dailyFees: ['', [Validators.required]],
      perDayAddOnFees: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  reset() {
    this.router.navigate(["admin/insurance-price-ranges"]);
  }

  submit() {
    if (this.formInsurancePriceRange.valid) {
      var request = {
        minBoatPrice: this.formInsurancePriceRange.controls["minBoatPrice"].value,
        maxBoatPrice: this.formInsurancePriceRange.controls["maxBoatPrice"].value,
        weeklyFees: this.formInsurancePriceRange.controls["weeklyFees"].value,
        dailyFees: this.formInsurancePriceRange.controls["dailyFees"].value,
        perDayAddOnFees: this.formInsurancePriceRange.controls["perDayAddOnFees"].value,
      };
      this.insuranceService.SaveInsurancePriceRange(request)
        .subscribe(
          res => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/insurance-price-ranges']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }

}
