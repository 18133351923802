<div *ngIf="error" class="row justify-content-center">
  <div class="col-md-8 text-center">
    <div class="alert alert-warning" role="alert">
      Oops, there was an error, please try to <a routerLink="/login">login again</a>.
    </div>
  </div>
</div>
<!-- <div class="loader" *ngIf="spiner"> </div> -->
<div class="main-loader" *ngIf="spiner">
  <div class="loader">
    <mat-spinner mode="indeterminate">
      <p class="p-3">Loading</p>
    </mat-spinner>
  </div>
</div>