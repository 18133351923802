import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopularDestinationService } from 'src/app/services/popular-destination/popular-destination.service';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-popular-destinations',
  templateUrl: './popular-destinations.component.html',
  styleUrls: ['./popular-destinations.component.css']
})
export class PopularDestinationsComponent implements OnInit, OnDestroy {

  popularDestinationsDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  includeAll: boolean = true;
  defaultvalue: number = 0;
  frmFilter: FormGroup;
  orderColumn: string = "id";
  orderDirection: string = "desc";
  navigationSubscription: any;
  public exportFileName :string = "popular_destinations";

   constructor(private popularDestinationService: PopularDestinationService, private modalService: NgbModal,
     private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
      this.frmFilter = this.fb.group({
        name: ['']
      });

      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.reset();
        }
      });
  
    }
  
    ngOnInit(): void {
      this.getPopularDestinations();
    }
  
    ngOnDestroy() {
      if (this.navigationSubscription) {
        this.navigationSubscription.unsubscribe();
      }
    }
  
    getPopularDestinations() {
      var popularDestinationRequest = {
        name: this.frmFilter.controls["name"].value,
        page: this.page,
        pageSize: this.pageSize,
        orderColumn: this.orderColumn,
        orderDirection: this.orderDirection,
        includeAll: this.includeAll
      };
      this.popularDestinationService.fetchPopularDestinations(popularDestinationRequest)
        .subscribe(
          response => {
            this.popularDestinationsDetails = response;
            this.changeCheckBoxStatus();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }

    toggleSort(orderColumn: string) {
      if (this.orderDirection == 'DESC') {
        this.orderDirection = '';
      } else if (this.orderDirection == 'ASC') {
        this.orderDirection = 'DESC';
      } else {
        this.orderDirection = 'ASC';
      }
      this.orderColumn = orderColumn;
      this.getPopularDestinations();
    }
  
    getPage(page: number) {
      this.page = page;
      this.getPopularDestinations();
    }

    open(content) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  
    onFilter() {
      let name = this.frmFilter.controls["name"].value;
      if (name == "") {
        this.includeAll = true;
      }
      else {
        this.includeAll = false;
      }
      this.getPopularDestinations();
      this.modalService.dismissAll('submit');
    }
  
    reset() {
      this.frmFilter.setValue({
        name: "",
      });
      this.includeAll = true;
      this.modalService.dismissAll('submit');
      this.getPopularDestinations();
    }
  
    onDelete(id: number) {
      var isDelete = confirm("are you sure want to delete selected Popular Destionation?");
      if (isDelete) {
        this.popularDestinationService.DeletePopularDestination([id])
          .pipe(finalize(() => {
          })).subscribe(
            response => {
              this.tostr.success("Deleted Successfully.");
              this.getPopularDestinations();
            },
            error => {
              console.log(error);
              this.tostr.error("Something went wrong, please try again.");
            }
          );
      }
    }
  
  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.popularDestinationsDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.popularDestinationsDetails.data[a].id);
      }
      this.popularDestinationsDetails.data.forEach((popularDestination) => {
        popularDestination.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.popularDestinationsDetails.data.forEach((popularDestination) => {
        popularDestination.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.popularDestinationsDetails.data.length && this.popularDestinationsDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }
  
    deletePopularDestinations() {
      var isDelete = confirm("are you sure want to delete selected Popular Destinations?");
      if (isDelete) {
        if (this.checkboxCheckedList.length > 0) {
          this.popularDestinationService.DeletePopularDestination(this.checkboxCheckedList).subscribe(
            response => {
              this.tostr.success("Deleted Successfully.");
              this.checkboxCheckedList = [];
              this.getPopularDestinations();
            },
            error => {
              console.log(error);
              this.tostr.error("Something went wrong, please try again.");
            }
          );
        }
      }
    }

}
