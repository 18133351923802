import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MetagroupService } from 'src/app/services/metagroup.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-metagroup-view',
  templateUrl: './metagroup-view.component.html',
  styleUrls: ['./metagroup-view.component.css']
})
export class MetagroupViewComponent implements OnInit {

  id: number = 0;
  resourceType: string = "";
  resourceId: string = "";
  metagroupDetails: any;

  constructor(private activatedRoute: ActivatedRoute, private route: Router,
    private metagroupService: MetagroupService, private tostr: ToastrService) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.id = data.id;
    });
  }

  ngOnInit(): void {
    this.resourceId = this.id.toString();
    this.resourceType = "Meta-Group";
    this.metagroupService.fetchMetagroup(this.id)
      .pipe(finalize(() => {
      })).subscribe(
        response => {
          this.metagroupDetails = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  delete(id: number) {
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      this.metagroupService.deleteMetagroup([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.route.navigate(["admin/metagroups"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }
}
