import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferralService } from 'src/app/services/referral.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css']
})
export class ReferralsComponent implements OnInit {
  page: number = 1;
  pageSize: number = 10;
  orderColumn: string = "Id"
  orderDirection: string = "DESC"
  frmFilter: FormGroup;
  referralListDetails: any;
  accepted: number = 0;
  unaccepted: number = 0;
  includeAll: boolean = true;
  type; string = "";
  navigationSubscription: any;
  public exportFileName: string = "referrals";

  constructor(private userService: UsersService, private router: Router, private fb: FormBuilder,
    private referralService: ReferralService, private modalService: NgbModal, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      email: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.type = "";
    this.getReferrals();
  }

  getReferrals() {
    var refferalRequest = {
      type: this.type,
      email: this.frmFilter.controls["email"].value,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      includeAll: this.includeAll
    };
    this.referralService.fetchRefferals(refferalRequest).subscribe(
      res => {
        this.referralListDetails = res;
        this.accepted = this.referralListDetails.acceptedCount;
        this.unaccepted = this.referralListDetails.unacceptedCount;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getReferrals();
  }

  getPage(page: number) {
    this.page = page;
    this.getReferrals();
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  getAccepted() {
    this.type = "Accepted";
    this.getReferrals();
  }

  getUnaccepted() {
    this.type = "Unaccepted";
    this.getReferrals();
  }

  new() {
    this.router.navigate(["admin/referral-new"]);
  }

  submit() {
    this.includeAll = false;
    this.getReferrals();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.reset();
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getReferrals();
  }
}
