import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BoatService } from 'src/app/services/boat.service';
import { ToastrService } from 'ngx-toastr';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-boat-view',
  templateUrl: './boat-view.component.html',
  styleUrls: ['./boat-view.component.css']
})
export class BoatViewComponent implements OnInit {
  id: number = 0;
  boatDetails: any;
  public active = 1;
  public boatName: string = "";
  boatStatus: any;
  btnPublishtxt = "";
  btnFeaturedtxt = "";
  public resourceType: string = "";
  public resourceId: string = "";

  constructor(private route: ActivatedRoute, private router: Router,
    private boatService: BoatService, private toastr: ToastrService, private location: Location) {
    this.route.queryParams.subscribe(params => { this.id = params.id, this.boatName = params.name, this.active = (params.sec == "" || params.sec === undefined ? 1 : Number(params.sec)) });
    this.GgetBoatStatus();
    this.resourceType = "boat";
    this.resourceId = this.id.toString();
  }

  ngOnInit(): void {

  }

  GgetBoatStatus() {
    this.boatService.fetchBoatDetails(this.id).subscribe(
      res => {
        console.log(res);

        this.boatDetails = res;
        this.btnFeaturedtxt = this.boatDetails.isFeatured ? "Unmark" : "Mark";
        this.btnPublishtxt = this.boatDetails.isPublished ? "Unpublish" : "Publish"
      },
      error => {
        console.log(error);
        this.toastr.error("Something went wrong, please try again.");
      }
    );
    /* this.boatService.fetchBoatStatus(this.id).subscribe(
       res => {
         this.boatStatus = res;
         if (this.boatStatus != null) {
           this.btnFeaturedtxt = this.boatStatus.isFeatured ? "Unmark" : "Mark";
           this.btnPublishtxt = this.boatStatus.isPublished ? "Unpublish" : "Publish"
         }
       },
       error => {
         this.toastr.error("Error occurred while fatching status.");
         console.log(error);
       }
     );*/
  }

  delete() {
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      this.boatService.deleteBoats([this.id])
        .subscribe(
          response => {
            this.toastr.success("Deleted Successfully.");
            this.router.navigate(['admin/boats']);
          },
          error => {
            console.log(error);
            this.toastr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  publishBoat() {
    var action = "";
    if (this.btnPublishtxt == "Unpublish") {
      action = "unpublish";
    }
    else {
      action = "publish";
    }
    var togglePublish = {
      boatId: this.id,
      action: action
    };
    this.boatService.toggleBoatPublish(togglePublish).subscribe(
      res => {
        this.toastr.success("The boat was " + action + " correctly")
        this.btnPublishtxt = action == "publish" ? "Unpublish" : "Publish";
      },
      error => {
        this.toastr.error("Error occurred.");
        console.log(error);
      });
  }

  markFeatured() {
    var action = "";
    if (this.btnFeaturedtxt == "Mark") {
      action = "featured";
    }
    else {
      action = "unfeatured";
    }
    var toggleFeatured = {
      boatId: this.id,
      action: action
    };
    this.boatService.toggleBoatFeatured(toggleFeatured).subscribe(
      res => {
        if (action == "unfeatured") {
          this.toastr.success("The boat was unmarked as featured correctly")
          this.btnFeaturedtxt = "Mark";
        }
        else {
          this.toastr.success("The boat was marked as featured correctly")
          this.btnFeaturedtxt = "Unmark";
        }
      },
      error => {
        this.toastr.error("Error occurred.");
        console.log(error);
      });
  }

  changeInsurance() {
    this.router.navigate(["admin/insurance"], { queryParams: { id: this.id } });
  }

  tabNavChange(changeEvent: NgbNavChangeEvent) {
    this.location.replaceState(window.location.pathname, "id=" + this.id + "&name=" + this.boatName + "&sec=" + changeEvent.nextId)
  }
}
