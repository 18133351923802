<div class="container mt-2">
    <h2 class="`text-default">New Category</h2>
    <form [formGroup]="frmCategory" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header">
            Category details
        </div>
        <div class="card-body">
            <div class="form-group row">
                <div class="col-md-2">
                <label for="language">Language</label>
              </div>
                <div class="col-md-10" *ngIf="poEditorsLanguages != null && poEditorsLanguages.length > 0">
                    <select formControlName="language" id="language" class="form-control" ng-options="">
                        <option value=''>--Select--</option>
                        <option *ngFor="let poEditorsLanguage of poEditorsLanguages" value={{poEditorsLanguage.code}}>{{poEditorsLanguage.name}}</option>
                    </select>
                  <div *ngIf="frmCategory.controls['language'].invalid && frmCategory.controls['language'].touched" class="alert alert-danger form-danger" role="alert">
                    <div *ngIf="frmCategory.controls['language'].hasError('required')">
                        Please choose Language.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-2">
                <label for="name">Category Name</label>
                </div>
                <div class="col-md-10">
                  <input type="name" class="form-control" id="name" formControlName="name" placeholder="category name" required>
                  <div *ngIf="frmCategory.controls['name'].invalid && frmCategory.controls['name'].touched" class="alert alert-danger form-danger" role="alert">
                    <div *ngIf="frmCategory.controls['name'].hasError('required')">
                        Please enter category name.
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
      <div class="form-group row m-3">
        <button type="submit" [disabled]="!frmCategory.valid" class="btn btn-primary">Create Category
        </button>
        <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
      </div>
    </form>
  </div>