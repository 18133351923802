import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { BaseService } from '../shared/base.service';
import { ConfigService } from '../shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class BoatService extends BaseService {
  httpOptions; httpOptionsEdit;

  public boatFilter: any = null;
  constructor(private authService: LoginService, private http: HttpClient, private configService: ConfigService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
    this.httpOptionsEdit = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      }), responseType: 'text'
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }

  fetchUserBoats(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'boat/userboats?ownerId=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchBoats(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'boat/boats', data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteBoats(ids: number[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'boat', options).pipe(catchError(this.handleError));
  }

  updateBoat(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'boat/followup', data, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  fetchFollowUpDate(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'boat/followup?boatId=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchBoatDetails(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'boat/boatinfo?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchBoatSections(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'boat/sections?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchBoatPrice(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'boat/boat-prices?boatId=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchBoatStatus(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'boat/status?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  toggleBoatPublish(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'boat/publish', data, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  toggleBoatFeatured(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'boat/featured', data, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  fetchBoatInsuranceDetails(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'boat/insurance?boatId=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  updateBoatInsurance(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'boat/insurance', data, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  fetchBoatDataForExport() {
    return this.http.get(this.configService.resourceApiURI + 'boat/boat-data-export', this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
