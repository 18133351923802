<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <h2 class=" mt-2 mb-4">Booking-Broadcast #{{id}}</h2>
        </div>
        <div class="col-lg-6 col-md-12 text-right" *ngIf="bookingBroadcastViewDetails != null">
            <button type="button" (click)="delete(bookingBroadcastViewDetails.id)" class="btn btn-secondary m-2">Delete
                Booking-Broadcast</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Basic Info</h5>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped"
                                *ngIf="bookingBroadcastViewDetails != null && bookingBroadcastViewDetails != undefined">
                                <tr>
                                    <td class="bt-none">Id</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.id}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Created At</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.createdAt}}</td>
                                </tr>
                                <tr>
                                    <td>User</td>
                                    <td><a (click)="gotoUser(bookingBroadcastViewDetails.userId)" class="text-primary"
                                            style="text-decoration: underline;cursor: pointer;">{{bookingBroadcastViewDetails.userName}}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Comment</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.comment}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Boat Type</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.createdAt}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Booking Type</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.bookingType}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Minimum Price</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.minimumPrice}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Maximum Price</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.maximumPrice}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Peoples</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.people}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Cabins</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.cabins}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Beds</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.beds}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-4"
                        *ngIf="bookingBroadcastViewDetails != undefined && bookingBroadcastViewDetails != null">
                        <div class="card-header">
                            <h5>Location & Timings</h5>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped"
                                *ngIf="bookingBroadcastViewDetails != null && bookingBroadcastViewDetails != undefined">
                                <tr>
                                    <td class="bt-none">Location</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.location}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Latitude</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.latitude}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Longitude</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.longitude}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">From Date</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.fromDate}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">To Date</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.toDate}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Check In Time</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.checkInTime}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Check Out Time</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.checkOutTime}}</td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Flexible Timing</td>
                                    <td class="bt-none">{{bookingBroadcastViewDetails.flexibleTiming}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>