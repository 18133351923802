
<div class="container mt-2">
    <h2 class="`text-default">New Trip Type</h2>
    <form [formGroup]="formTripType" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header">
            Trip Type details
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-2">
            <label for="title">Title</label>
            </div>
            <div class="col-md-10">
              <input type="title" class="form-control" id="title" formControlName="title" placeholder="title" required>
              <div *ngIf="formTripType.controls['title'].invalid && formTripType.controls['title'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="formTripType.controls['title'].hasError('required')">
                    Please enter title.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-2">
            <label for="subTitle">Sub Title</label>
            </div>
            <div class="col-md-10">
              <input type="subTitle" class="form-control" id="subTitle" formControlName="subTitle" placeholder="Sub Title" required>
              <div *ngIf="formTripType.controls['subTitle'].invalid && formTripType.controls['subTitle'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="formTripType.controls['subTitle'].hasError('required')">
                    Please enter Sub Title.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-2">
            <label for="fileUrl">Icon</label>
            </div>
            <div class="col-md-10">
              <span *ngIf="fileUrl != ''">
                <img src="{{fileUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
              </span> 
              <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadFile()">Upload</a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row m-3">
      <button type="submit" [disabled]="!formTripType.valid" class="btn btn-primary">Create Trip Type</button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
    </form>
  </div>
  
  
  
