<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <h2 class=" mt-2 mb-4 booking-title">Payouts</h2>
        </div>

    </div>
    <div class="row">
        <div class="col-md-10 filter-btns">


        </div>
        <div class="col-md-2 filter-btns">
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class=" table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th><a (click)="toggleSort('BookingId')" style="cursor: pointer;">Booking</a></th>
                        <th>Payout ID</th>
                        <th><a (click)="toggleSort('PayoutDate')" style="cursor: pointer;">Payout Date</a></th>
                        <th>Amount</th>
                        <th><a (click)="toggleSort('Status')" style="cursor: pointer;">Status</a></th>
                        <th>Arrival Date</th>
                        <th>Failure Code</th>
                        <th>Failure Message</th>
                        <th>Deposit Charge</th>
                        <th>Action</th>
                    </thead>
                    <tbody *ngIf="payouts != undefined && payouts != null">
                        <tr *ngFor="let payout of payouts">
                            <td><a routerLink="../booking-view"
                                    [queryParams]="{id: payout.bookingId}">{{payout.bookingId}}</a></td>
                            <td>
                                <a *ngIf="payout.stripePayoutId != null && payout.stripePayoutId != ''"
                                    href="https://dashboard.stripe.com/{{payout.stripeAccountId}}/payouts/{{payout.stripePayoutId}}">
                                    {{payout.stripePayoutId}}</a>
                            </td>
                            <td>{{payout.payoutDate}}</td>
                            <td>{{payout.amountCents}} {{payout.currency}}</td>
                            <td>
                                <p [style.backgroundColor]="payout.statusColor"
                                    style="width: fit-content;color: #ffffff;">{{payout.status}}</p>
                            </td>
                            <td>{{payout.arrivalDate}}</td>
                            <td>{{payout.failureCode}}</td>
                            <td>{{payout.failureMessage}}</td>
                            <td>{{payout.isDeposit}}</td>
                            <td> <button class="btn btn-secondary " (click)="processPayout(payout.id)"
                                    *ngIf="payout.status !='PENDING' && payout.status !='PAID'">Process</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">

            <div class="form-group row">
                <div class="col-md-4">
                    <label for="bookingId">BOOKING ID</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="bookingId" formControlName="bookingId" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="status">STATUS</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="status" id="status" class="form-control" ng-options="">
                        <option value="waiting">Waiting</option>
                        <option value="failed">Failed</option>
                        <option value="pending">Pending</option>
                        <option value="paid">Paid</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="payoutDate">Payout Date</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="payoutDate" formControlName="payoutDate" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>