<div class="container mt-2">
  <h2 class="`text-default">Edit Amenity</h2>
  <form [formGroup]="frmAmenity" (ngSubmit)="submit()">
    <div class="card">
      <div class="card-header">
        Amenity details
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="col-md-2">
            <label for="category">Category</label>
          </div>
          <div class="col-md-10" *ngIf="amenitiesCategories != null && amenitiesCategories.length > 0">
            <select formControlName="category" id="category" class="form-control" ng-options="">
              <option value=0>--Select--</option>
              <option *ngFor="let category of amenitiesCategories" value={{category.id}}>{{category.name}}</option>
            </select>
            <div *ngIf="frmAmenity.controls['category'].invalid && frmAmenity.controls['category'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmAmenity.controls['category'].hasError('required')">
                Please choose category.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="name">Name</label>
          </div>
          <div class="col-md-10">
            <input type="name" class="form-control" id="name" formControlName="name" placeholder="name" required>
            <div *ngIf="frmAmenity.controls['name'].invalid && frmAmenity.controls['name'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmAmenity.controls['name'].hasError('required')">
                Please enter name.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="icon">InActive Icon</label>
          </div>
          <div class="col-md-10">
            <img src="{{fileUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
            <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadFile()">Change</a>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="icon">Active Icon</label>
          </div>
          <div class="col-md-10">
            <img src="{{activeIconUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
            <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadActiveIcon()">Change</a>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="icon">Green Icon</label>
          </div>
          <div class="col-md-10">
            <img src="{{greenIconUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
            <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadGreenIcon()">Change</a>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row m-3">
      <button type="submit" [disabled]="!frmAmenity.valid" class="btn btn-primary">Update Amenity</button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
  </form>
</div>