import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { BoatLibraryService } from 'src/app/services/boat-library.service';

@Component({
  selector: 'app-boat-library',
  templateUrl: './boat-library.component.html',
  styleUrls: ['./boat-library.component.css']
})
export class BoatLibraryComponent implements OnInit, OnDestroy {

  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  navigationSubscription: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  defaultvalue: number = 0;
  frmFilter: FormGroup;
  orderColumn: string = "Id";
  orderDirection: string = "desc";
  boatLibraryDetails: any;

  constructor(private modalService: NgbModal, private router: Router,
    private fb: FormBuilder, private tostr: ToastrService, private boatLibrary: BoatLibraryService,
    private clipboardService: ClipboardService) {
    this.frmFilter = this.fb.group({
      boatModel: [''],
      boatType: [''],
      imageUrl: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.reset();
      }
    });
  }

  ngOnInit(): void {
    this.getBoatLibrary();
  }

  getBoatLibrary() {
    var boatLibraryRequest = {
      imageUrl: this.frmFilter.controls["imageUrl"].value,
      boatModel: this.frmFilter.controls["boatModel"].value,
      boatType: this.frmFilter.controls["boatType"].value,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection
    };
    this.boatLibrary.fetchBoatLibraryList(boatLibraryRequest)
      .subscribe(
        response => {
          this.boatLibraryDetails = response;
          this.changeCheckBoxStatus();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  setPageForReload() {
    var pageNumber = localStorage.getItem('pageAmenities');
    if (pageNumber !== null) { this.page = +pageNumber; }
    else { this.page = 1; }

    window.onbeforeunload = function () {
      localStorage.setItem('pageAmenities', $(".ngx-pagination .current span:eq(1)").text());
    }
  }

  onFilter() {
    this.page = 1;
    this.getBoatLibrary();
    this.modalService.dismissAll('submit');
  }

  reset() {
    this.frmFilter.setValue({
      boatModel: "",
      boatType: "",
      imageUrl: ""
    });
    this.modalService.dismissAll('submit');
    this.page = 1;
    this.getBoatLibrary();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  deletePhotos() {
    var isDelete = confirm("are you sure want to delete selected item(s)?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.boatLibrary.delete(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getBoatLibrary();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected item?");
    if (isDelete) {
      this.boatLibrary.delete([id])
        .subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getBoatLibrary();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = 'ASC';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getBoatLibrary();
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.boatLibraryDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.boatLibraryDetails.data[a].id);
      }
      this.boatLibraryDetails.data.forEach((amenitydata) => {
        amenitydata.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.boatLibraryDetails.data.forEach((amenitydata) => {
        amenitydata.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  getPage(page: number) {
    this.page = page;
    this.getBoatLibrary();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.boatLibraryDetails.data.length && this.boatLibraryDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  copy(text: string) {
    this.clipboardService.copy(text);
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
}
