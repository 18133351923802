import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-business',
  templateUrl: './user-business.component.html',
  styleUrls: ['./user-business.component.css']
})
export class UserBusinessComponent implements OnInit {
  id: number = 0;
  businessInfo: any;

  constructor(private userService: UsersService, private route: ActivatedRoute, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.userService.fetchBusinessInfo(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.businessInfo = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }

}
