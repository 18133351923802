import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-credit',
  templateUrl: './update-credit.component.html',
  styleUrls: ['./update-credit.component.css']
})
export class UpdateCreditComponent implements OnInit {
  id: number = 0;
  frmCredit: FormGroup;

  constructor(private activatedRoute: ActivatedRoute, private route: Router,
    private fb: FormBuilder, private tostr: ToastrService) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.id = data.id;
    });
    this.frmCredit = this.fb.group({
      credit: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  reset() {
    this.route.navigate(["admin/users"]);
  }

  submit() {

  }
}
