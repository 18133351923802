import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MetagroupService } from 'src/app/services/metagroup.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-metagroup-new',
  templateUrl: './metagroup-new.component.html',
  styleUrls: ['./metagroup-new.component.css']
})
export class MetagroupNewComponent implements OnInit {
  frmMetagroup: FormGroup;

  constructor(private route: Router, private fb: FormBuilder,
    private metagroupService: MetagroupService, private tostr: ToastrService) {
    this.frmMetagroup = this.fb.group({
      url: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      imageUrl: ['']
    });
  }

  ngOnInit(): void {
  }

  reset() {
    this.route.navigate(["admin/metagroups"]);
  }

  submit() {
    if (this.frmMetagroup.valid) {
      var metagroup = {
        id: null,
        url: this.frmMetagroup.controls["url"].value,
        title: this.frmMetagroup.controls["title"].value,
        description: this.frmMetagroup.controls["description"].value,
        imageUrl: this.frmMetagroup.controls["imageUrl"].value
      };
      this.metagroupService.addMetagroup(metagroup)
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Updated Sucessfully.");
            this.route.navigate(['admin/metagroups']);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

}
