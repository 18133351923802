import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ReferralService } from 'src/app/services/referral.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-referral-view',
  templateUrl: './referral-view.component.html',
  styleUrls: ['./referral-view.component.css']
})
export class ReferralViewComponent implements OnInit {
  id: number = 0;
  email: string = "";
  referral: any;
  resourceType: string = "";
  resourceId: string = "";

  constructor(private userService: UsersService, private router: Router, private route: ActivatedRoute,
    private referralService: ReferralService, private tostr: ToastrService) {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.email = this.referralService.email;
  }

  ngOnInit(): void {
    this.resourceId = this.id.toString();
    this.resourceType = "Referral";
    this.getReferralDetails();
  }

  getReferralDetails() {
    this.referralService.fetchRefferal(this.id).subscribe(
      res => {
        this.referral = res;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  delete() {
    var isDelete = confirm("are you sure want to delete selected refferal?");
    if (isDelete) {
      var req = {
        id: this.id
      }
      this.referralService.deleteRefferal(req)
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.router.navigate(["admin/referrals"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

}
