import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewService } from 'src/app/services/review.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-review-edit',
  templateUrl: './review-edit.component.html',
  styleUrls: ['./review-edit.component.css']
})
export class ReviewEditComponent implements OnInit {
  id: number = 0;
  reviewDetails: any;
  frmReview: FormGroup;

  constructor(private reviewService: ReviewService, private activatedRoute: ActivatedRoute,
    private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.frmReview = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
      stars: ['', [Validators.required, Validators.max, Validators.min]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.getReview();
  }

  getReview() {
    this.reviewService.fetchReview(this.id).subscribe(res => {
      this.reviewDetails = res;
      if (this.reviewDetails != null) {
        this.frmReview.setValue({
          subject: this.reviewDetails.subject,
          body: this.reviewDetails.body,
          stars: this.reviewDetails.stars
        });
      }
    },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  reset() {
    this.router.navigate(["admin/reviews"]);
  }

  submit() {
    if (this.frmReview.valid) {
      if (this.frmReview.controls["stars"].value > 0) {
        var reviewRequest = {
          id: this.id,
          subject: this.frmReview.controls["subject"].value,
          body: this.frmReview.controls["body"].value,
          stars: this.frmReview.controls["stars"].value
        }
        this.reviewService.editReviews(reviewRequest).subscribe(
          res => {
            this.tostr.success("Updated successfully.");
            this.router.navigate(['admin/reviews']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
      }
      else {
        this.tostr.warning("Please enter stars.");
      }
    }
  }
}
