import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService extends BaseService{

  httpOptions: any;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
  }

  fetchInsurancePriceRanges(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'insurance/insurance-range', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  fetchInsurancePriceRange(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'insurance/insurance-range/' + id, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  SaveInsurancePriceRange(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'insurance/save-insurance-range', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  EditInsurancePriceRange(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'insurance/edit-insurance-range', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  DeleteInsurancePriceRange(ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'insurance/remove-insurance-ranges', options).pipe(
      catchError(this.handleError)
    );
  }
}
