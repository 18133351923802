import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InsuranceService } from 'src/app/services/insurance/insurance.service';

@Component({
  selector: 'app-insurance-price-range-edit',
  templateUrl: './insurance-price-range-edit.component.html',
  styleUrls: ['./insurance-price-range-edit.component.css']
})
export class InsurancePriceRangeEditComponent implements OnInit {

  insuranePriceRangeViewDetails: any;
  id: number = 0;
  frmEditInsuramceRange: FormGroup;

  constructor(private activatedRoute: ActivatedRoute, private insuranceService: InsuranceService, private fb: FormBuilder,
    private router: Router, private tostr: ToastrService) {
    this.frmEditInsuramceRange = this.fb.group({
      minBoatPrice: ['', [Validators.required]],
      maxBoatPrice: ['', [Validators.required]],
      weeklyFees: ['', [Validators.required]],
      dailyFees: ['', [Validators.required]],
      perDayAddOnFees: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.getInsuranceRange();
  }

  getInsuranceRange() {
    this.insuranceService.fetchInsurancePriceRange(this.id)
      .subscribe(
        response => {
          this.insuranePriceRangeViewDetails = response;
          if (this.insuranePriceRangeViewDetails != null) {
            this.frmEditInsuramceRange.setValue({
              minBoatPrice: this.insuranePriceRangeViewDetails.minBoatPrice,
              maxBoatPrice: this.insuranePriceRangeViewDetails.maxBoatPrice,
              dailyFees: this.insuranePriceRangeViewDetails.dailyFees,
              weeklyFees: this.insuranePriceRangeViewDetails.weeklyFees,
              perDayAddOnFees: this.insuranePriceRangeViewDetails.perDayAddOnFees
            });
          }
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }


  reset() {
    this.router.navigate(["admin/insurance-price-ranges"]);
  }

  submit() {
    if (this.frmEditInsuramceRange.valid) {
        var request = {
          id: this.id,
          minBoatPrice: this.frmEditInsuramceRange.controls["minBoatPrice"].value,
          maxBoatPrice: this.frmEditInsuramceRange.controls["maxBoatPrice"].value,
          weeklyFees: this.frmEditInsuramceRange.controls["weeklyFees"].value,
          dailyFees: this.frmEditInsuramceRange.controls["dailyFees"].value,
          perDayAddOnFees: this.frmEditInsuramceRange.controls["perDayAddOnFees"].value
        }
        this.insuranceService.EditInsurancePriceRange(request).subscribe(
          res => {
            this.tostr.success("Updated successfully.");
            this.router.navigate(['admin/insurance-price-ranges']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
      }
    }

}
