import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as JsonToXML from "js2xmlparser";

@Injectable({
  providedIn: 'root'
})

export class ExportService {

  constructor() { }

  public exportJsonAsCSVFile(json: any[], fileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'csv', type: 'array' });
    const contentType = 'text/csv;charset=utf-8';
    const FILE_EXTENSION = '.csv';
    const data: Blob = new Blob([excelBuffer], {
      type: contentType
    });
    FileSaver.saveAs(data, fileName+FILE_EXTENSION);
  }
  
  public exportJsonAsXLSFile(json: any[], fileName: string): void {
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const FILE_EXTENSION = '.xlsx';
    const data: Blob = new Blob([excelBuffer], {
      type: contentType
    });
    FileSaver.saveAs(data, fileName+FILE_EXTENSION);
  }

  public exportJsonAsXMLFile(json: any[], fileName: string) {
    const contentType = 'application/xml;charset=UTF-8' ;
    const FILE_EXTENSION = '.xml';
    var result = fileName.split('-');
    const data: Blob = new Blob([JsonToXML.parse(result[0], json)], {
      type: contentType
    });
    FileSaver.saveAs(data, fileName+FILE_EXTENSION);
  }

  public exportJsonFile(json: any[], fileName: string): void {
    let contentType = "application/json;charset=utf-8;";
    const FILE_EXTENSION = '.json';
    var data = new Blob([decodeURIComponent(encodeURI(JSON.stringify(json)))], { type: contentType });
    FileSaver.saveAs(data, fileName+FILE_EXTENSION);
  }

}

