<div class="container mt-2">
  <h2 class="`text-default">New Admin User</h2>
  <div class="card">
    <div class="card-header">
      Admin Details
    </div>
    <div class="card-body">
      <form [formGroup]="frmCreateUser" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="fName">First Name</label>
          <input type="text" class="form-control" id="fName" formControlName="fname" placeholder="Enter name" required>
          <div *ngIf="frmCreateUser.controls['fname'].invalid && frmCreateUser.controls['fname'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmCreateUser.controls['fname'].hasError('required')">
              Please enter first name.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="LName">Last Name</label>
          <input type="text" class="form-control" id="LName" formControlName="lname" placeholder="Enter last name"
            required>
          <div *ngIf="frmCreateUser.controls['lname'].invalid && frmCreateUser.controls['lname'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmCreateUser.controls['lname'].hasError('required')">
              Please enter last name.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" class="form-control" id="email" formControlName="email" aria-describedby="emailHelp"
            placeholder="Enter email" required>
          <div *ngIf="frmCreateUser.controls['email'].invalid && frmCreateUser.controls['email'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmCreateUser.controls['email'].hasError('required')">
              Please enter email.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="phoneNumber">Phone Number</label>
          <input type="text" formControlName="phoneNumber" class="form-control" required
            (countryChange)="onCountryChange($event)" (hasError)="hasError($event)"
            (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
            [ng2TelInputOptions]="phoneNumberInputSetting" ng2TelInput />
          <div *ngIf="frmCreateUser.controls['phoneNumber'].invalid && frmCreateUser.controls['phoneNumber'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmCreateUser.controls['phoneNumber'].hasError('required')">
              Please enter phone Number.
            </div>
            <div *ngIf="frmCreateUser.controls['phoneNumber'].invalid && frmCreateUser.controls['phoneNumber'].errors != null ">
              Please enter valid phone Number.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="newPass">Password</label>
          <input type="password" class="form-control" id="newPass" formControlName="newPassword"
            placeholder="Enter password"  required>
          <div *ngIf="frmCreateUser.controls['newPassword'].invalid && frmCreateUser.controls['newPassword'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmCreateUser.controls['newPassword'].hasError('required')">
              Please enter password.
            </div>
            <div *ngIf="frmCreateUser.controls['newPassword'].hasError('pattern')">
              Password should contain at least one special character, one upper case letter and one number.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPass">Confirm password</label>
          <input type="password" class="form-control" id="confirmPass" formControlName="confirmPassword"
            placeholder="Confirm Password"  required>
          <div
            *ngIf="frmCreateUser.controls['confirmPassword'].invalid && frmCreateUser.controls['confirmPassword'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmCreateUser.controls['confirmPassword'].hasError('required')">
              Please enter password.
            </div>
            <div *ngIf="frmCreateUser.controls['confirmPassword'].hasError('confirmPasswordValidator')">
              Passsword and Confirm Password didn't match.
            </div>
          </div>
        </div>

        <button type="submit" [disabled]="!frmCreateUser.valid" class="btn btn-primary">Create Admin User</button>
        <button type="reset" (click)="onReset()" class="btn btn-secondary ml-2">Cancel</button>
      </form>
    </div>
  </div>
</div>