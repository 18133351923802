<div class="container mt-2">
    <h2 class="`text-default">Edit Trip Type</h2>
    <form [formGroup]="frmEditTripType" (ngSubmit)="submit()">
        <div class="card">
            <div class="card-header">
                Trip Type Details
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="title">Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="title" class="form-control" id="title" formControlName="title" placeholder="title"
                            required>
                        <div *ngIf="frmEditTripType.controls['title'].invalid && frmEditTripType.controls['title'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmEditTripType.controls['title'].hasError('required')">
                                Please enter title.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="subTitle">Sub Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="subTitle" class="form-control" id="subTitle" formControlName="subTitle" placeholder="Sub Title"
                            required>
                        <div *ngIf="frmEditTripType.controls['subTitle'].invalid && frmEditTripType.controls['subTitle'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmEditTripType.controls['subTitle'].hasError('required')">
                                Please enter sub title.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="icon">Icon</label>
                    </div>
                    <div class="col-md-10">
                        <img src="{{fileUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
                        <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadFile()">Change</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row m-3">
            <button type="submit" [disabled]="!frmEditTripType.valid" class="btn btn-primary">Update Trip Type</button>
            <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
        </div>
    </form>
</div>