import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BoatService } from 'src/app/services/boat.service';
import { UsersService } from 'src/app/services/users/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { TripTypeService } from 'src/app/services/trip-type/trip-type.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-boat',
  templateUrl: './boats.component.html',
  styleUrls: ['./boats.component.css']
})
export class BoatComponent implements OnInit, OnDestroy {
  boatsDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  orderColumn: string = "Id"
  orderDirection: string = "DESC"
  type: string = "All";
  insuranceStatuses: string[] = ["missing", "pending", "verified", "declined"];
  boatTypes: string[] = ["sail", "power", "mega_yacht", "catamaran", "gulet", "other"];
  tripTypes: any;
  countries: any;
  frmFilter: FormGroup;
  includeAll: boolean = true;
  navigationSubscription: any;
  public exportFileName: string = "boats";


  constructor(private boatService: BoatService, private userService: UsersService,
    private router: Router, private modalService: NgbModal, private fb: FormBuilder,
    private commonService: CommonService, private tostr: ToastrService, private tripTypeService: TripTypeService) {
    this.frmFilter = this.fb.group({
      id: [],
      insuranceStatus: [''],
      boatType: [''],
      country: [''],
      customMade: [''],
      name: [''],
      model: [''],
      location: [''],
      length: [''],
      capacity: [''],
      beds: [''],
      cabins: [''],
      tripType: ['']
    });
    if (this.boatService.boatFilter != null) {
      this.frmFilter.setValue({
        id: this.boatService.boatFilter.id,
        insuranceStatus: this.boatService.boatFilter.insuranceStatus,
        boatType: this.boatService.boatFilter.boatType,
        country: this.boatService.boatFilter.countryCode,
        customMade: this.boatService.boatFilter.isCustomMade,
        name: this.boatService.boatFilter.name,
        model: this.boatService.boatFilter.model,
        location: this.boatService.boatFilter.locationName,
        length: this.boatService.boatFilter.length,
        capacity: this.boatService.boatFilter.capacity,
        beds: this.boatService.boatFilter.beds,
        cabins: this.boatService.boatFilter.cabins,
        tripType: this.boatService.boatFilter.tripTypeId
      });
      this.page = this.boatService.boatFilter.page;
      this.pageSize = this.boatService.boatFilter.pageSize;
      this.orderColumn = this.boatService.boatFilter.orderColumn;
      this.orderDirection = this.boatService.boatFilter.orderDirection;
      this.type = this.boatService.boatFilter.type;
      this.includeAll = this.boatService.boatFilter.includeAll;
    };
    /*this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });*/
  }

  ngOnInit(): void {
    this.getBoats();
    this.GetCountryList();
    this.getTripTypes();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  GetCountryList() {
    this.commonService.fetchCountryList().subscribe(
      response => {
        this.countries = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  getTripTypes() {
    this.tripTypeService.fetchTripTypesForBoatFiltration().subscribe(
      res => {
        this.tripTypes = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  getBoats() {
    var boatParams = {
      type: this.type,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      id: this.frmFilter.controls["id"].value,
      insuranceStatus: this.frmFilter.controls["insuranceStatus"].value,
      boatType: this.frmFilter.controls["boatType"].value,
      countryCode: this.frmFilter.controls["country"].value,
      isCustomMade: this.frmFilter.controls["customMade"].value ? this.frmFilter.controls["customMade"].value == "Yes" ? true : false : null,
      name: this.frmFilter.controls["name"].value,
      model: this.frmFilter.controls["model"].value,
      locationName: this.frmFilter.controls["location"].value,
      length: this.frmFilter.controls["length"].value ? this.frmFilter.controls["length"].value : null,
      capacity: this.frmFilter.controls["capacity"].value ? this.frmFilter.controls["capacity"].value : null,
      beds: this.frmFilter.controls["beds"].value ? this.frmFilter.controls["beds"].value : null,
      cabins: this.frmFilter.controls["cabins"].value ? this.frmFilter.controls["cabins"].value : null,
      tripTypeId: this.frmFilter.controls["tripType"].value != "" ? this.frmFilter.controls["tripType"].value : null,
      includeAll: this.includeAll
    };
    this.boatService.boatFilter = boatParams;
    this.boatService.fetchBoats(boatParams).subscribe(
      response => {
        this.boatsDetails = response;
        this.changeCheckBoxStatus();
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = 'ASC';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getBoats();
  }

  getPage(page: number) {
    this.page = page;
    this.getBoats();
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.boatsDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.boatsDetails.data[a].id);
      }
      this.boatsDetails.data.forEach((boat) => {
        boat.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.boatsDetails.data.forEach((boat) => {
        boat.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.boatsDetails.data.length && this.boatsDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected boat?");
    if (isDelete) {
      this.boatService.deleteBoats([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getBoats();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  deleteBoats() {
    var isDelete = confirm("are you sure want to delete selected boats?");
    if (isDelete) {
      console.log(this.checkboxCheckedList);
      if (this.checkboxCheckedList.length > 0) {
        this.boatService.deleteBoats(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getBoats();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

  all() {
    var hasValue = Object.keys(this.frmFilter.value).some(k => !!this.frmFilter.value[k]);
    if (!hasValue) {
      this.includeAll = true;
    }
    this.type = "All";
    this.getBoats();
  }

  published() {
    this.type = "Published"
    this.getBoats();
  }

  publishedOwned() {
    this.type = "PublishedOwned"
    this.getBoats();
  }
  publishedBM() {
    this.type = "PublishedBM"
    this.getBoats();
  }
  unpublished() {
    this.type = "Unpublished"
    this.getBoats();
  }

  bookingManager() {
    this.type = "BookingManager"
    this.getBoats();
  }

  userOwned() {
    this.type = "UserOwned"
    this.getBoats();
  }

  deleted() {
    this.type = "Deleted"
    this.getBoats();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    this.page = 1;
    this.includeAll = false;
    this.getBoats();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      id: null,
      insuranceStatus: "",
      boatType: "",
      country: "",
      customMade: "",
      name: "",
      model: "",
      location: "",
      length: "",
      capacity: "",
      beds: "",
      cabins: "",
      tripType: ""
    });
    this.boatService.boatFilter = null;
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getBoats();
  }
}
