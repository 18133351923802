<div class="container-fluid">
    <div class="row">
        <div ngbDropdown class="col-md-4 d-inline-block pt-2 pb-2">
            <h2 class=" mt-2 mb-4">Alert {{id}}</h2>
        </div>
        <div class="col-md-8 text-right pt-2 pb-2">
            <button type="button" (click)="delete()" class="btn btn-secondary ml-2">Delete Alert</button>
            <button type="button" (click)="changeStatus('open')" class="btn btn-secondary ml-2"
                [style.display]="btnOpen">Transition to Open</button>
            <button type="button" (click)="changeStatus('in progress')" class="btn btn-secondary ml-2"
                [style.display]="btnInProgress">Transition to In progress</button>
            <button type="button" (click)="changeStatus('closed')" class="btn btn-secondary ml-2"
                [style.display]="btnClosed">Transition to Closed</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h6>Basic Info</h6>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped" *ngIf="alert != null && alert != undefined">
                                <tr>
                                    <td class="bt-none">ID</td>
                                    <td class="bt-none">{{alert.id}}</td>
                                </tr>
                                <tr>
                                    <td>ALERT TYPE</td>
                                    <td>{{alert.alertType}}</td>
                                </tr>
                                <tr>
                                    <td>BOAT</td>
                                    <td>
                                        <div *ngIf="alert.boatName">
                                            <a routerLink="../boat-view"
                                                [queryParams]="{id: alert.boatId,name:alert.boatName}"
                                                class="text-primary"
                                                style="text-decoration: underline;">{{alert.boatName}}
                                                {{alert.boatModel}}</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>BOOKING</td>
                                    <td>
                                        <div *ngIf="alert.bookingId > 0">
                                            <a routerLink="../booking-view" [queryParams]="{id: alert.bookingId}"
                                                class="text-primary" style="text-decoration: underline;">Booking
                                                #{{alert.bookingId}}</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>USER</td>
                                    <td>
                                        <a (click)="gotoUser(alert.userFName,alert.userLName,alert.userId)"
                                            class="text-primary"
                                            style="text-decoration: underline;cursor: pointer;">{{alert.userFName}}
                                            {{alert.userLName}}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>STATUS</td>
                                    <td>
                                        <p [style.backgroundColor]="alert.statusColor"
                                            style="color: #ffffff;width:fit-content;">{{alert.status}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>CREATED AT</td>
                                    <td> {{alert.createdAt}}</td>
                                </tr>
                                <tr>
                                    <td>UPDATED AT</td>
                                    <td> {{alert.updatedAt}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h6>Metadata</h6>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <th>KEY</th>
                                    <th>VALUE</th>
                                </thead>
                                <tbody *ngIf="alert != null && alert.metaList != undefined && alert.metaList != null">
                                    <tr *ngFor="let meta of alert.metaList">
                                        <td>{{meta.key}}</td>
                                        <td>{{meta.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>