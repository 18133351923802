import { Component, Input, OnInit } from '@angular/core';
import { ExportService } from 'src/app/services/export/export.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AmenitiesService } from 'src/app/services/amenities/amenities.service'
import { AmenitycategoriesService } from 'src/app/services/amenitycategories/amenitycategories.service'
import { BoatService } from 'src/app/services/boat.service';
import { BookingService } from 'src/app/services/booking.service';
import { ReviewService } from 'src/app/services/review.service';
import { TripTypeService } from 'src/app/services/trip-type/trip-type.service';
import { PopularDestinationService } from 'src/app/services/popular-destination/popular-destination.service';
import { ConversationsService } from 'src/app/services/conversations.service';
import { ReferralService } from 'src/app/services/referral.service';
import { UsersService } from 'src/app/services/users/users.service';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { MetagroupService } from 'src/app/services/metagroup.service';
import { BookingRequestService } from 'src/app/services/booking-request.service';


@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css']
})

export class ExportDataComponent implements OnInit {
  @Input() exportFileName: any;
  fullFileName: string = "";
  todayDate: any;

  constructor(private exportService: ExportService, private datePipe: DatePipe, private tostr: ToastrService,
    private amenitiesService: AmenitiesService, private amenitycategoriesService: AmenitycategoriesService,
    private boatService: BoatService, private bookingService: BookingService,
    private reviewService: ReviewService, private tripTypeService: TripTypeService,
    private popularDestinationService: PopularDestinationService, private conversationsService: ConversationsService,
    private referralService: ReferralService, private usersService: UsersService, private adminUsersService: UserService,
    private alertService: AlertService, private metagroupService: MetagroupService, private bookingRequest: BookingRequestService) {
    var mydate = new Date();
    this.todayDate = this.datePipe.transform(mydate, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
  }

  private getFullFileName(name: string): string {
    return name + "-" + this.todayDate + "";
  }

  private exportAsCSV(data: any, name: string) {
    this.fullFileName = this.getFullFileName(name);
    this.exportService.exportJsonAsCSVFile(data, this.fullFileName);
  }

  private exportAsXLS(data: any, name: string) {
    this.fullFileName = this.getFullFileName(name);
    this.exportService.exportJsonAsXLSFile(data, this.fullFileName);
  }

  private exportAsXML(data: any, name: string) {
    this.fullFileName = this.getFullFileName(name);
    this.exportService.exportJsonAsXMLFile(data, this.fullFileName);
  }

  private exportAsJSON(data: any, name: string) {
    this.fullFileName = this.getFullFileName(name);
    this.exportService.exportJsonFile(data, this.fullFileName);
  }

  private switchButton(Button: string, fileName: string, jsonObj: any) {
    if (jsonObj != undefined) {
      switch (Button) {
        case "CSV":
          this.exportAsCSV(jsonObj, fileName);
          break;

        case "XLS":
          this.exportAsXLS(jsonObj, fileName);
          break;

        case "XML":
          this.exportAsXML(jsonObj, fileName);
          break;

        case "JSON":
          this.exportAsJSON(jsonObj, fileName);
          break;
      }
    }
    else {
      this.tostr.error("Something went wrong, please try again.");
    }
  }

  export(Button: string, fileName: string) {
    switch (fileName) {
      case "amenities":
        this.exportAmenities(Button, fileName);
        break;

      case "amenity_categories":
        this.exportAmenityCategories(Button, fileName);
        break;

      case "boats":
        this.exportBoats(Button, fileName);
        break;

      case "bookings":
        this.exportBookings(Button, fileName);
        break;

      case "reviews":
        this.exportReviews(Button, fileName);
        break;

      case "trip_types":
        this.exportTripTypes(Button, fileName);
        break;

      case "popular_destinations":
        this.exportPopularDestinations(Button, fileName);
        break;

      case "conversations":
        this.exportConversations(Button, fileName);
        break;

      case "referrals":
        this.exportReferrals(Button, fileName);
        break;

      case "users":
        this.exportUsers(Button, fileName);
        break;

      case "admin_users":
        this.exportAdminUsers(Button, fileName);
        break;

      case "alerts":
        this.exportAlerts(Button, fileName);
        break;

      case "meta_groups":
        this.exportMetaGroups(Button, fileName);
        break;

      case "booking_request":
        this.exportBookingRequest(Button, fileName);
        break;
    }
  }

  private exportAmenities(Button: string, fileName: string) {
    this.amenitiesService.fetchAmenityDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing amenities data, please try again.");
        }
      );
  }
  private exportAmenityCategories(Button: string, fileName: string) {
    this.amenitycategoriesService.fetchAmenityCategoriesDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing amenity categories data, please try again.");
        }
      );
  }
  private exportBoats(Button: string, fileName: string) {
    this.boatService.fetchBoatDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing boats data, please try again.");
        }
      );
  }
  private exportBookings(Button: string, fileName: string) {
    this.bookingService.fetchBookingsDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing bookings data, please try again.");
        }
      );
  }
  private exportTripTypes(Button: string, fileName: string) {
    this.tripTypeService.fetchTripTypesDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing trip types data, please try again.");
        }
      );
  }
  private exportReviews(Button: string, fileName: string) {
    this.reviewService.fetchReviewsDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing reviews data, please try again.");
        }
      );
  }
  private exportPopularDestinations(Button: string, fileName: string) {
    this.popularDestinationService.fetchPopularDestinationsDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing popular destinations data, please try again.");
        }
      );
  }
  private exportConversations(Button: string, fileName: string) {
    this.conversationsService.fetchConversationsDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing conversations data, please try again.");
        }
      );
  }
  private exportReferrals(Button: string, fileName: string) {
    this.referralService.fetchReferralsDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing referrals data, please try again.");
        }
      );
  }
  private exportUsers(Button: string, fileName: string) {
    this.usersService.fetchUsersDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing users data, please try again.");
        }
      );
  }
  private exportAdminUsers(Button: string, fileName: string) {
    this.adminUsersService.fetchAdminUsersDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing admin users data, please try again.");
        }
      );
  }
  private exportAlerts(Button: string, fileName: string) {
    this.alertService.fetchAlertsDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing alerts data, please try again.");
        }
      );
  }
  private exportMetaGroups(Button: string, fileName: string) {
    this.metagroupService.fetchMetaGroupsDataForExport()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing meta groups data, please try again.");
        }
      );
  }
  private exportBookingRequest(Button: string, fileName: string) {
    this.bookingRequest.fetchUserBookingRequestExportData()
      .subscribe(
        response => {
          this.switchButton(Button, fileName, response);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong while facthing meta groups data, please try again.");
        }
      );
  }
}
