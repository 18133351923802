<div class="container mt-2">
  <h2 class="`text-default">Change Insurance</h2>
  <form [formGroup]="frmInsurance" (ngSubmit)="submit()">
    <div class="card">
      <div class="card-header">
        Insurance Details
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="col-md-2">
            <label for="iType">Insurance type</label>
          </div>
          <div class="col-md-10" *ngIf="insuranceTypes != null && insuranceTypes.length > 0">
            <select formControlName="insuranceType" id="iType" class="form-control" (change)="setValidation()"
              ng-options="">
              <option *ngFor="let type of insuranceTypes" value={{type}}>{{type}}</option>
            </select>
            <div
              *ngIf="frmInsurance.controls['insuranceType'].invalid && frmInsurance.controls['insuranceType'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmInsurance.controls['insuranceType'].hasError('required')">
                Please choose insurance type.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="iStatus">Insurance Status</label>
          </div>
          <div class="col-md-10">
            <div *ngIf="insuranceStatuses != null && insuranceStatuses.length > 0">
              <select formControlName="insuranceStatus" id="iType" class="form-control" ng-options="">
                <option *ngFor="let status of insuranceStatuses" value={{status}}>{{status}}</option>
              </select>
              <div
                *ngIf="frmInsurance.controls['insuranceStatus'].invalid && frmInsurance.controls['insuranceStatus'].touched"
                class="alert alert-danger form-danger" role="alert">
                <div *ngIf="frmInsurance.controls['insuranceStatus'].hasError('required')">
                  Please choose insurance status.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row"
          *ngIf="frmInsurance.controls['insuranceType'].value != null && frmInsurance.controls['insuranceType'].value == 'boatflex'">
          <div class="col-md-2">
            <label for="value">Value</label>
          </div>
          <div class="col-md-10">
            <div>
              <input type="text" id="value" formControlName="value" required class="form-control">
              <div *ngIf="frmInsurance.controls['value'].invalid && frmInsurance.controls['value'].touched"
                class="alert alert-danger form-danger" role="alert">
                <div *ngIf="frmInsurance.controls['value'].hasError('required')">
                  Please enter value.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row"
          *ngIf="frmInsurance.controls['insuranceType'].value != null && frmInsurance.controls['insuranceType'].value == 'third_party'">
          <div class="col-md-2">
            <label for="mDeposit">Minimum Deposit</label>
          </div>
          <div class="col-md-10">
            <input type="text" id="mDeposit" formControlName="minimumDeposit" required class="form-control">
            <div
              *ngIf="frmInsurance.controls['minimumDeposit'].invalid && frmInsurance.controls['minimumDeposit'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmInsurance.controls['minimumDeposit'].hasError('required')">
                Please enter minimum deposit.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="currency">Currency</label>
          </div>
          <div class="col-md-10">
            <div *ngIf="currencies != null && currencies.length > 0">
              <select formControlName="currency" id="currency" class="form-control" ng-options="" required>
                <option *ngFor="let currency of currencies" value={{currency}}>{{currency}}</option>
              </select>
              <div *ngIf="frmInsurance.controls['currency'].invalid && frmInsurance.controls['currency'].touched"
                class="alert alert-danger form-danger" role="alert">
                <div *ngIf="frmInsurance.controls['currency'].hasError('required')">
                  Please choose currency.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row m-3">
        <button type="submit" [disabled]="!frmInsurance.valid" class="btn btn-primary">Change Insurance</button>
      </div>
    </div>
  </form>
</div>