import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CommonProvider } from 'src/app/shared/modals/common-provider';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit, OnDestroy {
  alertsDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  alertTypes: any;
  page: number = 1;
  pageSize: number = 10;
  orderColumn: string = "Id"
  orderDirection: string = "DESC"
  frmFilter: FormGroup;
  scope : string = "All";
  includeAll: boolean = true;
  navigationSubscription: any;
  public exportFileName :string = "alerts";

  constructor(private alertService: AlertService, private userService: UsersService,
    private router: Router, private modalService: NgbModal, private fb: FormBuilder,
    private alertModel: CommonProvider, private tostr: ToastrService) {
    this.alertTypes = alertModel.getAlertTypes();
    this.frmFilter = this.fb.group({
      alertType: [''],
      status: [''],
      from: [''],
      to: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.getAlerts();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getAlerts() {
    var alertParams = {
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      status: this.frmFilter.controls["status"].value,
      alertType: this.frmFilter.controls["alertType"].value,
      fromDate: this.frmFilter.controls["from"].value,
      toDate: this.frmFilter.controls["to"].value,
      scope : this.scope,
      includeAll: this.includeAll
    };
    this.alertService.fetchAlerts(alertParams).subscribe(
      response => {
        this.alertsDetails = response;
        this.changeCheckBoxStatus();
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getAlerts();
  }

  getPage(page: number) {
    this.page = page;
    this.getAlerts();
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.alertsDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.alertsDetails.data[a].id);
      }
      this.alertsDetails.data.forEach((alert) => {
        alert.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.alertsDetails.data.forEach((alert) => {
        alert.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.alertsDetails.data.length && this.alertsDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected alert?");
    if (isDelete) {
      this.alertService.deleteAlerts([id])
        .subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getAlerts();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  deleteAlerts() {
    var isDelete = confirm("are you sure want to delete selected alerts?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.alertService.deleteAlerts(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getAlerts();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

  all() {
    var hasValue = Object.keys(this.frmFilter.value).some(k => !!this.frmFilter.value[k]);
    if (!hasValue) {
      this.includeAll = true;
    }
    this.scope = "All"
    this.getAlerts();
  }

  openAlerts() {
    this.scope = "Open";
    this.getAlerts();
  }

  inProgress() {
    this.scope = "InProgress";
    this.getAlerts();
  }

  closed() {
    this.scope = "Closed"
    this.getAlerts();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    this.includeAll = false;
    this.getAlerts();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.includeAll = true;
    this.frmFilter.reset();
    this.modalService.dismissAll('submit');
    this.getAlerts();
  }
}
