import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AmenitycategoriesService } from 'src/app/services/amenitycategories/amenitycategories.service';
import { AmenityCategoryRequest } from 'src/app/shared/modals/amenity-category-request';
import { ToastrService } from 'ngx-toastr';
import { BmAmenityService } from 'src/app/services/bm-amenity.service';

@Component({
  selector: 'app-amenitycategory-edit',
  templateUrl: './amenitycategory-edit.component.html',
  styleUrls: ['./amenitycategory-edit.component.css']
})
export class AmenitycategoryEditComponent implements OnInit {
  id: number = 0;
  amenitycategory: any;
  frmCategory: FormGroup;
  constructor(
    //private amenityService: AmenitycategoriesService,
    private amenityService: BmAmenityService,
    private activatedRoute: ActivatedRoute,
    private route: Router, private fb: FormBuilder, private tostr: ToastrService) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.id = data.id;
    });
    this.frmCategory = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.amenityService.viewAmenitiesCategories(this.id)
      .pipe(finalize(() => {
      })).subscribe(
        response => {
          this.amenitycategory = response;
          this.frmCategory.setValue({
            name: this.amenitycategory.name
          });
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  submit() {
    if (this.frmCategory.valid) {
      var amenityCategoryRequest = new AmenityCategoryRequest(this.id, this.frmCategory.controls["name"].value);
      this.amenityService.updateAmenitycategory(amenityCategoryRequest)
        .pipe(finalize(() => {
        })).subscribe(
          res => {
            if (res.toString() == "Success") {
              this.tostr.success("Updated Sucessfully.");
              this.route.navigate(['admin/amenitycategories']);
            }
            else {
              this.tostr.error(res.toString());
            }
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  reset() {
    this.route.navigate(['admin/amenitycategories']);
  }

}
