import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-alert-view',
  templateUrl: './alert-view.component.html',
  styleUrls: ['./alert-view.component.css']
})
export class AlertViewComponent implements OnInit {
  id: number = 0;
  alert: any;
  status: string = "";
  btnOpen: string = "";
  btnInProgress = "";
  btnClosed = "";
  resourceType: string = ""
  resourceId: string = ""

  constructor(private alertService: AlertService, private router: ActivatedRoute,
    private route: Router, private userService: UsersService, private tostr: ToastrService) {
    this.router.queryParams.subscribe(param => { this.id = param.id, this.status = param.status });
  }

  ngOnInit(): void {
    this.resourceType = "Alert";
    this.resourceId = this.id.toString();
    this.getAlert();
    this.toggleButtons();
  }

  getAlert() {
    this.alertService.fetchAlert(this.id).subscribe(
      response => {
        this.alert = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.route.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  changeStatus(status: string) {
    var alertRequest = {
      id: this.id,
      status: status
    };
    this.alertService.updateAlertStatus(alertRequest)
      .subscribe(
        response => {
          this.tostr.success(response.toString());
          this.status = status;
          this.toggleButtons();
          this.getAlert();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  delete() {
    var isDelete = confirm("are you sure want to delete this alert?");
    if (isDelete) {
      this.alertService.deleteAlerts([this.id])
        .subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.route.navigate(["admin/alerts"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  toggleButtons() {
    if (this.status.toUpperCase() != "OPEN" && this.status.toUpperCase() != "CLOSED") {
      this.btnOpen = "inline-block";
    }
    else {
      this.btnOpen = "none";
    }
    if (this.status.toUpperCase() != "IN PROGRESS") {
      this.btnInProgress = "inline-block";
    }
    else {
      this.btnInProgress = "none";
    }
    if (this.status.toUpperCase() == "IN PROGRESS") {
      this.btnClosed = "inline-block";
    }
    else {
      this.btnClosed = "none";
    }
  }
}
