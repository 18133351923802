import { Component, OnInit } from '@angular/core';
import { AmenitycategoriesService } from 'src/app/services/amenitycategories/amenitycategories.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader.service';
import { ToastrService } from 'ngx-toastr';
import { BmAmenityService } from 'src/app/services/bm-amenity.service';

@Component({
  selector: 'app-amenitiescategoryview',
  templateUrl: './amenitiescategoryview.component.html',
  styleUrls: ['./amenitiescategoryview.component.css']
})
export class AmenitiescategoryviewComponent implements OnInit {
  aminityCategoryDetails: any;
  id: number = 0;
  resourceType: string = "";
  resourceId: string = "";

  constructor(
    //private amenityService: AmenitycategoriesService,
    private amenityService: BmAmenityService,
    private activatedRoute: ActivatedRoute,
    private route: Router, private tostr: ToastrService) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.id = data.id;
    });
  }

  ngOnInit(): void {
    this.resourceType = "Amenity-Category";
    this.resourceId = this.id.toString();

    this.amenityService.viewAmenitiesCategories(this.id)
      .pipe(finalize(() => {
      })).subscribe(
        response => {
          this.aminityCategoryDetails = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  delete(id: number) {
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      this.amenityService.AmenityCategoriesDelete([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.route.navigate(["admin/amenitycategories"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }
}
