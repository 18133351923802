import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-booking-request',
  templateUrl: './booking-request.component.html',
  styleUrls: ['./booking-request.component.css']
})
export class BookingRequestComponent implements OnInit {
  bookingRequestDetails: any;
  boatTypes: string[] = ["sail", "power", "mega_yacht", "catamaran", "gulet", "other"];
  frmFilter: FormGroup;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  orderColumn: string = "Id"
  orderDirection: string = "DESC"
  navigationSubscription: any;
  public exportFileName: string = "booking_request";

  constructor(private userService: UsersService,
    private router: Router, private modalService: NgbModal, private fb: FormBuilder,
    private tostr: ToastrService, private bookingReq: BookingRequestService) {
    this.frmFilter = this.fb.group({
      locationName: [''],
      boatType: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.getBookingRequests();
  }

  getBookingRequests() {
    var bookingRequestParams = {
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      locationName: this.frmFilter.controls["locationName"].value,
      boatType: this.frmFilter.controls["boatType"].value
    };
    this.bookingReq.fetchBookingRequests(bookingRequestParams).subscribe(
      response => {
        this.bookingRequestDetails = response;
        this.changeCheckBoxStatus();
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  clearFilter() {
    this.frmFilter.setValue({
      locationName: "",
      boatType: ""
    });
    this.modalService.dismissAll('submit');
    this.getBookingRequests();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getBookingRequests();
  }

  getPage(page: number) {
    this.page = page;
    this.getBookingRequests();
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.bookingRequestDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.bookingRequestDetails.data[a].id);
      }
      this.bookingRequestDetails.data.forEach((booking) => {
        booking.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.bookingRequestDetails.data.forEach((booking) => {
        booking.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.bookingRequestDetails.data.length && this.bookingRequestDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected boat?");
    if (isDelete) {
      this.bookingReq.deleteBookingRequest([id]).subscribe(
        response => {
          this.tostr.success("Deleted Successfully.");
          this.getBookingRequests();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
    }
  }

  deleteBoats() {
    var isDelete = confirm("are you sure want to delete selected booking request?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.bookingReq.deleteBookingRequest(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getBookingRequests();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    this.getBookingRequests();
    this.modalService.dismissAll('submit');
  }

}
