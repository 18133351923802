<div class="row">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Basic info</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-striped" *ngIf="accountInfo != null && accountInfo != undefined">
                            <tr>
                                <td class="bt-none">ID</td>
                                <td class="bt-none">{{accountInfo.id}}</td>
                            </tr>
                            <tr>
                                <td>EMAIL</td>
                                <td>{{accountInfo.email}}</td>
                            </tr>
                            <tr>
                                <td>AVATAR URL</td>
                                <td>{{accountInfo.avatarUrl}}</td>
                            </tr>
                            <tr>
                                <td>INVITED BY</td>
                                <td>{{accountInfo.invitedBy}}</td>
                            </tr>
                            <tr>
                                <td>ACCOUNT TYPE</td>
                                <td>{{accountInfo.accountType}}</td>
                            </tr>
                            <tr>
                                <td>CREDIT</td>
                                <td>{{accountInfo.credit}}</td>
                            </tr>
                            <tr>
                                <td>SIGN IN COUNT</td>
                                <td>{{accountInfo.signInCount}}</td>
                            </tr>
                            <tr>
                                <td>CURRENT SIGN IN AT</td>
                                <td>{{accountInfo.currentSignInAt}}</td>
                            </tr>
                            <tr>
                                <td>CURRENT SIGN IN IP</td>
                                <td>{{accountInfo.CurrentSignInIp}}</td>
                            </tr>
                            <tr>
                                <td>EMAIL VERIFIED?</td>
                                <td>
                                    <p *ngIf="accountInfo.isEmailVerified!= '' && accountInfo.isEmailVerified == 'YES'"
                                        style="background-color: #6090DB;width:fit-content;">
                                        {{accountInfo.isEmailVerified}}</p>
                                    <p *ngIf="accountInfo.isEmailVerified!= '' && accountInfo.isEmailVerified == 'NO'"
                                        style="background-color: grey;width:fit-content;">
                                        {{accountInfo.isEmailVerified}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>SIGN UP BONUS?</td>
                                <td>
                                    <p *ngIf="accountInfo.isSignUpBonus!= '' && accountInfo.isSignUpBonus == 'YES'"
                                        style="background-color: #6090DB;width:fit-content;">
                                        {{accountInfo.isSignUpBonus}}</p>
                                    <p *ngIf="accountInfo.isSignUpBonus!= '' && accountInfo.isSignUpBonus == 'NO'"
                                        style="background-color: grey;width:fit-content;">{{accountInfo.isSignUpBonus}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>OWNER SERVICE FEE</td>
                                <td>{{accountInfo.ownerServiceFee}} %</td>
                            </tr>
                            <tr>
                                <td>RENTER SERVICE FEE</td>
                                <td>{{accountInfo.renterServiceFee}} %</td>
                            </tr>
                            <tr>
                                <td>IS BOAT OWNER</td>
                                <td>
                                    <p *ngIf="accountInfo.isBoatOwner!= '' && accountInfo.isBoatOwner == 'YES'"
                                        style="background-color: #6090DB;width:fit-content;">{{accountInfo.isBoatOwner}}
                                    </p>
                                    <p *ngIf="accountInfo.isBoatOwner!= '' && accountInfo.isBoatOwner == 'NO'"
                                        style="background-color: grey;width: fit-content;">{{accountInfo.isBoatOwner}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>IS SUPER HOST</td>
                                <td>
                                    <p *ngIf="accountInfo.isSuperHost!= '' && accountInfo.isSuperHost == 'YES'"
                                        style="background-color: #6090DB;width:fit-content;">{{accountInfo.isSuperHost}}
                                    </p>
                                    <p *ngIf="accountInfo.isSuperHost!= '' && accountInfo.isSuperHost == 'NO'"
                                        style="background-color: grey;width: fit-content;">{{accountInfo.isSuperHost}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>HAS SAIL CERTIFICATE</td>
                                <td>
                                    <p *ngIf="accountInfo.hasSailCertificate!= '' && accountInfo.hasSailCertificate == 'YES'"
                                        style="background-color: #6090DB;width:fit-content;">
                                        {{accountInfo.hasSailCertificate}}</p>
                                    <p *ngIf="accountInfo.hasSailCertificate!= '' && accountInfo.hasSailCertificate == 'NO'"
                                        style="background-color: grey;width: fit-content;">
                                        {{accountInfo.hasSailCertificate}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>HAS BOAT RENTAL INTERESTS</td>
                                <td>
                                    <p *ngIf="accountInfo.hasBoatRentelInterest!= '' && accountInfo.hasBoatRentelInterest == 'YES'"
                                        style="background-color: #6090DB;width:fit-content;">
                                        {{accountInfo.hasBoatRentelInterest}}</p>
                                    <p *ngIf="accountInfo.hasBoatRentelInterest!= '' && accountInfo.hasBoatRentelInterest == 'NO'"
                                        style="background-color: grey;width: fit-content;">
                                        {{accountInfo.hasBoatRentelInterest}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>MARKETING SOURCE</td>
                                <td>{{accountInfo.marketingSource}}</td>
                            </tr>
                            <tr>
                                <td>MARKETING SOURCE NOTE</td>
                                <td>{{accountInfo.marketingSourceNote}}</td>
                            </tr>
                            <tr>
                                <td>CREATED AT</td>
                                <td>{{accountInfo.createdAt}}</td>
                            </tr>
                            <tr>
                                <td>UPDATED AT</td>
                                <td>{{accountInfo.updatedAt}}</td>
                            </tr>
                            <tr *ngIf="accountInfo.stripeAccount != null && accountInfo.stripeAccount != ''">
                                <td>STRIPE ACCOUNT</td>
                                <td><a target="_bank"
                                        href="https://dashboard.stripe.com/applications/users/{{accountInfo.stripeAccount}}">{{accountInfo.stripeAccount}}</a>
                                </td>
                            </tr>
                            <tr *ngIf="accountInfo.stripeCustomer != null && accountInfo.stripeCustomer != ''">
                                <td>STRIPE CUSTOMER ACCOUNT</td>
                                <td><a target=" _bank"
                                        href="https://dashboard.stripe.com/customers/{{accountInfo.stripeCustomer}}">
                                        {{accountInfo.stripeCustomer}}</a></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-2">
                <div class="card">
                    <div class="card-header">
                        <h5>Follow Up</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-striped" *ngIf="accountInfo != null && accountInfo != undefined">
                            <tr>
                                <td class="bt-none">FOLLOW UP STATUS</td>
                                <td class="bt-none">
                                    <p *ngIf="accountInfo.followUpStatus != '' && accountInfo.followUpStatus != null"
                                        class="w-30" [style.backgroundColor]="accountInfo.followUpStatusColour">
                                        {{accountInfo.followUpStatus.toUpperCase()}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>FOLLOW UP TASK</td>
                                <td>{{accountInfo.followUpTask}}</td>
                            </tr>
                            <tr>
                                <td>FOLLOW UP DATE</td>
                                <td>{{accountInfo.followUpDate}}</td>
                            </tr>
                            <tr>
                                <td>ASSIGNEE</td>
                                <td> {{accountInfo.assignee}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Verifications</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-striped" *ngIf="accountInfo != null && accountInfo != undefined">
                            <tr>
                                <td class="bt-none">PHONE NUMBER</td>
                                <td class="bt-none">{{accountInfo.phoneNumber}}</td>
                            </tr>
                            <tr>
                                <td>PHONE NUMBER VERIFIED?</td>
                                <td>
                                    <p *ngIf="accountInfo.phoneNoVerified!= '' && accountInfo.phoneNoVerified == 'YES'"
                                        style="background-color: #6090DB;width: fit-content;">
                                        {{accountInfo.phoneNoVerified}}</p>
                                    <p *ngIf="accountInfo.phoneNoVerified!= '' && accountInfo.phoneNoVerified == 'NO'"
                                        style="background-color: grey;width: fit-content;">
                                        {{accountInfo.phoneNoVerified}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td class="bt-none">EMAIL</td>
                                <td class="bt-none">{{accountInfo.email}}</td>
                            </tr>
                            <tr>
                                <td>EMAIL VERIFIED?</td>
                                <td>
                                    <p *ngIf="accountInfo.isEmailVerified!= '' && accountInfo.isEmailVerified == 'YES'"
                                        style="background-color: #6090DB;width: fit-content;">
                                        {{accountInfo.isEmailVerified}}</p>
                                    <p *ngIf="accountInfo.isEmailVerified!= '' && accountInfo.isEmailVerified == 'NO'"
                                        style="background-color: grey;width: fit-content;">
                                        {{accountInfo.isEmailVerified}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>SAIL CERTIFICATE URL</td>
                                <td>{{accountInfo.sailCertificateUrl}}</td>
                            </tr>
                            <tr>
                                <td>SAIL CERTIFICATE STATUS</td>
                                <td>
                                    <p *ngIf="accountInfo.sailCertificateStatus != '' && accountInfo.sailCertificateStatus != null"
                                        style="width: fit-content;"
                                        [style.backgroundColor]="accountInfo.sailCertificateColour">
                                        {{accountInfo.sailCertificateStatus.toUpperCase()}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>PHOTO ID URL</td>
                                <td>{{accountInfo.photoIdUrl}}</td>
                            </tr>
                            <tr>
                                <td>PHOTO ID STATUS</td>
                                <td>
                                    <p *ngIf="accountInfo.photoIdStatus != '' && accountInfo.photoIdStatus != null"
                                        style="width: fit-content;" [style.backgroundColor]="accountInfo.photoIdColour">
                                        {{accountInfo.photoIdStatus.toUpperCase()}}</p>
                                </td>
                            </tr>
                            <tr *ngIf="accountInfo.photoIdType != null && accountInfo.photoIdType != 'Passport_Id'">
                                <td>PHOTO ID BACK URL</td>
                                <td>{{accountInfo.photoIdBackUrl}}</td>
                            </tr>
                            <tr *ngIf="accountInfo.photoIdType != null && accountInfo.photoIdType != 'Passport_Id'">
                                <td>PHOTO ID BACK STATUS</td>
                                <td>
                                    <p *ngIf="accountInfo.photoIdbackStatus != '' && accountInfo.photoIdbackStatus != null"
                                        style="width: fit-content;"
                                        [style.backgroundColor]="accountInfo.photoIdBackColour">
                                        {{accountInfo.photoIdbackStatus.toUpperCase()}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>DOCUMENT ID URL</td>
                                <td>{{accountInfo.documentIdUrl}}</td>
                            </tr>
                            <tr>
                                <td>DOCUMENT ID STATUS</td>
                                <td>
                                    <p *ngIf="accountInfo.documentIdStatus != '' && accountInfo.documentIdStatus != null"
                                        style="width: fit-content;"
                                        [style.backgroundColor]="accountInfo.documentColour">
                                        {{accountInfo.documentIdStatus.toUpperCase()}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>ADDITIONAL DOCUMENT ID URL</td>
                                <td>{{accountInfo.additionalDocumentIdUrl}}</td>
                            </tr>
                            <tr>
                                <td>ADDITIONAL DOCUMENT ID STATUS</td>
                                <td>
                                    <p *ngIf="accountInfo.additionalDocumentIdStatus != '' && accountInfo.additionalDocumentIdStatus != null"
                                        style="width: fit-content;"
                                        [style.backgroundColor]="accountInfo.documentColour">
                                        {{accountInfo.additionalDocumentIdStatus.toUpperCase()}}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="card">
                    <div class="card-header">
                        <h5>Stats</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <th>KEY</th>
                                <th>VALUE</th>
                            </thead>
                            <tbody
                                *ngIf="accountInfo != null && accountInfo.statsData != undefined && accountInfo.statsData != null">
                                <tr *ngFor="let stat of accountInfo.statsData">
                                    <td>{{stat.key}}</td>
                                    <td>{{stat.value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>