import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';
import { finalize } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonProvider } from 'src/app/shared/modals/common-provider';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})

export class UserListComponent implements OnInit {
  userDetails: any;
  page: number = 1;
  pageSize: number = 50;
  orderColumn: string = "Id";
  orderDirection: string = "DESC";
  isGlobalChecked: boolean = false;
  isDisableBatch: boolean = true;
  checkboxCheckedList: number[] = [];
  countries: any;
  assignees: any;
  followUpTaskList: any;
  frmFilter: FormGroup;
  includeAll: boolean = true;
  type: string = "All";
  navigationSubscription: any;
  public exportFileName: string = "users";

  constructor(private usersService: UsersService, private router: Router,
    private common: CommonProvider, private commonService: CommonService,
    private modalService: NgbModal, private fb: FormBuilder, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      id: [],
      firstName: [''],
      lastName: [''],
      email: [''],
      phoneNumber: [''],
      isEmailVerified: [''],
      isSailCertificateVerified: [''],
      isPhotoIdVerified: [''],
      isPhotoIdBackVerified: [''],
      isAdditionalDocumentVerified: [''],
      countryCode: [''],
      followUpStatus: [''],
      followUpFromDate: [''],
      followUpToDate: [''],
      assignee: [''],
      followUpTask: [''],
      isBoatOwner: [''],
      hasSailCertificate: [''],
      hasBoatRentalInterest: ['']
    });
    if (this.usersService.userFilter != null) {
      this.frmFilter.setValue({
        id: this.usersService.userFilter.id,
        firstName: this.usersService.userFilter.firstName,
        lastName: this.usersService.userFilter.lastName,
        email: this.usersService.userFilter.email,
        phoneNumber: this.usersService.userFilter.phoneNumber,
        isEmailVerified: this.usersService.userFilter.isEmailVerified,
        isSailCertificateVerified: this.usersService.userFilter.isSailCertificateVerified,
        isPhotoIdVerified: this.usersService.userFilter.isPhotoIdVerified,
        isPhotoIdBackVerified: this.usersService.userFilter.isPhotoIdBackVerified,
        isAdditionalDocumentVerified: this.usersService.userFilter.isAdditionalDocumentVerified,
        countryCode: this.usersService.userFilter.countryCode,
        followUpStatus: this.usersService.userFilter.followUpStatus,
        followUpFromDate: this.usersService.userFilter.followUpFromDate,
        followUpToDate: this.usersService.userFilter.followUpToDate,
        assignee: this.usersService.userFilter.assigneeId,
        followUpTask: this.usersService.userFilter.followUpTask,
        isBoatOwner: this.usersService.userFilter.isBoatOwner,
        hasSailCertificate: this.usersService.userFilter.hasSailCertificate,
        hasBoatRentalInterest: this.usersService.userFilter.hasBoatRentalInterest
      });

      this.page = this.usersService.userFilter.page;
      this.pageSize = this.usersService.userFilter.pageSize;
      this.orderColumn = this.usersService.userFilter.orderColumn;
      this.orderDirection = this.usersService.userFilter.orderDirection;
      this.type = this.usersService.userFilter.type;
      this.includeAll = this.usersService.userFilter.includeAll;
    };
    /* this.navigationSubscription = this.router.events.subscribe((e: any) => {
       if (e instanceof NavigationEnd) {
         this.clearFilter();
       }
     });*/

  }

  ngOnInit(): void {
    this.getUsers();
    this.GetCountryList();
    // this.getAssignees();
    this.followUpTaskList = this.common.getFollowUpTask();
  }

  getUsers() {
    var userFilterRequest = {
      type: this.type,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      id: this.frmFilter.controls["id"].value,
      firstName: this.frmFilter.controls["firstName"].value,
      lastName: this.frmFilter.controls["lastName"].value,
      email: this.frmFilter.controls["email"].value,
      phoneNumber: this.frmFilter.controls["phoneNumber"].value,
      isEmailVerified: this.frmFilter.controls["isEmailVerified"].value ? this.frmFilter.controls["isEmailVerified"].value == "Yes" ? true : false : null,
      isSailCertificateVerified: this.frmFilter.controls["isSailCertificateVerified"].value ? this.frmFilter.controls["isSailCertificateVerified"].value == "Yes" ? true : false : null,
      isPhotoIdVerified: this.frmFilter.controls["isPhotoIdVerified"].value ? this.frmFilter.controls["isPhotoIdVerified"].value == "Yes" ? true : false : null,
      isPhotoIdBackVerified: this.frmFilter.controls["isPhotoIdBackVerified"].value ? this.frmFilter.controls["isPhotoIdBackVerified"].value == "Yes" ? true : false : null,
      isAdditionalDocumentVerified: this.frmFilter.controls["isAdditionalDocumentVerified"].value ? this.frmFilter.controls["isAdditionalDocumentVerified"].value == "Yes" ? true : false : null,
      countryCode: this.frmFilter.controls["countryCode"].value,
      followUpStatus: this.frmFilter.controls["followUpStatus"].value,
      followUpFromDate: this.frmFilter.controls["followUpFromDate"].value,
      followUpToDate: this.frmFilter.controls["followUpToDate"].value,
      assigneeId: this.frmFilter.controls["assignee"].value != "" ? this.frmFilter.controls["assignee"].value : null,
      followUpTask: this.frmFilter.controls["followUpTask"].value,
      isBoatOwner: this.frmFilter.controls["isBoatOwner"].value ? this.frmFilter.controls["isBoatOwner"].value == "Yes" ? true : false : null,
      hasSailCertificate: this.frmFilter.controls["hasSailCertificate"].value ? this.frmFilter.controls["hasSailCertificate"].value == "Yes" ? true : false : null,
      hasBoatRentalInterest: this.frmFilter.controls["hasBoatRentalInterest"].value ? this.frmFilter.controls["hasBoatRentalInterest"].value == "Yes" ? true : false : null,
      includeAll: this.includeAll
    };
    this.usersService.userFilter = userFilterRequest;
    this.usersService.fetchUsers(userFilterRequest).pipe(finalize(() => {
    })).subscribe(res => {
      this.userDetails = res;
      this.changeCheckBoxStatus();
    },
      error => {
        this.tostr.error("Error occurred while fatching data.");
        console.log(error);
      });
  }

  getPage(page: number) {
    this.page = page;
    this.getUsers();
  }

  edit(id: number) {
    this.router.navigate(['admin/user-edit'], { queryParams: { id: id } });
  }

  view(id: number, fName: string, fullName: string) {
    this.router.navigate(['admin/user-view'], { queryParams: { id: id } });
    this.usersService.fName = fName;
    this.usersService.fullName = fullName;
  }

  delete(id: number) {
    var isDelete = confirm("are you sure want to delete selected user?");
    if (isDelete) {
      this.usersService.deleteUsers([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getUsers();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  deleteUsers() {
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.usersService.deleteUsers(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getUsers();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'desc') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'asc') {
      this.orderDirection = 'desc';
    } else {
      this.orderDirection = 'asc';
    }
    this.orderColumn = orderColumn;
    this.getPage(this.page);
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.userDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.userDetails.data[a].id);
      }
      this.userDetails.data.forEach((user) => {
        user.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.userDetails.data.forEach((user) => {
        user.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.userDetails.data.length && this.userDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    var hasValue = Object.keys(this.frmFilter.value).some(k => !!this.frmFilter.value[k]);
    if (!hasValue) {
      this.includeAll = true;
    }
    else {
      this.includeAll = false;
    }
    this.getUsers();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      isEmailVerified: "",
      isSailCertificateVerified: "",
      isPhotoIdVerified: "",
      isPhotoIdBackVerified: "",
      isAdditionalDocumentVerified: "",
      countryCode: "",
      followUpStatus: "",
      followUpFromDate: "",
      followUpToDate: "",
      assignee: "",
      followUpTask: "",
      isBoatOwner: "",
      hasSailCertificate: "",
      hasBoatRentalInterest: ""
    });
    this.usersService.userFilter = null;
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getUsers();
  }

  all() {
    var hasValue = Object.keys(this.frmFilter.value).some(k => !!this.frmFilter.value[k]);
    if (!hasValue) {
      this.includeAll = true;
    }
    this.type = "All";
    this.getUsers();
  }

  pendingVerification() {
    this.type = "PendingVerification"
    this.getUsers();
  }

  GetCountryList() {
    this.commonService.fetchCountryList().subscribe(
      response => {
        this.countries = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  getAssignees() {
    this.commonService.fetchAssignees().subscribe(
      res => {
        this.assignees = res;
      },
      error => {
        console.log(error);
      }
    );
  }
}
