import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { BaseService } from '../shared/base.service';
import { ConfigService } from '../shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends BaseService {
  httpOptions;
  constructor(private authService: LoginService, private http: HttpClient, private configService: ConfigService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }

  fetchReviews(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'review/list', data, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchReview(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'review/' + id, this.httpOptions).pipe(catchError(this.handleError));
  }

  editReviews(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'review', data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteReview(ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'review', options).pipe(
      catchError(this.handleError)
    );
  }

  fetchReviewsDataForExport() {
    return this.http.get(this.configService.resourceApiURI + 'review/review-data-export', this.httpOptions).pipe(catchError(this.handleError));
  }
}
