<div class="container-fluid">
    <div class="row mb-3 row-y">
        <div class="col-lg-8 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="metagroupDetails != null">{{metagroupDetails.title}}</h2>
        </div>
        <div class="col-lg-4 col-md-12 text-right" *ngIf="metagroupDetails != null">
            <a routerLink="../metagroup-edit" [queryParams]="{id: metagroupDetails.id}"
                class="btn btn-secondary mt-2">Edit Meta Group</a>
            <button type="button" (click)="delete(metagroupDetails.id)" class="btn btn-secondary mt-2 ml-2">Delete Meta
                Group</button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card mb-4">
                <div class="card-header">
                    <h5>Meta Group details</h5>
                </div>
                <div class="card-body" *ngIf="metagroupDetails != null && metagroupDetails != undefined">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label class="label-header">ID</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label class="label-inner">{{metagroupDetails.id}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label class="label-header">URL</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label class="label-inner">{{metagroupDetails.url}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label class="label-header">TITLE</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label class="label-inner">{{metagroupDetails.title}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label class="label-header">DESCRIPTION</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label class="label-inner">{{metagroupDetails.description}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label class="label-header">IMAGE URL</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label class="label-inner">{{metagroupDetails.imageUrl}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>