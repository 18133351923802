import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ReviewService } from 'src/app/services/review.service';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit, OnDestroy {
  reviewDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  defaultvalue: number = 0;
  frmFilter: FormGroup;
  orderColumn: string = "Id";
  orderDirection: string = "DESC";
  navigationSubscription: any;
  public exportFileName :string = "reviews";
  

  constructor(private fb: FormBuilder, private modalService: NgbModal,
    private reviewService: ReviewService, private router: Router, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      role: [""],
      type: [""],
      stars: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.reset();
      }
    });

  }

  ngOnInit(): void {
    this.getReviews();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getReviews() {
    var reviewRequest = {
      role: this.frmFilter.controls["role"].value,
      Type: this.frmFilter.controls["type"].value,
      stars: this.frmFilter.controls["stars"].value == "" || this.frmFilter.controls["stars"].value == null ? 0 : this.frmFilter.controls["stars"].value,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection
    };
    this.reviewService.fetchReviews(reviewRequest)
      .subscribe(
        response => {
          this.reviewDetails = response;
          this.changeCheckBoxStatus();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getReviews();
  }

  getPage(page: number) {
    this.page = page;
    this.getReviews();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  onFilter() {
    this.getReviews();
    this.modalService.dismissAll('submit');
  }

  reset() {
    this.frmFilter.setValue({
      role: "",
      type: "",
      stars: 0
    });
    this.modalService.dismissAll('submit');
    this.getReviews();
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected Review?");
    if (isDelete) {
      this.reviewService.deleteReview([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getReviews();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.reviewDetails.reviewDto .length; a++) {
        this.checkboxCheckedList.push(this.reviewDetails.reviewDto [a].id);
      }
      this.reviewDetails.reviewDto .forEach((review) => {
        review.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.reviewDetails.reviewDto .forEach((review) => {
        review.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.reviewDetails.reviewDto .length && this.reviewDetails.reviewDto .length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  deleteReviews() {
    var isDelete = confirm("are you sure want to delete selected Reviews?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.reviewService.deleteReview(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getReviews();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }
}
