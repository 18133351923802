import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { timeStamp } from 'console';
import { ToastrService } from 'ngx-toastr';
import { ReferralService } from 'src/app/services/referral.service';

@Component({
  selector: 'app-referral-edit',
  templateUrl: './referral-edit.component.html',
  styleUrls: ['./referral-edit.component.css']
})
export class ReferralEditComponent implements OnInit {
  userList;
  initialUserList;
  id: number = 0;
  frmEditReferral: FormGroup;
  referralDetail;
  constructor(private router: Router, private toatsrService: ToastrService,
    private refService: ReferralService, private activeRoute: ActivatedRoute,
    private fb: FormBuilder) {
    this.activeRoute.queryParams.subscribe(params => { this.id = params.id });
    this.frmEditReferral = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      invitedBy: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getUsers();
    this.getReferralDetails(this.id);
    this.setReferralValue();
  }

  onSubmit() {
    if (!this.frmEditReferral.invalid) {
      var referralEditRequest = {
        "userId": this.id,
        "firstName": this.frmEditReferral.controls["firstName"].value,
        "lastName": this.frmEditReferral.controls["lastName"].value,
        "email": this.frmEditReferral.controls["email"].value,
        "invitedById": this.frmEditReferral.controls["invitedBy"].value
      };

      this.refService.updateRefferals(referralEditRequest).subscribe(
        res => {
          if (res.toString() == "Success") {
            this.toatsrService.success("Updated successfully.");
            this.router.navigate(["admin/referrals"]);
          }
          else {
            this.toatsrService.error("Error occurred while updating data.");
          }
        },
        err => {
          this.toatsrService.error("Error occurred while updating data.");
          console.log(err);
        }
      );
    }
    else {
      this.toatsrService.error("Error occurred while updating data.");
    }
  }
  onReset() {
    this.router.navigate(["admin/referrals"]);
  }

  getUsers() {
    this.refService.fetchInvitedByUsers().subscribe(
      res => {
        this.initialUserList = res;
        this.userList = this.initialUserList;
      },
      err => {
        this.toatsrService.error("Error occurred while fetching data.");
        console.log(err);
      }
    )
  }

  setReferralValue() {
    if (this.referralDetail != null && this.referralDetail != undefined) {
      this.frmEditReferral.setValue({
        firstName: this.referralDetail.firstName,
        lastName: this.referralDetail.lastName,
        email: this.referralDetail.email,
        invitedBy: this.referralDetail.invitedById
      });
    }
  }

  getReferralDetails(id: number) {
    this.refService.fetchRefferalEditDetails(id).subscribe(
      res => {
        this.referralDetail = res;
        this.setReferralValue();
      },
      err => {
        this.toatsrService.error("Error occurred while fetching details.");
        console.log(err);
      }
    );
  }

  onKey(event: any) {
    this.userList = this.search(event.target.value);
  }

  search(value: string) {
    if (value.length > 0) {
      let filter = value.toLowerCase();
      return this.userList.filter(x => x.fullName.toLowerCase().startsWith(filter));
    }
    return this.userList = this.initialUserList;
  }
}
