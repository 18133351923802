import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoEditorService extends BaseService {

  httpOptions: any; httpOptionsEdit: any;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/x-www-form-urlencoded'
        })
    };

    this.httpOptionsEdit = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      }), responseType: 'text'
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }

  refresh() {
    return this.http.post(this.configService.resourceApiURI + 'cms/refresh', null, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }

  fetchPoEditorLanguage(data: any) {

    return this.http.post(this.configService.poEditorsApiBaseURI + 'languages/list', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  fetchPoEditorTerms() {
    const body = new HttpParams()
      .set(`api_token`, environment.poEditor_api_token)
      .set('id', environment.poEditor_project_id.toString());
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.post(this.configService.poEditorsApiBaseURI + 'terms/list', body.toString(), this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
