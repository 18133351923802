<div class="containe-fluid">
    <div class="row row-y">
        <div ngbDropdown class="col-md-4 d-inline-block pt-2 pb-2 mia-title">
            <h2 class=" mt-2 mb-4">{{bookingRequestDetails.locationName}}</h2>
        </div>
        <div class=" col-md-8 text-right function-btns pt-2 pb-2">
            <button type="button" (click)="deleteBookingRequest()" class="btn btn-secondary ml-2">Delete Booking
                Request</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="bookingRequestDetails != undefined && bookingRequestDetails != null">
                <div class="card-header">
                    <h5>Booking Request Details</h5>
                </div>
                <div class="card-body table-responsive">
                    <table class="table table-striped"
                        *ngIf="bookingRequestDetails != null && bookingRequestDetails != undefined">
                        <tr>
                            <td class="bt-none">
                                RENTER
                            </td>
                            <td class="bt-none">
                                <a (click)="gotoUser(bookingRequestDetails.renterFirstName,bookingRequestDetails.renterLastName,bookingRequestDetails.renterId)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{bookingRequestDetails.renterFirstName}}
                                    {{bookingRequestDetails.renterLastName}}</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                LOCATION NAME
                            </td>
                            <td>
                                {{bookingRequestDetails.locationName}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                BOAT TYPE
                            </td>
                            <td>
                                {{bookingRequestDetails.boatType}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                GUESTS
                            </td>
                            <td>
                                {{bookingRequestDetails.guests}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                STARTS ON
                            </td>
                            <td>
                                {{bookingRequestDetails.startsOn}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                ENDS ON
                            </td>
                            <td>
                                {{bookingRequestDetails.endsOn}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                MAXIMUM PRICE
                            </td>
                            <td>
                                {{bookingRequestDetails.currency}} {{bookingRequestDetails.maximumPrice}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                MESSAGE
                            </td>
                            <td>
                                {{bookingRequestDetails.message}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                STATUS
                            </td>
                            <td>
                                <p [style.backgroundColor]="bookingRequestDetails.statusColour"
                                    style="color: #ffffff;width: fit-content;">
                                    {{bookingRequestDetails.status.toUpperCase()}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                CREATED AT
                            </td>
                            <td>
                                {{bookingRequestDetails.createdAt}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                UPDATED AT
                            </td>
                            <td>
                                {{bookingRequestDetails.updatedAt}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>