<div class="container mt-2">
    <h2 class="`text-default">New Insurance Price Range</h2>
    <form [formGroup]="formInsurancePriceRange" (ngSubmit)="submit()">
        <div class="card">
            <div class="card-header">
                Insurance Price Range details
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="minBoatPrice">Minimum Boat Price</label>
                    </div>
                    <div class="col-md-10">
                        <input type="minBoatPrice" class="form-control" id="minBoatPrice" formControlName="minBoatPrice"
                            placeholder="Minimum Boat Price" required>
                        <div *ngIf="formInsurancePriceRange.controls['minBoatPrice'].invalid && formInsurancePriceRange.controls['minBoatPrice'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="formInsurancePriceRange.controls['minBoatPrice'].hasError('required')">
                                Please enter Minimum Boat Price.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="maxBoatPrice">Maximum Boat Price</label>
                    </div>
                    <div class="col-md-10">
                        <input type="maxBoatPrice" class="form-control" id="maxBoatPrice" formControlName="maxBoatPrice"
                            placeholder="Maximum Boat Price" required>
                        <div *ngIf="formInsurancePriceRange.controls['maxBoatPrice'].invalid && formInsurancePriceRange.controls['maxBoatPrice'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="formInsurancePriceRange.controls['maxBoatPrice'].hasError('required')">
                                Please enter Maximum Boat Price.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="dailyFees">Daily Fees</label>
                    </div>
                    <div class="col-md-10">
                        <input type="dailyFees" class="form-control" id="dailyFees" formControlName="dailyFees"
                            placeholder="Daily Fees" required>
                        <div *ngIf="formInsurancePriceRange.controls['dailyFees'].invalid && formInsurancePriceRange.controls['dailyFees'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="formInsurancePriceRange.controls['dailyFees'].hasError('required')">
                                Please enter Daily Fees.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="weeklyFees">Weekly Fees</label>
                    </div>
                    <div class="col-md-10">
                        <input type="weeklyFees" class="form-control" id="weeklyFees" formControlName="weeklyFees"
                            placeholder="Weekly Fees" required>
                        <div *ngIf="formInsurancePriceRange.controls['weeklyFees'].invalid && formInsurancePriceRange.controls['weeklyFees'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="formInsurancePriceRange.controls['weeklyFees'].hasError('required')">
                                Please enter Weekly Fees.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="perDayAddOnFees">Per Day Add On Fees</label>
                    </div>
                    <div class="col-md-10">
                        <input type="perDayAddOnFees" class="form-control" id="perDayAddOnFees"
                            formControlName="perDayAddOnFees" placeholder="Per Day Add On Fees" required>
                        <div *ngIf="formInsurancePriceRange.controls['perDayAddOnFees'].invalid && formInsurancePriceRange.controls['perDayAddOnFees'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="formInsurancePriceRange.controls['perDayAddOnFees'].hasError('required')">
                                Please enter Per Day Add On Fees.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row m-3">
            <button type="submit" [disabled]="!formInsurancePriceRange.valid" class="btn btn-primary">Create Insurance
                Price Range </button>
            <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
        </div>
    </form>
</div>