<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>Payouts</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th>Payout ID</th>
                        <th>Booking</th>
                        <th>Deposit Charge</th>
                        <th>Amount</th>
                        <th>Payout Date</th>
                        <th>Arrival Date</th>
                        <th>Status</th>
                        <th>Failure Code</th>
                        <th>Failure Message</th>
                    </thead>
                    <tbody *ngIf="payouts != undefined && payouts != null">
                        <tr *ngFor="let payout of payouts">
                            <td>
                                <a *ngIf="payout.stripePayoutId != null && payout.stripePayoutId != ''"
                                    href="https://dashboard.stripe.com/{{payout.stripeAccountId}}/payouts/{{payout.stripePayoutId}}">
                                    {{payout.stripePayoutId}}</a>
                            </td>
                            <td><a routerLink="../booking-view"
                                    [queryParams]="{id: payout.bookingId}">{{payout.bookingId}}</a></td>
                            <td>{{payout.isDeposit}}</td>
                            <td>{{payout.amountCents}} {{payout.currency}}</td>
                            <td>{{payout.payoutDate}}</td>
                            <td>{{payout.arrivalDate}}</td>
                            <td>{{payout.status}}</td>
                            <td>{{payout.failureCode}}</td>
                            <td>{{payout.failureMessage}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>Bank Account</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="bankAccount != null && bankAccount != undefined">
                    <tr>
                        <td class="bt-none">NUMBER</td>
                        <td class="bt-none" *ngIf="bankAccount.iban != '' else elsenumber">{{bankAccount.iban}}</td>
                        <ng-template #elsenumber>
                            <td>N/A</td>
                        </ng-template>
                    </tr>
                    <tr>
                        <td>COUNTRY</td>
                        <td *ngIf="bankAccount.country != '' else elsecountry">{{bankAccount.country}}</td>
                        <ng-template #elsecountry>
                            <td>N/A</td>
                        </ng-template>
                    </tr>
                    <tr>
                        <td>CURRENCY</td>
                        <td *ngIf="bankAccount.currency != '' else elsecurrency">{{bankAccount.currency}}</td>
                        <ng-template #elsecurrency>
                            <td>N/A</td>
                        </ng-template>
                    </tr>
                    <tr>
                        <td>ROUTING NUMBER</td>
                        <td *ngIf="bankAccount.routingNumber != '' else elseRouting">{{bankAccount.routingNumber}}</td>
                        <ng-template #elseRouting>
                            <td>N/A</td>
                        </ng-template>
                    </tr>
                    <tr>
                        <td>BIC OR SWIFT</td>
                        <td *ngIf="bankAccount.bicOrSwift != '' else elsebic">{{bankAccount.bicOrSwift}}</td>
                        <ng-template #elsebic>
                            <td>N/A</td>
                        </ng-template>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>