import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoatService } from 'src/app/services/boat.service';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  insuranceTypes: string[] = ["boatflex", "Hull insurance", "Accident insurance", "Liability insurance", "Total damage insurance", "Charter insurance"];
  insuranceStatuses: string[] = ["missing", "pending", "verified", "declined"];
  currencies: string[] = ["EUR", "USD", "DKK", "GBP", "NOK", "SEK"];
  insuranceDetails: any;
  frmInsurance: FormGroup;
  id: number = 0;

  constructor(private activatedRoute: ActivatedRoute, private boatService: BoatService,
    private fb: FormBuilder, private router: Router, private toastr: ToastrService) {
    this.frmInsurance = this.fb.group({
      insuranceType: ['', [Validators.required]],
      insuranceStatus: ['', [Validators.required]],
      value: [''],
      minimumDeposit: [''],
      currency: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.boatService.fetchBoatInsuranceDetails(this.id).subscribe(
      res => {
        this.insuranceDetails = res;
        this.frmInsurance.setValue({
          insuranceType: this.insuranceDetails.insuranceType,
          insuranceStatus: this.insuranceDetails.insuranceStatus,
          value: this.insuranceDetails.value,
          minimumDeposit: this.insuranceDetails.minimumDeposit,
          currency: this.insuranceDetails.currency
        });
        if (this.insuranceDetails.insuranceType == "boatflex") {
          this.frmInsurance.controls["value"].setValidators([Validators.required]);
          this.frmInsurance.controls["minimumDeposit"].clearValidators();
        }
        else if (this.insuranceDetails.insuranceType != "boatflex") {
          this.frmInsurance.controls["minimumDeposit"].setValidators([Validators.required]);
          this.frmInsurance.controls["value"].clearValidators();
        }
      },
      error => {
        this.toastr.error("Couldn't load details.");
        console.log(error);
      }
    );
  }

  setValidation() {
    if (this.frmInsurance.controls["insuranceType"].value == "boatflex") {
      this.frmInsurance.controls["value"].setValidators([Validators.required]);
      this.frmInsurance.controls["minimumDeposit"].clearValidators();
      this.frmInsurance.controls["minimumDeposit"].reset();
    }
    else if (this.frmInsurance.controls["insuranceType"].value != "boatflex") {
      this.frmInsurance.controls["minimumDeposit"].setValidators([Validators.required]);
      this.frmInsurance.controls["value"].clearValidators();
      this.frmInsurance.controls["value"].reset();
    }
  }

  submit() {
    if (this.frmInsurance.valid) {
      var insuranceUpdateRequest = {
        boatId: this.id,
        insuranceStatus: this.frmInsurance.controls["insuranceStatus"].value,
        insuranceType: this.frmInsurance.controls["insuranceType"].value,
        currency: this.frmInsurance.controls["currency"].value,
        value: this.frmInsurance.controls["insuranceType"].value == "boatflex" ? this.frmInsurance.controls["value"].value : 0,
        minimumDeposit: this.frmInsurance.controls["insuranceType"].value == "third_party" ? this.frmInsurance.controls["minimumDeposit"].value : 0
      };
      this.boatService.updateBoatInsurance(insuranceUpdateRequest).subscribe(
        res => {
          this.toastr.success(res.toString());
          this.router.navigate(['admin/boats']);
        },
        error => {
          this.toastr.error("Error occured while saving data.");
          console.log(error);
        }
      );
    }
  }
}
