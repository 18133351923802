<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h5>Personal info</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="personalInfo != null && personalInfo != undefined">
                    <tr>
                        <td class="bt-none">FIRST NAME</td>
                        <td class="bt-none">{{personalInfo.firstName}}</td>
                    </tr>
                    <tr>
                        <td>LAST NAME</td>
                        <td>{{personalInfo.lastName}}</td>
                    </tr>
                    <tr>
                        <td>GENDER</td>
                        <td>{{personalInfo.gender}}</td>
                    </tr>
                    <tr>
                        <td>BORN ON</td>
                        <td>{{personalInfo.bornOn}}</td>
                    </tr>
                    <tr>
                        <td>LOCATION</td>
                        <td>{{personalInfo.location}}</td>
                    </tr>
                    <tr>
                        <td>BIO</td>
                        <td>{{personalInfo.bio}}</td>
                    </tr>
                    <tr>
                        <td>BOATING EXPERIENCE</td>
                        <td>{{personalInfo.boatingExperience}}</td>
                    </tr>
                    <tr>
                        <td>PHONE NUMBER</td>
                        <td>{{personalInfo.phoneNo}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h5>Personal Address</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="personalInfo != null && personalInfo != undefined">
                    <tr>
                        <td class="bt-none">ADDRESS FIRST LINE</td>
                        <td class="bt-none">{{personalInfo.addressFirstLine}}</td>
                    </tr>
                    <tr>
                        <td>ADDRESS SECOND LINE</td>
                        <td>{{personalInfo.addressSecondLine}}</td>
                    </tr>
                    <tr>
                        <td>ADDRESS ZIP</td>
                        <td>{{personalInfo.addressZip}}</td>
                    </tr>
                    <tr>
                        <td>ADDRESS CITY</td>
                        <td>{{personalInfo.addressCity}}</td>
                    </tr>
                    <tr>
                        <td>ADDRESS STATE</td>
                        <td> {{personalInfo.addressState}}</td>
                    </tr>
                    <tr>
                        <td>ADDRESS COUNTRY</td>
                        <td>{{personalInfo.addressCountry}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>