import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MetagroupService } from 'src/app/services/metagroup.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-metagroups',
  templateUrl: './metagroups.component.html',
  styleUrls: ['./metagroups.component.css']
})
export class MetagroupsComponent implements OnInit {
  metagroupDetails: any;
  page: number = 1;
  pageSize: number = 10;
  delete: any;
  orderColumn: string = "Id";
  orderDirection: string = "DESC";
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  frmFilter: FormGroup;
  includeAll: boolean = true;
  navigationSubscription: any;
  public exportFileName :string = "meta_groups";

  constructor(private router: Router, private modalService: NgbModal, private fb: FormBuilder,
    private metagroupService: MetagroupService, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      url: [''],
      title: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.getMetagroups();
  }

  getMetagroups() {
    var metagroup = {
      Page: this.page,
      PageSize: this.pageSize,
      OrderColumn: this.orderColumn,
      OrderDirection: this.orderDirection,
      url: this.frmFilter.controls["url"].value,
      title: this.frmFilter.controls["title"].value,
      includeAll: this.includeAll
    };
    this.metagroupService.fetchMetagroups(metagroup)
      .subscribe(
        res => {
          this.metagroupDetails = res;
          this.changeCheckBoxStatus();
        },
        err => {
          this.tostr.error("Error occurred while feching data.");
          console.log(err);
        });
  }

  getPage(page: number) {
    this.page = page;
    this.getMetagroups();
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getMetagroups();
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.metagroupDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.metagroupDetails.data[a].id);
      }
      this.metagroupDetails.data.forEach((metaData) => {
        metaData.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.metagroupDetails.data.forEach((metaData) => {
        metaData.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.metagroupDetails.data.length && this.metagroupDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  deleteMetagroups() {
    var isDelete = confirm("are you sure want to delete selected Meta Groups?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.metagroupService.deleteMetagroup(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getMetagroups();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete this Meta Group?");
    if (isDelete) {
      this.metagroupService.deleteMetagroup([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getMetagroups();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    let url = this.frmFilter.controls["url"].value;
    let title = this.frmFilter.controls["title"].value;
    if (url == "" && title == "") {
      this.includeAll = true;
    }
    else {
      this.includeAll = false;
    }
    this.getMetagroups();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      url: "",
      title: "",
    });
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getMetagroups();
  }

}
