import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AmenitiesService } from 'src/app/services/amenities/amenities.service';
import { AmenitycategoriesService } from 'src/app/services/amenitycategories/amenitycategories.service';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BmAmenityService } from 'src/app/services/bm-amenity.service';

@Component({
  selector: 'app-amenity-edit',
  templateUrl: './amenity-edit.component.html',
  styleUrls: ['./amenity-edit.component.css']
})
export class AmenityEditComponent implements OnInit {
  aminityViewDetails: any;
  amenitiesCategories: any;
  id: number = 0;
  frmAmenity: FormGroup;
  fileUrl: string = "";
  activeIconUrl: string = "";
  iconFileType: string = "";
  activeIconFileType: string = "";
  greenIconUrl: string = "";
  greenIconFileType: string = "";


  constructor(private activatedRoute: ActivatedRoute,
    // private amenityService: AmenitiesService, private amenityCategoriesService: AmenitycategoriesService
    private amenityService: BmAmenityService,
    private fb: FormBuilder,
    private router: Router, private tostr: ToastrService) {
    this.frmAmenity = this.fb.group({
      category: [0, [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.getAmenity();
    this.amenitycategories();
  }

  getAmenity() {
    this.amenityService.fetchAmenity(this.id)
      .subscribe(
        response => {
          this.aminityViewDetails = response;
          if (this.aminityViewDetails != null) {
            this.frmAmenity.setValue({
              category: this.aminityViewDetails.categoryId,
              name: this.aminityViewDetails.name
            });
            this.fileUrl = this.aminityViewDetails.inActiveIconUrl;
            this.activeIconUrl = this.aminityViewDetails.activeIconUrl;
            this.iconFileType = this.aminityViewDetails.inActiveIconFileType;
            this.activeIconFileType = this.aminityViewDetails.activeIconFileType;
            this.greenIconUrl = this.aminityViewDetails.greenIconUrl,
              this.greenIconFileType = this.aminityViewDetails.greenIconFileType
          }
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  amenitycategories() {
    this.amenityService.fetchAmenitiesCategories()
      .subscribe(
        response => {
          this.amenitiesCategories = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  reset() {
    this.router.navigate(["admin/amenities"]);
  }

  submit() {
    if (this.frmAmenity.valid) {
      console.log(this.iconFileType);
      console.log(this.activeIconFileType);

      if (this.frmAmenity.controls["category"].value > 0) {
        var amenityRequest = {
          id: this.id,
          categoryId: this.frmAmenity.controls["category"].value,
          name: this.frmAmenity.controls["name"].value,
          iconUrl: this.fileUrl,
          activeIconUrl: this.activeIconUrl,
          iconFileType: this.iconFileType,
          activeIconFileType: this.activeIconFileType,
          greenIconUrl: this.greenIconUrl,
          greenIconFileType: this.greenIconFileType
        }
        this.amenityService.EditAmenity(amenityRequest).subscribe(
          res => {
            this.tostr.success("Updated successfully.");
            this.router.navigate(['admin/amenities']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
      }
      else {
        this.tostr.warning("Please select category.");
      }
    }
  }

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string; }[]; }) => (this.fileUrl = file.filesUploaded[0].url, this.iconFileType = file.filesUploaded[0].mimetype)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

  onClickUploadActiveIcon() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string }[]; }) => (this.activeIconUrl = file.filesUploaded[0].url, this.activeIconFileType = file.filesUploaded[0].mimetype)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

  onClickUploadGreenIcon() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string }[]; }) => (this.greenIconUrl = file.filesUploaded[0].url, this.greenIconFileType = file.filesUploaded[0].mimetype)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }
}
