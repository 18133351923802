import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TripTypeService } from 'src/app/services/trip-type/trip-type.service';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-trip-type-edit',
  templateUrl: './trip-type-edit.component.html',
  styleUrls: ['./trip-type-edit.component.css']
})

export class TripTypeEditComponent implements OnInit {
  tripTypeViewDetails: any;
  id: number = 0;
  frmEditTripType: FormGroup;
  fileUrl: string = "";

  constructor(private activatedRoute: ActivatedRoute, private tripTypeService: TripTypeService, private fb: FormBuilder,
    private router: Router, private tostr: ToastrService) {
    this.frmEditTripType = this.fb.group({
      title: ['', [Validators.required]],
      subTitle: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.getTripType();
  }

  getTripType() {
    this.tripTypeService.fetchTripType(this.id)
      .subscribe(
        response => {
          this.tripTypeViewDetails = response;
          if (this.tripTypeViewDetails != null) {
            this.frmEditTripType.setValue({
              title: this.tripTypeViewDetails.title,
              subTitle: this.tripTypeViewDetails.subTitle
            });
            this.fileUrl = this.tripTypeViewDetails.iconUrl;
          }
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }


  reset() {
    this.router.navigate(["admin/trip-types"]);
  }

  submit() {
    if (this.frmEditTripType.valid) {
        var tripTypeRequest = {
          id: this.id,
          title: this.frmEditTripType.controls["title"].value,
          subTitle: this.frmEditTripType.controls["subTitle"].value,
          iconUrl: this.fileUrl
        }
        this.tripTypeService.EditTripType(tripTypeRequest).subscribe(
          res => {
            this.tostr.success("Updated successfully.");
            this.router.navigate(['admin/trip-types']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
      }
    }
  

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml','image/*'],
      onUploadDone: (file: { filesUploaded: { url: string; }[]; }) => this.fileUrl = file.filesUploaded[0].url
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

}
