<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>{{name}}'s Conversations</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th>ID</th>
                        <th>HOST</th>
                        <th>GUEST</th>
                        <th>BOOKING</th>
                        <th>CREATED AT</th>
                        <th>SUBJECT</th>
                        <th>UPDATED AT</th>
                    </thead>
                    <tbody
                        *ngIf="conversations != null && conversations != undefined && conversations.length > 0 else noRecord ">
                        <tr *ngFor="let conversation of conversations">
                            <td>{{conversation.conversationId}}</td>
                            <td><a (click)="gotoUser(conversation.hostDetail.name,conversation.hostDetail.id)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{conversation.hostDetail.name}}</a>
                            </td>
                            <td><a (click)="gotoUser(conversation.guestDetail.name,conversation.guestDetail.id)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{conversation.guestDetail.name}}</a>
                            </td>
                            <td>{{conversation.bookingId}}</td>
                            <td>{{conversation.createdAt}}</td>
                            <td><a routerLink="../conversation-view" [queryParams]="{id: conversation.conversationId}"
                                    class="text-primary" style="text-decoration: underline;">View Conversation</a></td>
                            <td>{{conversation.updatedAt}}</td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>