import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'src/app/login.service';
import { throwError } from 'rxjs';
import { ToastRef, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseService {
  httpOptions: any; httpOptionsEdit: any;
  public fName: string = "";
  public fullName: string = "";
  public userFilter: any = null;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
    this.httpOptionsEdit = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      }), responseType: 'text'
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }

  fetchUsers(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'user/users', data, this.httpOptions).pipe(catchError(this.handleError));
  }

  deleteUsers(userId: number[]) {
    return this.http.put<any>(this.configService.resourceApiURI + 'user/delete', userId, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchUser(userId: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/editdetails?id=' + userId, this.httpOptions).pipe(catchError(this.handleError));
  }

  editUser(userEditRequest: any) {
    return this.http.put<any>(this.configService.resourceApiURI + 'user/editdetails', userEditRequest, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }

  fetchUserAccount(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/account?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchPersonalInfo(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/personal?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchUserSections(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/user-section?userId=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchBusinessInfo(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/business?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  verifyEmail(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/emailverify?id=' + id, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  verifyPhone(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/phoneverify?id=' + id, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  fetchUsersDataForExport() {
    return this.http.get(this.configService.resourceApiURI + 'user/users-data-export', this.httpOptions).pipe(catchError(this.handleError));
  }

  fetchUsersVerifications(userId: number) {
    return this.http.get(this.configService.resourceApiURI + 'user/user-verifications?userId=' + userId, this.httpOptions).pipe(catchError(this.handleError));
  }

  UpdateUserVerifications(updateVerifications: any) {
    return this.http.put<any>(this.configService.resourceApiURI + 'user/user-verifications', updateVerifications, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }

  updatePassword(userEditRequest: any) {
    return this.http.put<any>(this.configService.authApiURI + '/api/update-password', userEditRequest, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }

}