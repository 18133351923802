<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="tripTypeViewDetails != null">{{tripTypeViewDetails.title}}</h2>
        </div>
        <div class="col-lg-6 col-md-12 text-right" *ngIf="tripTypeViewDetails != null">
            <a routerLink="../trip-type-edit" [queryParams]="{id: tripTypeViewDetails.id}"
                class="btn btn-secondary">Edit Trip Type</a>
            <button type="button" (click)="delete(tripTypeViewDetails.id)" class="btn btn-secondary m-2">Delete Trip
                Type</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="tripTypeViewDetails != undefined && tripTypeViewDetails != null">
                <div class="card-header">
                    <h5>Trip Type Details</h5>
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Id</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{tripTypeViewDetails.id}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Title</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{tripTypeViewDetails.title}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Sub Title</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{tripTypeViewDetails.subTitle}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Icon</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <img src="{{tripTypeViewDetails.iconUrl}}" height="40px" width="40px" alt="icon"
                                style="border-radius: 20px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>