<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Reviews</h2>
    <div class="row">
        <div class="col-md-11">
            <div ngbDropdown class="d-inline-block pt-2 pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteReviews()">Delete selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked" (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th>
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('Role')" style="cursor: pointer;">Role</a>
                            </th>
                            <th>Booking</th>
                            <th>
                                <a (click)="toggleSort('Subject')" style="cursor: pointer;">Subject</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('Stars')" style="cursor: pointer;">Stars</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('CreatedAt')" style="cursor: pointer;">Created At</a>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="reviewDetails != undefined  && reviewDetails.totalEntries > 0">
                        <tr
                            *ngFor="let review of reviewDetails.reviewDto | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:reviewDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" value="{{review.id}}" [(ngModel)]="review.selected"
                                        (change)="checkboxChange($event,review.id)">
                                </div>
                            </td>
                            <td><a routerLink="../review-view" [queryParams]="{id: review.id}">{{review.id}}</a></td>
                            <td>{{review.role}}</td>
                            <td><a routerLink="../booking-view" [queryParams]="{id: review.bookingId}"
                                    style="text-decoration: underline;">Booking #{{review.bookingId}}</a></td>
                            <td>{{review.subject}} </td>
                            <td>{{review.stars}} </td>
                            <td>{{review.createdAt}} </td>
                            <td>
                                <a routerLink="../review-view" [queryParams]="{id: review.id}">
                                    <button type="submit" title="Click to View" class="btn">
                                        <i class="bi bi-eye"></i>
                                    </button>
                                </a>
                                <a routerLink="../review-edit" [queryParams]="{id: review.id}">
                                    <button type="submit" title="Click to Edit" class="btn">
                                        <i class="bi bi-pencil-square"></i>
                                    </button>
                                </a>
                                <button type="submit" title="Click to Delete" (click)="onDelete(review.id)" class="btn">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="reviewDetails != undefined && reviewDetails.totalEntries != undefined && reviewDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="categories">Role</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="role" id="role" class="form-control" ng-options="">
                        <option value="">--Select--</option>
                        <option value="boat_review">Boat review</option>
                        <option value="renter_review">Renter review</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="name">Stars</label>
                </div>
                <div class="col-md-4">
                    <select formControlName="type" class="form-control" ng-options="">
                        <option value="">--Select--</option>
                        <option value="Equals">Equals</option>
                        <option value="greater">Greater than</option>
                        <option value="less">Less than</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <input type="number" formControlName="stars" id="stars" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="onFilter()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="reset()">Clear Filters</button>
    </div>
</ng-template>