export class CreateUserModel {
    constructor(public FirstName: string,
        public LastName: string,
        public Email: string,
        public CountryCode: string,
        public PhoneNumber: string,
        public Password: string,
        public AgreeForPrivacyPolicy:boolean,
        public AgreeForMarketingPermissions:boolean){}
     
}
