import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ConversationsService } from 'src/app/services/conversations.service';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-user-conversations',
  templateUrl: './user-conversations.component.html',
  styleUrls: ['./user-conversations.component.css']
})
export class UserConversationsComponent implements OnInit {
  conversations: any;
  id: number = 0;
  @Input() name;
  @Output() newItemEvent = new EventEmitter<number>();

  constructor(private conversationService: ConversationsService, private route: ActivatedRoute,
    private tostr: ToastrService, private router: Router, private userService: UsersService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.conversationService.fetchConversations(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.conversations = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }

  gotoUser(fName: string, id: number) {
    this.userService.fName = fName.split(' ')[0];
    this.userService.fullName = fName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } });
    this.newItemEvent.emit(1);
  }
}
