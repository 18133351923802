import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
  frmVerification: FormGroup;
  id: number = 0;
  verifications: any;
  constructor(private activatedRoute: ActivatedRoute, private route: Router,
    private fb: FormBuilder, private tostr: ToastrService, private userService: UsersService) {
    this.activatedRoute.queryParams.subscribe(data => {
      this.id = data.id;
    });
    this.frmVerification = this.fb.group({
      status: [''],
      certificateType: [''],
      photoIdStatus: [''],
      photoIdBackStatus: [''],
      additionalDocStatus: [''],
      addressCertificateStatus: ['']
    });
  }

  ngOnInit(): void {
    this.userService.fetchUsersVerifications(this.id).subscribe(
      res => {
        this.verifications = res;
        this.frmVerification.setValue({
          status: this.verifications.sailCertificateStatus ?? "",
          certificateType: this.verifications.sailCertificateType ?? "",
          photoIdStatus: this.verifications.photoIdStatus ?? "",
          photoIdBackStatus: this.verifications.photoIdBackStatus ?? "",
          additionalDocStatus: this.verifications.additionalDocStatus ?? "",
          addressCertificateStatus: this.verifications.addressCertificateStatus ?? ""
        });
      },
      err => {
        console.log(err);
        this.tostr.error("Something went wrong, please try again.");
      }
    )
  }

  submit() {
    var userVerificationAddRequest = {
      id: this.id,
      sailCertificateStatus: this.frmVerification.controls["status"].value == "" ? null : this.frmVerification.controls["status"].value,
      certificateType: this.frmVerification.controls["certificateType"].value,
      photoIdStatus: this.frmVerification.controls["photoIdStatus"].value == "" ? null : this.frmVerification.controls["photoIdStatus"].value,
      photoIdBackStatus: this.frmVerification.controls["photoIdBackStatus"].value == "" ? null : this.frmVerification.controls["photoIdBackStatus"].value,
      additionalDocStatus: this.frmVerification.controls["additionalDocStatus"].value == "" ? null : this.frmVerification.controls["additionalDocStatus"].value,
      addressCertificateStatus: this.frmVerification.controls["addressCertificateStatus"].value == "" ? null : this.frmVerification.controls["addressCertificateStatus"].value
    };

    this.userService.UpdateUserVerifications(userVerificationAddRequest).subscribe(
      res => {
        if (res.toString() == "Success") {
          this.tostr.success(res.toString());
          this.route.navigate(['admin/user-view'], { queryParams: { id: this.id } });
        }
        else {
          this.tostr.error(res.toString());
        }
      },
      err => {
        this.tostr.error("Error occurred while executing your query");
      }
    );
  }
}
