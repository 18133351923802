<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="popularDestinationViewDetails != null">{{popularDestinationViewDetails.title}}</h2>
        </div>
        <div class="col-lg-6 col-md-12 text-right" *ngIf="popularDestinationViewDetails != null">
            <a routerLink="../popular-destination-edit" [queryParams]="{id: popularDestinationViewDetails.id}"
                class="btn btn-secondary">Edit Popular Destination</a>
            <button type="button" (click)="delete(popularDestinationViewDetails.id)" class="btn btn-secondary m-2">Delete Popular Destination </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="popularDestinationViewDetails != undefined && popularDestinationViewDetails != null">
                <div class="card-header">
                    <h5>Popular Destination Details</h5>
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Id</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{popularDestinationViewDetails.id}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Name</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{popularDestinationViewDetails.name}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Icon</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <img src="{{popularDestinationViewDetails.iconUrl}}" height="40px" width="40px" alt="icon"
                                style="border-radius: 20px;">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Latitude</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{popularDestinationViewDetails.latitude}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Longitude</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{popularDestinationViewDetails.longitude}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>