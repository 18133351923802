<div class="d-flex" id="wrapper">
  <!-- Sidebar -->
  <div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <h1 class="text-primary">Boatflex</h1>
    </div>
    <div class="list-group list-group-flush" id="accordion">

      <a class="list-group-item list-group-item-action bg-light toggled" data-toggle="collapse"
        data-target="#collapseDashboard" aria-expanded="false" aria-controls="collapseDashboard"
        (click)="goToDashboard()">Dashboard</a>
      <div id="collapseDashboard" class="collapse" aria-labelledby="collapseDashboard" data-parent="#accordion">
      </div>

      <a class="list-group-item list-group-item-action bg-light" data-toggle="collapse" data-target="#collapseBoats"
        aria-expanded="false" aria-controls="collapseBoats">Boats <i class="float-right bi bi-chevron-down"></i></a>
      <div id="collapseBoats" class="collapse" aria-labelledby="collapseBoats" data-parent="#accordion">
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="amenities">Amenities</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="amenitycategories">Amenity Category</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="boats">Boats</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="bookings">Bookings</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="payouts">Payouts</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="booking-requests">Booking Requests</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="booking-broadcasts">Booking Broadcasts</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="reviews">Reviews</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="trip-types">Trip Types</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="popular-destinations">Popular Destinations</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light" routerLink="error">Yacht
          Clubs</a>
      </div>
      <!-- <a class="list-group-item list-group-item-action bg-light" data-toggle="collapse" data-target="#collapseCms"
        aria-expanded="false" aria-controls="collapseCms">Cms</a>
      <div class="list-group list-group-flush" id="accordion2">
        <div id="collapseCms" class="collapse" aria-labelledby="collapseCms" data-parent="#accordion">
          <a class="list-group-item list-group-item-action bg-light" data-toggle="collapse"
            data-target="#collapseSupportCenter" aria-expanded="false" aria-controls="collapseSupportCenter">Support
            Center</a>
          <div id="collapseSupportCenter" class="collapse" aria-labelledby="collapseSupportCenter"
            data-parent="#accordion2">
            <a class="list-group-item list-group-item-action" routerLink="categories"> Categories</a>
            <a class="list-group-item list-group-item-action" routerLink="ques">Top Questions</a>
          </div>
          <a class="list-group-item list-group-item-action bg-light" data-toggle="collapse" data-target="#collapseTips"
            aria-expanded="false" aria-controls="collapseTips">Tips & Tricks</a>
          <div id="collapseTips" class="collapse" aria-labelledby="collapseTips" data-parent="#accordion2">
            <a class="list-group-item list-group-item-action" routerLink="tips">Tips</a>
            <a class="list-group-item list-group-item-action" routerLink="trics">Tricks</a>
          </div>
        </div>
      </div> -->
      <a routerLink="events" class="list-group-item list-group-item-action bg-light" data-toggle="collapse"
        data-target="#collapseEvents" aria-expanded="false" aria-controls="collapseEvents">Events</a>
      <!-- <div id="collapseEvents" class="collapse" aria-labelledby="collapseEvents" data-parent="#accordion"
        routerLinkActive="collapse show" [routerLinkActiveOptions]="{exact: true}">
      </div> -->

      <a routerLink="admin/experience" class="list-group-item list-group-item-action bg-light" data-toggle="collapse"
        data-target="#collapseExperiences" aria-expanded="false" aria-controls="collapseExperiences">Experiences</a>
      <div id="collapseExperiences" class="collapse" aria-labelledby="collapseExperiences" data-parent="#accordion">
      </div>

      <a routerLink="admin/package" class="list-group-item list-group-item-action bg-light" data-toggle="collapse"
        data-target="#collapsePackages" aria-expanded="false" aria-controls="collapsePackages">Packages</a>
      <div id="collapsePackages" class="collapse" aria-labelledby="collapsePackages" data-parent="#accordion">
      </div>

      <a class="list-group-item list-group-item-action bg-light" data-toggle="collapse" data-target="#collapseReports"
        aria-expanded="false" aria-controls="collapseReports">Reports <i class="float-right bi bi-chevron-down"></i></a>
      <div id="collapseReports" class="collapse" aria-labelledby="collapseReports" data-parent="#accordion">
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="boat-statistics">Boat Statistics</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="review-by-week">Reviews by Week</a>
        <a class="list-group-item list-group-item-action" routerLinkActive="background-light"
          routerLink="searches-by-destination">Searches by
          Destination</a>
      </div>

      <a class="list-group-item list-group-item-action bg-light" data-toggle="collapse" data-target="#collapseSocial"
        aria-expanded="false" aria-controls="collapseSocial">Social <i class="float-right bi bi-chevron-down"></i></a>
      <div id="collapseSocial" class="collapse" aria-labelledby="collapseSocial" data-parent="#accordion">
        <a routerLink="conversations" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Conversations</a>
        <a routerLink="referrals" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Referrals</a>
        <a routerLink="users" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Users</a>
      </div>

      <a class="list-group-item list-group-item-action bg-light" data-toggle="collapse" data-target="#collapseSystem"
        aria-expanded="false" aria-controls="collapseSystem">System <i class="float-right bi bi-chevron-down"></i></a>
      <div id="collapseSystem" class="collapse" aria-labelledby="collapseSystem" data-parent="#accordion">
        <a routerLink="admin-users" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Admin Users</a>
        <a routerLink="alerts" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Alerts</a>
        <a routerLink="metagroups" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Meta Groups</a>
        <a routerLink="rules" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Rules</a>
        <a routerLink="insurance-price-ranges" routerLinkActive="background-light"
          class="list-group-item list-group-item-action">Insurance-Price-Ranges</a>
        <a routerLink="library" routerLinkActive="background-light" class="list-group-item list-group-item-action">Boat
          library</a>
      </div>
    </div>
  </div>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <button class="btn" id="menu-toggle" (click)="toggleButtonClass()">
        <i class="{{buttonClass}}"></i>
      </button>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
          <li class="nav-item active">
            <a class="nav-link" href="admin">Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="user">{{user}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link pointer" (click)="logout()">Logout</a>
          </li>
          <li class="nav-item">
            <a class="nav-link pointer" (click)="refreshCache()">Refresh cache</a>
          </li>
          <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" >Action</a>
                <a class="dropdown-item" >Another action</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" >Something else here</a>
              </div>
            </li> -->
        </ul>
      </div>
    </nav>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- main-loader -->
  <div class="main-loader" *ngIf="loader.isLoading | async">
    <div class="loader">
      <mat-spinner mode="indeterminate">
        <p class="p-3">Loading</p>
      </mat-spinner>
    </div>
  </div>
  <!-- /#main-loader -->
  <!-- /#page-content-wrapper -->
</div>
<!-- /#wrapper -->