import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService {

  httpOptions: any;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
  }

  fetchPoEditorLanguages() {
    return this.http.get(this.configService.resourceApiURI + 'cms/languages', this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  fetchCategories(data: any){
    return this.http.post(this.configService.resourceApiURI + 'cms/categories', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  SaveCategory(data :any){
    return this.http.post(this.configService.resourceApiURI + 'cms/category', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  DeleteCategory(id: number) {
    return this.http.delete(this.configService.resourceApiURI + 'cms/category/'+id, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  DeleteCategories(ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'cms/categories', options).pipe(
      catchError(this.handleError)
    );
  }

}
