import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookingService } from 'src/app/services/booking.service';
import { CommonService } from 'src/app/services/common.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {
  bookingListDetails: any;
  page: number = 1;
  pageSize: number = 10;
  orderColumn: string = "Id"
  orderDirection: string = "DESC"
  type: string = "All";
  countries: any;
  frmFilter: FormGroup;
  includeAll: boolean = true;
  navigationSubscription: any;
  public exportFileName: string = "bookings";
  currentYear: number = 0;


  constructor(private userService: UsersService, private router: Router,
    private modalService: NgbModal, private fb: FormBuilder,
    public bookingService: BookingService, private commonService: CommonService,
    private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      bookingId: [],
      insuranceStatus: [''],
      manualPayout: [''],
      country: [''],
      status: [''],
      createdFrom: [''],
      createdTo: [''],
      startOnFrom: [''],
      startOnTo: [''],
      endsOnFrom: [''],
      endsOnTo: ['']
    });
    if (this.bookingService.bookingFilter != null) {
      this.frmFilter.setValue({
        bookingId: this.bookingService.bookingFilter.id,
        insuranceStatus: this.bookingService.bookingFilter.insuranceStatus,
        manualPayout: this.bookingService.bookingFilter.manualPayout,
        country: this.bookingService.bookingFilter.countryCode,
        status: this.bookingService.bookingFilter.status,
        createdFrom: this.bookingService.bookingFilter.createdFrom,
        createdTo: this.bookingService.bookingFilter.createdTo,
        startOnFrom: this.bookingService.bookingFilter.startOnFrom,
        startOnTo: this.bookingService.bookingFilter.startOnTo,
        endsOnFrom: this.bookingService.bookingFilter.endsOnFrom,
        endsOnTo: this.bookingService.bookingFilter.endsOnTo
      });
      this.page = this.bookingService.bookingFilter.page;
      this.pageSize = this.bookingService.bookingFilter.pageSize;
      this.orderColumn = this.bookingService.bookingFilter.orderColumn;
      this.orderDirection = this.bookingService.bookingFilter.orderDirection;
      this.type = this.bookingService.bookingFilter.type;
      this.includeAll = this.bookingService.bookingFilter.includeAll;
    };

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {

      }
    });
    var date = new Date();
    this.currentYear = date.getFullYear();
  }

  ngOnInit(): void {
    this.GetCountryList();
    this.getBookings();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  GetCountryList() {
    this.commonService.fetchCountryList().subscribe(
      response => {
        this.countries = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getBookings();
  }

  getPage(page: number) {
    this.page = page;
    this.getBookings();
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }
  gotoBooking(id: number) {

    var bookingRequest = {
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      type: this.type,
      includeAll: this.includeAll,
      id: this.frmFilter.controls["bookingId"].value,
      insuranceStatus: this.frmFilter.controls["insuranceStatus"].value,
      manualPayout: this.frmFilter.controls["manualPayout"].value != "" ? this.frmFilter.controls["manualPayout"].value == "true" ? true : false : null,
      createdFrom: this.frmFilter.controls["createdFrom"].value,
      createdTo: this.frmFilter.controls["createdTo"].value,
      startOnFrom: this.frmFilter.controls["startOnFrom"].value,
      startOnTo: this.frmFilter.controls["startOnTo"].value,
      endsOnFrom: this.frmFilter.controls["endsOnFrom"].value,
      endsOnTo: this.frmFilter.controls["endsOnTo"].value,
      status: this.frmFilter.controls["status"].value,
      countryCode: this.frmFilter.controls["country"].value
    };

    this.bookingService.bookingFilter = bookingRequest;
    this.router.navigate(["admin/booking-view"], { queryParams: { id: id } })
  }
  getBookings() {
    var bookingRequest = {
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      type: this.type,
      includeAll: this.includeAll,
      id: this.frmFilter.controls["bookingId"].value,
      insuranceStatus: this.frmFilter.controls["insuranceStatus"].value,
      manualPayout: this.frmFilter.controls["manualPayout"].value != "" ? this.frmFilter.controls["manualPayout"].value == "true" ? true : false : null,
      createdFrom: this.frmFilter.controls["createdFrom"].value,
      createdTo: this.frmFilter.controls["createdTo"].value,
      startOnFrom: this.frmFilter.controls["startOnFrom"].value,
      startOnTo: this.frmFilter.controls["startOnTo"].value,
      endsOnFrom: this.frmFilter.controls["endsOnFrom"].value,
      endsOnTo: this.frmFilter.controls["endsOnTo"].value,
      status: this.frmFilter.controls["status"].value,
      countryCode: this.frmFilter.controls["country"].value
    };

    this.bookingService.bookingFilter = bookingRequest;
    this.bookingService.fetchBookings(bookingRequest).subscribe(
      res => {
        this.bookingListDetails = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }

  all() {
    var hasValue = Object.keys(this.frmFilter.value).some(k => !!this.frmFilter.value[k]);
    if (!hasValue) {
      this.includeAll = true;
    }
    this.type = "All";
    this.getBookings();
  }

  confirmed() {
    this.type = "confirmed";
    this.getBookings();
  }

  pending() {
    this.type = "pending";
    this.getBookings();
  }

  rejected() {
    this.type = "rejected";
    this.getBookings();
  }

  cancelled() {
    this.type = "cancelled";
    this.getBookings();
  }

  expired() {
    this.type = "expired";
    this.getBookings();
  }

  paidOut() {
    this.type = "paidOut";
    this.getBookings();
  }
  incomplete() {
    this.type = "incomplete"
    this.getBookings();
  }
  getTypeColumnName() {
    var header = "Confirmed At"
    switch (this.type) {
      case "paidOut":
        header = "Paid Out At"
        break;

      case "expired":
        header = "Expired At"
        break;

      case "cancelled":
        header = "Cancelled At"
        break;

      case "rejected":
        header = "Rejected At"

    }
    return header;
  }
  getDateAsPerType(booking) {
    var date = "";
    if (booking.status.toLowerCase() == "confirmed") {
      date = booking.confirmedAt;
    }
    else if (booking.status.toLowerCase() == "paid out") {
      date = booking.paidOutAt;
    }
    else if (this.type != "All" && (booking.status.toLowerCase() == "expired" || booking.status.toLowerCase() == "cancelled" || booking.status.toLowerCase() == "rejected")) {
      date = booking.updatedAt;
    }
    return date;
  }
  bookingCreatedReport() {
    let bookingCreatedReportData: any;
    this.bookingService.fetchBookingCreatedReportData().subscribe(
      res => {
        bookingCreatedReportData = res;
        this.commonService.exportAsExcelFile(bookingCreatedReportData, "Booking created in " + this.currentYear + " report");
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }

  bookingStartingReport() {
    let bookingStartedReportData: any;
    this.bookingService.fetchBookingStartedReportData().subscribe(
      res => {
        bookingStartedReportData = res;
        this.commonService.exportAsExcelFile(bookingStartedReportData, "Booking started in " + this.currentYear + " report");
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }

  getMvpReport() {
    let mvpReportData: any;
    this.bookingService.fetchMvpReportData().subscribe(
      res => {
        mvpReportData = res;
        this.commonService.exportAsExcelFile(mvpReportData, this.currentYear + " MVP Report");
      },
      error => {
        this.tostr.error("Error occurred while fetching data for report.");
        console.log(error);
      }
    );
  }


  // sendInsuranceReport() {

  // }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    this.includeAll = false;
    this.getBookings();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      bookingId: null,
      insuranceStatus: "",
      manualPayout: "",
      country: "",
      status: "",
      createdFrom: "",
      createdTo: "",
      startOnFrom: "",
      startOnTo: "",
      endsOnFrom: "",
      endsOnTo: ""
    });
    this.bookingService.bookingFilter = null;
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getBookings();
  }
}
