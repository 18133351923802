import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommentService } from 'src/app/services/comment/comment.service';
import { LoginService } from 'src/app/login.service';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Input() resourceType :any;
  @Input() resourceId :any;
  authorId: string = "";
  authorType: string = "";
  frmComment: FormGroup;
  commentDetails: any;

  constructor(
    private commentService: CommentService,
    private authService: LoginService,
    private userService: UserService,
    private fb: FormBuilder,
    private tostr: ToastrService,
    private router: Router,) {
    this.frmComment = this.fb.group({
      body: ['', [Validators.required]],

    });
  }

  ngOnInit(): void {
    this.authorId = this.authService.userId;
    this.authorType = "";
    this.getComments();
  }

  submit() {
    if (this.frmComment.valid) {
      var addCommentReq = {
        body: this.frmComment.controls["body"].value,
        userId: this.authorId,
        authorType: this.authorType,
        resourceId: this.resourceId,
        resourceType: this.resourceType,
      };
      this.commentService.addComment(addCommentReq).subscribe(
        res => {
          this.tostr.success("Comment Added successfully.");
          this.frmComment.reset();
          this.getComments();
        },
        err => {
          this.tostr.error("Something went wrong, please try again.")
          console.log(err);
        });
    }
  }

  getComments() {
    var commentRequest = {
      resourceType: this.resourceType,
      resourceId: this.resourceId,
    };
    this.commentService.fetchComments(commentRequest).subscribe(
      response => {
        this.commentDetails = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }
  deleteComment(id: number) {
    var isDelete = confirm("are you sure want to delete this comment?");
    if (isDelete) {
      this.commentService.deleteComments([id]).subscribe(
        response => {
          this.tostr.success("Deleted Successfully.");
          this.getComments();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
    }
  }
  viewUser(id:string,email:string){
    this.router.navigate(['admin/user'], { queryParams: { id: id,email:email } }); 
  }
}

