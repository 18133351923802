<div class="container-fluid">
    <div class="row row-y">
        <div ngbDropdown class="col-lg-4 col-md-6 d-inline-block pt-2 pb-2">
            <h2 class=" mt-2 mb-4">Booking #{{id}}</h2>
        </div>
        <div class="col-lg-8 col-md-12 text-right pt-2 pb-2">
            <!-- <a routerLink="../booking-edit" [queryParams]="{id: booking.id}" class="btn btn-secondary ml-2">Edit
                Booking</a> -->
            <button type="button" (click)="downloadPickupReturnPdf(true)" class="btn btn-secondary ml-2"
                *ngIf="booking != null && booking.isPickupDone">Download Pickup Report PDF</button>
            <button type="button" (click)="downloadPickupReturnPdf(false)" class="btn btn-secondary ml-2"
                *ngIf="booking != null && booking.isReturnDone">Download Return Report PDF</button>
            <button type="button" (click)="rejectBooking()" class="btn btn-secondary ml-2"
                *ngIf="booking != null && booking.status == 'PENDING'">Decline Booking</button>
            <button type="button" (click)="approveBooking()" class="btn btn-secondary ml-2"
                *ngIf="booking != null && booking.status == 'PENDING'">Accept Booking</button>
            <button type="button" (click)="cancelBooking()" class="btn btn-secondary ml-2"
                *ngIf="booking != null && booking.status == 'CONFIRMED'">Cancel Booking</button>
            <button type="button" (click)="openRefund(refundmodal)" class="btn btn-secondary ml-2"
                *ngIf="booking != null && booking.status == 'CONFIRMED'">Refund</button>
            <button type="button" (click)="markAsTransfered()" class="btn btn-secondary ml-2">Mark as
                Transferred</button>
            <button type="button" (click)="open(mymodal)" class="btn btn-secondary ml-2">Charge Deposit</button>

        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Basic Info</h5>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped" *ngIf="booking != null && booking != undefined">
                                <tr>
                                    <td class="bt-none">ID</td>
                                    <td class="bt-none">{{booking.id}}</td>
                                </tr>
                                <tr>
                                    <td>BOAT</td>
                                    <td><a routerLink="../boat-view"
                                            [queryParams]="{id: booking.boatId,name:booking.boatName}">{{booking.boatName}}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>RENTER</td>
                                    <td *ngIf="booking.renterFName != null && booking.renterLName != null">
                                        <a (click)="gotoUser(booking.renterFName,booking.renterLName,booking.renterId)"
                                            class="text-primary"
                                            style="text-decoration: underline;cursor: pointer;">{{booking.renterFName}}
                                            {{booking.renterLName}}</a>
                                    </td>
                                    <td style="color: red;">Renter no longer exist for this Booking! testing-purpose </td>
                                </tr>
                                <tr>
                                    <td>STATUS</td>
                                    <td>
                                        <p [style.backgroundColor]="booking.statusColor" style="width: fit-content;">
                                            {{booking.status}}
                                        </p>
                                    </td>
                                </tr>
                                <tr *ngIf="booking != null && booking.status == 'INCOMPLETE'">
                                    <td>Error Code</td>
                                    <td>
                                        {{booking.paymentFailedCode}}
                                    </td>
                                </tr>
                                <tr *ngIf="booking != null && booking.status == 'INCOMPLETE'">
                                    <td>Error Message</td>
                                    <td>
                                        {{booking.paymentFailedMessage}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>PLATFORM</td>
                                    <td>
                                        {{booking.bookingPlatform}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>REJECTION REASON</td>
                                    <td>
                                        {{booking.rejectionReason}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>MANUAL PAYOUT</td>
                                    <td>
                                        <p *ngIf="booking.manualPayout!= '' && booking.manualPayout == 'YES'"
                                            style="background-color: #6090DB;width:fit-content;">
                                            {{booking.manualPayout}}</p>
                                        <p *ngIf="booking.manualPayout!= '' && booking.manualPayout == 'NO'"
                                            style="background-color: grey;width: fit-content;">{{booking.manualPayout}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>INSURANCE STATUS</td>
                                    <td>
                                        <p [style.backgroundColor]="booking.insuranceColor" style="width: fit-content;">
                                        </p>{{booking.insuranceStatus}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>FROM DATE</td>
                                    <td> {{booking.fromDate}}</td>
                                </tr>
                                <tr>
                                    <td>TO DATE</td>
                                    <td>
                                        {{booking.toDate}}
                                    </td>
                                </tr>

                                <tr>
                                    <td>CANCELLATION POLICY</td>
                                    <td> {{booking.cancellationPolicy}}</td>
                                </tr>
                                <tr>
                                    <td>NOTES</td>
                                    <td> {{booking.notes}}</td>
                                </tr>
                                <tr>
                                    <td>EARLY BOOKING PERIOD (MONTHS) </td>
                                    <td>
                                        {{booking.earlyBookingPeriodMonth}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>EARLY BOOKING DISCOUNT (%)</td>
                                    <td> {{booking.ealryBookingDiscountPer}}</td>
                                </tr>
                                <tr>
                                    <td>WEEKLY DISCOUNT (%)</td>
                                    <td> {{booking.weeklyDiscountPer}}</td>
                                </tr>
                                <tr>
                                    <td>OWNER SERVICE FEE (%)</td>
                                    <td> {{booking.ownerServiceFeePer}}</td>
                                </tr>
                                <tr>
                                    <td>RENTER SERVICE FEE (%)</td>
                                    <td> {{booking.renterServiceFeePer}}</td>
                                </tr>
                                <tr>
                                    <td>CREATED AT</td>
                                    <td> {{booking.createdAt}}</td>
                                </tr>
                                <tr>
                                    <td>UPDATED AT</td>
                                    <td> {{booking.updatedAt}}</td>
                                </tr>
                                <tr>
                                    <td>RENTER CERTIFICATE INFO</td>
                                    <td> {{booking.renterCertificateInfo}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Amounts</h5>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped" *ngIf="booking != null && booking != undefined">
                                <tr>
                                    <td colspan="3">
                                        <b>Renter</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bt-none">Price Per Day</td>
                                    <td class="bt-none">{{booking.currency}}
                                        {{booking.priceData.renterPriceData.dailyPrice}}</td>
                                    <td class="bt-none">{{booking.priceData.renterPriceData.dailyPriceInEur}}</td>
                                </tr>
                                <tr>
                                    <td>TOTAL DAYS</td>
                                    <td colspan="2">
                                        {{booking.priceData.renterPriceData.days}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>TOTAL EXPECTED RENTER PRICE</td>
                                    <td class="bt-none">{{booking.currency}}
                                        {{booking.priceData.renterPriceData.renterPriceWSF}}</td>
                                    <td class="bt-none">{{booking.priceData.renterPriceData.renterPriceWSFInEur}}
                                    </td>
                                </tr>

                                <!-- <tr>
                                    <td>BOATFLEX CREDITS</td>
                                    <td>{{booking.boatflexCredits}}</td>
                                    <td></td>
                                </tr> -->
                                <tr>
                                    <td>EARLY BOOKING PRICE DISCOUNT</td>
                                    <td>- {{booking.currency}}
                                        {{booking.priceData.renterPriceData.earlyBookingPriceDiscount}}</td>
                                    <td>- {{booking.priceData.renterPriceData.earlyBookingPriceDiscountInEur}}</td>
                                </tr>
                                <tr>
                                    <td>WEEKLY PRICE DISCOUNT</td>
                                    <td>- {{booking.currency}} {{booking.priceData.renterPriceData.weeklyPriceDiscount}}
                                    </td>
                                    <td>- {{booking.priceData.renterPriceData.weeklyPriceDiscountInEur}}</td>
                                </tr>
                                <tr>
                                    <td>CAPTAIN HIRED</td>
                                    <td>{{booking.priceData.renterPriceData.captainHired}}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>CAPTAIN FEE</td>
                                    <td>{{booking.currency}} {{booking.priceData.renterPriceData.captainFee}}</td>
                                    <td>{{booking.priceData.renterPriceData.captainFeeInEur}}</td>
                                </tr>
                                <tr>
                                    <td>CREW HIRED</td>
                                    <td>{{booking.priceData.renterPriceData.crewHired}}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>CREW FEE</td>
                                    <td>{{booking.currency}} {{booking.priceData.renterPriceData.crewFee}}</td>
                                    <td>{{booking.priceData.renterPriceData.crewFeeInEur}}</td>
                                </tr>
                                <tr>
                                    <td>TOTAL PAID RENTER PRICE</td>
                                    <td class="bt-none">{{booking.currency}} {{booking.priceData.totalPriceWSF}}</td>
                                    <td class="bt-none">{{booking.priceData.totalPriceWSFInEur}}</td>
                                </tr>
                                <tr>
                                    <td>RENTER SERVICE FEE (6%)</td>
                                    <td>{{booking.currency}} {{booking.priceData.renterPriceData.renterServiceFee}}</td>
                                    <td>{{booking.priceData.renterPriceData.renterServiceFeeInEur}}</td>
                                </tr>
                                <tr>
                                    <td>INSURANCE FEE</td>
                                    <td>{{booking.currency}} {{booking.insuranceFee}}</td>
                                    <td>{{booking.insuranceFeeInEur}}</td>
                                </tr>
                                <tr>
                                    <td>FINAL AMOUNT</td>
                                    <td>{{booking.currency}} {{booking.priceData.renterPriceData.totalPrice}}</td>
                                    <td>{{booking.priceData.renterPriceData.totalPriceInEur}}</td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <b>Owner</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>BOAT PRICE</td>
                                    <td class="bt-none">{{booking.currency}} {{booking.priceData.totalPriceWSF}}</td>
                                    <td class="bt-none">{{booking.priceData.totalPriceWSFInEur}}</td>
                                </tr>
                                <tr>
                                    <td>OWNER SERVICE FEE (16%)</td>
                                    <td>{{booking.currency}} {{booking.priceData.ownerPriceData.ownerServiceFee}}</td>
                                    <td>{{booking.priceData.ownerPriceData.ownerServiceFeeInEur}}</td>
                                </tr>
                                <tr>
                                    <td>INSURANCE FEE</td>
                                    <td>{{booking.currency}} {{booking.insuranceFee}}</td>
                                    <td>{{booking.insuranceFeeInEur}}</td>
                                </tr>
                                <tr>
                                    <td>PAYOUT TO BOAT OWNER</td>
                                    <td>{{booking.currency}} {{booking.priceData.ownerPriceData.payout}}</td>
                                    <td>{{booking.priceData.ownerPriceData.payoutInEur}}</td>
                                </tr>
                                <tr>
                                    <td>PAYOUT EXPECTED DATE</td>
                                    <td>{{booking.payoutDate}}</td>
                                    <td></td>
                                </tr>



                                <tr>
                                    <td>DEPOSIT</td>
                                    <td>{{booking.currency}} {{booking.priceData.ownerPriceData.deposit}}</td>
                                    <td>{{booking.priceData.ownerPriceData.depositInEur}}</td>
                                </tr>
                                <tr>
                                    <td>CHARGED DEPOSIT</td>
                                    <td>{{booking.priceData.ownerPriceData.chargedDeposit}}</td>
                                    <td></td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Payouts</h5>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <th>Payout ID</th>
                                    <th>Amount</th>
                                    <th>Arrival Date</th>
                                    <th>Status</th>
                                </thead>
                                <tbody
                                    *ngIf="booking != null && booking.payouts != undefined && booking.payouts != null">
                                    <tr *ngFor="let payout of booking.payouts">
                                        <td><a *ngIf="payout.stripePayoutId != null && payout.stripePayoutId != ''"
                                                href="https://dashboard.stripe.com/{{payout.stripeAccountId}}/payouts/{{payout.stripePayoutId}}">
                                                {{payout.stripePayoutId}}</a></td>
                                        <td>{{payout.amountCents}} {{payout.currency}}</td>
                                        <td>{{payout.arrivalDate}}</td>
                                        <td>{{payout.status}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Payment</h5>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped" *ngIf="booking != null && booking != undefined">
                                <tr>
                                    <td class="bt-none"> STRIPE INTENT</td>
                                    <td class="bt-none">
                                        <a target="_bank"
                                            href="https://dashboard.stripe.com/payments/{{booking.stripeIntentId}}">{{booking.stripeIntentId}}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>STRIPE PAYMENT METHOD</td>
                                    <td>{{booking.stripePaymentMethod}}</td>
                                </tr>
                                <tr>
                                    <td>STRIPE CHARGE</td>
                                    <td><a target="_bank"
                                            href="https://dashboard.stripe.com/payments/{{booking.stripeCharge}}">{{booking.stripeCharge}}</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Events</h5>
                        </div>
                        <div class="card-body table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <th>EVENT TYPE</th>
                                    <th>ACTOR</th>
                                    <th>CREATED AT</th>
                                </thead>
                                <tbody *ngIf="booking != null && booking.events != undefined && booking.events != null">
                                    <tr *ngFor="let event of booking.events">
                                        <td>{{event.eventType}}</td>
                                        <td>
                                            <a (click)="gotoUser(event.actorFName,event.actorLName,event.actorId)"
                                                class="text-primary"
                                                style="text-decoration: underline;cursor: pointer;">{{event.actorFName}}
                                                {{event.actorLName}}</a>
                                        </td>
                                        <td>{{event.createdDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>

<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Charge Deposit</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmDeposit" id="depositForm">

            <div class="form-group row">
                <div class="col-md-4">
                    <label for="amount">Amount</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="amount" formControlName="amount" class="form-control">
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="chageDeposit()">Charge</button>
        <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
    </div>
</ng-template>
<ng-template #refundmodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Refund</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmRefund" id="RefundForm">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="nod">No of Days</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="nod" formControlName="nod" class="form-control"
                        (change)="onNODChange($event)">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="nod">Amount</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="amf" formControlName="amf" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="nod">Service fee</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="serviceFee" formControlName="serviceFee" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="amount">Amount</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="amount" formControlName="amount" class="form-control">
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="refund()">Refund</button>
        <button type="button" class="btn btn-outline-secondary" (click)="cancelRefund()">Cancel</button>
    </div>
</ng-template>