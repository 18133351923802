import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
  userDetails: any;
  page: number = 1;
  pageSize: number = 10;
  orderColumn: string = "createdAt";
  orderDirection: string = "DESC";
  isGlobalChecked: boolean = false;
  isDisableBatch: boolean = true;
  checkboxCheckedList: string[] = [];
  frmFilter: FormGroup;
  includeAll: boolean = true;
  navigationSubscription: any;
  public exportFileName: string = "admin_users";

  constructor(private userService: UserService, private router: Router, private modalService: NgbModal,
    private fb: FormBuilder, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      email: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    var userFilterRequest = {
      Page: this.page,
      PageSize: this.pageSize,
      OrderColumn: this.orderColumn,
      OrderDirection: this.orderDirection,
      email: this.frmFilter.controls["email"].value,
      includeAll: this.includeAll
    };
    this.userService.fetchAdminUsers(userFilterRequest).pipe(finalize(() => {
    })).subscribe(res => {
      this.userDetails = res;
      this.changeCheckBoxStatus();
    },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      });
  }

  getPage(page: number) {
    this.page = page;
    this.isGlobalChecked = false;
    this.getUsers();
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'desc') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'asc') {
      this.orderDirection = 'desc';
    } else {
      this.orderDirection = 'asc';
    }
    this.orderColumn = orderColumn;
    this.getPage(this.page);
  }

  edit(id: string, email: string) {
    this.router.navigate(['admin/admin-edit'], { queryParams: { id: id, email: email } });
  }

  view(id: string, email: string) {
    this.router.navigate(['admin/user'], { queryParams: { id: id, email: email } });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    let email = this.frmFilter.controls["email"].value;
    if (email == "") {
      this.includeAll = true;
    }
    else {
      this.includeAll = false;
    }
    this.getUsers();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      email: ""
    });
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getUsers();
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.userDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.userDetails.data[a].userId);
      }
      this.userDetails.data.forEach((user) => {
        user.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.userDetails.data.forEach((user) => {
        user.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, userId: string) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(userId);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != userId);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.userDetails.data.length && this.userDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  delete(userId: string) {
    var isDelete = confirm("are you sure want to delete selected admin user?");
    if (isDelete) {
      this.userService.deleteUsers([userId]).subscribe(
        response => {
          this.tostr.success("Deleted Successfully.");
          this.getUsers();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
    }
  }

  deleteUsers() {
    var isDelete = confirm("are you sure want to delete selected admin users?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.userService.deleteUsers(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getUsers();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

}
