import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BoatService } from 'src/app/services/boat.service';
import { ToastRef, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit {
  @Input() boatName;
  id: number = 0;
  sections: any;
  
  constructor(private boatService: BoatService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.boatService.fetchBoatSections(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.sections = res;
      },
      error => {
        this.toastr.error("Couldn't load details.");
        console.log(error);
      }
    );
  }

}
