<div class="card mt-2">
  <div class="card-header" *ngIf="commentDetails != undefined ; else countElseBlock">
    Comments({{commentDetails.allCount}})
  </div>
  <ng-template #countElseBlock>
    <div class="card-header">
      Comments(0)
    </div>
  </ng-template>

  <div class="card-body">
    <div class="row">
      <div class="col-md-5 col-sm-5">
        <div *ngIf="commentDetails != undefined && commentDetails.allCount > 0; else elseBlock">
          <div class="comment mt-4 text-justify" *ngFor="let comment of commentDetails.data">
            <h4><a (click)="viewUser(comment.userId,comment.userEmail)"> {{comment.userEmail}}</a> </h4> <span>  - {{comment.createdAt}}</span>
            <br>
            <p>{{comment.body}}</p>
            <div class="deleteIcon" *ngIf="comment.userId == authorId">
              <button type="submit" (click)="deleteComment(comment.id)" title="Click to Delete this comment"
                class="btn"><i class="bi bi-trash"></i></button>
            </div>
          </div>
        </div>
        <ng-template #elseBlock>
          <p>No comments yet.</p>
        </ng-template>
      </div>
      <div class="col-md-6">
        <form [formGroup]="frmComment" (ngSubmit)="submit()">
          <textarea class="form-control" id="body" formControlName="body" rows="4" cols="50"
            placeholder="Please enter comment message" required maxlength="200"></textarea>
          <div *ngIf="frmComment.controls['body'].invalid && frmComment.controls['body'].touched"
            class="alert alert-danger form-danger" role="alert">
            <div *ngIf="frmComment.controls['body'].hasError('required')">
              Please enter comment message.
            </div>
          </div>
          <button type="submit" [disabled]="!frmComment.valid" class="btn btn-secondary mt-2">Add Comment</button>
        </form>
      </div>
    </div>
  </div>
</div>