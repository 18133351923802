<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Alerts</h2>
    <div class="row">
        <div class="col-md-2">
            <div ngbDropdown class="d-inline-block pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteAlerts()">Delete selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-10" *ngIf="alertsDetails != undefined">
            <button class="btn btn-outline-secondary" (click)="all()">All({{alertsDetails.allCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="openAlerts()">Open({{alertsDetails.openCount}})</button>
            <button class="btn btn-outline-secondary ml-1" (click)="inProgress()">In
                progress({{alertsDetails.inProgressCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="closed()">Closed({{alertsDetails.closedCount}})</button>
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked" (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th>
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('AlertType')" style="cursor: pointer;">Alert Type</a>
                            <th>
                                boat
                            </th>
                            <th>
                                Booking
                            </th>
                            <th>
                                User
                            </th>
                            <th>
                                <a (click)="toggleSort('Status')" style="cursor: pointer;">Status</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('CreatedAt')" style="cursor: pointer;">Created At</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('UpdatedAt')" style="cursor: pointer;">Updated At</a>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="alertsDetails != undefined  && alertsDetails.totalEntries > 0">
                        <tr
                            *ngFor="let alert of alertsDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:alertsDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" value="{{alert.id}}" [(ngModel)]="alert.selected"
                                        (change)="checkboxChange($event,alert.id)">
                                </div>
                            </td>
                            <td>
                                <a routerLink="../alert-view" [queryParams]="{id: alert.id,status:alert.status}"
                                    class="text-primary" style="text-decoration: underline;">{{alert.id}}</a>
                            </td>
                            <td>{{alert.alertType}}</td>
                            <td>
                                <div *ngIf="alert.boatName">
                                    <a routerLink="../boat-view" [queryParams]="{id: alert.boatId,name:alert.boatName}"
                                        class="text-primary" style="text-decoration: underline;">{{alert.boatName}}
                                        {{alert.boatModel}}</a>
                                </div>
                            </td>
                            <td>
                                <div *ngIf="alert.bookingId > 0">
                                    <a routerLink="../booking-view" [queryParams]="{id: alert.bookingId}"
                                        class="text-primary" style="text-decoration: underline;">Booking
                                        #{{alert.bookingId}}</a>
                                </div>
                            </td>
                            <td>
                                <a (click)="gotoUser(alert.userFName,alert.userLName,alert.userId)" class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{alert.userFName}}
                                    {{alert.userLName}}</a>
                            </td>
                            <td>
                                <p [style.backgroundColor]="alert.statusColor"
                                    style="color: #ffffff;width: fit-content;">{{alert.status}}</p>
                            </td>
                            <td>{{alert.createdAt}} </td>
                            <td>{{alert.updatedAt}} </td>
                            <td><a routerLink="../alert-view" [queryParams]="{id: alert.id,status:alert.status}"><button
                                        type="submit" title="Click to View" class="btn"><i
                                            class="bi bi-eye"></i></button></a>
                                <button type="submit" (click)="onDelete(alert.id)" title="Click to Delete"
                                    class="btn"><i class="bi bi-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
            *ngIf="alertsDetails != undefined && alertsDetails.totalEntries != undefined && alertsDetails.totalEntries > 0">
            <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
        </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-2">
                    <label for="alertType">ALERT TYPE</label>
                </div>
                <div class="col-md-10" *ngIf="alertTypes != null && alertTypes.length > 0">
                    <select formControlName="alertType" id="alertType" class="form-control" ng-options="">
                        <option *ngFor="let type of alertTypes" value={{type.value}}>{{type.text}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-2">
                    <label for="status">STATUS</label>
                </div>
                <div class="col-md-10">
                    <select formControlName="status" id="status" class="form-control" ng-options="">
                        <option value="open">Open</option>
                        <option value="in progress">In progress</option>
                        <option value="closed">Closed</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-2">
                    <label for="createdFrom">CREATED FROM</label>
                </div>
                <div class="col-md-10">
                    <input type="date" id="createdFrom" formControlName="from" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-2">
                    <label for="createdTo">CREATED TO</label>
                </div>
                <div class="col-md-10">
                    <input type="date" id="createdTo" formControlName="to" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>