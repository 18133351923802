import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { ConfirmPasswordValidator } from 'src/app/shared/customValidator';
import { CreateUserModel } from 'src/app/shared/modals/createUserModel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})

export class CreateUserComponent implements OnInit {
  frmCreateUser: FormGroup;
  countryCode: string = "+1";
  phoneNumberInputSetting: any;
  inputObject: any;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router,
    private tostr: ToastrService) {
    this.frmCreateUser = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}')]],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: ConfirmPasswordValidator("newPassword", "confirmPassword")
      }
    );
  }

  ngOnInit(): void {
    this.phoneNumberInputSetting = {
      initialCountry: 'us',
      separateDialCode: true,
    }
  }

  onCountryChange(event: any) {
    this.countryCode = "+" + event.dialCode;
  }

  hasError(event: any): void {
    if (!event && this.frmCreateUser.controls["phoneNumber"].value !== '') {
      this.frmCreateUser.controls["phoneNumber"].setErrors(['invalid_phone_number', true]);
    }
    else{
      this.frmCreateUser.controls["phoneNumber"].setErrors(null);
    }
  }

  getNumber(event: any) {
    console.log("get number runs");
    console.log(event);
  }

  telInputObject(event: any) {
    this.inputObject = event;
    console.log("tell input obj runs");
    console.log(event);
  }

  onReset() {
    this.router.navigate(["admin/admin-users"]);
  }

  onSubmit() {
    var createUserModel = new CreateUserModel(
      this.frmCreateUser.controls["fname"].value,
      this.frmCreateUser.controls["lname"].value,
      this.frmCreateUser.controls["email"].value,
      this.countryCode, this.frmCreateUser.controls["phoneNumber"].value,
      this.frmCreateUser.controls["newPassword"].value, true, false)

    this.userService.createUser(createUserModel).subscribe(
      response => {
        this.tostr.success("Successfully created.");
        this.router.navigate(["admin/admin-users"]);
      },
      error => {
        this.tostr.error("Something went wrong!! please try again.");
      }
    );
  }

}


