import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { param } from 'jquery';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {
  id: number = 0;
  public fName: string = "";
  public fullName: string = "";
  public heading = "";
  public active = 1;
  checkboxCheckedList: number[] = [];
  resourceType: string = "";
  resourceId: string = "";
  btnVerification: string = "inline-block"

  constructor(private router: ActivatedRoute, private route: Router,
    private userService: UsersService, private tostr: ToastrService, private location: Location) { }

  ngOnInit(): void {
    this.fName = this.userService.fName;
    this.fullName = this.userService.fullName;
    this.router.queryParams.subscribe(params => { this.id = params.id, this.active = (params.sec == "" || params.sec === undefined ? 1 : Number(params.sec)) });

    this.resourceId = this.id.toString();
    this.resourceType = "user";
    this.userService.fetchUsersVerifications(this.id).subscribe(
      res => {
        var verification: any;
        verification = res;
        console.log(verification);
        if (verification.photoIdBackStatus == "" && verification.sailCertificateStatus == "" &&
          verification.photoIdStatus == "" && verification.additionalDocStatus == "") {
          this.btnVerification = "none"
        }
        else {
          this.btnVerification = "inline-block";
        }
      },
      err => {
        console.log(err);
        this.tostr.error("Something went wrong, please try again.");
      }
    )
  }
  changeTab(tabId: number) {
    this.active = tabId;
    this.fName = this.userService.fName;
    this.fullName = this.userService.fullName;
  }
  delete() {
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      this.checkboxCheckedList.push(this.id);
      if (this.checkboxCheckedList.length > 0) {
        this.userService.deleteUsers(this.checkboxCheckedList).subscribe(
          response => {
            this.checkboxCheckedList = [];
            this.route.navigate(["admin/users"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }
  verifyEmail() {
    this.userService.verifyEmail(this.id).subscribe(
      res => {
        if (res.toString() == "Success") {
          this.tostr.success(res.toString());
        }
        else {
          this.tostr.error(res.toString());
        }
      },
      error => {
        this.tostr.error("Error occurred while executing your query");
      }
    );
  }

  verifyPhone() {
    this.userService.verifyPhone(this.id).subscribe(
      res => {
        if (res.toString() == "Success") {
          this.tostr.success(res.toString());
        }
        else {
          this.tostr.error(res.toString());
        }
      },
      error => {
        this.tostr.error("Error occurred while executing your query");
      }
    );
  }

  verification(id: number) {
    this.route.navigate(["admin/verifications"], { queryParams: { id: this.id } });
  }
  tabNavChange(changeEvent: NgbNavChangeEvent) {

    sessionStorage.setItem('activeUri', window.location.pathname + "?id=" + this.id + "&sec=" + changeEvent.nextId);
    this.location.replaceState(window.location.pathname, "id=" + this.id + "&sec=" + changeEvent.nextId)
  }
}
