<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>{{heading}}</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th>#</th>
                        <th>BOAT</th>
                        <th>BOAT OWNER</th>
                        <th>RENTER</th>
                        <th>FROM DATE</th>
                        <th>TO DATE</th>
                        <th>STATUS</th>
                        <th>MANUAL PAYOUT</th>
                        <th>CREATED AT</th>
                    </thead>
                    <tbody
                        *ngIf="userBookings!= null && userBookings != undefined && userBookings.length > 0 else noRecord ">
                        <tr *ngFor="let userBooking of userBookings">
                            <td>
                                <a routerLink="../booking-view"
                                    [queryParams]="{id: userBooking.bookingId}">{{userBooking.bookingId}}</a>
                            </td>
                            <td>{{userBooking.boatId}}</td>
                            <td>{{userBooking.boatOwner}}</td>
                            <td>{{userBooking.renterName}}</td>
                            <td>{{userBooking.fromDate}}</td>
                            <td>{{userBooking.toDate}}</td>
                            <td>{{userBooking.status}}</td>
                            <td>{{userBooking.manualPayout}}</td>
                            <td>{{userBooking.createdAt}}</td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>