import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/services/cms services/category/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})

export class CategoriesComponent implements OnInit, OnDestroy {
  categoriesDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  defaultvalue: number = 0;
  frmFilter: FormGroup;
  orderColumn: string = "name";
  orderDirection: string = "ASC";
  languageCode: string = "en";
  navigationSubscription: any;
  public exportFileName: string = "categories";

  constructor(private modalService: NgbModal, private categoryService: CategoryService,
    private fb: FormBuilder, private router: Router,
    private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      name: [''],
      context: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.reset();
      }
    });

  }

  ngOnInit(): void {
    this.getCategories();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getCategories() {

    var request = {
      name: this.frmFilter.controls["name"].value,
      context: this.frmFilter.controls["context"].value,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      languageCode: this.languageCode
    };

    this.categoryService.fetchCategories(request)
      .subscribe(
        response => {
          this.categoriesDetails = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected Category?");
    if (isDelete) {
      this.categoryService.DeleteCategory(id)
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getCategories();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  deleteCategories() {
    var isDelete = confirm("are you sure want to delete selected categories?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.categoryService.DeleteCategories(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getCategories();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

  getPage(page: number) {
    this.page = page;
    this.getCategories();
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  onFilter() {
    this.getCategories();
    this.modalService.dismissAll('submit');
  }

  reset() {
    this.frmFilter.setValue({
      name: "",
      context: ""
    });
    this.modalService.dismissAll('submit');
    this.getCategories();
  }

  globalCheckboxChange(e: any) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.isGlobalChecked = true;
      for (let a = 0; a < this.categoriesDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.categoriesDetails.data[a].id);
      }
    }
    else {
      this.isDisableBatch = true;
      this.isGlobalChecked = false;
      this.checkboxCheckedList = []
    }
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
  }

}




