import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ReviewService } from 'src/app/services/review.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-review-view',
  templateUrl: './review-view.component.html',
  styleUrls: ['./review-view.component.css']
})
export class ReviewViewComponent implements OnInit {
  id: number = 0;
  reviewDetails: any;
  public resourceType: string = "";
  public resourceId: string = "";

  constructor(private reviewService: ReviewService, private router: Router, private activatedRoute: ActivatedRoute,
    private tostr: ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.resourceId = this.id.toString();
    this.resourceType = "Review";

    this.reviewService.fetchReview(this.id).subscribe(
      response => {
        this.reviewDetails = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  delete(id: number) {
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      this.reviewService.deleteReview([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.router.navigate(["admin/reviews"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

}
