import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() {}

    get authApiURI() {
        return environment.tokenApiEndPoint;
    }    
     
    get resourceApiURI() {
        return environment.apiEndpoint;
    }  

    get poEditorsApiBaseURI(){
      return environment.poEditor_api_base_uri;
    }
}
