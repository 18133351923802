import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TripTypeService } from 'src/app/services/trip-type/trip-type.service';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-trip-type-new',
  templateUrl: './trip-type-new.component.html',
  styleUrls: ['./trip-type-new.component.css']
})
export class TripTypeNewComponent implements OnInit {
  formTripType: FormGroup;
  fileUrl: string = "";

  constructor(private tripTypeService: TripTypeService, private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.formTripType = this.fb.group({
      title: ['', [Validators.required]],
      subTitle: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {  
  }

  reset() {
    this.router.navigate(["admin/trip-types"]);
  }

  submit() {
    if (this.formTripType.valid) {
      var tripTypeRequest = {
        title: this.formTripType.controls["title"].value,
        subTitle: this.formTripType.controls["subTitle"].value,
        iconUrl: this.fileUrl
      };
      this.tripTypeService.SaveTripType(tripTypeRequest)
        .subscribe(
          res => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/trip-types']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml','image/*'],
      onUploadDone: (file: { filesUploaded: { url: string; }[]; }) => this.fileUrl = file.filesUploaded[0].url
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

}
