<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="insurancePriceViewDetails != null">#{{insurancePriceViewDetails.id}}</h2>
        </div>
        <div class="col-lg-6 col-md-12 text-right" *ngIf="insurancePriceViewDetails != null">
            <a routerLink="../insurance-price-range-edit" [queryParams]="{id: insurancePriceViewDetails.id}"
                class="btn btn-secondary">Edit Insurance Price Range</a>
            <!-- <button type="button" (click)="delete(insurancePriceViewDetails.id)" class="btn btn-secondary m-2">Delete
                Insurance Price Range
            </button> -->
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="insurancePriceViewDetails != undefined && insurancePriceViewDetails != null">
                <div class="card-header">
                    <h5>Insurance Price Range Details</h5>
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Id</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{insurancePriceViewDetails.id}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Minimum Boat Price</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{insurancePriceViewDetails.minBoatPrice}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Maximum Boat Price</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{insurancePriceViewDetails.maxBoatPrice}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Daily Fees</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{insurancePriceViewDetails.dailyFees}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Weekly Fees</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{insurancePriceViewDetails.weeklyFees}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Per Day Add On Fees</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{insurancePriceViewDetails.perDayAddOnFees}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>