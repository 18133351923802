import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';
import { WishService } from 'src/app/services/wish.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-favoriteers',
  templateUrl: './favoriteers.component.html',
  styleUrls: ['./favoriteers.component.css']
})
export class FavoriteersComponent implements OnInit {
  @Input() boatName;
  id:number = 0;
  favoriters:any;
  constructor(private route:ActivatedRoute
    ,private userService:UsersService, private router:Router,
    private wishService:WishService, private tostr:ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param=> {this.id = param.id});
    this.wishService.fetchFevoriters(this.id).subscribe(
      res=> {
        this.favoriters = res;
      },
      error=>{
        this.tostr.error("Couldn't load details.");
        console.log(error);
      }
    );
  }

  gotoUser(name:string,lname:string,id:number){
    this.userService.fName = name;
    this.userService.fullName = name +" "+ lname;
    this.router.navigate(["admin/user-view"],{ queryParams: { id: id } })
  }

}
