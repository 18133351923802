<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Boats</h2>
    <div class="row mb-3">
        <div class="col-md-2 delete-btn">
            <div ngbDropdown class="d-inline-block pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteBoats()">Delete selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-10 filter-btns" *ngIf="boatsDetails != undefined">
            <button class="btn btn-outline-secondary" (click)="all()">All({{boatsDetails.allCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="published()">Published({{boatsDetails.publishedCount}})</button>
            <button class="btn btn-outline-secondary ml-1" (click)="publishedOwned()">Published
                Owned({{boatsDetails.publishedOwnedCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="unpublished()">Unpublished({{boatsDetails.unpublishedCount}})</button>
            <button class="btn btn-outline-secondary ml-1" (click)="bookingManager()">Booking
                Manager({{boatsDetails.bookingManagerCount}})</button>
            <button class="btn btn-outline-secondary ml-1" (click)="publishedBM()">Published
                BM({{boatsDetails.publishedBMCount}})</button>
            <button class="btn btn-outline-secondary ml-1" (click)="userOwned()">User
                Owned({{boatsDetails.userOwnedCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="deleted()">Deleted({{boatsDetails.deletedCount}})</button>
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked"
                                        (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th>
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('Name')" style="cursor: pointer;">Name</a>
                            <th>
                                <a (click)="toggleSort('user.FirstName')" style="cursor: pointer;">Owner</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('ListingType')" style="cursor: pointer;">Listing Type</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('CountryCode')" style="cursor: pointer;">Country Code</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('PublishedAt')" style="cursor: pointer;">Published At</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('UnPublishedAt')" style="cursor: pointer;">Unpublished At</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('DeleteAt')" style="cursor: pointer;">Deleted At</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('Views')" style="cursor: pointer;">views</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('WishlistedCount')" style="cursor: pointer;">Wishlisted Count</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('CreatedAt')" style="cursor: pointer;">Created At</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('InsuranceStatus')" style="cursor: pointer;">Insurance Status</a>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="boatsDetails != undefined  && boatsDetails.totalEntries > 0">
                        <tr
                            *ngFor="let boat of boatsDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:boatsDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" [(ngModel)]="boat.selected" value="{{boat.id}}"
                                        (change)="checkboxChange($event,boat.id)">
                                </div>
                            </td>
                            <td><a routerLink="../boat-view"
                                    [queryParams]="{id: boat.id,name:boat.name}">{{boat.id}}</a></td>
                            <td>{{boat.name}}</td>
                            <td><a (click)="gotoUser(boat.ownerFName,boat.ownerLName,boat.ownerId)" class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{boat.ownerFName}}
                                    {{boat.ownerLName}}</a></td>
                            <td>{{boat.listingType}} </td>
                            <td>{{boat.countryCode}} </td>
                            <td>{{boat.publishedAt}} </td>
                            <td>{{boat.unpublishedAt}} </td>
                            <td>{{boat.deleteAt}} </td>
                            <td>{{boat.views}} </td>
                            <td>{{boat.wishlistedCount}} </td>
                            <td>{{boat.createdAt}} </td>
                            <td>
                                <div *ngIf="boat.insuranceColour != 'black' else whiteText">
                                    <!-- <p [style.backgroundColor]="boat.insuranceColour">{{boat.insuranceType}}</p> -->
                                    <p [style.backgroundColor]="boat.insuranceColour">{{boat.insuranceStatus}}</p>
                                </div>
                                <ng-template #whiteText>
                                    <!-- <p [style.backgroundColor]="boat.insuranceColour" style="color: #ffffff;">
                                        {{boat.insuranceType}}</p> -->
                                    <p [style.backgroundColor]="boat.insuranceColour" style="color: #ffffff;">
                                        {{boat.insuranceStatus}}</p>
                                </ng-template>
                            </td>
                            <td><a routerLink="../boat-view" [queryParams]="{id: boat.id,name:boat.name}"><button
                                        type="submit" title="Click to View" class="btn"><i
                                            class="bi bi-eye"></i></button></a>
                                <a routerLink="../boat-edit" [queryParams]="{id: boat.id}"><button type="submit"
                                        title="Click to Edit" class="btn"> <i
                                            class="bi bi-pencil-square"></i></button></a>
                                <button type="submit" title="Click to Delete" (click)="onDelete(boat.id)" class="btn"><i
                                        class="bi bi-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="boatsDetails != undefined && boatsDetails.totalEntries != undefined && boatsDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="id">Id</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="id" formControlName="id" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="iStatus">INSURANCE STATUS</label>
                </div>
                <div class="col-md-8" *ngIf="insuranceStatuses != null && insuranceStatuses.length > 0">
                    <select formControlName="insuranceStatus" id="iStatus" class="form-control" ng-options="">
                        <option *ngFor="let type of insuranceStatuses" value={{type}}>{{type}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="bType">BOAT TYPE</label>
                </div>
                <div class="col-md-8" *ngIf="boatTypes != null && boatTypes.length > 0">
                    <select formControlName="boatType" id="bType" class="form-control" ng-options="">
                        <option *ngFor="let type of boatTypes" value={{type}}>{{type}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="bType">TRIP TYPE</label>
                </div>
                <div class="col-md-8" *ngIf="tripTypes != null && tripTypes.length > 0">
                    <select formControlName="tripType" id="tripType" class="form-control" ng-options="">
                        <option value=""></option>
                        <option *ngFor="let tripType of tripTypes" value={{tripType.id}}>{{tripType.title}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="country">COUNTRY</label>
                </div>
                <div class="col-md-8" *ngIf="countries != null && countries.length > 0">
                    <select formControlName="country" id="country" class="form-control" ng-options="">
                        <option *ngFor="let country of countries" value={{country.code}}>{{country.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="customMade">CUSTOM MADE</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="customMade" id="customMade" class="form-control" ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="name">NAME</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="name" formControlName="name" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="model">BOAT MODEL</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="model" formControlName="model" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="location">LOCATION NAME</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="location" formControlName="location" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="length">LENGTH</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="length" formControlName="length" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="capacity">CAPACITY</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="capacity" formControlName="capacity" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="btn-outline-secondary">BEDS</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="beds" formControlName="beds" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="cabins">CABINS</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="cabins" formControlName="cabins" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>