<div class="container-fluid">
    <div class="row">
        <div class="col-md-8">
            <h2 class=" mt-2 mb-4" *ngIf="reviewDetails != null">Review #{{reviewDetails.id}}</h2>
        </div>
        <div class="col-md-4" *ngIf="reviewDetails != null">
            <a routerLink="../review-edit" [queryParams]="{id: reviewDetails.id}" class="btn btn-secondary">Edit
                Review</a>
            <button type="button" (click)="delete(reviewDetails.id)" class="btn btn-secondary m-2">Delete
                Review</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="reviewDetails != undefined && reviewDetails != null">
                <div class="card-header">
                    <h5>Review Details</h5>
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>ROLE</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{reviewDetails.role}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>BOOKING</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label><a routerLink="../booking-view" [queryParams]="{id: reviewDetails.bookingId}"
                                    style="text-decoration: underline;">Booking #{{reviewDetails.bookingId}}</a></label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>SUBJECT</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{reviewDetails.subject}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>BODY</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{reviewDetails.body}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>STARS</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{reviewDetails.stars}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>CREATED AT</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{reviewDetails.createdAt}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>UPDATED AT</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{reviewDetails.updatedAt}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>