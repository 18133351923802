
<div class="container mt-2">
    <h2 class="`text-default">New Amenity Category</h2>
    <form [formGroup]="frmAmenityCategory" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header">
            Category details
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-2">
            <label for="name">Name</label>
            </div>
            <div class="col-md-10">
              <input type="name" class="form-control" id="name" formControlName="name" placeholder="name" required>
              <div *ngIf="frmAmenityCategory.controls['name'].invalid && frmAmenityCategory.controls['name'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="frmAmenityCategory.controls['name'].hasError('required')">
                    Please enter name.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row m-3">
      <button type="submit" [disabled]="!frmAmenityCategory.valid" class="btn btn-primary">Create Amenity Category        
      </button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
    </form>
  </div>
  
  
  
