<div class="container-fluid">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="aminityCategoryDetails != null">{{aminityCategoryDetails.name}}</h2>
        </div>
        <div class="col-lg-8 col-md-12 text-right" *ngIf="aminityCategoryDetails != null">
            <a routerLink="../amenitycategory-edit" [queryParams]="{id: aminityCategoryDetails.id}" class="btn btn-secondary">Edit Amenity Category</a>
            <button type="button" (click)="delete(aminityCategoryDetails.id)" class="btn btn-secondary ml-2 my-2">Delete Amenity Category</button>
        </div>
    </div>  
    <div class="row">
        <div class="col-lg-12">
                <div class="card mb-4" >
                    <div class="card-header">
                        <h5>Amenity Category details</h5>
                    </div>
                    <div class="card-body"  *ngIf="aminityCategoryDetails != null && aminityCategoryDetails != undefined">
                            <div class="form-group row">
                                <div class="col-md-3 float-left">
                                    <label>Id</label>
                                </div>
                                <div class="col-md-3 float-left">
                                    <label>{{aminityCategoryDetails.id}}</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-3 float-left">
                                    <label>Name</label>
                                </div>
                                <div class="col-md-3 float-left">
                                    <label>{{aminityCategoryDetails.name}}</label>
                                </div>
                            </div>
                    </div>
                </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType] = "resourceType" [resourceId] = "resourceId"></app-comment>
        </div>
    </div>
</div>
