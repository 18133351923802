<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <h2 class=" mt-2 mb-4 booking-title">Bookings</h2>
        </div>
        <div class="col-md-9 booking-btns">
            <button class="btn btn-outline-secondary mt-2" (click)="bookingCreatedReport()">Bookings Created At
                {{currentYear}}
                Revenue Report</button>
            <button class="btn btn-outline-secondary mt-2" (click)="getMvpReport()"> MVP Report</button>
            <button class="btn btn-outline-secondary ml-1 mt-2" (click)="bookingStartingReport()">Bookings Starting On
                {{currentYear}}
                Revenue Report</button>
            <!-- <button class="btn btn-outline-secondary ml-1 mt-2 float-right" (click)="sendInsuranceReport()">Send
                Insurance Report</button> -->
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 filter-btns" *ngIf="bookingListDetails != undefined">
            <button class="btn btn-outline-secondary" (click)="all()">All({{bookingListDetails.allCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="confirmed()">Confirmed({{bookingListDetails.confirmedCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="pending()">Pending({{bookingListDetails.pendingCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="rejected()">Rejected({{bookingListDetails.rejectedCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="cancelled()">Cancelled({{bookingListDetails.cancelledCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="expired()">Expired({{bookingListDetails.expiredCount}})</button>
            <button class="btn btn-outline-secondary ml-1" (click)="paidOut()">Paid
                Out({{bookingListDetails.paidOutCount}})</button>
            <button class="btn btn-outline-secondary ml-1"
                (click)="incomplete()">Incomplete({{bookingListDetails.incompleteCount}})</button>

        </div>
        <div class="col-md-2 filter-btns">
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped" id="excel">
                    <thead>
                        <th>
                            <a (click)="toggleSort('Id')" style="cursor: pointer;">#</a>
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Boat Name
                        </th>
                        <th>
                            Boat Location
                        </th>
                        <th>
                            Boat Owner
                        </th>
                        <th>
                            Renter
                        </th>
                        <th>
                            Renter Ceritificate
                        </th>
                        <th>
                            <a (click)="toggleSort('StartsOn')" style="cursor: pointer;">From date</a>
                        </th>
                        <th>
                            <a (click)="toggleSort('EndsOn')" style="cursor: pointer;">To date</a>
                        </th>
                        <th>
                            <a (click)="toggleSort('status')" style="cursor: pointer;">Status</a>
                        </th>
                        <th>
                            <a (click)="toggleSort('CreatedAt')" style="cursor: pointer;">Created At</a>
                        </th>
                        <th>{{getTypeColumnName()}}

                        </th>
                        <th>
                            <a (click)="toggleSort('BookingPlatform')" style="cursor: pointer;">Platform</a>
                        </th>
                        <th>
                            Insurance Status
                        </th>
                        <th>
                            Action
                        </th>
                    </thead>
                    <tbody *ngIf="bookingListDetails != undefined  && bookingListDetails.totalEntries > 0">
                        <tr
                            *ngFor="let booking of bookingListDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:bookingListDetails.totalEntries}">
                            <td>
                                <a (click)="gotoBooking(booking.id)"
                                class="text-primary"
                                style="cursor: pointer;">{{booking.id}}</a>
                            </td>
                            <td>
                                {{booking.itemType}}
                            </td>
                            <td>
                                <a routerLink="../boat-view"
                                    [queryParams]="{id: booking.boatId,name:booking.boatName}">{{booking.boatName}}</a>
                            </td>
                            <td>
                                {{booking.boatLocationName}}
                            </td>
                            <td>
                                <a (click)="gotoUser(booking.boatOwnerFName,booking.boatOwnerLName,booking.boatOwnerId)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{booking.boatOwnerFName}}
                                    {{booking.boatOwnerLName}}</a>
                            </td>
                            <td>
                                <a (click)="gotoUser(booking.renterFName,booking.renterLName,booking.renterId)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{booking.renterFName}}
                                    {{booking.renterLName}}</a>
                            </td>
                            <td>
                                <p [style.backgroundColor]="booking.renterCertificateColor"
                                    style="width: fit-content;color: #ffffff;">{{booking.renterCertificate}}</p>
                            </td>
                            <td>
                                {{booking.startOn}}
                            </td>
                            <td>
                                {{booking.endsOn}}
                            </td>
                            <td>
                                <p [style.backgroundColor]="booking.statusColor"
                                    style="width: fit-content; color: #ffffff;">{{booking.status}}</p>
                            </td>
                            <td>
                                {{booking.createdAt}}
                            </td>
                            <td>
                                {{getDateAsPerType(booking)}}
                            </td>
                            <td>{{booking.bookingPlatform}}
                            </td>
                            <td>
                                <!-- <p [style.backgroundColor]="booking.insuranceColor"
                                    style="width: fit-content; color: #ffffff;">
                                    {{booking.insuranceType}}</p> -->
                                <p [style.backgroundColor]="booking.insuranceColor"
                                    style="width: fit-content; color: #ffffff;">{{booking.insuranceStatus}}</p>
                            </td>
                            <td>
                                <a routerLink="../booking-view" [queryParams]="{id: booking.id}"><button type="submit"
                                        title="Click to View" class="btn"><i class="bi bi-eye"></i></button></a>
                                <a routerLink="../booking-edit" [queryParams]="{id: booking.id}"><button type="submit"
                                        title="Click to Edit" class="btn"> <i
                                            class="bi bi-pencil-square"></i></button></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="bookingListDetails != undefined && bookingListDetails.totalEntries != undefined && bookingListDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">

            <div class="form-group row">
                <div class="col-md-4">
                    <label for="bookingId">BOOKING ID</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="bookingId" formControlName="bookingId" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="iStatus">INSURANCE STATUS</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="insuranceStatus" id="iStatus" class="form-control" ng-options="">
                        <option value="declined">Declined</option>
                        <option value="missing">Missing</option>
                        <option value="pending">Pending</option>
                        <option value="verified">Verified</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="bType">MANUAL PAYOUT</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="manualPayout" id="bType" class="form-control" ng-options="">
                        <option value="true">YES</option>
                        <option value="false">NO</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="country">COUNTRY</label>
                </div>
                <div class="col-md-8" *ngIf="countries != null && countries.length > 0">
                    <select formControlName="country" id="country" class="form-control" ng-options="">
                        <option *ngFor="let country of countries" value={{country.code}}>{{country.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="status">STATUS</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="status" id="status" class="form-control" ng-options="">
                        <option value="confirmed">Confirmed</option>
                        <option value="incomplete">Incomplete</option>
                        <option value="Expired">Expired</option>
                        <option value="cancelled_by_owner">Owner cancelled</option>
                        <option value="paid_out">Paid out</option>
                        <option value="pending">Pending</option>
                        <option value="rejected">Rejected</option>
                        <option value="cancelled_by_renter">Renter cancelled</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdFrom">CREATED FROM</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdFrom" formControlName="createdFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="createdTo">CREATED TO</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="createdTo" formControlName="createdTo" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="startOnFrom">START ON FROM</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="startOnFrom" formControlName="startOnFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="startOnTo">START ON TO</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="startOnTo" formControlName="startOnTo" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="endsOnFrom">ENDS ON FROM</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="endsOnFrom" formControlName="endsOnFrom" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="endsOnTo">ENDS ON TO</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="endsOnTo" formControlName="endsOnTo" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>