
<div class="container mt-2">
    <h2 class="`text-default">Update Credit</h2>
    <form [formGroup]="frmCredit" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header">
            Credit Change Details
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-2">
                <label for="credits">Credits</label>
            </div>
            <div class="col-md-10">
              <input type="number" class="form-control" id="credits" formControlName="credit" required>
              <div *ngIf="frmCredit.controls['credit'].invalid && frmCredit.controls['credit'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="frmCredit.controls['credit'].hasError('required')">
                    Please enter credits.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-2">
                <label for="description">Description</label>
            </div>
            <div class="col-md-10">
              <input type="text" class="form-control" id="description" formControlName="description" required>
              <div *ngIf="frmCredit.controls['description'].invalid && frmCredit.controls['description'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="frmCredit.controls['description'].hasError('required')">
                    Please enter description.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row m-3">
      <button type="submit" [disabled]="!frmCredit.valid" class="btn btn-primary">Update User Credit        
      </button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
    </form>
  </div>
  
  
  

