<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Events</h2>
    <div class="row mb-3">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <tr class="text-danger text-center text-bold">
                        This is not implemented yet.
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>