<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Boat library</h2>
    <div class="row row-y">
        <div class="col-md-5">
            <div ngbDropdown class="d-inline-block pt-2 pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deletePhotos()">Delete selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <button class="btn btn-secondary float-right filter-btn" (click)="open(mymodal)">Filters</button>

            <a routerLink="../library-new">
                <button type="button" class="btn btn-secondary float-right new-btn">Add new</button>
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked"
                                        (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('ImageUrl')" style="cursor: pointer;">Image</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('BoatModel')" style="cursor: pointer;">Boat Model</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('BoatType')" style="cursor: pointer;">Boat Type</a>
                            </th>
                            <th scope="col" class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="boatLibraryDetails != undefined  && boatLibraryDetails.totalEntries > 0">
                        <tr
                            *ngFor="let library of boatLibraryDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:boatLibraryDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" value="{{library.id}}" [(ngModel)]="library.selected"
                                        (change)="checkboxChange($event,library.id)">
                                </div>
                            </td>
                            <td>
                                <a routerLink="../library-view" [queryParams]="{id: library.id}">{{library.id}}</a>
                            </td>
                            <td>
                                <img src="{{library.imageUrl}}" title="Click on image to get image url."
                                    (click)="copy(library.imageUrl)" height="40px" width="40px" alt="icon"
                                    style="border-radius: 20px;">
                            </td>
                            <td>
                                {{library.boatModel}}
                            </td>
                            <td>
                                {{library.boatType}}
                            </td>
                            <td>
                                <a routerLink="../library-view" [queryParams]="{id: library.id}">
                                    <button type="submit" title="Click to View" class="btn"><i
                                            class="bi bi-eye"></i></button>
                                </a>
                                <a routerLink="../library-edit" [queryParams]="{id: library.id}">
                                    <button type="submit" title="Click to Edit" class="btn"><i
                                            class="bi bi-pencil-square"></i></button>
                                </a>
                                <button type="submit" title="Click to Delete" (click)="onDelete(library.id)"
                                    class="btn"><i class="bi bi-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-6">
            <div
                *ngIf="boatLibraryDetails != undefined && boatLibraryDetails.totalEntries != undefined && boatLibraryDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
    <ng-template #mymodal let-modal>
        <div class="modal-header bg-dark">
            <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body bg-light p-4">
            <form [formGroup]="frmFilter" id="filter">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="model">Boat model</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" formControlName="boatModel" class="form-control" id="model">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="type">Boat type</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" formControlName="boatType" class="form-control" id="type">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label for="image">Image url</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" formControlName="imageUrl" class="form-control" id="image">
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer" style="justify-content:center;">
            <button type="button" class="btn btn-outline-dark" (click)="onFilter()">Filter</button>
            <button type="button" class="btn btn-outline-secondary" (click)="reset()">Clear Filters</button>
        </div>
    </ng-template>