import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PopularDestinationService } from 'src/app/services/popular-destination/popular-destination.service';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-popular-destination-new',
  templateUrl: './popular-destination-new.component.html',
  styleUrls: ['./popular-destination-new.component.css']
})
export class PopularDestinationNewComponent implements OnInit {
  formPopularDestination: FormGroup;
  fileUrl: string = "";

  constructor(private popularDestinationService: PopularDestinationService, private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.formPopularDestination = this.fb.group({
      name: ['', [Validators.required]],
      latitude: ['', [Validators.required]],
      longitude: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {  
  }

  reset() {
    this.router.navigate(["admin/popular-destinations"]);
  }

  submit() {
    if (this.formPopularDestination.valid) {
      var popularDestinationRequest = {
        name: this.formPopularDestination.controls["name"].value,
        latitude : this.formPopularDestination.controls["latitude"].value,
        longitude : this.formPopularDestination.controls["longitude"].value,
        iconUrl: this.fileUrl
      };
      this.popularDestinationService.SavePopularDestination(popularDestinationRequest)
        .subscribe(
          res => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/popular-destinations']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml','image/*'],
      onUploadDone: (file: { filesUploaded: { url: string; }[]; }) => this.fileUrl = file.filesUploaded[0].url
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

}
