import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RuleService } from 'src/app/services/rule/rule.service';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-rule-new',
  templateUrl: './rule-new.component.html',
  styleUrls: ['./rule-new.component.css']
})

export class RuleNewComponent implements OnInit {
  formRule: FormGroup;
  fileUrl: string = "";
  fileType: string = "";
  currentUserId: number = 0;

  constructor(private ruleService: RuleService, private authService: LoginService, private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.formRule = this.fb.group({
      rule: ['', [Validators.required]],
      isCustom: ['', [Validators.required]],
    });
  }


  ngOnInit(): void {
    this.currentUserId = this.authService.Id;
  }

  reset() {
    this.router.navigate(["admin/rules"]);
  }

  submit() {
    if (this.formRule.valid) {
      var ruleRequest = {
        rule: this.formRule.controls["rule"].value,
        isCustom: this.formRule.controls["isCustom"].value ? this.formRule.controls["isCustom"].value == "Yes" ? true : false : false,
        iconUrl: this.fileUrl,
        fileType: this.fileType,
        createdBy: this.currentUserId,
        updatedBy: this.currentUserId
      };
      this.ruleService.SaveRule(ruleRequest)
        .subscribe(
          res => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/rules']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string; }[]; }) => (this.fileUrl = file.filesUploaded[0].url, this.fileType = file.filesUploaded[0].mimetype)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

}
