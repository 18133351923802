import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BookingService } from 'src/app/services/booking.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-bookings',
  templateUrl: './user-bookings.component.html',
  styleUrls: ['./user-bookings.component.css']
})
export class UserBookingsComponent implements OnInit {
  @Input() name;
  @Input() isRenter;
  heading: string = "";
  userBookings: any;
  id: number = 0;

  constructor(private route: ActivatedRoute, private bookingService: BookingService, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    if (this.isRenter) {
      this.heading = this.name + "'s Bookings as Renter"
      this.getData('renter');
    }
    else {
      this.heading = this.name + "'s Bookings as Owner"
      this.getData('owner');
    }
  }

  getData(type: string) {
    this.bookingService.fetchRenterBookings(this.id, type).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.userBookings = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }
}
