import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';

@Injectable({
  providedIn: 'root'
})

export class ReportService extends BaseService {
  httpOptions: any; httpOptionsEdit;

  constructor(private authService: LoginService, private http: HttpClient, private configService: ConfigService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
    this.httpOptionsEdit = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      }), responseType: 'json'
    };
  }

  fetchBoatStatistics() {
    return this.http.get(this.configService.resourceApiURI + 'report/boat-statistics', this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  fetchReviewByWeek(displayYear: string) {
    return this.http.get(this.configService.resourceApiURI + 'report/review-by-week?displayYear=' + displayYear, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  fetchGMVReport(year: string) {
    return this.http.get(this.configService.resourceApiURI + 'report/gmv-data?year=' + year, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  fetchDIWReport(year: string) {
    return this.http.get(this.configService.resourceApiURI + 'report/diw-data?year=' + year, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  fetchYearlyBooking(years: string) {
    return this.http.get(this.configService.resourceApiURI + 'report/yearly-bookings-data?years=' + years, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  fetchBookingStatus(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'report/yearly-bookings-status', data, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  fetchUserBoatStats() {
    return this.http.get(this.configService.resourceApiURI + 'report/user-data', this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

}
