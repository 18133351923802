import { Component, OnInit } from '@angular/core';
import { AmenitiesService } from 'src/app/services/amenities/amenities.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BmAmenityService } from 'src/app/services/bm-amenity.service';

@Component({
  selector: 'app-amenitiesview',
  templateUrl: './amenitiesview.component.html',
  styleUrls: ['./amenitiesview.component.css']
})
export class AmenitiesviewComponent implements OnInit {
  aminityViewDetails: any;
  id: number = 0;
  public resourceType: string = "";
  public resourceId: string = "";


  constructor(private activatedRoute: ActivatedRoute,
    // private amenityService: AmenitiesService,
    private amenityService: BmAmenityService,
    private router: Router, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.resourceId = this.id.toString();
    this.resourceType = "Amenity";

    this.amenityService.fetchAmenity(this.id).subscribe(
      response => {
        this.aminityViewDetails = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  delete(id: number) {
    var isDelete = confirm("are you sure want to delete selected users?");
    if (isDelete) {
      this.amenityService.AmenitiesDelete([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.router.navigate(["admin/amenities"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }
}
