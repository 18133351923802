import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searches-by-destination',
  templateUrl: './searches-by-destination.component.html',
  styleUrls: ['./searches-by-destination.component.css']
})
export class SearchesByDestinationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
