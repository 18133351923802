<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="boatLibraryDetails != null">{{boatLibraryDetails.boatModel}}</h2>
        </div>
        <div class="col-lg-6 col-md-12 text-right" *ngIf="boatLibraryDetails != null">
            <a routerLink="../library-edit" [queryParams]="{id: boatLibraryDetails.id}" class="btn btn-secondary">Edit
                Library</a>
            <button type="button" (click)="delete(boatLibraryDetails.id)" class="btn btn-secondary m-2">Delete
                Library</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="boatLibraryDetails != undefined && boatLibraryDetails != null">
                <div class="card-header">
                    <h5>Library Photo Details</h5>
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Id</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{boatLibraryDetails.id}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Boat model</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{boatLibraryDetails.boatModel}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Boat type</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{boatLibraryDetails.boatType}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Image</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <img src="{{boatLibraryDetails.imageUrl}}" (click)="copy(boatLibraryDetails.imageUrl)"
                                title="Click on image to copy image url." height="70px" width="70px" alt="icon"
                                style="border-radius: 20px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>