import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { BaseService } from '../shared/base.service';
import { ConfigService } from '../shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReferralService extends BaseService {
  httpOptions; httpOptionsEdit;
  public email: string = "";
  constructor(private authService: LoginService, private http: HttpClient, private configService: ConfigService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
    this.httpOptionsEdit = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      }), responseType: 'text'
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }


  fetchInvitedByUsers() {
    return this.http.get(this.configService.resourceApiURI + 'user/invitedby-users', this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchRefferal(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'refferal?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchRefferalEditDetails(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'refferal/edit?refId=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchUserRefferal(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'refferal/invitedby?id=' + id, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchRefferals(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'refferal', data, this.httpOptions).pipe(catchError(this.handleError));
  }
  addRefferals(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'refferal/new', data, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  updateRefferals(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'refferal/update', data, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
  deleteRefferal(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'refferal', data, this.httpOptions).pipe(catchError(this.handleError));
  }
  fetchReferralsDataForExport() {
    return this.http.get(this.configService.resourceApiURI + 'refferal/referrals-data-export', this.httpOptions).pipe(catchError(this.handleError));
  }

}
