<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Reviews by Week</h2>
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <form [formGroup]="frmFilter" id="filter">
                <div class="form-group row">
                    <div class="col-md-3">
                        <label for="displayYear">Display Year :</label>
                    </div>
                    <div class="col-md-5">
                        <select formControlName="displayYear" id="displayYear" class="form-control" ng-options="">
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <button type="submit" class="btn btn-outline-dark" (click)="submit()">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-5 col-md-12">
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Week Start</th>
                            <th scope="col">Week End</th>
                            <th scope="col">Reviews</th>
                        </tr>
                    </thead>
                    <tbody
                        *ngIf="reviewByWeekDetails!= null && reviewByWeekDetails != undefined && reviewByWeekDetails.length > 0 else noRecord ">
                        <tr *ngFor="let reviewByWeekDetail of reviewByWeekDetails">
                            <td>
                                {{reviewByWeekDetail.weekStartDate}}
                            </td>
                            <td>
                                {{reviewByWeekDetail.weekEndDate}}
                            </td>
                            <td>
                                {{reviewByWeekDetail.reviewCount}}
                            </td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>