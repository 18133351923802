import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BookingService } from 'src/app/services/booking.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-boat-bookings',
  templateUrl: './boat-bookings.component.html',
  styleUrls: ['./boat-bookings.component.css']
})
export class BoatBookingsComponent implements OnInit {
  @Input() boatName;
  id: number = 0;
  boatBookings: any;
  
  constructor(private route: ActivatedRoute,
    private userService: UsersService, private bookingService: BookingService,
    private router: Router, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.bookingService.fetchBoatBookings(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.boatBookings = res;
      },
      error => {
        this.tostr.error("Couldn't load details.");
        console.log(error);
      }
    );
  }
  gotoUser(name: string, fname: string, id: number) {
    this.userService.fName = name;
    this.userService.fullName = fname;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }
}
