<div class="container-fluid">
    <div class="row">
        <div class="col-md-4 d-inline-block pt-2 pb-2">
            <h2 class=" mt-2 mb-4">Conversation #{{id}}</h2>
        </div>
        <div class="col-md-8 text-right pt-2 pb-2">
            <a routerLink="../conversation-edit" [queryParams]="{id:id}" class="btn btn-secondary ml-2">Edit
                Conversation</a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Conversation Info</h5>
                </div>
                <div class="card-body table-responsive">
                    <table class="table table-striped" *ngIf="conversation != null && conversation != undefined">
                        <tr>
                            <td class="bt-none">ID</td>
                            <td class="bt-none">{{conversation.id}}</td>
                        </tr>
                        <tr>
                            <td>GUEST</td>
                            <td>
                                <a (click)="gotoUser(conversation.guestFName,conversation.guestLName,conversation.guestId)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{conversation.guestFName}}
                                    {{conversation.guestLName}}</a>
                            </td>
                        </tr>
                        <tr>
                            <td>HOST</td>
                            <td>
                                <div *ngIf="conversation.hostId > 0">
                                    <a (click)="gotoUser(conversation.hostFName,conversation.hostLName,conversation.hostId)"
                                        class="text-primary"
                                        style="text-decoration: underline;cursor: pointer;">{{conversation.hostFName}}
                                        {{conversation.hostLName}}</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>ITEM</td>
                            <td>
                                <div *ngIf="conversation.boatId > 0">
                                    <a routerLink="../boat-view"
                                        [queryParams]="{id: conversation.boatId,name:conversation.boatName}"
                                        class="text-primary"
                                        style="text-decoration: underline;">{{conversation.boatName}}
                                        {{conversation.boatModel}}</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>BOOKING</td>
                            <td>
                                <div *ngIf="conversation.bookingId > 0">
                                    <a routerLink="../booking-view" [queryParams]="{id: conversation.bookingId}"
                                        class="text-primary" style="text-decoration: underline;">Booking
                                        #{{conversation.bookingId}}</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>CREATED AT</td>
                            <td> {{conversation.createdAt}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Follow-Up Info</h5>
                </div>
                <div class="card-body table-responsive">
                    <table class="table table-striped w-58" *ngIf="conversation != null && conversation != undefined">
                        <tr>
                            <td class="bt-none">FOLLOW UP TASK</td>
                            <td class="bt-none">{{conversation.followUpTask}}</td>
                        </tr>
                        <tr>
                            <td>FOLLOW UP DATE</td>
                            <td>
                                {{conversation.followUpDate}}
                            </td>
                        </tr>
                        <tr>
                            <td>ASSIGNEE</td>
                            <td>
                                {{conversation.assignee}}
                            </td>
                        </tr>
                        <tr>
                            <td>FOLLOW UP STATUS</td>
                            <td>
                                <p [style.backgroundColor]="conversation.statusColor"
                                    style="color: #ffffff;width: fit-content;">{{conversation.followUpStatus}}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>CLOSURE REASON</td>
                            <td>
                                {{conversation.closureReason}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Messages</h5>
                </div>
                <div class="card-body table-responsive">
                    <table class="table table-striped" *ngIf="conversation != null && conversation != undefined">
                        <thead>
                            <th>ID</th>
                            <th>CREATED AT</th>
                            <th>AUTHOR</th>
                            <th>RECEPIENT</th>
                            <th>BODY</th>
                        </thead>
                        <tbody *ngIf="conversation.messages != null && conversation.messages.length > 0">
                            <tr *ngFor="let msg of conversation.messages">
                                <td>{{msg.messageId}}</td>
                                <td>{{msg.createdAt}}</td>
                                <td>
                                    <a (click)="gotoUser(msg.authorFName,msg.authorLName,msg.authorId)"
                                        class="text-primary"
                                        style="text-decoration: underline;cursor: pointer;">{{msg.authorFName}}
                                        {{msg.authorLName}}</a>
                                </td>
                                <td>
                                    <a (click)="gotoUser(msg.recipientFName,msg.recipientLName,msg.recipientId)"
                                        class="text-primary"
                                        style="text-decoration: underline;cursor: pointer;">{{msg.recipientFName}}
                                        {{msg.recipientLName}}</a>
                                </td>
                                <td>{{msg.body}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>