<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>{{boatName}}'s Bookings</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th>#</th>
                        <th>RENTER</th>
                        <th>FROM DATE</th>
                        <th>TO DATE</th>
                        <th>STATUS</th>
                        <th>CREATED AT</th>
                    </thead>
                    <tbody
                        *ngIf="boatBookings!= null && boatBookings != undefined && boatBookings.length > 0 else noRecord ">
                        <tr *ngFor="let boatBooking of boatBookings">
                            <td><a routerLink="../booking-view"
                                    [queryParams]="{id: boatBooking.bookingId}">{{boatBooking.bookingId}}</a></td>
                            <td><a (click)="gotoUser(boatBooking.renterName, boatBooking.renterFullName, boatBooking.renterId)"
                                    class="text-primary" style="text-decoration: underline;cursor: pointer;">
                                    {{boatBooking.renterFullName}}</a></td>
                            <td>{{boatBooking.fromDate}}</td>
                            <td>{{boatBooking.toDate}}</td>
                            <td>{{boatBooking.status}}</td>
                            <td>{{boatBooking.createdAt}}</td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>