import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-bm-view',
  templateUrl: './boat-bm-view.component.html',
  styleUrls: ['./boat-bm-view.component.css']
})
export class BoatBmViewComponent implements OnInit {
  @Input() boatName;
  id: number = 0;
  @Input() boatDetails: any;

  constructor() { }

  ngOnInit(): void {
  }

}
