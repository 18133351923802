import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';

@Injectable({
  providedIn: 'root'
})
export class PopularDestinationService  extends BaseService{

  httpOptions: any;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
  }

  fetchPopularDestinations(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'populardestinations', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  fetchPopularDestination(id: number) {
    return this.http.get(this.configService.resourceApiURI + 'populardestinations/' + id, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  SavePopularDestination(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'populardestinations/save', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  EditPopularDestination(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'populardestinations/edit', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  DeletePopularDestination(ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'populardestinations/remove', options).pipe(
      catchError(this.handleError)
    );
  }
  
  fetchPopularDestinationsDataForExport() {
    return this.http.get(this.configService.resourceApiURI + 'populardestinations/popular-destinations-data-export', this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  

}
