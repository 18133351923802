<div class="row">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Basic info</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-striped fix-width"
                            *ngIf="boatDetails != null && boatDetails != undefined">
                            <tr>
                                <td class="bt-none">ID</td>
                                <td class="bt-none">{{boatDetails.id}}</td>
                            </tr>
                            <tr *ngIf="boatDetails.bookingManagerId != null && boatDetails.bookingManagerId != ''">
                                <td>BOOKING MANAGER ID</td>
                                <td> <a *ngIf="boatDetails.bookingManagerId != null && boatDetails.bookingManagerId != '' && boatDetails.bookingManagerCompanyId != null && boatDetails.stripbookingManagerCompanyIdePayoutId != ''"
                                        href="https://portal.booking-manager.com/wbm2/page.html?view=YachtDetails&companyid={{boatDetails.bookingManagerCompanyId}}&yachtId={{boatDetails.bookingManagerId}}&addMargins=true&setlang=en">
                                        {{boatDetails.bookingManagerId}}</a></td>
                            </tr>
                            <tr>
                                <td>OWNER</td>
                                <td><a (click)="gotoUser(boatDetails.ownerFName,boatDetails.ownerLName,boatDetails.ownerId)"
                                        class="text-primary"
                                        style="text-decoration: underline;cursor: pointer;">{{boatDetails.ownerFName}}
                                        {{boatDetails.ownerName}}</a></td>
                            </tr>
                            <tr>
                                <td>NAME</td>
                                <td>{{boatDetails.name}}</td>
                            </tr>
                            <tr>
                                <td>DESCRIPTION</td>
                                <td>{{boatDetails.description}}</td>
                            </tr>
                            <tr>
                                <td>BOAT TYPE</td>
                                <td>{{boatDetails.boatType}}</td>
                            </tr>
                            <tr>
                                <td>BOAT MODEL</td>
                                <td>{{boatDetails.boatModel}}</td>
                            </tr>
                            <tr>
                                <td>CUSTOM MADE</td>
                                <td>
                                    <p *ngIf="boatDetails.customMade!= '' && boatDetails.customMade == 'YES'"
                                        style="background-color: #6090DB;width: fit-content;">{{boatDetails.customMade}}
                                    </p>
                                    <p *ngIf="boatDetails.customMade!= '' && boatDetails.customMade == 'NO'"
                                        style="background-color: grey;width: fit-content;">{{boatDetails.customMade}}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>LOCATION NAME</td>
                                <td>{{boatDetails.locationName}}</td>
                            </tr>
                            <tr>
                                <td>COUNTRY CODE</td>
                                <td>{{boatDetails.countryCode}}</td>
                            </tr>
                            <tr>
                                <td>LENGTH</td>
                                <td>
                                    {{boatDetails.length}}
                                </td>
                            </tr>
                            <tr>
                                <td>CAPACITY</td>
                                <td>
                                    {{boatDetails.capacity}}
                                </td>
                            </tr>
                            <tr>
                                <td>BUILD YEAR</td>
                                <td>{{boatDetails.buildYear}}</td>
                            </tr>
                            <tr>
                                <td>HULL MATERIAL</td>
                                <td>{{boatDetails.hullMaterial}}</td>
                            </tr>
                            <tr>
                                <td>BEDS</td>
                                <td>
                                    {{boatDetails.beds}}
                                </td>
                            </tr>
                            <tr>
                                <td>CABINS</td>
                                <td>
                                    {{boatDetails.cabins}}
                                </td>
                            </tr>
                            <tr>
                                <td>PUBLISHED AT</td>
                                <td>
                                    {{boatDetails.publishedAt}}
                                </td>
                            </tr>
                            <tr>
                                <td>UNPUBLISHED AT</td>
                                <td>{{boatDetails.unpublishedAt}}</td>
                            </tr>
                            <tr>
                                <td>VIEWS</td>
                                <td>{{boatDetails.views}}</td>
                            </tr>
                            <tr>
                                <td>CREATED AT</td>
                                <td>{{boatDetails.createdAt}}</td>
                            </tr>
                            <tr>
                                <td>UPDATED AT</td>
                                <td>{{boatDetails.updatedAt}}</td>
                            </tr>
                            <tr>
                                <td>DELETED AT</td>
                                <td>{{boatDetails.deletedAt}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Trip Types</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="fix-width table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Sub title</th>
                                    <th scope="col">Icon</th>
                                </tr>
                            </thead>
                            <tbody
                                *ngIf="boatDetails != null && boatDetails != undefined && boatDetails.tripTypes != null && boatDetails.tripTypes.length > 0 else noRecord ">
                                <tr *ngFor="let tripType of boatDetails.tripTypes">
                                    <td>
                                        {{tripType.id}}
                                    </td>
                                    <td>
                                        {{tripType.title}}
                                    </td>
                                    <td>
                                        {{tripType.subTitle}}
                                    </td>
                                    <td>
                                        <img src="{{tripType.iconUrl}}" height="40px" width="40px" alt="icon"
                                            style="border-radius: 20px;">
                                    </td>
                                </tr>
                            </tbody>
                            <ng-template #noRecord>
                                <p>No records found.</p>
                            </ng-template>
                        </table>
                        <table class="fix-width table table-striped"
                            *ngIf="boatDetails != null && boatDetails != undefined && boatDetails.tripTypes != null">

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Engine Info</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="fix-width table table-striped"
                            *ngIf="boatDetails != null && boatDetails != undefined">
                            <tr>
                                <td class="bt-none">ENGINE NUMBER</td>
                                <td class="bt-none">{{boatDetails.engineNumber}}</td>
                            </tr>
                            <tr>
                                <td>ENGINE TYPE</td>
                                <td>{{boatDetails.engineType}}</td>
                            </tr>
                            <tr>
                                <td>ENGINE MANUFACTURER</td>
                                <td>{{boatDetails.engineManufecturer}}</td>
                            </tr>
                            <tr>
                                <td>TOP SPEED</td>
                                <td> {{boatDetails.topSpeed}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Legal Info</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="fix-width table table-striped"
                            *ngIf="boatDetails != null && boatDetails != undefined">
                            <tr>
                                <td class="bt-none">OWNERSHIP TYPE</td>
                                <td class="bt-none">{{boatDetails.ownershipType}}</td>
                            </tr>
                            <tr>
                                <td>INSURANCE TYPE</td>
                                <td>{{boatDetails.insuranceType}}</td>
                            </tr>
                            <tr>
                                <td>INSURANCE STATUS</td>
                                <td>{{boatDetails.insuranceStatus}}</td>
                            </tr>
                            <tr>
                                <td>MINIMUM DEPOSIT</td>
                                <td>{{boatDetails.minimumDeposit}}</td>
                            </tr>
                            <tr>
                                <td>THIRD PARTY RIGHTS</td>
                                <td>{{boatDetails.thirdPartyRights}}</td>
                            </tr>
                            <tr>
                                <td>VALUE</td>
                                <td>{{boatDetails.value}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Rental Info</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="fix-width table table-striped"
                            *ngIf="boatDetails != null && boatDetails != undefined">
                            <tr>
                                <td class="bt-none">CURRENCY</td>
                                <td class="bt-none">
                                    <p [style.backgroundColor]="boatDetails.followUpStatusColour">
                                        {{boatDetails.currency}}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>MINIMUM RENTAL DAYS</td>
                                <td>{{boatDetails.minimumRentalDays}}</td>
                            </tr>
                            <tr>
                                <td>RENTAL TYPES</td>
                                <td>{{boatDetails.rentalTypes}}</td>
                            </tr>
                            <tr>
                                <td>CAPTAIN PRICE</td>
                                <td> {{boatDetails.captainPrice}}</td>
                            </tr>
                            <tr>
                                <td>CREW AVAILABILITY</td>
                                <td> {{boatDetails.crewAvailablity}}</td>
                            </tr>
                            <tr>
                                <td>CREW PRICE</td>
                                <td> {{boatDetails.crewPrice}}</td>
                            </tr>
                            <tr>
                                <td>CREW DESCRIPTION</td>
                                <td> {{boatDetails.crewDescription}}</td>
                            </tr>
                            <tr>
                                <td>CANCELLATION POLICY</td>
                                <td> {{boatDetails.cancellationPolicy}}</td>
                            </tr>
                            <tr>
                                <td>RENTAL LIMITATIONS</td>
                                <td> {{boatDetails.rentalLimitations}}</td>
                            </tr>
                            <tr>
                                <td>EARLY BOOKING PERIOD (MONTHS)</td>
                                <td> {{boatDetails.earlyBookingPeriod}}</td>
                            </tr>
                            <tr>
                                <td>EARLY BOOKING DISCOUNT (%)</td>
                                <td> {{boatDetails.earlyBookingDiscount}} %</td>
                            </tr>
                            <tr>
                                <td>WEEKLY DISCOUNT (%)</td>
                                <td> {{boatDetails.weeklyDiscount}} %</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Guest Requirements</h5>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="fix-width table table-striped"
                            *ngIf="boatDetails != null && boatDetails != undefined">
                            <tr>
                                <td class="bt-none">AGE</td>
                                <td class="bt-none">{{boatDetails.guestRequirement.age}}</td>
                            </tr>
                            <tr>
                                <td>BOAT EXPERIENCE</td>
                                <td>{{boatDetails.guestRequirement.boatExperience}}</td>
                            </tr>
                            <tr>
                                <td>BOOKING PERIOD</td>
                                <td>{{boatDetails.guestRequirement.bookingPeriod}}</td>
                            </tr>
                            <tr>
                                <td>MINIMUM GUEST RATING</td>
                                <td>{{boatDetails.guestRequirement.minimumGuestRating}}</td>
                            </tr>
                            <tr>
                                <td>REQUIREMENTS</td>
                                <td>{{boatDetails.guestRequirement.requirements}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>