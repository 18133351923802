import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-review-by-week',
  templateUrl: './review-by-week.component.html',
  styleUrls: ['./review-by-week.component.css']
})
export class ReviewByWeekComponent implements OnInit {
  frmFilter: FormGroup;
  reviewByWeekDetails: any;
  displayYear: string = new Date().getFullYear().toString() ;

  constructor(private fb: FormBuilder, private tostr: ToastrService, private reportService: ReportService) {
    this.frmFilter = this.fb.group({
      displayYear: [this.displayYear]
    });
  }

  ngOnInit(): void {
    this.getReviwByWeek();
  }

  getReviwByWeek() {
    this.displayYear = this.frmFilter.controls["displayYear"].value
    this.reportService.fetchReviewByWeek(this.displayYear)
      .subscribe(
        response => {
          this.reviewByWeekDetails = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  submit() {
    this.getReviwByWeek();
   }
}
