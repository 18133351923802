import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BoatService } from 'src/app/services/boat.service';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-boat-price',
  templateUrl: './boat-price.component.html',
  styleUrls: ['./boat-price.component.css']
})
export class BoatPriceComponent implements OnInit {
  @Input() boatName;
  id: number = 0;
  prices: any;
  @Input() boatDetails: any;

  constructor(private boatService: BoatService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.boatName = this.boatDetails.name;
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.boatService.fetchBoatPrice(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.prices = res;
        console.log(this.prices)
      },
      error => {
        this.toastr.error("Couldn't load details.");
        console.log(error);
      }
    );
  }

}
