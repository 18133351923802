import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BoatLibraryService } from 'src/app/services/boat-library.service';
import { environment } from 'src/environments/environment';
import * as filestack from 'filestack-js';

@Component({
  selector: 'app-library-new',
  templateUrl: './library-new.component.html',
  styleUrls: ['./library-new.component.css']
})
export class LibraryNewComponent implements OnInit {
  frmPhoto: FormGroup;
  fileUrl: string = "";
  boatLibraryDetails: any;
  constructor(private fb: FormBuilder, private router: Router, private tostr: ToastrService,
    private boatLibraryService: BoatLibraryService) {
    this.frmPhoto = this.fb.group({
      model: ['', [Validators.required]],
      type: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {

  }

  reset() {
    this.router.navigate(["admin/library"]);
  }

  submit() {
    if (this.frmPhoto.valid) {
      var boatLibraryRequest = {
        boatType: this.frmPhoto.controls["type"].value,
        boatModel: this.frmPhoto.controls["model"].value,
        imageUrl: this.fileUrl
      };
      this.boatLibraryService.createBoatLibrary(boatLibraryRequest)
        .subscribe(
          res => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/library']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string }[]; }) => (this.fileUrl = file.filesUploaded[0].url)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }
}
