<div class="container mt-2">
    <h2 class="`text-default">Edit Referral</h2>
    <form [formGroup]="frmEditReferral" (ngSubmit)="onSubmit()">
        <div class="card">
            <div class="card-header">
                Referral details
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="email">Email</label>
                    </div>
                    <div class="col-md-10">
                        <input type="email" class="form-control" id="email" formControlName="email" required>
                        <div *ngIf="frmEditReferral.controls['email'].invalid && frmEditReferral.controls['email'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmEditReferral.controls['email'].hasError('required')">
                                Please enter email.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="first">First name</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" id="first" formControlName="firstName" required>
                        <div *ngIf="frmEditReferral.controls['firstName'].invalid && frmEditReferral.controls['firstName'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmEditReferral.controls['firstName'].hasError('required')">
                                Please enter first name.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="last">Last name</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" id="last" formControlName="lastName" required>
                        <div *ngIf="frmEditReferral.controls['lastName'].invalid && frmEditReferral.controls['lastName'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmEditReferral.controls['lastName'].hasError('required')">
                                Please enter last name.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="invited">Invited by</label>
                    </div>
                    <div class="col-md-10">
                        <mat-select formControlName="invitedBy" class="form-control"
                            [value]="referralDetail.invitedById">
                            <cdk-virtual-scroll-viewport [itemSize]="5" [style.height.px]=5*40>
                                <input (keyup)="onKey($event)" class="form-control">
                                <mat-option *cdkVirtualFor="let user of userList" [value]="user.userId">
                                    {{user.fullName}}
                                </mat-option>
                            </cdk-virtual-scroll-viewport>
                        </mat-select>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <button type="submit" [disabled]="!frmEditReferral.valid" class="btn btn-primary">Update
                        Referral</button>
                    <button type="reset" (click)="onReset()" class="btn btn-secondary ml-2">Cancel</button>
                </div>
            </div>
        </div>
    </form>
</div>