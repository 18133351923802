import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AmenitiesService } from 'src/app/services/amenities/amenities.service';
import { AmenitycategoriesService } from 'src/app/services/amenitycategories/amenitycategories.service';
import * as filestack from 'filestack-js';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BmAmenityService } from 'src/app/services/bm-amenity.service';

@Component({
  selector: 'app-amenity-new',
  templateUrl: './amenity-new.component.html',
  styleUrls: ['./amenity-new.component.css']
})
export class AmenityNewComponent implements OnInit {
  frmAmenity: FormGroup;
  amenitiesCategories: any;
  fileUrl: string = "";
  activeIconUrl: string = "";
  iconFileType: string = "";
  activeIconFileType: string = "";
  greenIconUrl: string = "";
  greenIconFileType: string = "";

  constructor(
    //private amenityCategoriesService: AmenitycategoriesService,
    // private amenityService: AmenitiesService,
    private amenityService: BmAmenityService,
    private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.frmAmenity = this.fb.group({
      category: [0, [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.amenitycategories();
  }

  amenitycategories() {
    this.amenityService.fetchAmenitiesCategories()
      .subscribe(
        response => {
          this.amenitiesCategories = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  reset() {
    this.router.navigate(["admin/amenities"]);
  }

  submit() {
    if (this.frmAmenity.valid) {
      var amenityRequest = {
        id: 0,
        categoryId: this.frmAmenity.controls["category"].value,
        name: this.frmAmenity.controls["name"].value,
        bookingManager: false,
        iconUrl: this.fileUrl,
        activeIconUrl: this.activeIconUrl,
        iconFileType: this.iconFileType,
        activeIconFileType: this.activeIconFileType,
        greenIconUrl: this.greenIconUrl,
        greenIconFileType: this.greenIconFileType
      };
      this.amenityService.SaveAmenity(amenityRequest)
        .subscribe(
          res => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/amenities']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }

  onClickUploadFile() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string }[]; }) => (this.fileUrl = file.filesUploaded[0].url, this.iconFileType = file.filesUploaded[0].mimetype)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

  onClickUploadActiveIcon() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string }[]; }) => (this.activeIconUrl = file.filesUploaded[0].url, this.activeIconFileType = file.filesUploaded[0].mimetype)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

  onClickUploadGreenIcon() {
    const setUpFilepicker = {
      accept: ['image/svg+xml', 'image/*'],
      onUploadDone: (file: { filesUploaded: { url: string, mimetype: string }[]; }) => (this.greenIconUrl = file.filesUploaded[0].url, this.greenIconFileType = file.filesUploaded[0].mimetype)
    };
    filestack.init(environment.fileStackKey).picker(setUpFilepicker).open();
  }

}
