import { Component, OnInit, OnDestroy } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BookingBroadcastService } from 'src/app/services/booking-broadcast/booking-broadcast.service';

@Component({
  selector: 'app-booking-broadcasts',
  templateUrl: './booking-broadcasts.component.html',
  styleUrls: ['./booking-broadcasts.component.css']
})

export class BookingBroadcastsComponent implements OnInit, OnDestroy {
  bookingBroadcastsDetails: any;
  isDisableBatch: boolean = true;
  isGlobalChecked: any;
  checkboxCheckedList: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  includeAll: boolean = true;
  frmFilter: FormGroup;
  orderColumn: string = "Id";
  orderDirection: string = "desc";
  navigationSubscription: any;
  public exportFileName: string = "BookingBroadcasts";

  constructor(private modalService: NgbModal, private bookingBroadcastService: BookingBroadcastService,
    private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.frmFilter = this.fb.group({
      location: [''],
      bookingType: [''],
      boatType: [''],
      createdFrom: [''],
      createdTo: [''],
      fromDateFrom: [''],
      fromDateTo: [''],
      toDateFrom: [''],
      toDateTo: ['']
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.clearFilter();
      }
    });
  }

  ngOnInit(): void {
    this.getBookingBroadcasts();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  gotoUser(id: number) {
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }

  getBookingBroadcasts() {
    var bookingBroadcastRequest = {
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDirection: this.orderDirection,
      location: this.frmFilter.controls["location"].value,
      createdFrom: this.frmFilter.controls["createdFrom"].value,
      createdTo: this.frmFilter.controls["createdTo"].value,
      bookingType: this.frmFilter.controls["bookingType"].value,
      boatType: this.frmFilter.controls["boatType"].value,
      fromDateFrom: this.frmFilter.controls["fromDateFrom"].value,
      fromDateTo: this.frmFilter.controls["fromDateTo"].value,
      toDateFrom: this.frmFilter.controls["toDateFrom"].value,
      toDateTo: this.frmFilter.controls["toDateTo"].value,
      includeAll: this.includeAll
    };

    this.bookingBroadcastService.fetchBookingBroadcasts(bookingBroadcastRequest)
      .subscribe(
        response => {
          this.bookingBroadcastsDetails = response;
          this.changeCheckBoxStatus();
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

  toggleSort(orderColumn: string) {
    if (this.orderDirection == 'DESC') {
      this.orderDirection = '';
    } else if (this.orderDirection == 'ASC') {
      this.orderDirection = 'DESC';
    } else {
      this.orderDirection = 'ASC';
    }
    this.orderColumn = orderColumn;
    this.getBookingBroadcasts();
  }

  getPage(page: number) {
    this.page = page;
    this.getBookingBroadcasts();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  applyFilter() {
    this.includeAll = false;
    this.getBookingBroadcasts();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      location: "",
      bookingType: "",
      boatType: "",
      createdFrom: "",
      createdTo: "",
      fromDateFrom: "",
      fromDateTo: "",
      toDateFrom: "",
      toDateTo: ""
    });
    this.modalService.dismissAll('submit');
    this.includeAll = true
    this.getBookingBroadcasts();
  }

  onDelete(id: number) {
    var isDelete = confirm("are you sure want to delete selected Booking Broadcast?");
    if (isDelete) {
      this.bookingBroadcastService.DeleteBookingBroadcast([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.getBookingBroadcasts();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

  globalCheckboxChange(e: any) {
    this.checkboxCheckedList = []
    if (e.target.checked) {
      this.isDisableBatch = false;
      for (let a = 0; a < this.bookingBroadcastsDetails.data.length; a++) {
        this.checkboxCheckedList.push(this.bookingBroadcastsDetails.data[a].id);
      }
      this.bookingBroadcastsDetails.data.forEach((bookingBroadcastData) => {
        bookingBroadcastData.selected = true;
      });
    }

    else {
      this.isDisableBatch = true;
      this.bookingBroadcastsDetails.data.forEach((bookingBroadcastData) => {
        bookingBroadcastData.selected = false;
      });
    }
    this.getAllCheckedStatus();
  }

  checkboxChange(e: any, id: number) {
    if (e.target.checked) {
      this.isDisableBatch = false;
      this.checkboxCheckedList.push(id);
    }
    else {
      this.checkboxCheckedList = this.checkboxCheckedList.filter(m => m != id);
      if (this.checkboxCheckedList.length <= 0) {
        this.isDisableBatch = true;
      }
    }
    this.getAllCheckedStatus();
  }

  changeCheckBoxStatus() {
    this.isDisableBatch = true;
    this.checkboxCheckedList = [];
    this.getAllCheckedStatus();
  }

  getAllCheckedStatus() {
    if (this.checkboxCheckedList.length === this.bookingBroadcastsDetails.data.length && this.bookingBroadcastsDetails.data.length > 0) {
      this.isGlobalChecked = true;
    }
    else {
      this.isGlobalChecked = false;
    }
  }

  deleteBookingBroadcasts() {
    var isDelete = confirm("are you sure want to delete selected booking broadcast?");
    if (isDelete) {
      if (this.checkboxCheckedList.length > 0) {
        this.bookingBroadcastService.DeleteBookingBroadcast(this.checkboxCheckedList).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.checkboxCheckedList = [];
            this.getBookingBroadcasts();
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
      }
    }
  }

}
