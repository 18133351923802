
<div class="container mt-2">
    <h2 class="`text-default">New Popular Destination</h2>
    <form [formGroup]="formPopularDestination" (ngSubmit)="submit()">
      <div class="card">
        <div class="card-header">
            Popular Destination details
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-2">
            <label for="name">Name</label>
            </div>
            <div class="col-md-10">
              <input type="name" class="form-control" id="name" formControlName="name" placeholder="name" required>
              <div *ngIf="formPopularDestination.controls['name'].invalid && formPopularDestination.controls['name'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="formPopularDestination.controls['name'].hasError('required')">
                    Please enter name.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-2">
            <label for="latitude">Latitude</label>
            </div>
            <div class="col-md-10">
              <input type="latitude" class="form-control" id="latitude" formControlName="latitude" placeholder="latitude" required>
              <div *ngIf="formPopularDestination.controls['latitude'].invalid && formPopularDestination.controls['latitude'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="formPopularDestination.controls['latitude'].hasError('required')">
                    Please enter latitude.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-2">
            <label for="longitude">Longitude</label>
            </div>
            <div class="col-md-10">
              <input type="longitude" class="form-control" id="longitude" formControlName="longitude" placeholder="longitude" required>
              <div *ngIf="formPopularDestination.controls['longitude'].invalid && formPopularDestination.controls['longitude'].touched" class="alert alert-danger form-danger" role="alert">
                <div *ngIf="formPopularDestination.controls['longitude'].hasError('required')">
                    Please enter longitude.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-2">
            <label for="fileUrl">Icon</label>
            </div>
            <div class="col-md-10">
              <span *ngIf="fileUrl != ''">
                <img src="{{fileUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
              </span> 
              <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadFile()">Upload</a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row m-3">
      <button type="submit" [disabled]="!formPopularDestination.valid" class="btn btn-primary">Create Popular Destination</button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
    </form>
  </div>
  
  
  
