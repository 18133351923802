<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>{{boatName}}'s Favoriters</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th>ID</th>
                        <th>FULL NAME</th>
                    </thead>
                    <tbody *ngIf="favoriters!= null && favoriters != undefined && favoriters.length > 0 else noRecord ">
                        <tr *ngFor="let boat of favoriters">
                            <td>{{boat.id}}</td>
                            <td><a (click)="gotoUser(boat.firstName, boat.lastName, boat.userId)" class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;"> {{boat.firstName}}
                                    {{boat.lastName}}</a></td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>