<div class="container mt-2">
    <h2 class="`text-default">Edit Rule</h2>
    <form [formGroup]="frmEditRule" (ngSubmit)="submit()">
        <div class="card">
            <div class="card-header">
                Rule Details
            </div>
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="rule">Rule</label>
                        <div>
                            <p style="background-color: aliceblue; color: #007bff;">po_editor_context only</p>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <input type="rule" class="form-control" id="rule" formControlName="rule" placeholder="Please enter po_editor_context for rule."
                            required>
                        <div *ngIf="frmEditRule.controls['rule'].invalid && frmEditRule.controls['rule'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmEditRule.controls['rule'].hasError('required')">
                                Please enter po_editor_context for rule..
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="isCustom">Is Custom</label>
                    </div>
                    <div class="col-md-10">
                        <select formControlName="isCustom" id="isCustom" class="form-control" ng-options="" placeholder="please select IsCustom" required>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        <div *ngIf="frmEditRule.controls['isCustom'].invalid && frmEditRule.controls['isCustom'].touched"
                            class="alert alert-danger form-danger" role="alert">
                            <div *ngIf="frmEditRule.controls['subTitle'].hasError('required')">
                                Please select is Custom.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label for="icon">Icon</label>
                    </div>
                    <div class="col-md-10">
                        <img src="{{fileUrl}}" height="40px" width="40px" alt="icon" style="border-radius: 20px;">
                        <a class="ml-2 text-primary" style="cursor:pointer;" (click)="onClickUploadFile()">Change</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row m-3">
            <button type="submit" [disabled]="!frmEditRule.valid" class="btn btn-primary">Update Rule</button>
            <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
        </div>
    </form>
</div>