import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-user-sections',
  templateUrl: './user-sections.component.html',
  styleUrls: ['./user-sections.component.css']
})
export class UserSectionsComponent implements OnInit {

  @Input() name;
  id: number = 0;
  userSections: any;

  constructor(private userService: UsersService, private route: ActivatedRoute, private tostr: ToastrService) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.userService.fetchUserSections(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.userSections = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }


}
