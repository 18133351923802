import { Component, OnInit } from '@angular/core';
import {LoginService} from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  spiner:boolean = false;
  constructor(private auth: LoginService) { 
      this.auth.login();
      this.spiner = true;
      }
  ngOnInit(): void {
  }
 
}
