import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { BookingBroadcastService } from 'src/app/services/booking-broadcast/booking-broadcast.service';

@Component({
  selector: 'app-booking-broadcast-view',
  templateUrl: './booking-broadcast-view.component.html',
  styleUrls: ['./booking-broadcast-view.component.css']
})
export class BookingBroadcastViewComponent implements OnInit {
  bookingBroadcastViewDetails: any;
  id: number = 0;
  public resourceType: string = "";
  public resourceId: string = "";

  constructor(private activatedRoute: ActivatedRoute, private bookingBroadcastService: BookingBroadcastService,
    private router: Router, private tostr: ToastrService) { }

    ngOnInit(): void {
      this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
      this.resourceId = this.id.toString();
      this.resourceType = "booking-broadcast";
  
      this.bookingBroadcastService.fetchBookingBroadcast(this.id).subscribe(
        response => {
          this.bookingBroadcastViewDetails = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
    }
  
    delete(id: number) {
      var isDelete = confirm("are you sure want to delete selected booking-broadcast?");
      if (isDelete) {
        this.bookingBroadcastService.DeleteBookingBroadcast([id])
          .pipe(finalize(() => {
          })).subscribe(
            response => {
              this.tostr.success("Deleted Successfully.");
              this.router.navigate(["admin/booking-broadcasts"]);
            },
            error => {
              console.log(error);
              this.tostr.error("Something went wrong, please try again.");
            }
          );
      }
    }
    
    gotoUser(id: number) {
      this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
    }
}
