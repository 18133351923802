<div class="container-fluid">

  <div class="row row-y">
    <div ngbDropdown class="col-md-4 d-inline-block pt-2 pb-2 mia-title">
      <h2 class=" mt-2 mb-4">{{boatName}}</h2>
    </div>
    <div class=" col-md-8 text-right function-btns pt-2 pb-2">
      <a routerLink="../boat-edit" [queryParams]="{id:id}" class="btn btn-secondary">Edit Boat</a>
      <button type="button" (click)="delete()" class="btn btn-secondary ml-2">Delete Boat</button>
      <button type="button" (click)="publishBoat()" class="btn btn-secondary ml-2">{{btnPublishtxt}} Boat</button>
      <button type="button" (click)="markFeatured()" class="btn btn-secondary ml-2">{{btnFeaturedtxt}} Boat as
        Featured</button>
      <button type="button" (click)="changeInsurance()" class="btn btn-secondary ml-2">Change Insurance</button>
    </div>
  </div>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (navChange)="tabNavChange($event)">
    <li ngbNavItem [ngbNavItem]="1">
      <a ngbNavLink>Boat</a>
      <ng-template ngbNavContent>
        <app-boat-details [boatName]="boatName" [boatDetails]="boatDetails"></app-boat-details>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="2">
      <a ngbNavLink>Bookings</a>
      <ng-template ngbNavContent>
        <app-boat-bookings [boatName]="boatName" [boatDetails]="boatDetails"></app-boat-bookings>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="3">
      <a ngbNavLink>Favoriters</a>
      <ng-template ngbNavContent>
        <app-favoriteers [boatName]="boatName" [boatDetails]="boatDetails"></app-favoriteers>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="4">
      <a ngbNavLink>Sections</a>
      <ng-template ngbNavContent>
        <app-sections [boatName]="boatName" [boatDetails]="boatDetails"></app-sections>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="6">
      <a ngbNavLink>Prices</a>
      <ng-template ngbNavContent>
        <app-boat-price [boatName]="boatName" [boatDetails]="boatDetails"></app-boat-price>
      </ng-template>
    </li>
    <li ngbNavItem [ngbNavItem]="5"
      *ngIf="boatDetails != null && boatDetails.bookingManagerId != undefined && boatDetails.bookingManagerId != null">
      <a ngbNavLink>Booking Manager</a>
      <ng-template ngbNavContent>
        <app-boat-bm-view [boatName]="boatName" [boatDetails]="boatDetails"></app-boat-bm-view>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-1"></div>
  <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
  <div>