<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>{{boatName}}'s Sections</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped" *ngIf="sections!= null && sections != undefined">
                    <tr>
                        <td class="bt-none">ALL FILLED</td>
                        <td class="bt-none">
                            <p *ngIf="sections.allFilled!= '' && sections.allFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.allFilled}}</p>
                            <p *ngIf="sections.allFilled!= '' && sections.allFilled == 'NO'"
                                style="background-color: grey; ">{{sections.allFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>LOCATION FILLED</td>
                        <td>
                            <p *ngIf="sections.locationFilled!= '' && sections.locationFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.locationFilled}}</p>
                            <p *ngIf="sections.locationFilled!= '' && sections.locationFilled == 'NO'"
                                style="background-color: grey; ">{{sections.locationFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>DETAILS FILLED</td>
                        <td>
                            <p *ngIf="sections.detailsFilled!= '' && sections.detailsFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.detailsFilled}}</p>
                            <p *ngIf="sections.detailsFilled!= '' && sections.detailsFilled == 'NO'"
                                style="background-color: grey; ">{{sections.detailsFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>CAPACITY FILLED</td>
                        <td>
                            <p *ngIf="sections.capacityFilled!= '' && sections.capacityFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.capacityFilled}}</p>
                            <p *ngIf="sections.capacityFilled!= '' && sections.capacityFilled == 'NO'"
                                style="background-color: grey; ">{{sections.capacityFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>AMENITIES FILLED</td>
                        <td>
                            <p *ngIf="sections.amenitiesFilled!= '' && sections.amenitiesFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.amenitiesFilled}}</p>
                            <p *ngIf="sections.amenitiesFilled!= '' && sections.amenitiesFilled == 'NO'"
                                style="background-color: grey; ">{{sections.amenitiesFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>RENTAL TYPES FILLED</td>
                        <td>
                            <p *ngIf="sections.rentalTypesFilled!= '' && sections.rentalTypesFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.rentalTypesFilled}}</p>
                            <p *ngIf="sections.rentalTypesFilled!= '' && sections.rentalTypesFilled == 'NO'"
                                style="background-color: grey; ">{{sections.rentalTypesFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>RULES FILLED</td>
                        <td>
                            <p *ngIf="sections.rulesFilled!= '' && sections.rulesFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.rulesFilled}}</p>
                            <p *ngIf="sections.rulesFilled!= '' && sections.rulesFilled == 'NO'"
                                style="background-color: grey; ">{{sections.rulesFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>SPECIFICATIONS FILLED</td>
                        <td>

                            <p *ngIf="sections.specificationsFilled!= '' && sections.specificationsFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.specificationsFilled}}</p>
                            <p *ngIf="sections.specificationsFilled!= '' && sections.specificationsFilled == 'NO'"
                                style="background-color: grey; ">{{sections.specificationsFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>PHOTOS FILLED</td>
                        <td>
                            <p *ngIf="sections.photosFilled!= '' && sections.photosFilled == 'YES'"
                                style="background-color: #6090DB;  ">{{sections.photosFilled}}</p>
                            <p *ngIf="sections.photosFilled!= '' && sections.photosFilled == 'NO'"
                                style="background-color: grey;  ">{{sections.photosFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>AVAILABILITY FILLED</td>
                        <td>
                            <p *ngIf="sections.availabilityFilled!= '' && sections.availabilityFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.availabilityFilled}}</p>
                            <p *ngIf="sections.availabilityFilled!= '' && sections.availabilityFilled == 'NO'"
                                style="background-color: grey; ">{{sections.availabilityFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>PRICING FILLED</td>
                        <td>
                            <p *ngIf="sections.pricingFilled!= '' && sections.pricingFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.pricingFilled}}</p>
                            <p *ngIf="sections.pricingFilled!= '' && sections.pricingFilled == 'NO'"
                                style="background-color: grey; ">{{sections.pricingFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>CANCELLATION FILLED</td>
                        <td>
                            <p *ngIf="sections.cancellationFilled!= '' && sections.cancellationFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.cancellationFilled}}</p>
                            <p *ngIf="sections.cancellationFilled!= '' && sections.cancellationFilled == 'NO'"
                                style="background-color: grey; ">{{sections.cancellationFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>INSURANCE FILLED</td>
                        <td>
                            <p *ngIf="sections.insuranceFilled!= '' && sections.insuranceFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.insuranceFilled}}</p>
                            <p *ngIf="sections.insuranceFilled!= '' && sections.insuranceFilled == 'NO'"
                                style="background-color: grey; ">{{sections.insuranceFilled}}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>DEPOSIT FILLED</td>
                        <td>
                            <p *ngIf="sections.depositFilled!= '' && sections.depositFilled == 'YES'"
                                style="background-color: #6090DB; ">{{sections.depositFilled}}</p>
                            <p *ngIf="sections.depositFilled!= '' && sections.depositFilled == 'NO'"
                                style="background-color: grey; ">{{sections.depositFilled}}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>