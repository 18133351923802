<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <h2 class=" mt-2 mb-4" *ngIf="ruleViewDetails != null">{{ruleViewDetails.rules}}</h2>
        </div>
        <div class="col-lg-6 col-md-12 text-right" *ngIf="ruleViewDetails != null">
            <a routerLink="../rule-edit" [queryParams]="{id: ruleViewDetails.id}" class="btn btn-secondary">Edit
                Rule</a>
            <button type="button" (click)="delete(ruleViewDetails.id)" class="btn btn-secondary m-2">Delete
                Rule</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4" *ngIf="ruleViewDetails != undefined && ruleViewDetails != null">
                <div class="card-header">
                    <h5>Rule Details</h5>
                </div>
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Id</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{ruleViewDetails.id}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Name</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{ruleViewDetails.rules}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Is Custom</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{ruleViewDetails.isCustom}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Created Date</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{ruleViewDetails.createdDate}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Updated Date</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{ruleViewDetails.updatedDate}}</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Created By userId</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label *ngIf="ruleViewDetails.createdBy != null && ruleViewDetails.createdBy > 0">
                                <a (click)="gotoUser(ruleViewDetails.createdBy)" class="text-primary" title="Click to View User"
                                    style="text-decoration: underline;cursor: pointer;">{{ruleViewDetails.createdBy}}</a>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Updated By userId</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label *ngIf="ruleViewDetails.updatedBy != null && ruleViewDetails.updatedBy > 0">
                                <a (click)="gotoUser(ruleViewDetails.updatedBy)" class="text-primary" title="Click to View User"
                                    style="text-decoration: underline;cursor: pointer;">{{ruleViewDetails.updatedBy}}</a>
                            </label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Icon</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <img src="{{ruleViewDetails.iconUrl}}" height="40px" width="40px" alt="icon"
                                style="border-radius: 20px;">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3 float-left">
                            <label>Icon File Type</label>
                        </div>
                        <div class="col-md-3 float-left">
                            <label>{{ruleViewDetails.fileType}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <app-comment [resourceType]="resourceType" [resourceId]="resourceId"></app-comment>
        </div>
    </div>
</div>