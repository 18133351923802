import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AmenitycategoriesService } from 'src/app/services/amenitycategories/amenitycategories.service';
import { ToastrService } from 'ngx-toastr';
import { BmAmenityService } from 'src/app/services/bm-amenity.service';

@Component({
  selector: 'app-amenitycategory-new',
  templateUrl: './amenitycategory-new.component.html',
  styleUrls: ['./amenitycategory-new.component.css']
})
export class AmenitycategoryNewComponent implements OnInit {
  frmAmenityCategory: FormGroup;
  constructor(
    //private amenityCategoriesService: AmenitycategoriesService,
    private amenityCategoriesService: BmAmenityService,
    private fb: FormBuilder, private router: Router, private tostr: ToastrService) {
    this.frmAmenityCategory = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  reset() {
    this.router.navigate(["admin/amenitycategories"]);
  }

  submit() {
    if (this.frmAmenityCategory.valid) {
      var amenityCategory = {
        name: this.frmAmenityCategory.controls["name"].value
      };
      this.amenityCategoriesService.addAmenityCategory(amenityCategory)
        .subscribe(
          res => {
            this.tostr.success("Created successfully.");
            this.router.navigate(['admin/amenitycategories']);
          },
          err => {
            this.tostr.error("Something went wrong, please try again.")
            console.log(err);
          });
    }
  }
}
