<div class="container mt-2">
  <h2 class="`text-default">Edit Boat</h2>
  <form [formGroup]="frmEditBoat" (ngSubmit)="submit()">
    <div class="card">
      <div class="card-header">
        Boat details
      </div>
      <div class="card-body">
        <!-- <div class="form-group row">
          <div class="col-md-2">
            <label for="followupdate">Follow up date</label>
          </div>
          <div class="col-md-10">
            <input type="date" class="form-control" id="followupdate" formControlName="followupdate">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="assignee">Assignee</label>
          </div>
          <div class="col-md-10">
            <select formControlName="assignee" id="assignee" class="form-control">
              <option value="0">--Select--</option>
              <option *ngFor="let assignee of assignees" value={{assignee.id}}>{{assignee.email}}</option>
            </select>
          </div>
        </div> -->
        <div class="form-group row">
          <div class="col-md-2">
            <label for="tripType">Trip Types</label>
          </div>
          <div class="col-md-10 dropDownItems">
            <ng-multiselect-dropdown formControlName="tripType" [settings]="tripTypeDropdownSettings" [data]="tripTypes"
              [placeholder]="'--select trip types--'">
            </ng-multiselect-dropdown>
          </div> 
        </div>
      </div>
    </div>
    <div class="form-group row m-2">
      <button type="submit" [disabled]="!frmEditBoat.valid" class="btn btn-primary">Update Boat</button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
  </form>
</div>
