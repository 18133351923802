import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { AccountService } from 'src/app/services/account/account.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
userId:any;
userDetails:any;
  constructor(private authService: LoginService, private accountService: AccountService,
    private route:ActivatedRoute, private tostr:ToastrService) { }

  ngOnInit(): void {
    var id;
    if (this.route.snapshot.queryParams['id']) {
      this.route.queryParams.subscribe(params =>{id = params.id});
      if(id != null && id != undefined){
        this.userId = id;
      }
    }
    else{
      this.userId = this.authService.userId;
    }
    this.accountService.fetchAdminUserData(this.userId)
    .pipe(finalize(() => {
    })).subscribe(
    result => {    
      this.userDetails = result; 
      if(this.userDetails != null && this.userDetails!= undefined){
      localStorage.setItem("email",this.userDetails.email);   
      }
   },
   error=>{
     this.tostr.error("Error occurred while fetching data.");
     console.log(error);
   });
  }
}
