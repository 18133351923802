import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';
import { ChangePassword } from 'src/app/shared/modals/changePassword';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  httpOptions: any;
  httpOptionsEdit: any;
  constructor(private http: HttpClient, private configService: ConfigService, private authService: LoginService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
    this.httpOptionsEdit = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      }), responseType: 'text'
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }


  fetchAdminUserData(userId: string) {
    return this.http.get(this.configService.authApiURI + '/api/user/' + userId, this.httpOptions).pipe(catchError(this.handleError));
  }
  editUser(changePassword: ChangePassword) {
    return this.http.put<any>(this.configService.authApiURI + '/api/change-password', changePassword, this.httpOptionsEdit).pipe(catchError(this.handleError));
  }
}
