<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Admin Users</h2>
    <div class="row">
        <div ngbDropdown class="col-md-4 d-inline-block pt-2 pb-2">
            <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                ngbDropdownToggle>Batch Action</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="deleteUsers()">Delete users</button>
            </div>
        </div>
        <div class="col-md-8 text-right pt-2 pb-2">
            <button class="btn btn-secondary float-right ml-2" (click)="open(mymodal)">Filters</button>
            <a routerLink="../admin-new" class="btn btn-secondary">New Admin User</a>
        </div>
    </div>
    <div class="card">
        <div class="p-0 table-responsive">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked"
                                (change)="globalCheckboxChange($event)">
                        </th>
                        <th>
                            <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                        </th>
                        <th>
                            <a (click)="toggleSort('Email')" style="cursor: pointer;">Email</a>
                        </th>
                        <th style="width: 11%;">
                            <a (click)="toggleSort('CurrentSignInAt')" style="cursor: pointer;">Current Sign In At</a>
                        </th>
                        <th>
                            <a (click)="toggleSort('SignInCount')" style="cursor: pointer;">Sign In Count</a>
                        </th>
                        <th style="width: 11%;">
                            <a (click)="toggleSort('CreatedOn')" style="cursor: pointer;">Created at</a>
                        </th>
                        <th>
                            <p class="text-center">Actions</p>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="userDetails != null && userDetails != undefined">
                    <tr
                        *ngFor="let user of userDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:userDetails.totalEntries}">
                        <td>
                            <input type="checkbox" value="{{user.userId}}" [(ngModel)]="user.selected"
                                (change)="checkboxChange($event,user.userId)">
                        </td>
                        <td>
                            <a (click)="view(user.userId,user.email)"
                                style="cursor: pointer;color:#007bff;">{{user.userId}}</a>
                        </td>
                        <td>
                            <a href="mailto:user.email">{{user.email}}</a>
                        </td>
                        <td>
                            {{user.currentSignInAt}}
                        </td>
                        <td>
                            {{user.signInCount}}
                        </td>
                        <td>
                            {{user.createdAt}}
                        </td>
                        <td>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn" (click)="view(user.userId,user.email)"
                                    title="Click to View">
                                    <i class="bi bi-eye"></i>
                                </button>
                                <button type="button" class="btn" (click)="edit(user.userId,user.email)"
                                    title="Click to Edit">
                                    <i class="bi bi-pencil-square"></i>
                                </button>
                                <button type="button" class="btn" (click)="delete(user.userId)" title="Click to Delete">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mb-4 mt-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="userDetails != undefined && userDetails.totalEntries != undefined && userDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="iStatus">Email</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="email" formControlName="email" class="form-control">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>