import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReportService } from 'src/app/services/report/report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  gmvStats: any;
  diwStats: any;
  userStats: any;
  graphData: any;
  yearlyBookings: any;
  colors: string[] = [];
  years: number[] = [];
  selectedBookingYear = (new Date()).getFullYear();
  selectedGMVYear = (new Date()).getFullYear();
  selectedDIWYear = (new Date()).getFullYear();
  selectedBarYear = (new Date()).getFullYear();
  myChart: any;
  frmFilter: FormGroup;
  frmBarFilter: FormGroup;
  canvas: any; ctx: any; canvas2: any; ctx2: any; canvas3: any; ctx3: any;
  constructor(private reportService: ReportService, private fb: FormBuilder, private tostr: ToastrService,
    private modalService: NgbModal) {
    this.frmFilter = this.fb.group({
      bookingId: [],
      insuranceStatus: [''],
      manualPayout: [''],
      country: [''],
      status: [''],
      createdFrom: [''],
      createdTo: [''],
      startOnFrom: [''],
      startOnTo: [''],
      endsOnFrom: [''],
      endsOnTo: ['']
    });
    this.frmBarFilter = this.fb.group({
      years: [(new Date()).getFullYear().toString()]

    });
  }
  ngOnInit(): void {
    this.setColors();
    this.getGMVReport();
    this.getDIWReport();
    this.getYearlyBookingReport();
    this.getBookingStatusReport();
    this.getUserBoatStats();
    for (var y = (new Date()).getFullYear(); y > 2014; y--) {
      this.years.push(y);
    }
  }
  onBookingYearChange(newValue) {
    console.log(newValue);
    this.selectedBookingYear = newValue;
    this.getYearlyBookingReport()
    // ... do other stuff here ...
  }
  onGMVYearChange(newValue) {
    console.log(newValue);
    this.selectedGMVYear = newValue;
    this.getGMVReport()
    // ... do other stuff here ...
  }
  onDIWYearChange(newValue) {
    console.log(newValue);
    this.selectedDIWYear = newValue;
    this.getDIWReport()
    // ... do other stuff here ...
  }
  onBarYearChange(newValue) {
    console.log(newValue);
    this.selectedBarYear = newValue;
  }
  getGMVReport() {
    this.reportService.fetchGMVReport(this.selectedGMVYear.toString()).subscribe(
      res => {
        this.gmvStats = res;
        console.log(this.gmvStats);
        //this.commonService.exportAsExcelFile(mvpReportData, this.currentYear + " MVP Report");
      },
      error => {
        this.tostr.error("Error occurred while fetching data for report.");
        console.log(error);
      }
    );
  }
  getDIWReport() {
    this.reportService.fetchDIWReport(this.selectedDIWYear.toString()).subscribe(
      res => {
        this.diwStats = res;
        console.log(this.diwStats);
        //this.commonService.exportAsExcelFile(mvpReportData, this.currentYear + " MVP Report");
      },
      error => {
        this.tostr.error("Error occurred while fetching data for report.");
        console.log(error);
      }
    );
  }
  setColors() {
    for (var i = 0; i < 10; i++) {
      this.colors.push(this.random_rgba());

    }
  }
  random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',1)';
  }
  setupYearlyBarGraph() {
    var dataset: any = []
    var i = 0;
    for (var year of this.graphData) {

      var color = this.colors[i];
      i = i + 1;
      console.log(color);
      dataset.push({
        label: year.barGroupName,
        data: year.data,
        backgroundColor:
          color
        ,
        borderColor:
          color,
        borderWidth: 1,

      });
    }
    if (this.myChart != undefined)
      this.myChart.destroy();
    this.canvas = document.getElementById('myChart');
    this.ctx = this.canvas.getContext('2d');
    this.myChart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: dataset
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        parsing: {
          xAxisKey: 'barName',
          yAxisKey: 'value'
        },
        backgroundColor: 'rgba(54, 152, 200, 1)'
      }
    });
  }
  getYearlyBookingReport() {
    let years = this.frmBarFilter.controls["years"].value
    this.reportService.fetchYearlyBooking(years).subscribe(
      res => {
        if (res != null) {
          this.graphData = res;
          this.setupYearlyBarGraph();
        }
        else {
          this.graphData = [];

          this.graphData.push({ 'status': 'Bookings', 'bCount': 0 });
        }

      },
      error => {
        this.tostr.error("Error occurred while fetching data for report.");
        console.log(error);
      }
    );
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  submit() {
    this.getBookingStatusReport();
    this.modalService.dismissAll('submit');
  }

  clearFilter() {
    this.frmFilter.setValue({
      bookingId: null,
      insuranceStatus: "",
      manualPayout: "",
      country: "",
      status: "",
      createdFrom: "",
      createdTo: "",
      startOnFrom: "",
      startOnTo: "",
      endsOnFrom: "",
      endsOnTo: ""
    });
    this.modalService.dismissAll('submit');
    this.getBookingStatusReport();
  }
  submitBarYear() {
    this.getYearlyBookingReport();
    this.modalService.dismissAll('submit');
  }

  clearYearFilter() {
    this.frmBarFilter.setValue({
      years: ['2022']
    });
    this.modalService.dismissAll('submit');
    this.getYearlyBookingReport();
  }
  getBookingStatusReport() {
    var bookingRequest = {
      page: 0,
      pageSize: 0,
      orderColumn: '',
      orderDirection: '',
      type: '',
      includeAll: false,
      id: 0,
      insuranceStatus: '',
      manualPayout: null,
      createdFrom: this.frmFilter.controls["createdFrom"].value,
      createdTo: this.frmFilter.controls["createdTo"].value,
      startOnFrom: this.frmFilter.controls["startOnFrom"].value,
      startOnTo: this.frmFilter.controls["startOnTo"].value,
      endsOnFrom: this.frmFilter.controls["endsOnFrom"].value,
      endsOnTo: this.frmFilter.controls["endsOnTo"].value,
      status: '',
      countryCode: ''
    };
    this.reportService.fetchBookingStatus(bookingRequest).subscribe(
      res => {
        if (res != null) {
          this.yearlyBookings = res;
          console.log(this.yearlyBookings)
          //this.setupYearlyBarGraph();
        }
        else {
          this.yearlyBookings = [];

          this.yearlyBookings.push({ 'status': 'Bookings', 'bCount': 0 });
        }

      },
      error => {
        this.tostr.error("Error occurred while fetching data for report.");
        console.log(error);
      }
    );
  }
  getUserBoatStats() {
    this.reportService.fetchUserBoatStats().subscribe(
      res => {
        this.userStats = res;
        //this.commonService.exportAsExcelFile(mvpReportData, this.currentYear + " MVP Report");
      },
      error => {
        this.tostr.error("Error occurred while fetching data for report.");
        console.log(error);
      }
    );
  }

}
