import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/login.service';
import { BaseService } from 'src/app/shared/base.service';
import { ConfigService } from 'src/app/shared/config.service';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class CommentService extends BaseService {
  httpOptions;
  constructor(private authService: LoginService, private http: HttpClient, private configService: ConfigService,
    private toastrService: ToastrService) {
    super();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.authorizationHeaderValue
      })
    };
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.toastrService.error(errorMessage);
    return throwError(errorMessage);
  }

  fetchComments(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'comment/list', data, this.httpOptions).pipe(
      catchError(this.handleError));
  }

  addComment(data: any) {
    return this.http.post(this.configService.resourceApiURI + 'comment', data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  updateComment(data: any) {
    return this.http.put(this.configService.resourceApiURI + 'comment', data, this.httpOptions).pipe(
      catchError(this.handleError));
  }

  deleteComments(ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': this.authService.authorizationHeaderValue
      }),
      body: JSON.stringify(ids)
    }
    return this.http.delete(this.configService.resourceApiURI + 'comment', options).pipe(
      catchError(this.handleError)
    );
  }
}
