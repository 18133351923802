import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FilestackModule } from '@filestack/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoatComponent } from './components/boat/boat/boats.component';
import { EventComponent } from './components/events/event/event.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { ErrorComponent } from './components/error/error.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AdminComponent } from './admin/admin.component';
import { CookieService } from 'ngx-cookie-service';
import { AccountComponent } from './components/system/users/account/account.component';
import { AssignmentsComponent } from './components/system/users/assignments/assignments.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommentComponent } from './shared/comment/comment.component';
import { EditUserComponent } from './components/system/users/edit-user/edit-user.component';
import { UserListComponent } from './components/social//user-list/user-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserComponent } from './components/system/users/user.component';
import { EditComponent } from './components/social/edit/edit.component';
import { AdminUsersComponent } from './components/system/users/admin-users/admin-users.component';
import { CreateUserComponent } from './components/system/users/create-user/create-user.component';
import { AmenitycategoriesComponent } from './components/boat/amenity-category/amenitycategories/amenitycategories.component';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AmenitiescategoryviewComponent } from './components/boat/amenity-category/amenitiescategoryview/amenitiescategoryview.component'
import { AmenitiesComponent } from './components/boat/amenity/amenities/amenities.component';
import { AmenitiesviewComponent } from './components/boat/amenity/amenitiesview/amenitiesview.component';
import { CommonModule } from '@angular/common';
import { UserViewComponent } from './components/social/user-view/user-view.component';
import { UserAccountComponent } from './components/social/user-view/user-account/user-account.component';
import { UserPersonComponent } from './components/social/user-view/user-person/user-person.component';
import { UserBusinessComponent } from './components/social/user-view/user-business/user-business.component';
import { UserPayoutComponent } from './components/social/user-view/user-payout/user-payout.component';
import { UserBoatsComponent } from './components/social/user-view/user-boats/user-boats.component';
import { UserBookingsComponent } from './components/social/user-view/user-bookings/user-bookings.component';
import { UserReferralsComponent } from './components/social/user-view/user-referrals/user-referrals.component';
import { UserConversationsComponent } from './components/social/user-view/user-conversations/user-conversations.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InterceptorService } from './interceptor.service';
import { AmenityNewComponent } from './components/boat/amenity/amenity-new/amenity-new.component';
import { AmenityEditComponent } from './components/boat/amenity/amenity-edit/amenity-edit.component';
import { AmenitycategoryNewComponent } from './components/boat/amenity-category/amenitycategory-new/amenitycategory-new.component';
import { AmenitycategoryEditComponent } from './components/boat/amenity-category/amenitycategory-edit/amenitycategory-edit.component';
import { ReviewsComponent } from './components/boat/review/reviews/reviews.component';
import { ReviewViewComponent } from './components/boat/review/review-view/review-view.component';
import { ReviewEditComponent } from './components/boat/review/review-edit/review-edit.component';
import { MetagroupsComponent } from './components/system/metagroup/metagroups/metagroups.component';
import { MetagroupViewComponent } from './components/system/metagroup/metagroup-view/metagroup-view.component';
import { MetagroupEditComponent } from './components/system/metagroup/metagroup-edit/metagroup-edit.component';
import { MetagroupNewComponent } from './components/system/metagroup/metagroup-new/metagroup-new.component';
import { BoatViewComponent } from './components/boat/boat/boat-view/boat-view.component';
import { BoatEditComponent } from './components/boat/boat/boat-edit/boat-edit.component';
import { DatePipe } from '@angular/common';
import { BoatDetailsComponent } from './components/boat/boat/boat-view/boat-details/boat-details.component';
import { FavoriteersComponent } from './components/boat/boat/boat-view/favoriteers/favoriteers.component';
import { BoatBookingsComponent } from './components/boat/boat/boat-view/boat-bookings/boat-bookings.component';
import { SectionsComponent } from './components/boat/boat/boat-view/sections/sections.component';
import { InsuranceComponent } from './components/boat/boat/boat-view/insurance/insurance.component';
import { AlertsComponent } from './components/system/alert/alerts/alerts.component';
import { AlertViewComponent } from './components/system/alert/alert-view/alert-view.component';
import { CommonProvider } from './shared/modals/common-provider';
import { ConversationsComponent } from './components/social/conversations/conversations.component';
import { ConversationViewComponent } from './components/social/conversations/conversation-view/conversation-view.component';
import { ConversationEditComponent } from './components/social/conversations/conversation-edit/conversation-edit.component';
import { ReferralsComponent } from './components/social/referrals/referrals.component';
import { ReferralViewComponent } from './components/social/referrals/referral-view/referral-view.component';
import { ReferralNewComponent } from './components/social/referrals/referral-new/referral-new.component';
import { UpdateCreditComponent } from './components/social/user-view/update-credit/update-credit.component';
import { environment } from 'src/environments/environment';
import { BookingsComponent } from './components/boat/bookings/bookings.component';
import { BookingViewComponent } from './components/boat/bookings/booking-view/booking-view.component';
import { ToastrModule } from 'ngx-toastr';
import { BoatStatisticsComponent } from './components/reports/boat-statistics/boat-statistics.component';
import { ReviewByWeekComponent } from './components/reports/review-by-week/review-by-week.component';
import { SearchesByDestinationComponent } from './components/reports/searches-by-destination/searches-by-destination.component';
import { TripTypesComponent } from './components/boat/trip-type/trip-types/trip-types.component';
import { TripTypeNewComponent } from './components/boat/trip-type/trip-type-new/trip-type-new.component';
import { TripTypeViewComponent } from './components/boat/trip-type/trip-type-view/trip-type-view.component';
import { TripTypeEditComponent } from './components/boat/trip-type/trip-type-edit/trip-type-edit.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { PopularDestinationsComponent } from './components/boat/popular-destination/popular-destinations/popular-destinations.component';
import { PopularDestinationEditComponent } from './components/boat/popular-destination/popular-destination-edit/popular-destination-edit.component';
import { PopularDestinationNewComponent } from './components/boat/popular-destination/popular-destination-new/popular-destination-new.component';
import { PopularDestinationViewComponent } from './components/boat/popular-destination/popular-destination-view/popular-destination-view.component';
import { ExportDataComponent } from './shared/export-data/export-data.component';
import { BookingBroadcastsComponent } from './components/boat/booking-broadcast/booking-broadcasts/booking-broadcasts.component';
import { BookingBroadcastViewComponent } from './components/boat/booking-broadcast/booking-broadcast-view/booking-broadcast-view.component';
import { RulesComponent } from './components/system/rule/rules/rules.component';
import { RuleNewComponent } from './components/system/rule/rule-new/rule-new.component';
import { RuleEditComponent } from './components/system/rule/rule-edit/rule-edit.component';
import { RuleViewComponent } from './components/system/rule/rule-view/rule-view.component';
import { InsurancePriceRangesComponent } from './components/system/insurance-price-range/insurance-price-ranges/insurance-price-ranges.component';
import { InsurancePriceRangeNewComponent } from './components/system/insurance-price-range/insurance-price-range-new/insurance-price-range-new.component';
import { InsurancePriceRangeEditComponent } from './components/system/insurance-price-range/insurance-price-range-edit/insurance-price-range-edit.component';
import { InsurancePriceRangeViewComponent } from './components/system/insurance-price-range/insurance-price-range-view/insurance-price-range-view.component';
import { CategoriesComponent } from './components/cms/support center/categories/categories/categories.component';
import { CategoryNewComponent } from './components/cms/support center/categories/category-new/category-new.component';
import { CategoryEditComponent } from './components/cms/support center/categories/category-edit/category-edit.component';
import { VerificationComponent } from './components/social/user-view/verification/verification.component';
import { BookingRequestComponent } from './components/boat/booking-request/booking-request.component';
import { BookingRequestViewComponent } from './components/boat/booking-request/booking-request-view/booking-request-view.component';
import { ReferralEditComponent } from './components/social/referrals/referral-edit/referral-edit.component';
import { BoatLibraryComponent } from './components/system/boatLibrary/boat-library/boat-library.component';
import { LibraryEditComponent } from './components/system/boatLibrary/library-edit/library-edit.component';
import { LibraryNewComponent } from './components/system/boatLibrary/library-new/library-new.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LibraryViewComponent } from './components/system/boatLibrary/library-view/library-view.component';
import { MatSelectModule } from '@angular/material/select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UserSectionsComponent } from './components/social/user-view/user-sections/user-sections.component';
import { PayoutsComponent } from './components/boat/payouts/payouts.component';
import { BoatBmViewComponent } from './components/boat/boat/boat-view/boat-bm-view/boat-bm-view.component';
import { BoatPriceComponent } from './components/boat/boat/boat-view/boat-price/boat-price.component';

@NgModule({
  declarations: [
    AppComponent,
    BoatComponent,
    EventComponent,
    DashboardComponent,
    ErrorComponent,
    AdminComponent,
    AccountComponent,
    AssignmentsComponent,
    CommentComponent,
    EditUserComponent,
    UserListComponent,
    UserComponent,
    EditComponent,
    AdminUsersComponent,
    CreateUserComponent,
    AmenitycategoriesComponent,
    AmenitiescategoryviewComponent,
    AmenitiesComponent,
    AmenitiesviewComponent,
    UserViewComponent,
    UserAccountComponent,
    UserPersonComponent,
    UserBusinessComponent,
    UserPayoutComponent,
    UserBoatsComponent,
    UserBookingsComponent,
    UserReferralsComponent,
    UserConversationsComponent,
    LoginComponent,
    AuthCallbackComponent,
    AmenityNewComponent,
    AmenityEditComponent,
    AmenitycategoryNewComponent,
    AmenitycategoryEditComponent,
    ReviewsComponent,
    ReviewViewComponent,
    ReviewEditComponent,
    MetagroupsComponent,
    MetagroupViewComponent,
    MetagroupEditComponent,
    MetagroupNewComponent,
    BoatViewComponent,
    BoatEditComponent,
    BoatDetailsComponent,
    FavoriteersComponent,
    BoatBookingsComponent,
    SectionsComponent,
    InsuranceComponent,
    AlertsComponent,
    AlertViewComponent,
    ConversationsComponent,
    ConversationViewComponent,
    ConversationEditComponent,
    ReferralsComponent,
    ReferralViewComponent,
    ReferralNewComponent,
    UpdateCreditComponent,
    BookingsComponent,
    BookingViewComponent,
    BoatStatisticsComponent,
    ReviewByWeekComponent,
    SearchesByDestinationComponent,
    TripTypesComponent,
    TripTypeNewComponent,
    TripTypeViewComponent,
    TripTypeEditComponent,
    PopularDestinationsComponent,
    PopularDestinationEditComponent,
    PopularDestinationNewComponent,
    PopularDestinationViewComponent,
    ExportDataComponent,
    BookingBroadcastsComponent,
    BookingBroadcastViewComponent,
    RulesComponent,
    RuleNewComponent,
    RuleEditComponent,
    RuleViewComponent,
    InsurancePriceRangesComponent,
    InsurancePriceRangeNewComponent,
    InsurancePriceRangeEditComponent,
    InsurancePriceRangeViewComponent,
    CategoriesComponent,
    CategoryNewComponent,
    CategoryEditComponent,
    VerificationComponent,
    BookingRequestComponent,
    BookingRequestViewComponent,
    ReferralEditComponent,
    BoatLibraryComponent,
    LibraryEditComponent,
    LibraryNewComponent,
    LibraryViewComponent,
    UserSectionsComponent,
    PayoutsComponent,
    BoatBmViewComponent,
    BoatPriceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MatMenuModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
    FilestackModule.forRoot({
      apikey: environment.fileStackKey
    }),
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    Ng2TelInputModule,
    ClipboardModule,
    MatSelectModule,
    ScrollingModule
  ],
  providers: [CookieService, { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }, DatePipe, CommonProvider],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

