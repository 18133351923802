<div class="container mt-2">
  <h2 class="`text-default">Edit Review</h2>
  <form [formGroup]="frmReview" (ngSubmit)="submit()">
    <div class="card">
      <div class="card-header">
        Review
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="col-md-2">
            <label for="subject">Subject</label>
          </div>
          <div class="col-md-10">
            <input type="text" class="form-control" id="subject" formControlName="subject"
              placeholder="Please enter subject" required>
            <div *ngIf="frmReview.controls['subject'].invalid && frmReview.controls['subject'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmReview.controls['subject'].hasError('required')">
                Please enter subject.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="body">Body</label>
          </div>
          <div class="col-md-10">
            <textarea rows="10" class="form-control" id="body" formControlName="body" placeholder="Please enter body"
              required></textarea>
            <div *ngIf="frmReview.controls['body'].invalid && frmReview.controls['body'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmReview.controls['body'].hasError('required')">
                Please enter body.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="stars">Stars</label>
          </div>
          <div class="col-md-10">
            <input type="number" class="form-control" id="stars" formControlName="stars"
              placeholder="Please enter stars" required max="5" min="1">
            <div *ngIf="frmReview.controls['stars'].invalid && frmReview.controls['stars'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmReview.controls['stars'].hasError('required')">
                This is required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row m-3">
      <button type="submit"
        [disabled]="!frmReview.valid || frmReview.controls['stars'].value > 5 || frmReview.controls['stars'].value == 0"
        class="btn btn-primary">Update Review</button>
      <button type="reset" (click)="reset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
  </form>
</div>