<div class="container mt-2">
  <h2 class="`text-default">Edit User</h2>
  <form [formGroup]="frmEditUser" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="card-header">
        User Details
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="col-md-2">
            <label for="exampleInputEmail1">Email</label>
          </div>
          <div class="col-md-10">
            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="email"
              aria-describedby="emailHelp" placeholder="Enter email" required>
            <div *ngIf="frmEditUser.controls['email'].invalid && frmEditUser.controls['email'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmEditUser.controls['email'].hasError('required')">
                Please enter email.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="newPass">Password</label>
          </div>
          <div class="col-md-10">
            <input type="password" class="form-control" id="newPass" formControlName="password" placeholder="Password">
            <!-- <div *ngIf="frmEditUser.controls['password'].invalid && frmEditUser.controls['password'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmEditUser.controls['password'].hasError('required')">
                Please enter password.
              </div>
              <div *ngIf="frmEditUser.controls['password'].hasError('pattern')">
                Password should contain at least one special character, one upper case letter and one number.
              </div>
            </div> -->
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="confirmPass">Confirm password</label>
          </div>
          <div class="col-md-10">
            <input type="password" class="form-control" id="confirmPass" formControlName="confirmPassword"
              placeholder="Confirm Password">
            <!-- <div
              *ngIf="frmEditUser.controls['confirmPassword'].invalid && frmEditUser.controls['confirmPassword'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmEditUser.controls['confirmPassword'].hasError('required')">
                Please enter password.
              </div>
              <div *ngIf="frmEditUser.controls['confirmPassword'].hasError('confirmPasswordValidator')">
                Passsword and Confirm Password didn't match.
              </div>
            </div> -->
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="ownerServicefee">Owner service fee(%)</label>
          </div>
          <div class="col-md-10">
            <input type="number" min="0" class="form-control" id="ownerServicefee" formControlName="ownerServiceFee"
              aria-describedby="emailHelp" placeholder="Enter owner service fee" >
            <!-- <div
              *ngIf="frmEditUser.controls['ownerServiceFee'].invalid && frmEditUser.controls['ownerServiceFee'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmEditUser.controls['ownerServiceFee'].hasError('required')">
                Please enter owner service fee.
              </div>
            </div> -->
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="renterServiceFee">Renter service fee(%)</label>
          </div>
          <div class="col-md-10">
            <input type="number" min="0" class="form-control" id="renterServiceFee" formControlName="renterServiceFee"
              aria-describedby="emailHelp" placeholder="Enter renter service fee" >
            <!-- <div
              *ngIf="frmEditUser.controls['renterServiceFee'].invalid && frmEditUser.controls['renterServiceFee'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmEditUser.controls['renterServiceFee'].hasError('required')">
                Please enter renter service fee.
              </div>
            </div> -->
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2"></div>
          <div class="col-md-10">
            <input type="checkbox" formControlName="isBoatOwnerChbx" id="isBoatOwner" />
            <label for="isBoatOwner" class="pl-1">Is boat owner</label>
            <input type="checkbox" formControlName="hasSailCertificateChbx" [checked]="" class="ml-2"
              id="hasSailCertificate" />
            <label for="hasSailCertificate" class="pl-1">Has sail certificate</label>
            <input type="checkbox" formControlName="boatRentalInterestchkbx" class="ml-2" id="boatRentalInterest" />
            <label for="boatRentalInterest" class="pl-1">Has boat rental interest</label>
            <input type="checkbox" formControlName="isSuperHostChbx" [checked]="" class="ml-2" id="isSuperHost" />
            <label for="isSuperHost" class="pl-1">Is super host</label>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="marketingSource">Marketing source</label>
          </div>
          <div class="col-md-10">
            <select formControlName="marketingSource" class="form-control" ng-options="">
              <option value="select">select</option>
              <option *ngFor="let source of marketingSource" value={{source}}>{{source}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-2">
            <label for="marketingSourceNote">Marketing source note</label>
          </div>
          <div class="col-md-10">
            <input type="text" class="form-control" id="marketingSourceNote" formControlName="marketingSourceNote"
              placeholder="Enter marketing source note" >
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-header">
        Phone Numbers
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="col-md-2">
            <label for="exampleInputEmail1">Number</label>
          </div>
          <div class="col-md-10">
            <input type="text" class="form-control" id="number" formControlName="number"
              placeholder="Enter phone number" >
            <!-- <div *ngIf="frmEditUser.controls['number'].invalid && frmEditUser.controls['number'].touched"
              class="alert alert-danger form-danger" role="alert">
              <div *ngIf="frmEditUser.controls['number'].hasError('required')">
                Please enter phone number.
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row m-3">
      <button type="submit" [disabled]="!frmEditUser.valid" class="btn btn-primary">Update User</button>
      <button type="reset" (click)="onReset()" class="btn btn-secondary ml-2">Cancel</button>
    </div>
  </form>
</div>