import { Component, OnInit } from '@angular/core';
import { TripTypeService } from 'src/app/services/trip-type/trip-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-trip-type-view',
  templateUrl: './trip-type-view.component.html',
  styleUrls: ['./trip-type-view.component.css']
})
export class TripTypeViewComponent implements OnInit {
  tripTypeViewDetails: any;
  id: number = 0;
  public resourceType: string = "";
  public resourceId: string = "";

  constructor(private activatedRoute: ActivatedRoute, private tripTypeService: TripTypeService,
    private router: Router, private tostr: ToastrService) { }


  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    //this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.resourceId = this.id.toString();
    this.resourceType = "trip-type";

    this.tripTypeService.fetchTripType(this.id).subscribe(
      response => {
        this.tripTypeViewDetails = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  delete(id: number) {
    var isDelete = confirm("are you sure want to delete selected trip type?");
    if (isDelete) {
      this.tripTypeService.DeleteTripType([id])
        .pipe(finalize(() => {
        })).subscribe(
          response => {
            this.tostr.success("Deleted Successfully.");
            this.router.navigate(["admin/trip-types"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong, please try again.");
          }
        );
    }
  }

}
