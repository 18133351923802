<div class="container-fluid" style="position: relative;">
    <h2 class=" mt-2 mb-4">Users</h2>
    <div class="row userBtn">
        <div class="col-md-2">
            <div ngbDropdown class="d-inline-block pt-2 pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteUsers()">Delete users</button>
                </div>
            </div>
        </div>
        <div class="col-md-9 pt-2 pb-2 all-pending" *ngIf="userDetails != undefined">
            <button class="btn btn-outline-secondary" (click)="all()">All ({{userDetails.allCount}})</button>
            <button class="btn btn-outline-secondary ml-1" (click)="pendingVerification()">Pending Verification
                ({{userDetails.pendingVerificationCount}})</button>
        </div>
        <div class="col-md-1 text-right pt-2 pb-2">
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="card">
        <div class="p-0 table-responsive">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked"
                                (change)="globalCheckboxChange($event)">
                        </th>
                        <th scope="col">
                            <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                        </th>
                        <th scope="col">
                            <p class="align-middle">Full Name</p>
                        </th>
                        <th>
                            <a (click)="toggleSort('Email')" style="cursor: pointer;">Email</a>
                        </th>
                        <th>
                            <p>Verifications</p>
                        </th>
                        <th>
                            <a (click)="toggleSort('CreatedAt')" style="cursor: pointer;">Created at</a>
                        </th>
                        <th>
                            <p class="text-center">Actions</p>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="userDetails != null && userDetails != undefined">
                    <tr
                        *ngFor="let user of userDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:userDetails.totalEntries}">
                        <td>
                            <input type="checkbox" value="{{user.id}}" [(ngModel)]="user.selected"
                                (change)="checkboxChange($event,user.id)">
                        </td>
                        <td>
                            <a (click)="view(user.id, user.firstName,user.fullName)" class="text-primary"
                                style="text-decoration: underline;cursor: pointer;">{{user.id}}</a>
                        </td>
                        <td>
                            {{user.fullName}}
                        </td>
                        <td>
                            <a href="mailto:user.email">{{user.email}}</a>
                        </td>
                        <td>
                            <p [style.backgroundColor]="user.emailColour">EMAIL</p>
                            <p [style.backgroundColor]="user.photoIdColour">PHOTO ID</p>
                            <p [style.backgroundColor]="user.photoIdBackColour">PHOTO ID(BACK)</p>
                            <p [style.backgroundColor]="user.documentColour">DOCUMENT ID</p>
                            <p [style.backgroundColor]="user.sailCertificateColour">SAIL CERT</p>
                        </td>
                        <td>
                            {{user.createdAt}}
                        </td>
                        <td>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn" title="Click to View"
                                    (click)="view(user.id, user.firstName,user.fullName)"><i
                                        class="bi bi-eye"></i></button>
                                <button type="button" class="btn" title="Click to Edit" (click)="edit(user.id)"><i
                                        class="bi bi-pencil-square"></i></button>
                                <button type="button" class="btn" title="Click to Delete" (click)="delete(user.id)"><i
                                        class="bi bi-trash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="userDetails != undefined && userDetails.totalEntries != undefined && userDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="id">Id</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="id" formControlName="id" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="firstName">First Name</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="firstName" formControlName="firstName" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="lastName">Last Name</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="lastName" formControlName="lastName" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="email">Email</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="email" formControlName="email" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="phoneNumber">Phone Number</label>
                </div>
                <div class="col-md-8">
                    <input type="text" id="phoneNumber" formControlName="phoneNumber" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="isEmailVerified">Email Verified</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="isEmailVerified" id="isEmailVerified" class="form-control" ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="isSailCertificateVerified">Sail Certificate Verified</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="isSailCertificateVerified" id="isSailCertificateVerified"
                        class="form-control" ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="isPhotoIdVerified">Photo Id Verified</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="isPhotoIdVerified" id="isPhotoIdVerified" class="form-control"
                        ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="isPhotoIdBackVerified">Photo Id Back Verified</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="isPhotoIdBackVerified" id="isPhotoIdBackVerified" class="form-control"
                        ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="isAdditionalDocumentVerified">Additional Document Verified</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="isAdditionalDocumentVerified" id="isAdditionalDocumentVerified"
                        class="form-control" ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="country">Country</label>
                </div>
                <div class="col-md-8" *ngIf="countries != null && countries.length > 0">
                    <select formControlName="countryCode" id="countryCode" class="form-control" ng-options="">
                        <option *ngFor="let country of countries" value={{country.code}}>{{country.name}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="followUpStatus">Follow Up Status</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="followUpStatus" id="followUpStatus" class="form-control" ng-options="">
                        <option value="confirmed">Confirmed</option>
                        <option value="follow_up">Follow Up</option>
                        <option value="closed">Closed</option>
                        <option value="take_action">Take Action</option>
                    </select>
                </div>
            </div>
            <!-- <div class="form-group row">
                <div class="col-md-4">
                    <label for="followUpFromDate">Follow Up Date From</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="followUpFromDate" formControlName="followUpFromDate" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="followUpToDate">Follow Up Date To</label>
                </div>
                <div class="col-md-8">
                    <input type="date" id="followUpToDate" formControlName="followUpToDate" class="form-control">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="assignee">Assignee</label>
                </div>
                <div class="col-md-8" *ngIf="assignees != null && assignees.length > 0">
                    <select formControlName="assignee" id="assignee" class="form-control" ng-options="">
                        <option *ngFor="let assignee of assignees" value={{assignee.id}}>{{assignee.email}}</option>
                    </select>
                </div>
            </div> -->
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="followUpTask">Follow Up Task</label>
                </div>
                <div class="col-md-8" *ngIf="followUpTaskList != null && followUpTaskList.length > 0">
                    <select formControlName="followUpTask" id="followUpTask" class="form-control" ng-options="">
                        <option *ngFor="let task of followUpTaskList" value={{task.value}}>{{task.text}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="isBoatOwner">Is Boat Owner</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="isBoatOwner" id="isBoatOwner" class="form-control" ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="hasSailCertificate">Has Sail Certificate</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="hasSailCertificate" id="hasSailCertificate" class="form-control"
                        ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="hasBoatRentalInterest">Has Boat Rental Interest</label>
                </div>
                <div class="col-md-8">
                    <select formControlName="hasBoatRentalInterest" id="hasBoatRentalInterest" class="form-control"
                        ng-options="">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>