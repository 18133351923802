<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Trip-Types</h2>
    <div class="row row-y">
        <div class="col-md-5">
            <div ngbDropdown class="d-inline-block pt-2 pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteTripTypes()">Delete selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <button class="btn btn-secondary float-right filter-btn" (click)="open(mymodal)">Filters</button>

            <a routerLink="../trip-type-new">
                <button type="button" class="btn btn-secondary float-right new-trip-type-btn">New TripType</button>
            </a>

        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked"
                                        (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('title')" style="cursor: pointer;">Title</a>
                            </th>
                            <th scope="col">
                                <a (click)="toggleSort('subTitle')" style="cursor: pointer;">Sub Title</a>
                            </th>
                            <th scope="col">
                                Icon
                            </th>
                            <th scope="col" class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="tripTpyeDetails != undefined  && tripTpyeDetails.totalEntries > 0">
                        <tr
                            *ngFor="let tripTpyedata of tripTpyeDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:tripTpyeDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" value="{{tripTpyedata.id}}"
                                        [(ngModel)]="tripTpyedata.selected"
                                        (change)="checkboxChange($event,tripTpyedata.id)">
                                </div>
                            </td>
                            <td><a [routerLink]="['../trip-type-view', tripTpyedata.id]">{{tripTpyedata.id}}</a></td>
                            <td>{{tripTpyedata.title}} </td>
                            <td>{{tripTpyedata.subTitle}} </td>
                            <td><img src="{{tripTpyedata.iconUrl}}" height="40px" width="40px" alt="icon"
                                    style="border-radius: 20px;"></td>
                            <td>
                                <a [routerLink]="['../trip-type-view', tripTpyedata.id]">
                                    <button type="submit" title="Click to View" class="btn"><i
                                            class="bi bi-eye"></i></button>
                                </a>
                                <a routerLink="../trip-type-edit" [queryParams]="{id: tripTpyedata.id}">
                                    <button type="submit" title="Click to Edit" class="btn"><i
                                            class="bi bi-pencil-square"></i></button>
                                </a>
                                <button type="submit" title="Click to Delete" (click)="onDelete(tripTpyedata.id)"
                                    class="btn"><i class="bi bi-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="tripTpyeDetails != undefined && tripTpyeDetails.totalEntries != undefined && tripTpyeDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="name">Title</label>
                </div>
                <div class="col-md-8">
                    <input type="text" formControlName="title" class="form-control" id="titleText">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="name">Sub Title</label>
                </div>
                <div class="col-md-8">
                    <input type="text" formControlName="subTitle" class="form-control" id="subTitleText">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="onFilter()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="reset()">Clear Filters</button>
    </div>
</ng-template>