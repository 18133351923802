import { CommonService } from "src/app/services/common.service";

export class CommonProvider {
    public getAlertTypes():any{
        var alertTypes = [
            {"text":"Payout failed","value":"payout_failed"},
            {"text":"Stripe update failed","value":"stripe_update_failed"},
            {"text":"Payment intent create failed","value":"payout_intent_create_failed"},
            {"text":"Charge failed","value":"charge_failed"},
            {"text":"Deposit charge failed","value":"deposit_charge_failed"},
            {"text":"Stripe create failed","value":"stripe_create_failed"},
            {"text":"Stripe person create failed","value":"stripe_person_create_failed"},
            {"text":"Boatflex insurance","value":"boatflex_insurance"},
            {"text":"Booking creation failed","value":"booking_creation_failed"}
        ];
        return alertTypes;
    }
    public getFollowUpTask():any{
        var followUpTask = [
            {"text":"Captained booking","value":"Captained booking"},
            {"text":"Damage or insurance issue","value":"Damage or insurance issue"},
            {"text":"Long period booking lead","value":"Long period booking lead"},
            {"text":"Offline booking","value":"Offline booking"},
            {"text":"Short period booking lead","value":"Short period booking lead"},
            {"text":"Unpublished boats","value":"Unpublished boats"},
            {"text":"User help","value":"User help"},
            {"text":"Website issues","value":"Website issues"}
        ];
        return followUpTask;
    }
    public getClosureReason():any{
        var closureReason = [
            {"text":"Boat is already rented","value":"Boat is already rented"},
            {"text":"Boat is damaged","value":"Boat is damaged"},
            {"text":"Owner is not responsive","value":"Owner is not responsive"},
            {"text":"Owner is unavailable","value":"Owner is unavailable"},
            {"text":"Owner uses boat","value":"Owner uses boat"},
            {"text":"Renter/request not approved by owner","value":"Renter/request not approved by owner"}
        ];
        return closureReason;
    }
}
