<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>Section info</h5>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped">
                    <thead>
                        <th class="bt-none">Field</th>
                        <th class="bt-none">Value</th>
                    </thead>
                    <tbody
                        *ngIf="userSections != null && userSections != undefined && userSections.length > 0 else noRecord ">
                        <tr *ngFor="let userSection of userSections">
                            <td>{{userSection.field}}</td>
                            <td>{{userSection.value}}</td>
                        </tr>
                    </tbody>
                    <ng-template #noRecord>
                        <p>No records found.</p>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>