import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report/report.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-boat-statistics',
  templateUrl: './boat-statistics.component.html',
  styleUrls: ['./boat-statistics.component.css']
})

export class BoatStatisticsComponent implements OnInit {
  boatStatisticsDetail: any;
  constructor(private reportService: ReportService, private tostr: ToastrService) {

  }

  ngOnInit(): void {
    this.getBoatStatistics();
  }

  getBoatStatistics() {
    this.reportService.fetchBoatStatistics()
      .subscribe(
        response => {
          this.boatStatisticsDetail = response;
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
  }

}
