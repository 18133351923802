import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client';
import { BaseService } from "../app/shared/base.service";
import { ConfigService } from '../app/shared/config.service';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as Oidc from 'oidc-client';

@Injectable({
  providedIn: 'root'
})

export class LoginService extends BaseService {
  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();
  private manager = new UserManager(getClientSettings());
  private user: any;
  constructor(private router: Router, private httpClient: HttpClient, private configService: ConfigService) {
    super();
    this.manager.getUser().then(user => {
      this.user = user;
      this._authNavStatusSource.next(this.isAuthenticated());
    });

    //Oidc.Log.logger = console
    this.manager.events.addAccessTokenExpiring(x => {
      this.renewToken().then(u => {
      });
    });

    this.manager.events.addUserSignedOut(() => {
      this.signout();
    });

    this.manager.events.addUserSessionChanged(() => {
      this.renewToken().then(u => {
      });
    });
  }

  login() {
    return this.manager.signinRedirect();
  }

  async completeAuthentication() {
    this.user = await this.manager.signinRedirectCallback();
    this._authNavStatusSource.next(this.isAuthenticated());
  }

  register(userRegistration: any) {
    return this.httpClient.post(this.configService.authApiURI + '/account', userRegistration).pipe(catchError(this.handleError));
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  get authorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  get name(): string {
    return this.user != null ? this.user.profile.name : '';
  }
  get email(): string {
    return this.user != null ? this.user.profile.email : '';
  }
  get userId(): string {
    return this.user != null ? this.user.profile.sub : '';
  }
  get Id(): number {
    return this.user != null ? this.user.profile.id : 0;
  }
  async signout() {
    //await this.manager.signoutRedirect();

    await this.manager.signoutRedirect({ id_token_hint: this.user && this.user.id_token }).catch((reason) => {
      console.error(reason)
    })
  }

  public renewToken() {
    return this.manager.signinSilent().then(u => {
      this.user = u;
    }).catch(er => {
      console.log(er);
      this.signout();
    });
  }
}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: environment.authority,
    client_id: 'boatflexSPA',
    redirect_uri: environment.redirect_uri,
    post_logout_redirect_uri: environment.post_logout_redirect_uri,
    revokeAccessTokenOnSignout: true,
    response_type: "id_token token",
    scope: "openid profile email api:read",
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    checkSessionInterval: 1000,
    monitorSession: false,
    includeIdTokenInSilentRenew: true,
    silent_redirect_uri: environment.silentRefreshRedirectUri,
    accessTokenExpiringNotificationTime: 60,
    userStore: new WebStorageStateStore({ store: window.localStorage })
  };
}
