<div class="container-fluid">
    <h2 class=" mt-2 mb-4">Booking requests</h2>
    <div class="row mb-3">
        <div class="col-md-2 delete-btn">
            <div ngbDropdown class="d-inline-block pb-2">
                <button class="btn btn-outline-secondary" id="dropdownBasic1" [disabled]="isDisableBatch"
                    ngbDropdownToggle>Batch Action</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="deleteBoats()">Delete selected</button>
                </div>
            </div>
        </div>
        <div class="col-md-10">
            <button class="btn btn-secondary float-right" (click)="open(mymodal)">Filters</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="form-check mb-2">
                                    <input type="checkbox" name="ckboxGlobal" [checked]="isGlobalChecked"
                                        (change)="globalCheckboxChange($event)">
                                </div>
                            </th>
                            <th>
                                <a (click)="toggleSort('Id')" style="cursor: pointer;">Id</a>
                            </th>
                            <th>
                                Renter
                            <th>
                                <a (click)="toggleSort('LocationName')" style="cursor: pointer;">Location Name</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('BoatType')" style="cursor: pointer;">Boat Type</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('Guests')" style="cursor: pointer;">Guests</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('StartsOn')" style="cursor: pointer;">Starts On</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('EndsOn')" style="cursor: pointer;">Ends On</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('Status')" style="cursor: pointer;">Status</a>
                            </th>
                            <th>
                                <a (click)="toggleSort('CreatedAt')" style="cursor: pointer;">Created At</a>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="bookingRequestDetails != undefined  && bookingRequestDetails.totalEntries > 0">
                        <tr
                            *ngFor="let booking of bookingRequestDetails.data | paginate: { itemsPerPage: pageSize, currentPage: page,totalItems:bookingRequestDetails.totalEntries}">
                            <td>
                                <div class="form-check mb-2">
                                    <input type="checkbox" value="{{booking.id}}" [(ngModel)]="booking.selected"
                                        (change)="checkboxChange($event,booking.id)">
                                </div>
                            </td>
                            <td><a routerLink="../booking-request-view"
                                    [queryParams]="{id: booking.id}">{{booking.id}}</a></td>
                            <td><a (click)="gotoUser(booking.renterFirstName,booking.renterLastName,booking.renterId)"
                                    class="text-primary"
                                    style="text-decoration: underline;cursor: pointer;">{{booking.renterFirstName}}
                                    {{booking.renterLastName}}</a></td>
                            <td>{{booking.locationName}} </td>
                            <td>{{booking.boatType}} </td>
                            <td>{{booking.guests}} </td>
                            <td>{{booking.startsOn}} </td>
                            <td>{{booking.endsOn}} </td>
                            <td>
                                <p [style.backgroundColor]="booking.statusColour" style="color: #ffffff;">
                                    {{booking.status.toUpperCase()}}</p>
                            </td>
                            <td>{{booking.createdAt}} </td>
                            <td><a routerLink="../booking-request-view" [queryParams]="{id: booking.id}"><button
                                        type="submit" title="Click to View" class="btn"><i
                                            class="bi bi-eye"></i></button></a>
                                <button type="submit" title="Click to Delete" (click)="onDelete(booking.id)"
                                    class="btn"><i class="bi bi-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-lg-3">
            <app-export-data [exportFileName]="exportFileName"></app-export-data>
        </div>
        <div class="col-lg-6">
            <div
                *ngIf="bookingRequestDetails != undefined && bookingRequestDetails.totalEntries != undefined && bookingRequestDetails.totalEntries > 0">
                <pagination-controls (pageChange)="getPage(page = $event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header bg-dark">
        <h4 class="modal-title" id="modal-basic-title" style="color: #ffffff;">Filters</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body bg-light p-4">
        <form [formGroup]="frmFilter" id="filter">
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="location">LOCATION NAME</label>
                </div>
                <div class="col-md-8">
                    <input type="text" class="form-control" formControlName="locationName" id="location">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label for="bType">BOAT TYPE</label>
                </div>
                <div class="col-md-8" *ngIf="boatTypes != null && boatTypes.length > 0">
                    <select formControlName="boatType" id="bType" class="form-control" ng-options="">
                        <option *ngFor="let type of boatTypes" value={{type}}>{{type}}</option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" style="justify-content:center;">
        <button type="button" class="btn btn-outline-dark" (click)="submit()">Filter</button>
        <button type="button" class="btn btn-outline-secondary" (click)="clearFilter()">Clear Filters</button>
    </div>
</ng-template>