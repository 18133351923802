export const environment = {
  production: false,
  //apiEndpoint: 'https://api.boatflex.com/api/',
  apiEndpoint: 'https://api-dev.boatflex.com/api/',
  //apiEndpoint: 'https://boatflex-api.azurewebsites.net/api/',
  // apiEndpoint: 'https://localhost:44322/api/',
  //tokenApiEndPoint: "https://identity.boatflex.com",
  tokenApiEndPoint: "https://identity-dev.boatflex.com",
  //tokenApiEndPoint: "https://boatflex-identity.azurewebsites.net",
  // tokenApiEndPoint: "https://localhost:5005",
  //authority: 'https://identity.boatflex.com',
  authority: 'https://identity-dev.boatflex.com',
  //authority: 'https://boatflex-identity.azurewebsites.net',
  // authority: 'https://localhost:5005',
  redirect_uri: 'http://localhost:4200/auth-callback',
  //post_logout_redirect_uri: 'https://admin.boatflex.com',
  //post_logout_redirect_uri: 'https://boatflex-admin.azurewebsites.net',
  post_logout_redirect_uri: 'http://localhost:4200S',
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  fileStackKey: 'AHr4ahBWnTFuf3VjTVDfZz',
  poEditor_api_token: '4d3ec5b8062fca569a303dca1c8bc432',
  poEditor_api_base_uri: 'https://api.poeditor.com/v2/',
  poEditor_project_id: 477095
};
