import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BoatService } from 'src/app/services/boat.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-boats',
  templateUrl: './user-boats.component.html',
  styleUrls: ['./user-boats.component.css']
})
export class UserBoatsComponent implements OnInit {
  boats: any;
  id: number = 0;
  @Input() name;

  constructor(private boatService: BoatService, private route: ActivatedRoute, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.boatService.fetchUserBoats(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.boats = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }

}
