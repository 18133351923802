import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoatService } from 'src/app/services/boat.service';
import { DatePipe } from '@angular/common'
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { TripTypeService } from 'src/app/services/trip-type/trip-type.service';

@Component({
  selector: 'app-boat-edit',
  templateUrl: './boat-edit.component.html',
  styleUrls: ['./boat-edit.component.css']
})

export class BoatEditComponent implements OnInit {
  id: number = 0;
  frmEditBoat: FormGroup;
  assignees: any;
  boatDetails: any;
  tripTypes: any;
  tripTypeDropdownSettings: any;

  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private router: Router,
    private boatService: BoatService, public datepipe: DatePipe, private tripTypeService: TripTypeService,
    private commonService: CommonService, private tostr: ToastrService) {
    this.frmEditBoat = this.fb.group({
      followupdate: [''],
      assignee: [0],
      tripType: ['']
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    // this.getAssignees();
    this.getBoatDetails();
    this.getTripTypes();

    this.tripTypeDropdownSettings = {
      singleSelection: false,
      idField: 'tripTypeId',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All'
    };
  }

  getBoatDetails() {
    this.boatService.fetchFollowUpDate(this.id).subscribe(
      res => {
        this.boatDetails = res;
        console.log(res);
        this.frmEditBoat.setValue({
          followupdate: this.datepipe.transform(this.boatDetails.followupDate, "MM/dd/yyyy"),
          assignee: this.boatDetails.assigneeId,
          tripType: this.boatDetails.boatTripTypes
        });
      },
      error => {
        this.tostr.error("Couldn't load boat details.");
        console.log(error);
      }
    );
  }

  getAssignees() {
    this.commonService.fetchAssignees().subscribe(
      res => {
        this.assignees = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  getTripTypes() {
    this.tripTypeService.fetchAllTripTypes().subscribe(
      res => {
        this.tripTypes = res;
        console.log(res);
      },
      error => {
        console.log(error);
      }
    );
  }

  reset() {
    this.router.navigate(["admin/boats"]);
  }

  submit() {
    var boat = {
      boatId: this.id,
      // assigneeId: this.frmEditBoat.controls["assignee"].value,
      // followupDate: this.frmEditBoat.controls["followupdate"].value,
      tripTypes: this.frmEditBoat.controls["tripType"].value
    };
    this.boatService.updateBoat(boat).subscribe(
      res => {
        if (res.toString() == "Success") {
          this.tostr.success(res.toString());
          this.router.navigate(['admin/boats']);
        }
        else  {
          this.tostr.error(res.toString());
        }
      },
      err => {
        this.tostr.error("Something went wrong, please try again.")
        console.log("hello" + err);
      });
  }

}
