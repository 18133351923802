import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BankAccountService } from 'src/app/services/bank-account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-payout',
  templateUrl: './user-payout.component.html',
  styleUrls: ['./user-payout.component.css']
})
export class UserPayoutComponent implements OnInit {
  bankAccount: any;
  payouts: any;
  id: number = 0;

  constructor(private bankService: BankAccountService, private route: ActivatedRoute, private tostr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => { this.id = param.id });
    this.bankService.fetchBankAccount(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.bankAccount = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
    this.bankService.fetchUserPayout(this.id).pipe(finalize(() => {
    })).subscribe(
      res => {
        this.payouts = res;
      },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      }
    );
  }

}