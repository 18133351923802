import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BookingRequestService } from 'src/app/services/booking-request.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-booking-request-view',
  templateUrl: './booking-request-view.component.html',
  styleUrls: ['./booking-request-view.component.css']
})
export class BookingRequestViewComponent implements OnInit {
  id: number = 0;
  bookingRequestDetails: any;
  public resourceType: string = "";
  public resourceId: string = "";

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private tostr: ToastrService, private bookingRequest: BookingRequestService,
    private userService: UsersService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => { this.id = params.id });
    this.resourceId = this.id.toString();
    this.resourceType = "booking-Request";

    this.bookingRequest.fetchUserBookingRequest(this.id).subscribe(
      response => {
        this.bookingRequestDetails = response;
      },
      error => {
        console.log(error);
        this.tostr.error("Something went wrong, please try again.");
      }
    );
  }

  deleteBookingRequest() {
    var isDelete = confirm("are you sure want to delete selected booking request?");
    if (isDelete) {
      this.bookingRequest.deleteBookingRequest([this.id]).subscribe(
        response => {
          this.tostr.success("Deleted Successfully.");
          this.router.navigate(["admin/booking-requests"]);
        },
        error => {
          console.log(error);
          this.tostr.error("Something went wrong, please try again.");
        }
      );
    }
  }

  gotoUser(fName: string, lName: string, id: number) {
    this.userService.fName = fName;
    this.userService.fullName = fName + " " + lName;
    this.router.navigate(["admin/user-view"], { queryParams: { id: id } })
  }
}
