import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users/users.service';
import { ConfirmPasswordValidator } from 'src/app/shared/customValidator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  frmEditUser: FormGroup;
  id: number = 0;
  UserDetails;
  marketingSource: string[] = ["friend",
    "another_boat_owner",
    "boat_show",
    "facebook",
    "box_flyer",
    "google_ad",
    "instagram",
    "linkedin",
    "facebook_ad",
    "magazine",
    "newspaper",
    "tv",
    "radio",
    "seilmagasinet",
    "batliv",
    "hamnen",
    "minbaad",
    "other"];
  constructor(private fb: FormBuilder, private usersService: UsersService,
    private router: Router, private route: ActivatedRoute,
    private tostr: ToastrService) {
    this.frmEditUser = this.fb.group({
      email: ['', [Validators.required]],
      password: [''],
      confirmPassword: [''],
      // email: ['', [Validators.required, Validators.email]],
      // password: ['', [Validators.required, Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}')]],
      // confirmPassword: ['', Validators.required],
      renterServiceFee: [''],
      ownerServiceFee: [''],
      marketingSource: ['select'],
      marketingSourceNote: [''],
      isBoatOwnerChbx: [],
      isSuperHostChbx: [],
      hasSailCertificateChbx: [],
      boatRentalInterestchkbx: [],
      number: []
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => { this.id = params.id });
    this.usersService.fetchUser(this.id).pipe(finalize(() => {
    })).subscribe(res => {
      this.UserDetails = res;
      this.setFormValues();
    },
      error => {
        this.tostr.error("Error occurred while fetching data.");
        console.log(error);
      });
  }
  setFormValues() {
    if (this.UserDetails != null && this.usersService != undefined) {
      this.frmEditUser.setValue({
        email: this.UserDetails.email,
        password: this.UserDetails.password == undefined ? null : this.UserDetails.password,
        confirmPassword: this.UserDetails.confirmPassword == undefined ? null : this.UserDetails.confirmPassword,
        renterServiceFee: this.UserDetails.renterServiceFeePercent,
        ownerServiceFee: this.UserDetails.ownerServiceFeePercent,
        marketingSource: this.UserDetails.marketingSource,
        marketingSourceNote: this.UserDetails.marketingSourceNote,
        isBoatOwnerChbx: this.UserDetails.isBoatOwner,
        isSuperHostChbx: this.UserDetails.isSuperHost,
        hasSailCertificateChbx: this.UserDetails.hasSailCertificate,
        boatRentalInterestchkbx: this.UserDetails.hasBoatRentalInterest,
        number: this.UserDetails.phoneNumber
      });
    }
  }
  onReset() {
    this.router.navigate(["admin/users"]);
  }
  onSubmit() {
    if (this.frmEditUser.valid) {
      var userEditRequest = {
        "Id": this.UserDetails.id,
        "Email": this.frmEditUser.controls["email"].value,
        "OwnerServiceFeePercent": this.frmEditUser.controls["ownerServiceFee"].value,
        "RenterServiceFeePercent": this.frmEditUser.controls["renterServiceFee"].value,
        "IsBoatOwner": this.frmEditUser.controls["isBoatOwnerChbx"].value,
        "IsSuperHost": this.frmEditUser.controls["isSuperHostChbx"].value,
        "HasSailCertificate": this.frmEditUser.controls["hasSailCertificateChbx"].value,
        "HasBoatRentalInterest": this.frmEditUser.controls["boatRentalInterestchkbx"].value,
        "MarketingSource": this.frmEditUser.controls["marketingSource"].value,
        "MarketingSourceNote": this.frmEditUser.controls["marketingSourceNote"].value,
        "PhoneNumber": this.frmEditUser.controls["number"].value,
        "Password": this.frmEditUser.controls["password"].value
      };
      this.usersService.editUser(userEditRequest).subscribe(
        response => {
          this.tostr.success("User data Updated Successfully.");
          this.router.navigate(["admin/users"]);
        },
        error => {
          this.tostr.error("Something went wrong! while updating user data.");
          console.log(error);
        }
      );
      var updatePassRequest = {
        "Email": this.frmEditUser.controls["email"].value,
        "Password": this.frmEditUser.controls["password"].value
      };
      if (updatePassRequest.Password != null) {
        this.usersService.updatePassword(updatePassRequest).subscribe(
          response => {
            this.tostr.success("Password Updated Successfully.");
            this.router.navigate(["admin/users"]);
          },
          error => {
            console.log(error);
            this.tostr.error("Something went wrong! while updating password.");

          }
        );
      }
    }
  }
}
